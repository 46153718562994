import { API } from 'src/api/API';
import { SiteObject } from 'src/models/SiteObject';
import { mutate } from 'swr';

export type EditObjectDTO = {
    name: string;
    longitude: number | null;
    latitude: number | null;
    aTemp?: number;
    buildYear: number;
    floorsAboveGround?: number;
    floorsBelowGround?: number;
    roofHeight?: number;
    outsideWalls?: number;
    shapeOfHouse?: number;
    co2EmissionFactorHeating: number;
    co2EmissionFactorElectricity: number;
};

export const useEditObject = () => {
    const update = async (
        objectId: number,
        object: EditObjectDTO,
    ): Promise<SiteObject> => {
        return new API().updateObject(objectId, object).then(async (res) => {
            await mutate(`/objects/${objectId}`);
            await mutate(`/sites`);
            return res;
        });
    };
    return [update] as const;
};
