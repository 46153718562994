import { env } from 'src/utils/env';
import {
    GoogleMap,
    useJsApiLoader,
    OverlayViewF,
    OverlayView,
    Marker,
} from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';

interface IProps {
    markCenter?: boolean;
    static?: boolean;
    zoom?: number;
    center?: {
        lng: number;
        lat: number;
    };

    children?: React.ReactChild | React.ReactNode;
}

export const Map = (props: IProps) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: env.REACT_APP_GOOGLE_API_KEY,
    });
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (isLoaded) {
            setReady(true);
        }
    }, [isLoaded]);

    return (
        <div style={{ height: 400 }}>
            {isLoaded && (
                <GoogleMap
                    id="overlay-view-example"
                    mapContainerStyle={{ height: '400px' }}
                    center={props.center}
                    zoom={props.zoom || 11}
                    options={{
                        styles: require('./MapStyle.json'),
                        draggableCursor: 'default',
                        fullscreenControl: false,
                        zoomControl: props.static !== true,
                        draggable: props.static !== true,
                    }}
                >
                    {ready && props.markCenter && props.center !== undefined && (
                        <MapItem lat={props.center.lat} lng={props.center.lng}>
                            <Marker
                                position={{
                                    lat: props.center.lat,
                                    lng: props.center.lng,
                                }}
                            />
                        </MapItem>
                    )}
                    {props.children}
                </GoogleMap>
            )}

            {!isLoaded && <p>Loading...</p>}
        </div>
    );
};

interface MapItemProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    > {
    lng: number | null;
    lat: number | null;
}

export const MapItem = (props: MapItemProps) => {
    const position = {
        lat: props.lat!,
        lng: props.lng!,
    };

    return (
        <OverlayViewF
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={position}
        >
            <div {...{ ...props, children: undefined }}>{props.children}</div>
        </OverlayViewF>
    );
};
