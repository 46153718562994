import { StatusCode } from 'src/models/ModbusStatusCode';
import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';

export const useAsyncQueueStatus = (objectId: number) => {
    return useSWR<StatusCode>(
        `objects/${objectId}/async-queue/status`,
        swrFetch,
    );
};
