import {
    Col,
    Row,
    Typography,
    Form,
    Input,
    Button,
    Spin,
    notification,
    message,
    Tabs,
} from 'antd';
import { useAddCorporation } from 'src/hooks/corporations/useAddCorporation';
import { useSignUpUser } from 'src/hooks/sites/useSignUpUser';
import { useDeleteCorporation } from 'src/hooks/corporations/useDeleteCorporation';
import { useMe } from 'src/hooks/users/useMe';
import { useSignIn } from 'src/hooks/users/useSignIn';
import { useSignOut } from 'src/hooks/users/useSignOut';
import { useAddUser } from 'src/hooks/users/useAddUser';
import { useUsers } from 'src/hooks/users/useUsers';
import { useSites } from 'src/hooks/users/useSites';
import { useAddSite } from 'src/hooks/sites/useAddSite';
import { useForgotPassword } from 'src/hooks/users/useForgotPassword';
import { useAddObject } from 'src/hooks/sites/useAddObject';
import { useDesiredTemperatures } from 'src/hooks/objects/useDesiredTemperatures';
import { useUpdateDesiredTemperature } from 'src/hooks/objects/useUpdateDesiredTemperatures';
import TextArea from 'antd/lib/input/TextArea';
import { useTemperatures } from 'src/hooks/objects/useTemperatures';
import { useRealTimeHistory } from 'src/hooks/objects/useRealTimeHistory';

const errorMessage = (title: string, messsage: string) => {
    notification.open({
        message: title,
        type: 'error',
        description: messsage,
        placement: 'bottomRight',
    });
};

function SensorHistoryView(props: { objectId: number }) {
    const history = useRealTimeHistory(props.objectId);
    return <pre> {JSON.stringify(history.data, null, 4)} </pre>;
}

function ObjectView(props: { objectId: number }) {
    const { data: desiredTemperatures } = useDesiredTemperatures(
        props.objectId,
        0,
    );
    const [setDesiredTemperatures] = useUpdateDesiredTemperature();
    const { data: temperatures } = useTemperatures(props.objectId, 0);

    return (
        <div className="w-full h-screen overflow-scroll">
            <Row>
                <Typography.Title>ObjectId {props.objectId}</Typography.Title>
            </Row>
            <div>
                <SensorHistoryView objectId={props.objectId} />
            </div>
            <Row>
                <Col span={8}>
                    <Typography.Title level={3}>
                        useDesiredTemperatures
                    </Typography.Title>
                    {JSON.stringify(desiredTemperatures)}
                </Col>
                <Col span={8}>
                    <Typography.Title level={3}>
                        useFlowTemperatures
                    </Typography.Title>
                </Col>
                <Col span={8}>
                    <Typography.Title level={3}>
                        useTemperatures
                    </Typography.Title>
                    {JSON.stringify(temperatures)}
                </Col>
                <Col span={8}>
                    <Typography.Title level={3}>
                        useUpdateDesiredTemperature
                    </Typography.Title>
                    <Form
                        onFinish={(values) => {
                            let parsed;
                            try {
                                parsed = JSON.parse(values.data);
                            } catch {
                                errorMessage(
                                    'Not able to parse json',
                                    values.data,
                                );
                            }

                            if (parsed)
                                setDesiredTemperatures(
                                    props.objectId,
                                    parsed,
                                    0,
                                );
                        }}
                    >
                        <Form.Item name="data">
                            <TextArea placeholder='{"-5": 20, "0": 10, "5": 10}' />
                        </Form.Item>
                        <Button htmlType="submit">update</Button>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

function DevPage() {
    const { data: me, isValidating: meIsLoading } = useMe();
    const [addCorporation] = useAddCorporation();
    const [deleteCorporation] = useDeleteCorporation();
    const [signUpUser] = useSignUpUser();
    const [signInUser] = useSignIn();
    const [signOut] = useSignOut();
    const [addUser] = useAddUser();
    const [addSite] = useAddSite();
    const [addObject] = useAddObject();
    const { data: sites } = useSites();
    const { data: users } = useUsers();

    const [forgotPassword] = useForgotPassword();

    return (
        <div className="p-3">
            <Typography.Title>Development</Typography.Title>
            <Tabs defaultActiveKey="0" className="h-full">
                <Tabs.TabPane tab="Default" key="0">
                    <Row>
                        <Typography.Title level={2}>Examples</Typography.Title>
                    </Row>
                    <Row gutter={[100, 0]}>
                        <Col xs={12} md={8} lg={6}>
                            <Typography.Title level={3}>
                                useSites
                            </Typography.Title>
                            {JSON.stringify(sites)}
                        </Col>
                        <Col xs={12} md={8} lg={6}>
                            <Typography.Title level={3}>
                                useUsers
                            </Typography.Title>
                            {users?.map((v) => (
                                <>
                                    {v.email + `(${v.id})`}
                                    <br />
                                </>
                            ))}
                        </Col>
                        <Col xs={12} md={8} lg={6}>
                            <Typography.Title level={3}>
                                useMe {meIsLoading && <Spin />}
                            </Typography.Title>
                            <Typography>email : {me?.user.email}</Typography>
                            <Typography>id: {me?.user.id}</Typography>
                            <Typography>
                                {me?.corporations
                                    .map((v) => v.name + `(${v.id})`)
                                    .join(',')}
                            </Typography>
                        </Col>
                        <Col xs={12} md={8} lg={6}>
                            <Typography.Title level={3}>
                                useAddCorporation
                            </Typography.Title>
                            <Form
                                onFinish={(form: any) => {
                                    addCorporation(form).catch((e) => {
                                        errorMessage(
                                            'Not able to add corp',
                                            e.message,
                                        );
                                    });
                                }}
                            >
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Name is required',
                                        },
                                    ]}
                                    name="name"
                                    label="name"
                                >
                                    <Input />
                                </Form.Item>
                                <Button htmlType="submit">submit</Button>
                            </Form>
                        </Col>
                        <Col xs={12} md={8} lg={6}>
                            <Typography.Title level={3}>
                                useDeleteCorporation
                            </Typography.Title>
                            <Form
                                onFinish={(form: any) => {
                                    deleteCorporation(form.id).catch((e) => {
                                        errorMessage(
                                            'Unable to delete',
                                            e.message,
                                        );
                                    });
                                }}
                            >
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'id is required',
                                        },
                                    ]}
                                    name="id"
                                    label="id"
                                >
                                    <Input />
                                </Form.Item>
                                <Button htmlType="submit">submit</Button>
                            </Form>
                        </Col>
                        <Col xs={12} md={8} lg={6}>
                            <Typography.Title level={3}>
                                useSignUpUser
                            </Typography.Title>
                            <Form
                                onFinish={(form: any) => {
                                    signUpUser(form).catch((msg) => {
                                        notification.open({
                                            message: 'Unable to sign up user',
                                            type: 'error',
                                            description: msg.message,
                                            placement: 'bottomRight',
                                        });
                                    });
                                }}
                            >
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'email is required',
                                        },
                                    ]}
                                    name="email"
                                    label="Email"
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'name is required',
                                        },
                                    ]}
                                    name="name"
                                    label="Name"
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'password is required',
                                        },
                                    ]}
                                    name="password"
                                    label="Password"
                                >
                                    <Input />
                                </Form.Item>
                                <Button htmlType="submit">submit</Button>
                            </Form>
                        </Col>
                        <Col xs={12} md={8} lg={6}>
                            <Typography.Title level={3}>
                                useSignIn
                            </Typography.Title>
                            <Form
                                onFinish={(form: any) => {
                                    signInUser(form.email, form.password)
                                        .then(() => {
                                            message.success(
                                                'Log in successfull. Please redirect or reload the page',
                                            );
                                        })
                                        .catch((e) => {
                                            errorMessage(
                                                'Not able to login',
                                                e.message,
                                            );
                                        });
                                }}
                            >
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'email is required',
                                        },
                                    ]}
                                    name="email"
                                    label="Email"
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'password is required',
                                        },
                                    ]}
                                    name="password"
                                    label="Password"
                                >
                                    <Input />
                                </Form.Item>
                                <Button htmlType="submit">submit</Button>
                            </Form>
                        </Col>
                        <Col xs={12} md={8} lg={6}>
                            <Typography.Title level={3}>
                                useSignOut
                            </Typography.Title>
                            <Form
                                onFinish={() => {
                                    signOut()
                                        .then(() => {
                                            message.success(
                                                'Sign out successful. Please redirect or reload the page',
                                            );
                                        })
                                        .catch((e) => {
                                            errorMessage(
                                                'Unable to sign out',
                                                e.message,
                                            );
                                        });
                                }}
                            >
                                <Button htmlType="submit">submit</Button>
                            </Form>
                        </Col>
                        <Col xs={12} md={8} lg={6}>
                            <Typography.Title level={3}>
                                useAddUser
                            </Typography.Title>
                            <Form
                                onFinish={(form: any) => {
                                    addUser(form.corporationId, form)
                                        .then(() => {
                                            message.success(
                                                'Added user successful. Please redirect or reload the page',
                                            );
                                        })
                                        .catch((e: any) => {
                                            errorMessage(
                                                'Unable to add user',
                                                e.message,
                                            );
                                        });
                                }}
                            >
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'email is required',
                                        },
                                    ]}
                                    name="email"
                                    label="Email"
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'corp id is required',
                                        },
                                    ]}
                                    name="corporationId"
                                    label="Corp Id"
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'name is required',
                                        },
                                    ]}
                                    name="name"
                                    label="Name"
                                >
                                    <Input />
                                </Form.Item>

                                <Button htmlType="submit">submit</Button>
                            </Form>
                        </Col>
                        <Col xs={12} md={8} lg={6}>
                            <Typography.Title level={3}>
                                useAddSite
                            </Typography.Title>
                            <Form
                                onFinish={(form: any) => {
                                    addSite(
                                        Number.parseInt(form.corporationId),
                                        form,
                                    ).catch((e) => {
                                        errorMessage(
                                            'Not able to add site',
                                            e.message,
                                        );
                                    });
                                }}
                            >
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Name is required',
                                        },
                                    ]}
                                    name="name"
                                    label="name"
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Name is required',
                                        },
                                    ]}
                                    name="corporationId"
                                    label="Corp id"
                                >
                                    <Input />
                                </Form.Item>
                                <Button htmlType="submit">submit</Button>
                            </Form>
                        </Col>
                        <Col>
                            <Typography.Title level={3}>
                                useForgotPassword
                            </Typography.Title>
                            <Form
                                onFinish={(form) => {
                                    forgotPassword(form.email)
                                        .then(() => {
                                            message.success(
                                                'Succesfully forgot password',
                                            );
                                        })
                                        .catch((e) => {
                                            errorMessage(
                                                'Not able to forget passowrd',
                                                e.getMessage(),
                                            );
                                        });
                                }}
                            >
                                <Form.Item name="email" label="Email">
                                    <Input />
                                </Form.Item>
                                <Button htmlType="submit">submit</Button>
                            </Form>
                        </Col>
                        <Col>
                            <Typography.Title level={3}>
                                useAddObject
                            </Typography.Title>
                            <Form
                                onFinish={(form) => {
                                    addObject(
                                        Number.parseInt(form.siteid),
                                        form,
                                    );
                                }}
                            >
                                <Form.Item name="name" label="Object name">
                                    <Input />
                                </Form.Item>
                                <Form.Item name="siteid" label="siteid">
                                    <Input />
                                </Form.Item>
                                <Button htmlType="submit">submit</Button>
                            </Form>
                        </Col>
                    </Row>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Objects" key="1">
                    <>
                        {sites?.map((site) => {
                            return site.objects?.map((o) => {
                                return <ObjectView objectId={o.id} />;
                            });
                        })}
                    </>
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
}

export default DevPage;
