import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';
import { useState } from 'react';

function useSiteObjectsStats(siteId: number) {
    const [isLoading, setIsLoading] = useState(true);
    const { data, error } = useSWR(
        `/sites/${siteId}/objects/stats`,
        async () => {
            try {
                const response = await swrFetch(
                    `/sites/${siteId}/objects/stats`,
                );
                setIsLoading(false);
                return response;
            } catch (error) {
                setIsLoading(false);
                throw error;
            }
        },
        {
            suspense: false,
        },
    );

    return {
        data,
        isLoading,
        isError: error,
    };
}

export default useSiteObjectsStats;
