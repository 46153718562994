import Auth from '@aws-amplify/auth';

export const useForgotPasswordSubmit = () => {
    const forgotPassword = (email: string, code: string, password: string) => {
        return Auth.forgotPasswordSubmit(email, code, password)
            .then((res) => {
                return res;
            })
            .catch((e) => {
                throw e;
            });
    };

    return [forgotPassword] as const;
};
