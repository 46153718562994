import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootReducer';
import * as d3 from 'd3';

interface DataPoint {
    PosX: number;
    PosY: number;
    SizeX: number;
    SizeY: number;
    SpaceNumber: number;
    TemplateId: number;
}

interface Props {
    data: DataPoint[];
}

interface FloorTemplate {
    Id: number;
    Name: string;
}

interface ViewTemplateProps {
    floorTemplates: FloorTemplate[];
}

const D3Rectangles: React.FC<Props> = ({ data }) => {
    const svgRef = useRef<SVGSVGElement | null>(null);

    useEffect(() => {
        if (!svgRef.current) return;

        const svg = d3.select(svgRef.current);
        const width = +svg.attr('width');
        const height = +svg.attr('height');

        svg.selectAll('*').remove();

        // Define scales
        const xScale = d3.scaleLinear().domain([0, 1]).range([0, width]);
        const yScale = d3.scaleLinear().domain([0, 1]).range([height, 0]);
        const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

        svg.selectAll('rect')
            .data(data)
            .enter()
            .append('rect')
            .attr('x', (d) => xScale(d.PosX))
            .attr('y', (d) => yScale(d.PosY + d.SizeY))
            .attr('width', (d) => xScale(d.SizeX))
            .attr('height', (d) => yScale(0) - yScale(d.SizeY))
            .attr('fill', (_, i) => colorScale(String(i)));

        svg.selectAll('text')
            .data(data)
            .enter()
            .append('text')
            .attr('x', (d) => xScale(d.PosX + d.SizeX / 2))
            .attr('y', (d) => yScale(d.PosY + d.SizeY / 2))
            .attr('dy', '0.35em')
            .attr('text-anchor', 'middle')
            .attr('fill', 'white')
            .text((d) => d.SpaceNumber);
    }, [data]);

    return <svg ref={svgRef} width={500} height={500} />;
};

const ViewAllTemplates: React.FC<ViewTemplateProps> = ({ floorTemplates }) => {
    const templateSpaces = useSelector(
        (state: RootState) => state.templateSpaces,
    );

    // Group data points by TemplateId
    const groupedData = templateSpaces.reduce((acc, dataPoint) => {
        if (!acc[dataPoint.TemplateId]) {
            acc[dataPoint.TemplateId] = [];
        }
        acc[dataPoint.TemplateId].push(dataPoint);
        return acc;
    }, {} as { [key: number]: DataPoint[] });

    const getTemplateName = (templateId: string): string => {
        return (
            floorTemplates.find(
                (floorTemplate) => floorTemplate.Id === Number(templateId),
            )?.Name || ''
        );
    };

    return (
        <div>
            {Object.entries(groupedData).map(
                ([templateId, dataPoints], index) => (
                    <div key={index} style={{ marginBottom: '20px' }}>
                        <h3>
                            {templateId} - {getTemplateName(templateId)}
                        </h3>
                        <D3Rectangles data={dataPoints} />
                    </div>
                ),
            )}
        </div>
    );
};

export default ViewAllTemplates;
