import { API } from 'src/api/API';
import { User } from 'src/models/User';
import { mutate } from 'swr';
import { useMe } from './useMe';

export const useUpdateUser = () => {
    const { mutate: m } = useMe();
    const update = async (user: User): Promise<User> => {
        return new API().updateUser(user).then((res) => {
            mutate(`/users/${res.id}`, res);
            m();
            return res;
        });
    };

    return [update] as const;
};
