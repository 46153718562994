import { API } from 'src/api/API';
import { mutate } from 'swr';
import { Corporation, CorporationRights } from 'src/models/Corporation';

export const useUpdateRights = () => {
    const update = async (
        coprorationId: number,
        userId: number,
        rights: CorporationRights[],
    ): Promise<Corporation> => {
        return new API()
            .updateCorporationRights(coprorationId, userId, rights)
            .then(async (res) => {
                mutate(`/corporations/${coprorationId}/users`, true);
                return res;
            });
    };

    return [update] as const;
};
