import React, { useState, useEffect } from 'react';
import { Table, Input, Button } from 'antd';
import { useReportsByObjectId } from 'src/hooks/reports/useReportsByObjectId';
import { useObject } from 'src/hooks/objects/useObject';
import { useQueryParams, withDefault, NumberParam } from 'use-query-params';
import { API } from 'src/api/API';

interface Report {
    reportName: string | undefined;
    reportUrl: string | undefined;
    objectId?: number;
    id: number;
    email?: string;
}

const ReportList: React.FC = () => {
    const [params] = useQueryParams({
        objectId: withDefault(NumberParam, -1),
    });

    const { data: object } = useObject(params.objectId);

    const { data: allReports } = useReportsByObjectId(params.objectId);
    const [mappedReports, setMappedReports] = useState<Report[]>([]);
    const [sendingEmail, setSendingEmail] = useState<number | null>(null);

    useEffect(() => {
        if (allReports) {
            const newMappedReports = allReports.map((report) => ({
                reportName: report.reportUrl.split('/').pop(),
                reportUrl: report.reportUrl,
                id: report.id,
                objectId: report.objectId,
                email: '', // Add an 'email' property for each report
            }));
            setMappedReports(newMappedReports);
        }
    }, [allReports]);

    // Handle email input change for a specific report
    const handleEmailChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        reportId: number,
    ) => {
        const updatedReports = mappedReports.map((report) => {
            if (report.id === reportId) {
                return {
                    ...report,
                    email: e.target.value,
                };
            }
            return report;
        });
        setMappedReports(updatedReports);
    };

    const handleSendEmail = async (report: Report) => {
        if (report.reportName && report.email) {
            try {
                setSendingEmail(report.id);
                await new API().sendEmail(
                    report.reportName,
                    report.email,
                    object,
                );
            } catch (error) {
                alert('Error while sending email');
            } finally {
                setSendingEmail(null);
            }
        }
    };

    const columns = [
        {
            title: 'Report Name',
            dataIndex: 'reportName',
            key: 'reportName',
            width: '40%',
            render: (text: string | undefined) => <div>{text}</div>,
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            key: 'email',
            width: '40%',
            render: (email: string | undefined, record: Report) => (
                <Input
                    type="email"
                    style={{ border: '1px solid #ccc' }}
                    value={email}
                    onChange={(e) => handleEmailChange(e, record.id)}
                />
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: '20%',
            render: (_: any, record: Report) => (
                <div>
                    <Button
                        type="primary"
                        onClick={() => handleSendEmail(record)}
                        loading={sendingEmail === record.id}
                    >
                        {sendingEmail === record.id ? 'Sending...' : 'Send'}
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div style={{ margin: '15px' }}>
            <Table
                dataSource={mappedReports}
                columns={columns}
                pagination={false}
                bordered
            />
        </div>
    );
};

export default ReportList;
