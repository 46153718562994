import { Form, Input, Row, Col, Typography, Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAddSite } from 'src/hooks/sites/useAddSite';
import { useCorporations } from 'src/hooks/corporations/useCorporations';
import { useEditSite } from 'src/hooks/sites/useEditSite';
import { useNavigate } from 'react-router-dom';
import Nav from 'src/utils/nav/Nav';
import { Corporation } from 'src/models/Corporation';

interface Props {
    siteName?: string;
    siteId?: number;
}

function ManageSiteView(props: Props) {
    const { t } = useTranslation();
    const [addSite] = useAddSite();
    const [editSite] = useEditSite();
    const { data } = useCorporations();
    const history = useNavigate();

    const onSubmit = async (form: { name: string; corporationId: number }) => {
        if (props.siteName === undefined) {
            await addSite(form.corporationId, { name: form.name }).then(
                (site: Corporation) =>
                    history(
                        Nav.build({
                            path: '/sites/objects/:siteId',
                            routeParams: { siteId: site.id },
                            queryParams: {},
                        }),
                    ),
            );
        } else {
            await editSite(props.siteId!, { name: form.name }).then(() =>
                history(
                    Nav.build({
                        path: '/sites/objects/:siteId',
                        routeParams: { siteId: props.siteId! },
                        queryParams: {},
                    }),
                ),
            );
        }
    };

    const Option = Select.Option;

    return (
        <>
            <div className="  w-screen sm:w-auto p-2 sm:p-0">
                <Row>
                    <Col span={24} className="mb-3">
                        <Typography.Text strong type="secondary">
                            {t(
                                'A site is a collection of objects that makes organisation and finding your organisations objects easier',
                            )}
                        </Typography.Text>
                    </Col>

                    <Col span={24}>
                        <Form
                            layout="vertical"
                            onFinish={onSubmit}
                            initialValues={{ name: props?.siteName || null }}
                        >
                            <Row gutter={[20, 0]}>
                                <Col span={24}>
                                    <Form.Item
                                        label={t('Site name')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'Please select an email',
                                                ),
                                            },
                                        ]}
                                        name="name"
                                    >
                                        <Input name="name" />
                                    </Form.Item>
                                    {props.siteName === undefined && (
                                        <Form.Item
                                            required
                                            label={t('Corporation')}
                                            rules={[
                                                {
                                                    required:
                                                        props.siteId ===
                                                        undefined,
                                                    message: t(
                                                        'Please select a corporation',
                                                    ),
                                                },
                                            ]}
                                            name="corporationId"
                                        >
                                            <Select
                                                disabled={
                                                    props.siteName !== undefined
                                                }
                                            >
                                                {data &&
                                                    data.map((corp) => (
                                                        <Option
                                                            value={corp.id}
                                                            key={corp.id}
                                                        >
                                                            {corp.name}
                                                        </Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    )}
                                </Col>
                                <Col span={12}>
                                    <Button
                                        onClick={() => {
                                            history(-1);
                                        }}
                                        block
                                        size="large"
                                    >
                                        {t('Cancel')}
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button
                                        htmlType="submit"
                                        block
                                        size="large"
                                        type="primary"
                                    >
                                        {props.siteName === undefined
                                            ? t('Add site')
                                            : t('Update site')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ManageSiteView;
