import { Site } from 'src/models/Site';
import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';

export const useSitesAsc = () => {
    const { data: sites, error } = useSWR<Site[]>('/sites', swrFetch);

    // Function to sort sites by name in ascending order
    const sortSitesByName = (sites: Site[]) => {
        return sites.slice().sort((a, b) => a.name.localeCompare(b.name));
    };

    return {
        data: sites ? sortSitesByName(sites) : sites,
        isLoading: !error && !sites,
        isError: error,
    };
};
