import { FC, useState, useEffect } from 'react';
import { Menu, Typography, Slider } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { V3D_Object } from 'src/components/House3D/3d/chart/types';

interface PreferencesMenuProps {
    threshold: number;
    humidityLevel: number;
    setThreshold: (value: number) => void;
    setHumidityLevel: (value: number) => void;
    referenceTemperature: number;
    setReferenceTemperature: (temp: number) => void;
    data: V3D_Object;
    viewMode: string[];
    isHumidityDataAvailable: boolean;
}

const PreferencesMenu: FC<PreferencesMenuProps> = ({
    threshold,
    humidityLevel,
    setThreshold,
    setHumidityLevel,
    referenceTemperature,
    setReferenceTemperature,
    data,
    viewMode,
    isHumidityDataAvailable,
}) => {
    const { t } = useTranslation();

    const [isReferenceSet, setIsReferenceSet] = useState(false);

    useEffect(() => {
        if (data !== undefined && !isReferenceSet) {
            const min = Math.min(
                ...data.averageTemperatures.map((dp) => dp.fromTemperature),
            );
            const max = Math.max(
                ...data.averageTemperatures.map((dp) => dp.fromTemperature),
            );

            if (Number.isNaN(referenceTemperature)) {
                if (data.referenceTemperatures?.pseudoDOT) {
                    setReferenceTemperature(
                        Math.round(data.referenceTemperatures.pseudoDOT),
                    );
                    setIsReferenceSet(true);
                } else if (data.referenceTemperatures?.dot) {
                    setReferenceTemperature(
                        Math.round(data.referenceTemperatures.dot),
                    );
                    setIsReferenceSet(true);
                }
            } else if (!Number.isFinite(referenceTemperature)) {
                setReferenceTemperature(NaN);
                setIsReferenceSet(true);
            } else {
                if (min > referenceTemperature) {
                    setReferenceTemperature(min);
                    setIsReferenceSet(true);
                }
                if (max < referenceTemperature) {
                    setReferenceTemperature(max);
                    setIsReferenceSet(true);
                }
            }
        }
    }, [data, referenceTemperature, isReferenceSet, setReferenceTemperature]);

    const temperatureSelected = !!viewMode.some(
        (option) => option.toLowerCase() === 'temperature',
    );

    const conclusionSelected = !!viewMode.some(
        (option) => option.toLowerCase() === 'conclusion',
    );

    return (
        <Menu
            id="preferences"
            style={{
                width: 200,
                lineHeight: '23px',
                border: 'none',
            }}
            mode="horizontal"
        >
            <Menu.SubMenu
                title={
                    <span>
                        <EllipsisOutlined
                            style={{ transform: 'rotate(90deg)' }}
                        />
                        {t('Controls')}
                    </span>
                }
            >
                <div className="preferences-menu">
                    {(temperatureSelected || conclusionSelected) && (
                        <div className="preference-item">
                            <Typography.Text className="preference-label">
                                {t('Temperature level')}
                            </Typography.Text>
                            <Slider
                                className="preference-slider"
                                value={threshold}
                                step={0.5}
                                max={3}
                                min={0}
                                onChange={(v: number) => setThreshold(v)}
                            />
                        </div>
                    )}
                    {(isHumidityDataAvailable || conclusionSelected) && (
                        <div className="preference-item">
                            <Typography.Text className="preference-label">
                                {t('Humidity level')}
                            </Typography.Text>
                            <Slider
                                className="preference-slider"
                                value={humidityLevel}
                                step={0.05}
                                max={0.3}
                                min={0}
                                onChange={(v: number) => setHumidityLevel(v)}
                            />
                        </div>
                    )}
                    {data &&
                        data.blocks.length !== 0 &&
                        (temperatureSelected || conclusionSelected) && (
                            <div className="preference-item">
                                <Typography.Text className="preference-label">
                                    {t('Temperatur simulering')}
                                </Typography.Text>
                                <Slider
                                    className="preference-slider"
                                    step={1}
                                    min={Math.min(
                                        ...data.averageTemperatures.map(
                                            (dp: any) => dp.fromTemperature,
                                        ),
                                    )}
                                    max={Math.max(
                                        ...data.averageTemperatures.map(
                                            (dp: any) => dp.fromTemperature,
                                        ),
                                    )}
                                    value={referenceTemperature}
                                    onChange={(e: number) =>
                                        setReferenceTemperature(e)
                                    }
                                    disabled={Number.isNaN(
                                        referenceTemperature,
                                    )}
                                />
                            </div>
                        )}
                </div>
            </Menu.SubMenu>
        </Menu>
    );
};

export default PreferencesMenu;
