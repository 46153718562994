import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store/rootReducer';

export type SensorsState = {
    entities: number[];
    loading: 'idle' | 'pending';
    currentRequestId: string | undefined;
    error: any;
};

const initialState: SensorsState = {
    entities: [],
    loading: 'idle',
    currentRequestId: undefined,
    error: null,
};

export const sensorsSlice = createSlice({
    name: 'sensors',
    initialState,
    reducers: {
        setSensors: (state, action: PayloadAction<number[]>) => {
            state.entities = action.payload;
        },
        addSensorType: (state, action: PayloadAction<number>) => {
            state.entities.push(action.payload);
        },
    },
});

export const selectSensors = (state: RootState) => state.sensors;

// Slice action creators
export const { setSensors, addSensorType } = sensorsSlice.actions;

export default sensorsSlice.reducer;
