import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Typography, Affix, Avatar, Dropdown, Menu, Input } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useViewport from 'src/hooks/generic/useViewport';
import { useFavoriteObjects } from 'src/hooks/sites/useFavoriteObjects';
import { useFavoritesSites } from 'src/hooks/sites/useFavoriteSites';
import { Site } from 'src/models/Site';
import Nav from 'src/utils/nav/Nav';
import './SitesView.scss';

interface Props {
    sites: Site[];
    tabBarExtraContent?: React.ReactNode;
}
interface TitleRowProps {
    name: string;
    favorite?: boolean;
    id: number;
    numberOfChildren: number;

    handleFavChange: (id: number) => void;
}
interface SiteListProps {
    sites: Site[];
    handleFavObjChange: (id: number) => void;
    handleFavSiteChange: (id: number) => void;
    favoriteSites: number[];
}

const SiteList = (props: SiteListProps) => {
    const history = useNavigate();
    const { t } = useTranslation();
    const SiteRow = (props: TitleRowProps) => {
        return (
            <div className="pt-4 cursor-pointer w-full flex">
                <div
                    onClick={() => {
                        history(
                            Nav.build({
                                path: '/sites/objects/:siteId',
                                queryParams: {},
                                routeParams: { siteId: props.id },
                            }),
                        );
                    }}
                    className="flex flex-col w-full"
                >
                    <Typography.Text
                        style={{ fontSize: '20px', fontWeight: 200 }}
                        className=" float-left p-1"
                    >
                        <b>{props.name}</b>
                    </Typography.Text>
                    <Typography.Text
                        style={{ color: '#A1A5AA', fontWeight: 450 }}
                        className="text-l p-1"
                    >
                        {t('Number of objects')}{' '}
                        {props.numberOfChildren > 0
                            ? `${props.numberOfChildren}`
                            : undefined}
                    </Typography.Text>
                </div>
            </div>
        );
    };

    return (
        <>
            {props.sites.map((v, i) => {
                return (
                    <SiteRow
                        numberOfChildren={v.objects?.length || 0}
                        favorite={
                            props.favoriteSites.includes(v.id) ? true : false
                        }
                        handleFavChange={props.handleFavSiteChange}
                        id={v.id}
                        name={v.name}
                        key={v.id}
                    />
                );
            })}
        </>
    );
};

export default function SitesView(props: Props) {
    const { t } = useTranslation();
    const { sites } = props;
    const [siteList, setSiteList] = useState(sites);
    const [searchQuery, setSearchQuery] = useState('');
    const [setFavoriteObjects, favoriteObjects] = useFavoriteObjects();
    const [setFavoriteSites, favoriteSites] = useFavoritesSites();
    const { mode } = useViewport();
    const history = useNavigate();
    const { Search } = Input;

    const handleSearch = (value: string) => {
        setSearchQuery(value);
        const filteredSites = sites.filter((site) =>
            site.name.toLowerCase().includes(value.toLowerCase()),
        );
        setSiteList(filteredSites);
    };

    const handleFavObjectChange = (id: number) => {
        if (favoriteObjects.includes(id)) {
            setFavoriteObjects(
                favoriteObjects.filter((v) => {
                    return v !== id;
                }),
            );
        } else {
            const newFav = favoriteObjects.concat(id);
            setFavoriteObjects(newFav);
        }
    };

    const handelFavSiteChange = (id: number) => {
        if (favoriteSites.includes(id)) {
            setFavoriteSites(
                favoriteSites.filter((v) => {
                    return v !== id;
                }),
            );
        } else {
            const newFav = favoriteSites.concat(id);
            setFavoriteSites(newFav);
        }
    };

    return (
        <>
            <div
                id="search-sites"
                style={{ height: mode === 'mobile' ? '90vh' : 'auto' }}
            >
                <Search
                    prefix={<SearchOutlined />}
                    placeholder={t('Search site')}
                    allowClear
                    value={searchQuery}
                    onSearch={() => {}}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ marginBottom: '16px' }}
                />
                <SiteList
                    handleFavObjChange={handleFavObjectChange}
                    handleFavSiteChange={handelFavSiteChange}
                    sites={siteList}
                    favoriteSites={favoriteSites}
                />
            </div>
            {mode === 'mobile' && (
                <Affix
                    style={{
                        position: 'absolute',
                        top: '80%',
                        left: '80%',
                    }}
                >
                    <div>
                        <Dropdown
                            placement="topLeft"
                            trigger={['click', 'hover']}
                            arrow
                            overlay={
                                <Menu className="-translate-x-5" mode="inline">
                                    <Menu.Item
                                        onClick={() =>
                                            history(
                                                Nav.build({
                                                    path: '/manage-site',
                                                    queryParams: {},
                                                }),
                                            )
                                        }
                                    >
                                        {t('Add site')}
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Avatar
                                src={
                                    <PlusOutlined
                                        className="text-2xl"
                                        style={{ verticalAlign: 'middle' }}
                                    />
                                }
                                style={{ backgroundColor: '#51baa2' }}
                                size={65}
                                className="circle-shadow"
                            />
                        </Dropdown>
                    </div>
                </Affix>
            )}
        </>
    );
}
