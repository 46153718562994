import {
    Typography,
    Row,
    Col,
    Modal,
    Button,
    Form,
    Checkbox,
    Alert,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import logoSrc from 'src/assets/Logo.svg';
import { showError } from 'src/utils/showError';
import { PlusCircleOutlined } from '@ant-design/icons';
import { CorporationRights } from 'src/models/Corporation';
import { useUpdateRights } from 'src/hooks/corporations/useUpdateRights';

interface Props {
    visible: boolean;
    onCancel: () => void;
    onFinish: () => void;
    corporationId: number;
    userId: number;
}

export default function AddAccessDialog(props: Props) {
    const { visible, onCancel, onFinish, corporationId, userId } = props;
    const { t } = useTranslation();
    const [update] = useUpdateRights();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = (a: { rights: CorporationRights[] }) => {
        update(corporationId, userId, a.rights)
            .then(() => {
                onFinish();
            })
            .catch((e) => {
                showError(t('Not able to update rights'), e.message);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            <Modal
                className="round"
                visible={visible}
                onCancel={onCancel}
                footer={null}
                closable={false}
            >
                <Row>
                    <Col span={12}>
                        <Typography.Title level={4}>
                            {t('Set access rights')}
                        </Typography.Title>
                    </Col>
                    <Col span={12}>
                        <img
                            className="h-5 float-right"
                            src={logoSrc}
                            alt="Nrlyze logo"
                        />
                    </Col>
                </Row>
                <div className="mb-5">
                    <Alert
                        type="warning"
                        message={
                            <>
                                <Typography.Title level={4}>
                                    {t(
                                        'Action will change the access for the user',
                                    )}
                                </Typography.Title>
                                <Typography.Text>
                                    {t(
                                        'By continuing the users permissions will be updated. This will change what the user has access to. Always give the least amount of access possible.',
                                    )}
                                </Typography.Text>
                            </>
                        }
                    />
                </div>

                <Form
                    layout="vertical"
                    onFinish={(form: any) => {
                        onSubmit(form);
                    }}
                >
                    <div className="mb-12">
                        <Form.Item name={'rights'} style={{ marginBottom: 0 }}>
                            <Checkbox.Group>
                                <Checkbox
                                    value={CorporationRights.ALLOW_CREATE_SITE}
                                    defaultChecked={true}
                                >
                                    <b>{t('Can add new sites.')}</b>{' '}
                                    {t(
                                        'This allows the user to add new sites to this corporation without your permission.',
                                    )}
                                </Checkbox>
                                <br />

                                <Checkbox
                                    value={CorporationRights.DELETE_SITE}
                                    defaultChecked={true}
                                >
                                    <b>{t('Delete sites.')}</b>{' '}
                                    {t(
                                        'This allows the user to remove sites from this corporation without your permission.',
                                    )}
                                </Checkbox>
                                <br />

                                <Checkbox
                                    key={
                                        CorporationRights.ALLOW_CHANGE_SETTINGS
                                    }
                                    value={
                                        CorporationRights.ALLOW_CHANGE_SETTINGS
                                    }
                                    defaultChecked={true}
                                >
                                    <b>{t('Can change settings.')}</b>{' '}
                                    {t(
                                        'This allows the user to update settings (e.g. for devices and sensors)',
                                    )}
                                </Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>

                    <Row gutter={40}>
                        <Col span={12}>
                            <Form.Item>
                                <Button
                                    onClick={() => onCancel()}
                                    className="w-full"
                                    size="large"
                                    loading={isLoading}
                                >
                                    {t('Cancel')}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item>
                                <Button
                                    className="w-full"
                                    size="large"
                                    loading={isLoading}
                                    type="primary"
                                    htmlType="submit"
                                    icon={<PlusCircleOutlined />}
                                >
                                    {t('Add access')}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}
