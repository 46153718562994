import { useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { env } from 'src/utils/env';
// import loader from 'src/utils/mapsLoader';

export const usePlaceId = (
    long: number | null,
    lat: number | null,
    placeId: string | null,
) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: env.REACT_APP_GOOGLE_API_KEY,
    });
    const [geoCoder, setGeocoder] = useState<google.maps.Geocoder | null>(null);
    const [data, setData] = useState<string | null>(null);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        if (isLoaded) {
            setGeocoder(new google.maps.Geocoder());
        }
    }, [isLoaded]);

    useEffect(() => {
        const find = (
            long?: number | null,
            lat?: number | null,
            placeId?: string | null,
        ): Promise<string | null> => {
            if (!geoCoder) {
                throw new Error();
            }
            if (placeId !== null)
                return geoCoder.geocode({ placeId: placeId }).then((res) => {
                    return res.results[0].formatted_address;
                });
            else if (long && lat) {
                return geoCoder
                    .geocode({ location: { lat: lat, lng: long } })
                    .then((res) => {
                        return res?.results[0]?.formatted_address;
                    });
            } else {
                return Promise.resolve(null);
            }
        };
        if (geoCoder) {
            find(long, lat, placeId).then((address) => {
                setData(address);
                setIsDataLoaded(true);
            });
        }
    }, [geoCoder, lat, long, placeId]);

    return { data, isLoading: isLoaded, isDataLoaded };
};
