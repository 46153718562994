import { useNavigate } from 'react-router-dom';
import Nav from 'src/utils/nav/Nav';
import { Typography, Card, Button, Divider } from 'antd';

export default function F00() {
    const history = useNavigate();
    const { Title } = Typography;

    return (
        <div className="flex h-screen">
            <div className="w-screen bg-blue-500 h-screen lg:h-4/6 lg:w-2/6 m-auto">
                <Card className="w-full h-full ">
                    <Title level={1}>
                        Server
                        <br /> error
                    </Title>
                    <Typography>
                        Its seems that we have an internal sever error at our
                        end. Please try again soon.
                    </Typography>
                    <Divider />
                    <Button
                        onClick={() => {
                            history(
                                Nav.build({
                                    path: '/login',
                                }),
                            );
                        }}
                        className="float-right"
                    >
                        Go to login
                    </Button>
                </Card>
            </div>
        </div>
    );
}
