import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';

export const useHasMetryIntegration = (corporationId?: number) => {
    return useSWR<{ exists: boolean; isHidden: boolean }>(
        corporationId
            ? `external/metry/corporations/${corporationId}/integration_exists`
            : 'external/metry/integration_exists',
        swrFetch,
    );
};
