import { notification } from 'antd';

export const showError = (title: string, messsage: string) => {
    notification.open({
        message: title,
        type: 'error',
        description: messsage,
        placement: 'bottomRight',
    });
};
