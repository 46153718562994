// tableRowsSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TableRow {
    id: number;
    key: string;
    floorNumber: number;
    templateId: number;
}

interface TableRowsState {
    [blockIndex: number]: TableRow[];
}

const initialState: TableRowsState = {};

const tableRowsSlice = createSlice({
    name: 'tableRows',
    initialState,
    reducers: {
        setTableRows: (
            state,
            action: PayloadAction<{
                blockIndex: number;
                tableRows: TableRow[];
            }>,
        ) => {
            const { blockIndex, tableRows } = action.payload;
            state[blockIndex] = [...tableRows];
        },

        addToExistingBlockIndex: (
            state,
            action: PayloadAction<{
                blockIndex: number;
                tableRows: TableRow[];
            }>,
        ) => {
            const { blockIndex, tableRows } = action.payload;
            const existingRows = state[blockIndex] || [];
            state[blockIndex] = [...existingRows, ...tableRows];
        },

        clearTableRows: (state) => {
            // Reset state to initialState
            return { ...initialState };
        },
    },
});

export const {
    setTableRows,
    addToExistingBlockIndex,
    clearTableRows,
} = tableRowsSlice.actions;
export default tableRowsSlice.reducer;
