import { Skeleton } from 'antd';
import { Suspense } from 'react';
import { useControlStates } from 'src/hooks/objects/useControllStates';
import BalancingView from './Balancing3DView';

interface ThreeDModelProps {
    objectId: number;
}

function ThreeDModelView(props: ThreeDModelProps) {
    const { objectId } = props;
    const { data: controlStates } = useControlStates(objectId);

    return (
        <>
            {controlStates && (
                <Suspense fallback={<Skeleton />}>
                    <BalancingView
                        objectId={objectId}
                        controlStates={controlStates}
                    />
                </Suspense>
            )}
        </>
    );
}

export default ThreeDModelView;
