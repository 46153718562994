import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';

export const useMetryMeters = (corporationId?: number) => {
    return useSWR<{ name: string; id: string; metryIntegratorId: number }[]>(
        corporationId
            ? `external/metry/corporations/${corporationId}/meters`
            : `external/metry/meters`,
        swrFetch,
    );
};
