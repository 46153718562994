import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import EditCorporationFeatures from 'src/views/corporation/EditCorporationFeatures';
import {
    useCorporation,
    useCorporationUsers,
} from 'src/hooks/corporations/useCorporation';
import { Button, Col, message, Row, Typography } from 'antd';
import EditCorporationAccess from 'src/views/corporation/EditCorporationAcess';
import Nav from 'src/utils/nav/Nav';
import { useUsers } from 'src/hooks/users/useUsers';
import { useMe } from 'src/hooks/users/useMe';
import EditCorporationSites from 'src/views/corporation/EditCorporationSites';
import AddSiteDialog from 'src/views/corporation/AddSiteDialog';
import { useState } from 'react';
import useViewport from 'src/hooks/generic/useViewport';
import EditCorporationObject from 'src/views/corporation/EditCorporationObject';
import IntegratorsView from 'src/views/corporation/integrators/IntegratorsView';
import { TabTitle } from 'src/components/TabTitle';
import TabContainer from 'src/components/TabContainer';

function EditCorporation() {
    const { t } = useTranslation();
    const history = useNavigate();
    const { corporationId } = useParams<{ corporationId: string }>();
    const { isValidating, data: corporation } = useCorporation(
        Number.parseInt(corporationId!),
    );
    const { data: corporationsUsers } = useCorporationUsers(
        Number.parseInt(corporationId!),
    );

    const { data: allUsers } = useUsers();
    const { data: me } = useMe();
    const [addSite, setAddSite] = useState(false);
    const { mode } = useViewport();
    return (
        <>
            <AddSiteDialog
                corporationId={Number.parseInt(corporationId!)}
                onCancel={() => {
                    setAddSite(false);
                }}
                onSuccess={() => {
                    message.success(t('Successfully added new site'));
                    setAddSite(false);
                }}
                visible={addSite}
            />
            {corporation && (
                <Row gutter={[20, 20]}>
                    {corporationsUsers && allUsers && me && (
                        <Col span={24}>
                            {/* <Tabs
                                defaultActiveKey={tab ? tab : undefined}
                                onChange={setTab}
                            > */}
                            <TabContainer
                                children={[
                                    {
                                        tab: <TabTitle title={t('Basic')} />,

                                        body: (
                                            <div>
                                                <EditCorporationFeatures
                                                    corporation={corporation}
                                                    onDeleteCorporation={() => {
                                                        history(
                                                            Nav.build({
                                                                path:
                                                                    '/settings',
                                                                queryParams: {
                                                                    tab:
                                                                        'corporations',
                                                                },
                                                            }),
                                                        );
                                                    }}
                                                    isLoading={isValidating}
                                                />
                                            </div>
                                        ),
                                    },
                                    {
                                        tab: (
                                            <TabTitle
                                                title={t('Integrators')}
                                            />
                                        ),

                                        body: (
                                            <>
                                                <div className="float-left w-full">
                                                    <Typography.Title
                                                        className="float-left"
                                                        level={4}
                                                    >
                                                        {t('Integrators')}
                                                    </Typography.Title>
                                                </div>
                                                <div className="w-full md:w-2/3">
                                                    <IntegratorsView
                                                        corporationId={Number.parseInt(
                                                            corporationId!,
                                                        )}
                                                    />
                                                </div>
                                            </>
                                        ),
                                    },
                                    {
                                        tab: (
                                            <TabTitle title={t('Edit users')} />
                                        ),

                                        body: (
                                            <>
                                                <div className="float-left w-full">
                                                    <Typography.Title
                                                        className="float-left"
                                                        level={4}
                                                    >
                                                        {t('Users')}
                                                    </Typography.Title>
                                                </div>
                                                <EditCorporationAccess
                                                    corporation={corporation}
                                                    currentUser={me.user}
                                                    allUsers={allUsers}
                                                    users={corporationsUsers}
                                                />
                                            </>
                                        ),
                                    },
                                    {
                                        tab: (
                                            <TabTitle title={t('Edit sites')} />
                                        ),

                                        body: (
                                            <>
                                                <div className="float-left w-full">
                                                    <Typography.Title
                                                        className="float-left"
                                                        level={4}
                                                    >
                                                        {t('Sites')}
                                                    </Typography.Title>
                                                    <Button
                                                        onClick={() => {
                                                            setAddSite(true);
                                                        }}
                                                        className="float-right"
                                                    >
                                                        {t('Add new site')}
                                                    </Button>
                                                </div>
                                                <div>
                                                    <div
                                                        className={
                                                            mode === 'desktop'
                                                                ? 'mb-4 w-1/2'
                                                                : 'w-full'
                                                        }
                                                    >
                                                        <Typography.Text type="secondary">
                                                            {t(
                                                                'Here you can select which sites are avalible in this corporation. You can unselect the sites which no longer should be avalible to users in this corporation.',
                                                            )}
                                                        </Typography.Text>
                                                    </div>
                                                </div>

                                                <EditCorporationSites
                                                    corporationId={
                                                        corporation.id
                                                    }
                                                />
                                            </>
                                        ),
                                    },

                                    {
                                        tab: (
                                            <TabTitle
                                                title={t('Edit object')}
                                            />
                                        ),

                                        body: (
                                            <>
                                                <div className="float-left w-full">
                                                    <Typography.Title
                                                        className="float-left"
                                                        level={4}
                                                    >
                                                        {t('Objects')}
                                                    </Typography.Title>
                                                </div>
                                                <div>
                                                    <div
                                                        className={
                                                            mode === 'desktop'
                                                                ? 'mb-4 w-1/2'
                                                                : 'w-full'
                                                        }
                                                    >
                                                        <Typography.Text type="secondary">
                                                            {t(
                                                                'Here you can select which objects are avalible in this corporation.',
                                                            )}
                                                        </Typography.Text>
                                                    </div>
                                                </div>

                                                <EditCorporationObject
                                                    corporationId={
                                                        corporation.id
                                                    }
                                                />
                                            </>
                                        ),
                                    },
                                ]}
                            />
                            {/* <Tabs.TabPane
                                    key="basic"
                                    tab={<TabTitle title={t('Basic')} />}
                                >
                                    <EditCorporationFeatures
                                        corporation={corporation}
                                        onDeleteCorporation={() => {
                                            history(
                                                Nav.build({
                                                    path: '/settings',
                                                    queryParams: {
                                                        tab: 'corporations',
                                                    },
                                                }),
                                            );
                                        }}
                                        isLoading={isValidating}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    key="integrators"
                                    tab={<TabTitle title={t('Integrators')} />}
                                >
                                    <div className="float-left w-full">
                                        <Typography.Title
                                            className="float-left"
                                            level={4}
                                        >
                                            {t('Integrators')}
                                        </Typography.Title>
                                    </div>
                                    <div className="w-full md:w-2/3">
                                        <IntegratorsView
                                            corporationId={Number.parseInt(
                                                corporationId,
                                            )}
                                        />
                                    </div>
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    key="edit-users"
                                    tab={<TabTitle title={t('Edit users')} />}
                                >
                                    <div className="float-left w-full">
                                        <Typography.Title
                                            className="float-left"
                                            level={4}
                                        >
                                            {t('Users')}
                                        </Typography.Title>
                                    </div>
                                    <EditCorporationAccess
                                        corporation={corporation}
                                        currentUser={me.user}
                                        allUsers={allUsers}
                                        users={corporationsUsers}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    key="edit-sites"
                                    tab={<TabTitle title={t('Edit sites')} />}
                                >
                                    <div className="float-left w-full">
                                        <Typography.Title
                                            className="float-left"
                                            level={4}
                                        >
                                            {t('Sites')}
                                        </Typography.Title>
                                        <Button
                                            onClick={() => {
                                                setAddSite(true);
                                            }}
                                            className="float-right"
                                        >
                                            {t('Add new site')}
                                        </Button>
                                    </div>
                                    <div>
                                        <div
                                            className={
                                                screen === 'desktop'
                                                    ? 'mb-4 w-1/2'
                                                    : 'w-full'
                                            }
                                        >
                                            <Typography.Text type="secondary">
                                                {t(
                                                    'Here you can select which sites are avalible in this corporation. You can unselect the sites which no longer should be avalible to users in this corporation.',
                                                )}
                                            </Typography.Text>
                                        </div>
                                    </div>

                                    <EditCorporationSites
                                        corporationId={corporation.id}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    key="edit-objects"
                                    tab={<TabTitle title={t('Edit object')} />}
                                >
                                    <div className="float-left w-full">
                                        <Typography.Title
                                            className="float-left"
                                            level={4}
                                        >
                                            {t('Objects')}
                                        </Typography.Title>
                                    </div>
                                    <div>
                                        <div
                                            className={
                                                screen === 'desktop'
                                                    ? 'mb-4 w-1/2'
                                                    : 'w-full'
                                            }
                                        >
                                            <Typography.Text type="secondary">
                                                {t(
                                                    'Here you can select which objects are avalible in this corporation.',
                                                )}
                                            </Typography.Text>
                                        </div>
                                    </div>

                                    <EditCorporationObject
                                        corporationId={corporation.id}
                                    />
                                </Tabs.TabPane> */}
                            {/* </Tabs> */}
                        </Col>
                    )}
                </Row>
            )}
        </>
    );
}

export default EditCorporation;
