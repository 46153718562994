import { Button, Divider, Form, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SensorTypeSelector } from 'src/components/SensorTypeSelector';
import { SensorTypeSelectorHidden } from 'src/components/SensorTypeSelectorHidden';
import { IntegrationTag } from 'src/hooks/integrations/useListAllTagsOnIntegration';
import { Integration } from 'src/models/Integration';
import { Sensor, SensorType } from 'src/models/Sensor';
import { SensorTypeToTagMapping } from 'src/pages/SetupIntegrationPage';

const matchesSearchQuery = (identifiers: string[], searchQuery: string[]) => {
    if (searchQuery) {
        for (const query of searchQuery) {
            let found = false;

            for (const identifier of identifiers) {
                if (
                    identifier
                        .toLowerCase()
                        .trim()
                        .includes(query.toLowerCase().trim())
                ) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                return false;
            }
        }
    }

    return true;
};

const filter = (
    key: string,
    extra: string,
    list: IntegrationTag[],
    integration: Integration,
    searchQuery?: string[],
) => {
    const result: IntegrationTag[] = [];

    for (const tag of list) {
        const currentName =
            integration.integratorName === 'EcoGuard'
                ? `${key}:${tag.name}`
                : tag.name;
        const currentExtra =
            integration.integratorName === 'EcoGuard'
                ? `${extra}:${tag.extra}`
                : tag.extra;

        const identifiers: string[] = [currentExtra, currentName];

        if (
            integration.integratorName.toLowerCase() === 'proptechos' &&
            tag.kind
        ) {
            identifiers.push(tag.kind);
        }

        if (tag.children.length === 0) {
            if (
                searchQuery === undefined ||
                searchQuery.length === 0 ||
                matchesSearchQuery(identifiers, searchQuery)
            ) {
                result.push({
                    name: currentName,
                    extra: tag.extra,
                    id: tag.id,
                    key: tag.key,
                    apiIdentifier: tag.apiIdentifier || '',
                    kind: tag.kind || '',
                    children: [],
                });
            }
        } else {
            const children = filter(
                `${key}:${tag.name}`,
                `${extra}:${tag.extra}`,
                tag.children,
                integration,
                searchQuery,
            );

            if (children.length > 0) {
                result.push(...children);
            }
        }
    }

    return result;
};

const filteredTagTree = (
    tagTree: IntegrationTag,
    integration: Integration,
    searchQuery?: string[],
) => {
    return filter(
        tagTree.name,
        `${tagTree.extra}`,
        tagTree.children,
        integration,
        searchQuery,
    );
};

const getRegisteredSensorFromTag = (
    tag: IntegrationTag,
    integratedSensors: Sensor[],
) => {
    return integratedSensors.find(
        (sensor) => sensor.integratorDatapointId === tag.id,
    );
};

interface Props {
    tagTree: IntegrationTag;
    integration: Integration;
    searchQuery: string[] | undefined;
    onSubmit: any;
    integratedSensors: Sensor[];
    selectedTags: SensorTypeToTagMapping[];
    onSelect: (sensorType: SensorType, tag: IntegrationTag) => void;
    onSelectMany: (sensorType: SensorType, tags: IntegrationTag[]) => void;
}

function IntegratorTagTreeView(props: Props) {
    const {
        tagTree: debouncedTagTree,
        searchQuery,
        onSubmit,
        onSelect,
        onSelectMany,
        integration,
        integratedSensors,
        selectedTags,
    } = props;

    const { t } = useTranslation();

    const fitleredTree = filteredTagTree(
        debouncedTagTree,
        integration,
        searchQuery,
    ).slice(0, 100);

    const isProptech =
        integration.integratorName.toLowerCase() === 'proptechos';
    return (
        <>
            <Form
                onFinish={(values) => {
                    let result = [];

                    for (const sensorType in SensorType) {
                        const sensorFunctionType = Number.parseInt(
                            SensorType[sensorType],
                        );

                        result.push({
                            sensorFunctionType: sensorFunctionType,
                            sensorIdentifiers: selectedTags
                                .filter(
                                    (selection) =>
                                        selection.sensorType.valueOf() ===
                                        sensorFunctionType,
                                )
                                .map((selection) => {
                                    if (
                                        integration?.integratorName ===
                                        'EcoGuard'
                                    ) {
                                        return {
                                            tag: selection.tag.extra,
                                            name: selection.tag.name,
                                        };
                                    } else if (
                                        integration?.integratorName ===
                                        'Web Port'
                                    ) {
                                        return {
                                            tag: selection.tag.name,
                                            name: null,
                                        };
                                    } else if (
                                        integration.integratorName === 'Regin'
                                    ) {
                                        return {
                                            tag: selection.tag.name,
                                            name: null,
                                        };
                                    } else if (
                                        integration.integratorName === 'Desigo'
                                    ) {
                                        return {
                                            tag:
                                                selection.tag.key ||
                                                selection.tag.extra,
                                            name: selection.tag.name,
                                        };
                                    } else if (
                                        integration.integratorName.toLowerCase() ===
                                        'proptechos'
                                    ) {
                                        return {
                                            tag:
                                                selection.tag.key ||
                                                selection.tag.extra,
                                            name: selection.tag.name,
                                            kind: selection.tag.kind,
                                        };
                                    } else if (
                                        integration.integratorName === 'Elvaco'
                                    ) {
                                        return {
                                            tag: selection.tag.apiIdentifier,
                                            name:
                                                selection.tag.extra ||
                                                selection.tag.name,
                                        };
                                    } else {
                                        return null;
                                    }
                                }),
                        });
                    }

                    result = result.filter(
                        (value) => value.sensorIdentifiers.length !== 0,
                    );

                    onSubmit(result);
                }}
            >
                <div className="flex justify-between">
                    <Button htmlType="submit">{t('Confirm')}</Button>
                    {filteredTagTree(debouncedTagTree, integration, searchQuery)
                        .length > 100 && (
                        <Typography.Text type={'danger'} strong>
                            {t('Currently displaying only 100 items')}
                        </Typography.Text>
                    )}
                    <div className="flex-shrink-1 flex-grow-0 w-96">
                        <SensorTypeSelector
                            key={searchQuery?.join()}
                            defaultValue={-1}
                            forbidenSensors={[]}
                            onChange={(sensorType) => {
                                const tags = filteredTagTree(
                                    debouncedTagTree,
                                    integration,
                                    searchQuery,
                                ).slice(0, 100);

                                onSelectMany(sensorType, tags);
                            }}
                        />
                    </div>
                </div>

                <Divider />

                <div className="flex flex-col my-4">
                    {fitleredTree.map((tag: IntegrationTag, index: number) => {
                        const selection = selectedTags.find(
                            (selection) => selection.tag.name === tag.name,
                        );

                        const registeredSensor = getRegisteredSensorFromTag(
                            tag,
                            integratedSensors,
                        );

                        return (
                            <div
                                className="flex flex-col justify-between gap-4 my-3 sm:flex-row"
                                key={`${tag.name}-${index}-${tag.key}`}
                            >
                                <div className="flex flex-col">
                                    <span className="flex-grow">
                                        {isProptech
                                            ? `${tag.name} (${tag.kind})`
                                            : `${tag.name} `}
                                        <span className="text-gray-400">
                                            ({tag.extra})
                                        </span>
                                    </span>
                                    {registeredSensor && (
                                        <span className="text-gray-400">
                                            - {registeredSensor.name}
                                        </span>
                                    )}
                                </div>

                                <div className="flex-shrink-1 flex-grow-0 w-96">
                                    <SensorTypeSelectorHidden
                                        key={
                                            tag.name +
                                            `${selection?.sensorType}` +
                                            `${registeredSensor?.sensorFunctionTypeId}`
                                        }
                                        forbidenSensors={[]}
                                        defaultValue={
                                            selection !== undefined
                                                ? selection.sensorType
                                                : registeredSensor !== undefined
                                                ? registeredSensor.sensorFunctionTypeId
                                                : SensorType.NONE
                                        }
                                        onChange={(sensorType) =>
                                            onSelect(sensorType, tag)
                                        }
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Form>
        </>
    );
}

export default IntegratorTagTreeView;
