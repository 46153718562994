export const formatDate = (date: Date) => {
    const month =
        date.getMonth() + 1 > 9
            ? (date.getMonth() + 1).toString()
            : '0' + (date.getMonth() + 1).toString();
    const day =
        date.getDate() > 9
            ? date.getDate().toString()
            : '0' + date.getDate().toString();

    return `${date.getFullYear()}-${month}-${day}`;
};
