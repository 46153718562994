import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import {
    Col,
    DatePicker,
    Form,
    Input,
    Radio,
    Row,
    Slider,
    Statistic,
    Typography,
} from 'antd';
import { ReactComponent as Corner } from 'src/assets/corner.svg';
import { ReactComponent as Dented } from 'src/assets/dented.svg';
import { ReactComponent as Rectangle } from 'src/assets/rectangle.svg';
import { ReactComponent as Square } from 'src/assets/square.svg';

import { useTranslation } from 'react-i18next';
import LocationSearch from 'src/views/objects/LocationSearch';
import { useState } from 'react';
import { useGetHolistic } from 'src/hooks/objects/useHolistics';
import { useForm } from 'antd/lib/form/Form';
import { SiteObject } from 'src/models/SiteObject';
import { useDebouncedCallback } from 'use-debounce';

type HolisticsDTO = Omit<
    SiteObject,
    | 'co2EmissionFactorElectricity'
    | 'co2EmissionFactorHeating'
    | 'outsideWalls'
    | 'siteId'
    | 'siteName'
>;

interface HouseSelectorProps {
    shapeOfHouse: string | number;
    onChange: (shapeOfHouse: string) => void;
}

export function HouseSelector(props: HouseSelectorProps) {
    const { shapeOfHouse, onChange } = props;
    return (
        <>
            <div className="flex w-full p-2">
                <div
                    onClick={() => onChange('RECTANGLE')}
                    style={{
                        width: '100%',
                        display: 'flex',
                        borderWidth: '0.1em',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBlock: '0.5rem',
                        borderColor:
                            shapeOfHouse === 'RECTANGLE'
                                ? '#51baa2'
                                : '#d9d9d9',
                    }}
                >
                    <div style={{ maxWidth: '20%' }}>
                        <Rectangle />
                    </div>
                </div>
                <div
                    onClick={() => onChange('DENTED')}
                    style={{
                        width: '100%',
                        display: 'flex',
                        borderWidth: '0.1em',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBlock: '0.5rem',
                        borderColor:
                            shapeOfHouse === 'DENTED' ? '#51baa2' : '#d9d9d9',
                    }}
                >
                    <div style={{ maxWidth: '20%' }}>
                        <Dented />
                    </div>
                </div>

                <div
                    onClick={() => onChange('SQUARE')}
                    style={{
                        width: '100%',
                        display: 'flex',
                        borderWidth: '0.1em',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBlock: '0.5rem',
                        borderColor:
                            shapeOfHouse === 'SQUARE' ? '#51baa2' : '#d9d9d9',
                    }}
                >
                    <div style={{ maxWidth: '20%' }}>
                        <Square />
                    </div>
                </div>
                <div
                    onClick={() => onChange('CORNER')}
                    style={{
                        width: '100%',
                        display: 'flex',
                        borderWidth: '0.1em',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBlock: '0.5rem',
                        borderColor:
                            shapeOfHouse === 'CORNER' ? '#51baa2' : '#d9d9d9',
                    }}
                >
                    <div style={{ maxWidth: '20%' }}>
                        <Corner />
                    </div>
                </div>
            </div>
        </>
    );
}

function IFramePage() {
    const { t } = useTranslation();
    const [longLat, setLongLat] = useState<{
        longitude: number | null;
        latitude: number | null;
    }>({ longitude: null, latitude: null });
    const [savings, setSavings] = useState<number>(0);
    const [psuedoObject, setPsuedoObject] = useState<HolisticsDTO>();
    const [get] = useGetHolistic();
    const [form] = useForm();

    const fetchHolistics = useDebouncedCallback(() => {
        const formValues = form.getFieldsValue();
        const currentConsumption = formValues?.currentConsumption;

        const isReady: boolean =
            psuedoObject?.aTemp !== undefined &&
            psuedoObject?.buildYear !== undefined &&
            psuedoObject?.floorsAboveGround !== undefined &&
            psuedoObject?.floorsBelowGround !== undefined &&
            longLat.latitude !== null;

        if (isReady) {
            get(psuedoObject!).then((v) => {
                if (currentConsumption) {
                    setSavings(Math.round(currentConsumption - v.score));
                } else {
                    setSavings(Math.round(v.score * 0.12));
                }
            });
        }
    }, 1000);

    const onChange = () => {
        fetchHolistics();
    };

    return (
        <>
            <Typography.Title level={2} className="text-center">
                {t('Potential yearly savings')}
            </Typography.Title>
            <Row className="m-2" justify="space-between">
                <Col span={8}>
                    <Statistic
                        groupSeparator=""
                        value={Math.round(savings * 0.05)}
                        valueStyle={{
                            color: savings > 0 ? '#51BAA2' : '#ff4d4f',
                        }}
                        style={{ textAlign: 'center' }}
                        title={t('CO-2')}
                        suffix={
                            savings > 0 ? (
                                <ArrowDownOutlined
                                    style={{ color: '#51BAA2' }}
                                />
                            ) : (
                                <ArrowUpOutlined style={{ color: '#ff4d4f' }} />
                            )
                        }
                    />
                </Col>
                <Col span={8}>
                    <Statistic
                        groupSeparator=""
                        value={savings}
                        valueStyle={{
                            color: savings > 0 ? '#51BAA2' : '#ff4d4f',
                        }}
                        style={{ textAlign: 'center' }}
                        className="w-full"
                        title={t('kWh')}
                        suffix={
                            savings > 0 ? (
                                <ArrowDownOutlined
                                    style={{ color: '#51BAA2' }}
                                />
                            ) : (
                                <ArrowUpOutlined style={{ color: '#ff4d4f' }} />
                            )
                        }
                    />
                </Col>
                <Col span={8}>
                    <Statistic
                        groupSeparator=""
                        value={Math.round(savings * 0.71)}
                        valueStyle={{
                            color: savings > 0 ? '#51BAA2' : '#ff4d4f',
                        }}
                        style={{ textAlign: 'center' }}
                        className="w-full "
                        title={t('SEK')}
                        suffix={
                            savings > 0 ? (
                                <ArrowDownOutlined
                                    style={{ color: '#51BAA2' }}
                                />
                            ) : (
                                <ArrowUpOutlined style={{ color: '#ff4d4f' }} />
                            )
                        }
                    />
                </Col>
            </Row>
            <Form form={form} onFieldsChange={onChange} layout="vertical">
                <Row gutter={[20, 20]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            required
                            hasFeedback
                            label={t('Objects address')}
                        >
                            <LocationSearch
                                onSelect={(v) =>
                                    setLongLat({
                                        latitude: v.geometry.location.lat,
                                        longitude: v.geometry.location.lng,
                                    })
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            required
                            name="buildYear"
                            label={t('Build year')}
                        >
                            <DatePicker
                                onChange={(v) => {
                                    setPsuedoObject({
                                        ...psuedoObject,
                                        buildYear: v?.year() || 2020,
                                        id: -1,
                                        latitude: longLat?.latitude,
                                        longitude: longLat?.longitude,
                                        name: 'psuedoObject',
                                    });
                                    onChange();
                                }}
                                className="w-full"
                                picker="year"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            required
                            rules={[
                                {
                                    validator(_, value) {
                                        if (!isNaN(value) && value < 100000) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            t(
                                                'Please enter a number smaller than 100 000!',
                                            ),
                                        );
                                    },
                                },
                            ]}
                            name="aTemp"
                            label={t('A-temp')}
                        >
                            <Input
                                onChange={(v: any) => {
                                    setPsuedoObject({
                                        ...psuedoObject,
                                        buildYear:
                                            psuedoObject?.buildYear || 2020,
                                        id: -1,
                                        latitude: longLat?.latitude,
                                        longitude: longLat?.longitude,
                                        name: 'psuedoObject',
                                        aTemp: Number.parseInt(v.target.value),
                                    });
                                    onChange();
                                }}
                                suffix="m²"
                            />
                        </Form.Item>
                        <Form.Item
                            name="currentConsumption"
                            label={t('Current energy consumption')}
                        >
                            <Input suffix="kWh" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col xs={12} span={16}>
                        <Form.Item
                            required
                            name="floorsAboveGround"
                            label={t('Floors above ground')}
                        >
                            <div className="flex">
                                <Slider
                                    onChange={(v: any) => {
                                        setPsuedoObject({
                                            ...psuedoObject,
                                            buildYear:
                                                psuedoObject?.buildYear || 2020,
                                            id: -1,
                                            latitude: longLat?.latitude,
                                            longitude: longLat?.longitude,
                                            name: 'psuedoObject',
                                            floorsAboveGround: v,
                                        });
                                        onChange();
                                    }}
                                    className="flex-grow gap-x-4"
                                    max={15}
                                    min={1}
                                    trackStyle={{
                                        backgroundColor: '#51BAA2',
                                        borderColor: '#51BAA2',
                                    }}
                                    handleStyle={{
                                        backgroundColor: '#51BAA2',
                                        color: '#51BAA2',
                                        border: 'none',
                                    }}
                                />
                                <Typography.Text strong className="ml-2">
                                    {psuedoObject?.floorsAboveGround !==
                                    undefined
                                        ? psuedoObject.floorsAboveGround
                                        : '1'}{' '}
                                    {'st'}
                                </Typography.Text>
                            </div>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            required
                            name="floorsBelowGround"
                            label={t('Is there a cellar?')}
                        >
                            <Radio.Group
                                value={psuedoObject?.floorsBelowGround}
                                onChange={(v: any) => {
                                    setPsuedoObject({
                                        ...psuedoObject,
                                        buildYear:
                                            psuedoObject?.buildYear || 2020,
                                        id: -1,
                                        latitude: longLat?.latitude,
                                        longitude: longLat?.longitude,
                                        name: 'psuedoObject',
                                        floorsBelowGround: v.target.value,
                                    });
                                    onChange();
                                }}
                            >
                                <Radio value={0}>{t('No')}</Radio>
                                <Radio value={1}>{t('Yes')}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    required
                    name="shapeOfHouse"
                    label={t('Shape of the house')}
                >
                    <HouseSelector
                        shapeOfHouse={psuedoObject?.shapeOfHouse || 'SQUARE'}
                        onChange={(shape: string) => {
                            setPsuedoObject({
                                ...psuedoObject,
                                buildYear: psuedoObject?.buildYear || 2020,
                                id: -1,
                                latitude: longLat?.latitude,
                                longitude: longLat?.longitude,
                                name: 'psuedoObject',
                                shapeOfHouse: shape,
                            });
                            onChange();
                        }}
                    />
                </Form.Item>
            </Form>
        </>
    );
}

export default IFramePage;
