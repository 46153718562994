import { User } from './User';

export enum CorporationRights {
    'ALLOW_CREATE_SITE' = 'ALLOW_CREATE_SITE',
    'ALLOW_CHANGE_SETTINGS' = 'ALLOW_CHANGE_SETTINGS',
    'DELETE_SITE' = 'DELETE_SITE',
    'DELETE_CORPORATION' = 'DELETE_CORPORATION',
    'GET_CORPORATION' = 'GET_CORPORATION',
}
export interface Corporation {
    name: string;
    id: number;
    imgSrc: string;
}

export interface CorporationsUsers {
    users: User[];
    access: Record<string, CorporationRights[]>;
}
