import { useEffect, useState } from 'react';
import { API } from 'src/api/API';
import { Sensor, SensorType } from 'src/models/Sensor';

export interface RealTimeDataPoint {
    date: string;
    timestamp: number;
    timeString: string;
    value: number;
}

export interface RealTimeResponse extends Sensor {
    data: RealTimeDataPoint[];
}

export const useRealTimeHeating = (
    id: number,
    start: string,
    stop: string,
    type1: SensorType,
    type2: SensorType,
    type3: SensorType,
    type4: SensorType,
    type5: SensorType,
    type6: SensorType,
) => {
    const [data, setData] = useState<{
        data1: RealTimeResponse[];
        data2: RealTimeResponse[];
        data3: RealTimeResponse[];
        data4: RealTimeResponse[];
        data5: RealTimeResponse[];
        data6: RealTimeResponse[];
    }>({
        data1: [],
        data2: [],
        data3: [],
        data4: [],
        data5: [],
        data6: [],
    });
    const [isLoading, setIsLoading] = useState(true);
    const [hotColdFlag, setHotColdFlag] = useState(false);

    useEffect(() => {
        const api = new API();
        setIsLoading(true);
        (async () => {
            try {
                const promises = [
                    api.fetchRealTimeWithSensorTypes(id, start, stop, [
                        type1,
                        type2,
                        type3,
                        type4,
                        type5,
                        type6,
                    ]),
                ];

                const [data] = await Promise.all(promises);

                const [data1, data2, data3, data4, data5, data6] = data;

                setData({ data1, data2, data3, data4, data5, data6 });
                setIsLoading(false);
            } catch (error) {
                // Handle error here
                setIsLoading(false);
            }
        })();
    }, [id, start, stop, type1, type2, type3, type4, type5, type6]);

    return {
        isLoading,
        data,
        hotColdFlag,
        setHotColdFlag,
        setData,
        setIsLoading,
    };
};
