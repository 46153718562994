import { Corporation, CorporationsUsers } from 'src/models/Corporation';
import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';

export const useCorporation = (id: number) => {
    return useSWR<Corporation>(id ? `/corporations/${id}` : null, swrFetch);
};

export const useCorporationUsers = (id: number) => {
    return useSWR<CorporationsUsers>(
        id && !isNaN(id) ? `/corporations/${id}/users` : null,
        swrFetch,
    );
};
