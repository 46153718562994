import { Divider, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHasMetryIntegration } from 'src/hooks/integrations/useHasMetryIntegration';
import { useRemoveMetryIntegration } from 'src/hooks/integrations/useRemoveMetryIntegration';
import metryLogoSource from './icon.png';

interface MetryConfigProps {
    corporationId: number;
    onClose?: () => void;
}

function MetryConfig({ corporationId, onClose }: MetryConfigProps) {
    const { t } = useTranslation();
    const [remove] = useRemoveMetryIntegration();
    const { data: metryExists } = useHasMetryIntegration(corporationId);

    return (
        <div className="w-full">
            <div className="flex">
                <img
                    className="my-auto w-20 float-left"
                    src={metryLogoSource}
                    alt="Metry logo"
                />
                <Typography.Title className="my-auto">
                    {t('Metry integration')}
                </Typography.Title>
            </div>
            <Divider />
            {metryExists && (
                <>
                    <Typography.Paragraph>
                        {!metryExists.isHidden &&
                            t('You metry integration is complete')}
                        {metryExists.isHidden &&
                            t(
                                'You metry integration is manged by Nrlyze. ' +
                                    'You do not need to update any objects',
                            )}
                    </Typography.Paragraph>

                    {!metryExists.isHidden && (
                        <Button
                            onClick={() => {
                                const ok = window.confirm(
                                    t(
                                        'Are you sure you want remove this metry integration?',
                                    ),
                                );
                                if (!ok) return;
                                remove(corporationId).then(() => {
                                    onClose?.();
                                });
                            }}
                        >
                            {t('Remove')}
                        </Button>
                    )}
                </>
            )}
        </div>
    );
}

export default MetryConfig;
