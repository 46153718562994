import { ControlState } from 'src/models/ControlState';
import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';

export const useControlStates = (objectId: number) => {
    return useSWR<ControlState[]>(
        `objects/${objectId}/control-states`,
        swrFetch,
        {
            suspense: false,
        },
    );
};
