import { Table } from 'antd';
import React from 'react';
import { Site } from 'src/models/Site';
import { SiteObject } from 'src/models/SiteObject';
import { useNavigate } from 'react-router-dom';
import Nav from 'src/utils/nav/Nav';

interface SkeletonTableProps {
    loading: boolean;
    site: Site;
}

interface SkeletonTableViewProps {
    object: SiteObject;
    loading: boolean;
}

function SkeletonTableView(props: SkeletonTableViewProps) {
    const { object, loading } = props;
    const history = useNavigate();

    const columns = [
        {
            title: object.name,
            dataIndex: 'key',
            render: (text: string) => (
                <span
                    onClick={() => {
                        history(
                            Nav.build({
                                path: '/analytics/:objectId',
                                queryParams: {},
                                routeParams: {
                                    objectId: object.id,
                                },
                            }),
                        );
                    }}
                >
                    {text}
                </span>
            ),
        },
        {
            title: '',
            dataIndex: 'value',
        },
    ];
    const skeletonRowCount = 4;

    const navigateToAnalyticsPage = () => {
        history(
            Nav.build({
                path: '/analytics/:objectId',
                queryParams: {},
                routeParams: {
                    objectId: object.id,
                },
            }),
        );
    };

    const renderSkeletonRow = () => {
        return (
            <tr onClick={navigateToAnalyticsPage}>
                <td
                    style={{
                        height: '40px',
                        borderBottom: '1px solid #e8e8e8',
                    }}
                >
                    <span
                        style={{
                            display: 'inline-block',
                            width: '50%',
                            height: '50%',
                            backgroundColor: '#f2f2f2',
                        }}
                        className="skeleton-animation"
                    />
                </td>
                <td
                    style={{
                        height: '40px',
                        borderBottom: '1px solid #e8e8e8',
                    }}
                >
                    <span
                        style={{
                            display: 'inline-block',
                            width: '50%',
                            height: '50%',
                            backgroundColor: '#f2f2f2',
                        }}
                        className="skeleton-animation"
                    />
                </td>
            </tr>
        );
    };

    return (
        <Table
            size="middle"
            columns={columns}
            dataSource={loading ? Array(skeletonRowCount).fill(null) : []}
            pagination={false}
            onHeaderRow={() => ({
                onClick: navigateToAnalyticsPage,
            })}
            components={{
                body: {
                    row: renderSkeletonRow,
                },
            }}
            className="cursor-pointer hover:border-gray-400 border"
            bordered
        />
    );
}

const SkeletonTable: React.FC<SkeletonTableProps> = ({ loading, site }) => {
    return (
        <>
            {site.objects?.map((object) => (
                <SkeletonTableView
                    key={object.id}
                    loading={loading}
                    object={object}
                />
            ))}
        </>
    );
};

export default SkeletonTable;
