import { Tree, Skeleton } from 'antd';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Nav from 'src/utils/nav/Nav';
import { useTree } from 'src/hooks/tree/useCorporationTree';
import { NumberParam, withDefault, useQueryParams } from 'use-query-params';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedSensors } from 'src/store/reducers/selectedSensorsSlice';
import {
    setSelectedKeys,
    selectSelectedKeys,
    clearSelectedKeys,
} from 'src/store/reducers/selectedKeysSlice';

import { setRefreshFlag } from 'src/store/reducers/selectedObjectSlice';

import type { TreeProps } from 'antd/es/tree';

import { CaretDownFilled } from '@ant-design/icons';

interface TreeDataNode {
    // Add your specific properties here based on your use case
    id: number;
    key: string;
    title: string;
    type: string;
    disabled: boolean;
    children?: TreeDataNode[];
}

const findParentNode = (
    allTreeData: TreeDataNode[],
    targetKey: string | number,
): TreeDataNode | null => {
    const findNodeRecursive = (
        node: TreeDataNode,
        parent: TreeDataNode | null = null,
    ): TreeDataNode | null => {
        if (node.key === targetKey) {
            return parent;
        }

        if (node.children) {
            for (const child of node.children) {
                const foundNode = findNodeRecursive(child, node);
                if (foundNode) {
                    return foundNode;
                }
            }
        }

        return null;
    };

    for (const node of allTreeData) {
        const foundNode = findNodeRecursive(node);
        if (foundNode) {
            return foundNode;
        }
    }

    return null;
};

function NavigationTree() {
    const { data: myData } = useTree();

    const dispatch = useDispatch();

    const [params] = useQueryParams({
        index: withDefault(NumberParam, 0),
    });

    const [loading, setLoading] = useState<boolean>(true);

    const selectedKeys = useSelector(selectSelectedKeys);

    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

    const { pathname } = useLocation();

    const [treeData, setTreeData] = useState<TreeDataNode[]>([]);

    const history = useNavigate();

    const onSelect: TreeProps['onSelect'] = async (selectedKeys, info) => {
        const node: any = info.node;

        if (node) {
            const isExpanded = expandedKeys.includes(node.key);

            if (isExpanded) {
                // Node is expanded, so collapse it
                setExpandedKeys(expandedKeys.filter((key) => key !== node.key));
            } else {
                // Node is not expanded, so expand it
                setExpandedKeys([...expandedKeys, node.key]);
            }
        }

        if (node?.type === 'sensor') {
            const nodes: any[] = info.selectedNodes;

            const parentNode = findParentNode(treeData, info.node.key);

            const selectedSensors = nodes.filter(
                (node) => node.type === 'sensor',
            );

            const stringKeys = selectedKeys.map((key) => String(key));

            dispatch(setSelectedKeys(stringKeys));

            if (parentNode) {
                dispatch(
                    setSelectedSensors({
                        objectId: parentNode.id + '',
                        sensorIds: [
                            ...selectedSensors.map((sensor) => sensor.id),
                        ],
                    }),
                );
            }
        } else if (node?.type === 'corp') {
            history(
                Nav.build({
                    path: '/corporations/:corporationId',
                    queryParams: {},
                    routeParams: { corporationId: node.id },
                }),
            );
        } else if (node?.type === 'site') {
            history(
                Nav.build({
                    path: '/sites/objects/:siteId',
                    queryParams: {},
                    routeParams: { siteId: node.id },
                }),
            );
        } else if (node?.type === 'object') {
            if (node) {
                const objectPagePath = `/analytics/${node.id}`;
                const isObjectPageOpen = pathname === objectPagePath;

                const isExpanded = expandedKeys.includes(node.key);

                if (!isExpanded) {
                    await dispatch(clearSelectedKeys());
                    await dispatch(
                        setSelectedSensors({
                            objectId: node.id,
                            sensorIds: [],
                        }),
                    );

                    if (isObjectPageOpen) {
                        dispatch(setRefreshFlag(true));
                    }

                    history(
                        Nav.build({
                            path: '/analytics/:objectId',
                            queryParams: { index: '0' },
                            routeParams: { objectId: node.id },
                        }),
                        { replace: true },
                    );
                }
            }
        }

        if (node?.type !== 'sensor') {
            const nodes: any[] = info.selectedNodes;

            const filteredKeys = nodes
                .filter((node) => node.type !== 'sensor')
                .map((node) => node.key);
            dispatch(setSelectedKeys(filteredKeys));
        }
    };

    const shouldDisableNode = (node: TreeDataNode) =>
        params?.index === 1 && node.type === 'sensor';

    const disableNodesByType = (data: TreeDataNode[]): TreeDataNode[] => {
        return data.map((node) => {
            const disabled = shouldDisableNode(node);
            return {
                ...node,
                disabled,
                children: node.children && disableNodesByType(node.children),
            };
        });
    };

    useEffect(() => {
        if (myData && myData.length) {
            const newData = [];

            for (let i = 0; i < myData.length; i++) {
                newData.push(JSON.parse(myData[i]));
            }

            if (newData && newData.length) {
                setTreeData(newData);
                setLoading(false);
            }
        }
    }, [myData]);

    const onExpand: TreeProps['onExpand'] = (expandedKeys: any) => {
        setExpandedKeys(expandedKeys);
    };

    return (
        <>
            <div
                className="h-full border-r p-3 w-52"
                style={{
                    width: '22rem',
                    overflow: 'auto',
                    paddingBottom: '4rem',
                }}
            >
                {loading ? (
                    // Display a loader while the data is being loaded
                    <Skeleton active />
                ) : (
                    // Display the tree once the data is loaded
                    !!treeData.length && (
                        <Tree
                            showLine
                            switcherIcon={<CaretDownFilled />}
                            defaultExpandedKeys={[]}
                            onSelect={onSelect}
                            treeData={disableNodesByType(treeData)}
                            style={{ fontSize: '16px' }}
                            selectedKeys={selectedKeys}
                            multiple={true}
                            expandedKeys={expandedKeys}
                            onExpand={onExpand}
                        />
                    )
                )}
            </div>
        </>
    );
}

export default NavigationTree;
