import { Form, Input, Button, Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Topbar from '../generic/Topbar';
import { SensorTypeSelector } from 'src/components/SensorTypeSelectorUpdate';
import { useState } from 'react';
import { SensorType } from 'src/models/Sensor';
import { useConfigSensor } from 'src/hooks/sensors/useConfigSensor';

type Props = {
    objectId: string;
    onSuccess(): void;
    sensorId: string;
    sensorName: string;
    goBack(): void;
    isActive: boolean;
    sensorFunctionTypeId: number;
};
function UpdateSensorView(props: Props) {
    const [form] = Form.useForm();
    const {
        objectId,
        onSuccess,
        sensorId,
        goBack,
        sensorName,
        isActive,
        sensorFunctionTypeId,
    } = props;
    const { t } = useTranslation();
    const [sensorType, setSensorType] = useState(
        sensorFunctionTypeId as SensorType,
    );
    const [config] = useConfigSensor(Number.parseInt(objectId));
    const handleOnSubmit = (form: any) => {
        config(objectId, sensorId, {
            name: form.name,
            sensorFunctionTypeId: sensorType,
            isActive: isActive,
        }).then(() => onSuccess());
    };
    return (
        <>
            <div className="relative">
                <div className="w-screen p-10 h-screen">
                    <Typography.Title className="text-center" level={4}>
                        {t('Update sensor')}
                    </Typography.Title>
                    <Row justify="center" align="middle" gutter={[20, 20]}>
                        <Col lg={8} md={12} xs={24}>
                            <Form
                                initialValues={{
                                    name: sensorName,
                                }}
                                form={form}
                                layout="vertical"
                                onFinish={handleOnSubmit}
                            >
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                'Sensor name is required',
                                            ),
                                        },
                                    ]}
                                    label={t('New sensor name')}
                                    name="name"
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    initialValue={sensorType}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('Sensor type required'),
                                        },
                                    ]}
                                    label={t('Sensor type')}
                                    name="type"
                                >
                                    <SensorTypeSelector
                                        defaultValue={sensorType}
                                        onChange={setSensorType}
                                    />
                                </Form.Item>
                                <Button
                                    className="mt-3"
                                    block
                                    size="large"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {t('Update sensor')}
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </div>
                <div className="fixed bottom-0 w-screen">
                    <div className="w-full mb-5 flex">
                        <Button
                            onClick={() => {
                                goBack();
                            }}
                            type="primary"
                            className="m-auto"
                        >
                            {t('Go back')}
                        </Button>
                    </div>
                    <Topbar active="sites" />
                </div>
            </div>
        </>
    );
}

export default UpdateSensorView;
