import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store/rootReducer';

export type SensorsState = {
    entities: string[];
    loading: 'idle' | 'pending';
    error: any;
};

const initialState: SensorsState = {
    entities: [],
    loading: 'idle',
    error: null,
};

export const sensorNamesSlice = createSlice({
    name: 'sensorNames',
    initialState,
    reducers: {
        setSensorNames: (state, action: PayloadAction<string[]>) => {
            state.entities = action.payload;
        },
        addSensorName: (state, action: PayloadAction<string>) => {
            state.entities.push(action.payload);
        },
    },
});

export const selectSensorNames = (state: RootState) => state.sensorNames;

// Slice action creators
export const { setSensorNames, addSensorName } = sensorNamesSlice.actions;

export default sensorNamesSlice.reducer;
