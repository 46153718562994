import { TemperatureWithControlState } from 'src/models/Temperature';
import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';

export const useObjectEffect = (objectId: number) => {
    return useSWR<{
        desired: TemperatureWithControlState;
        measured: TemperatureWithControlState;
    }>(`objects/${objectId}/effect`, swrFetch, { suspense: true });
};
