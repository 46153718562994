import { useState } from 'react';
import { Modal, Form, Button, Steps, Input, Spin, List } from 'antd';
import MappingTableComponent from './MappingEditableTable';
import { useSensors } from 'src/hooks/objects/useMeasuredSensors';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootReducer';
import { Sensor } from 'src/models/Sensor';
import { useFetchSpaces } from 'src/hooks/3dmapping/useFetchSpaces';
import { useCreateBlocks } from 'src/hooks/3dmapping/userCreateBlocks';

interface BuildingBlock {
    Name: string;
    numFloors: number;
    floorTemplate: number;
}

interface FloorTemplate {
    Id: number;
    Name: string;
}

interface Props {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    blocks: BuildingBlock[];
    floorTemplates: FloorTemplate[];
    setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
    setCorners: React.Dispatch<React.SetStateAction<any[]>>;
    objectId: number | undefined;
}

function NewBlockModal({
    showModal,
    setShowModal,
    blocks,
    floorTemplates,
    objectId,
}: Props) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [matchedFloors, setMatchedFloors] = useState<string[]>([]);
    const [unMappedSpaces, setUnmappedSpaces] = useState<string[]>([]);
    const [unMappedSensors, setUnMappedSensors] = useState<any>();
    const [blocksToSubmit, setBlocksToSubmit] = useState<any>([]);

    const tableRows = useSelector((state: RootState) => state.tableRows);

    const [fetchSensors] = useSensors();
    const [fetchSpaces] = useFetchSpaces();
    const [createBlocks] = useCreateBlocks();

    const handleCancel = () => {
        setShowModal(false);
    };

    const findPTSNumber = (
        sensorName: string,
        separator: string,
        PTSlocation: number,
        AddressLocation: number[],
    ): [string, string] => {
        // Use this to convert
        const thisSeparator: string = 'GT-FL';
        const toThisSeparator: string = '1001';
        sensorName = sensorName.replace(thisSeparator, toThisSeparator);

        const splitString: string[] = sensorName.split(separator);
        const PTSNumber: string = splitString[PTSlocation];
        let address: string;
        if (AddressLocation.length > 1) {
            address =
                splitString[AddressLocation[0]] +
                separator +
                splitString[AddressLocation[1]];
        } else {
            address = splitString[AddressLocation[0]];
        }
        return [address, PTSNumber];
    };

    const createSensorList = (sensors: Sensor[], values: any) => {
        const sensorList = [];
        for (let sensor of sensors) {
            const addressAndPTS: [string, string] = findPTSNumber(
                sensor.name!,
                values.separator,
                Number(values.ptsLocation),
                [Number(values.addressLocation)],
            );
            const address: string = addressAndPTS[0];
            const PTS: string = addressAndPTS[1];
            // Check if PTS is numeric
            if (!isNaN(Number(PTS))) {
                const floorNumber: number = parseInt(PTS.slice(0, 2));
                const spaceIndex: number = parseInt(PTS.slice(2));
                const sensorFunctionTypeIndex = sensor.sensorFunctionTypeIndex;
                sensorList.push({
                    id: sensor.id,
                    name: sensor.name,
                    address,
                    floorNumber,
                    spaceIndex,
                    sensorFunctionTypeIndex,
                });
            }
        }

        return sensorList;
    };

    const handleFormFinish = async (values: any) => {
        const matchedFloors: string[] = [];
        const unMappedSpaces: string[] = [];

        const blocksToAdd = [];
        if (objectId) {
            const [sensors, spaces] = await Promise.all([
                fetchSensors(objectId),
                fetchSpaces(),
            ]);
            const sensorList = createSensorList(sensors, values);
            const unMappedSensorList = [...sensorList];
            if (blocks.length) {
                for (let i = 0; i < blocks.length; i++) {
                    const blockToAdd: any = { ...blocks[i] };
                    blockToAdd.floors = [];
                    const floors = tableRows[i];
                    for (let floor of floors) {
                        const numberOfSpacesFloor = spaces.find(
                            (space) => space.TemplateId === floor.templateId,
                        )?.MaxSpaceNumber;
                        let space = 1;
                        const floorSpace: any = { ...floor };
                        floorSpace.spaces = [];
                        while (space <= numberOfSpacesFloor!) {
                            const sensorIndex = unMappedSensorList.findIndex(
                                //eslint-disable-next-line
                                (sensor) =>
                                    sensor.address === blocks[i].Name &&
                                    sensor.floorNumber ===
                                        Number(floor.floorNumber) &&
                                    sensor.spaceIndex === space,
                            );
                            if (sensorIndex !== -1) {
                                matchedFloors.push(
                                    `We have a match with block: ${blocks[i].Name}, floor:  ${floor.floorNumber} and space: ${space}`,
                                );
                                floorSpace.spaces.push({
                                    space,
                                    sensorFunctionType: 101,
                                    sensorFunctionTypeIndex:
                                        unMappedSensorList[sensorIndex]
                                            .sensorFunctionTypeIndex,
                                });
                                unMappedSensorList.splice(sensorIndex, 1);
                            } else {
                                unMappedSpaces.push(
                                    `We have an unmatch space with block: ${blocks[i].Name}, floor:  ${floor.floorNumber} and space: ${space}`,
                                );
                            }
                            space++;
                        }

                        blockToAdd.floors.push(floorSpace);
                    }
                    blocksToAdd.push(blockToAdd);
                }
                setBlocksToSubmit(blocksToAdd);
            }

            setMatchedFloors(matchedFloors);
            setUnmappedSpaces(unMappedSpaces);
            setUnMappedSensors(unMappedSensorList!);
        }

        setLoading(false);
    };

    const onMappingClick = () => {
        setLoading(true);
        form.submit();
    };

    const onHandleSubmitForm = async () => {
        setLoading(true);
        await createBlocks(blocksToSubmit);
        setLoading(false);
        alert('Blocks submitted successfully');
    };

    const steps = [
        {
            title: 'Blocks & Floors',
            content: (
                <>
                    {blocks &&
                        blocks.map((buildingBlock, index) => (
                            <div key={index}>
                                <h3 className="text-lg font-bold mb-4">
                                    Block {buildingBlock.Name}
                                </h3>
                                <MappingTableComponent
                                    key={index}
                                    blockIndex={index}
                                    floorTemplates={floorTemplates}
                                />
                            </div>
                        ))}
                </>
            ),
        },
        {
            title: 'Mapping',
            content: (
                <>
                    <Form
                        form={form}
                        layout="horizontal"
                        onFinish={handleFormFinish}
                    >
                        <Form.Item
                            name="separator"
                            label="Separator"
                            labelCol={{ span: 4 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please specify the Separator',
                                },
                            ]}
                        >
                            <Input style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item
                            name="ptsLocation"
                            label="PTS Location"
                            labelCol={{ span: 4 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please specify the PTS Location',
                                },
                            ]}
                        >
                            <Input style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item
                            name="addressLocation"
                            label="Address Location"
                            labelCol={{ span: 4 }}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please specify the Address Location',
                                },
                            ]}
                        >
                            <Input style={{ width: '100%' }} />
                        </Form.Item>
                    </Form>
                    <div className="flex flex-wrap justify-end">
                        <Button
                            disabled={loading}
                            type="primary"
                            onClick={onMappingClick}
                        >
                            Mapping
                        </Button>
                    </div>
                    {loading && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '20px',
                            }}
                        >
                            <Spin spinning={loading} />
                        </div>
                    )}
                    {!!matchedFloors.length && (
                        <div>
                            <h1>
                                Total Matched Spaces: {matchedFloors.length}
                            </h1>
                            <List
                                header={<div>Matched Spaces</div>}
                                bordered
                                dataSource={matchedFloors}
                                renderItem={(item) => (
                                    <List.Item>{item}</List.Item>
                                )}
                            />
                        </div>
                    )}

                    {!!unMappedSpaces.length && (
                        <div className="mt-2">
                            <h1>
                                Total Unmapped Spaces: {unMappedSpaces.length}
                            </h1>
                            <List
                                header={<div>Unmapped Spaces</div>}
                                bordered
                                dataSource={unMappedSpaces}
                                renderItem={(item) => (
                                    <List.Item>{item}</List.Item>
                                )}
                            />
                        </div>
                    )}
                    {unMappedSensors && !!unMappedSensors.length && (
                        <div className="mt-2">
                            <h1>
                                Total Unmapped Sensors: {unMappedSensors.length}
                            </h1>
                            <List
                                header={<div>Unmapped Sensors</div>}
                                bordered
                                dataSource={unMappedSensors}
                                renderItem={(item: any) => (
                                    <List.Item>{`Sensor with id: ${item.id} and name: ${item.name}`}</List.Item>
                                )}
                            />
                        </div>
                    )}
                </>
            ),
        },
    ];

    return (
        <Modal
            open={showModal}
            width={'100vw'}
            title="Create Blocks"
            onCancel={handleCancel}
            footer={null}
        >
            <>
                <div className="flex flex-wrap lg:flex-nowrap items-start gap-4 p-8">
                    <div className="w-full max-w-full flex-shrink-0">
                        <Steps
                            current={currentStep}
                            style={{ marginBottom: '20px', width: '24rem' }}
                        >
                            {steps.map((step) => (
                                <Steps.Step
                                    key={step.title}
                                    title={step.title}
                                />
                            ))}
                        </Steps>
                        {steps[currentStep].content}
                    </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                    {currentStep === 0 && (
                        <>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type="primary"
                                className="ml-2"
                                onClick={() => setCurrentStep(1)}
                            >
                                Next
                            </Button>
                        </>
                    )}

                    {currentStep === 1 && (
                        <>
                            <Button onClick={() => setCurrentStep(0)}>
                                Previous
                            </Button>

                            <Button
                                type="primary"
                                disabled={loading || !matchedFloors.length}
                                className="ml-2"
                                htmlType="submit"
                                onClick={onHandleSubmitForm}
                            >
                                Submit
                            </Button>
                        </>
                    )}
                </div>
            </>
        </Modal>
    );
}

export default NewBlockModal;
