import React, { useState, useEffect } from 'react';

interface NotificationMessageProps {
    message: string;
    type: string;
}

const NotificationMessage: React.FC<NotificationMessageProps> = ({
    message,
    type,
}) => {
    const [showMessage, setShowMessage] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowMessage(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {showMessage && (
                <div
                    className={`text-white p-4 rounded-md shadow-lg fixed top-0 right-0 m-4`}
                    style={{ backgroundColor: '#5cb85c' }}
                >
                    <p className="font-semibold">{type}!</p>
                    <p>{message}</p>
                </div>
            )}
        </>
    );
};

export default NotificationMessage;
