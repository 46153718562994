import React, { useState, useEffect } from 'react';

// Layer id patterns by category
const layerSelector: { [key: string]: RegExp } = {
    parks: /park/,
    buildings: /building/,
    roads: /bridge|road|tunnel/,
    labels: /label|place|poi/,
};

function getLayerFilter(
    categories: { [key: string]: boolean },
    layerId: string,
): boolean {
    for (const key in categories) {
        if (categories[key] && layerSelector[key].test(layerId)) {
            return true;
        }
    }
    return false;
}

interface StyleControlsProps {
    onChange: (filter: (layerId: string) => boolean) => void;
}

const StyleControls: React.FC<StyleControlsProps> = (props) => {
    //eslint-disable-next-line
    const [categories, setCategories] = useState({
        parks: true,
        buildings: true,
        roads: true,
        labels: true,
    });

    useEffect(() => {
        const filter = (layerId: string) => getLayerFilter(categories, layerId);
        props.onChange(filter);
        //eslint-disable-next-line
    }, [categories, props.onChange]);

    return <div className="control-panel" />;
};

export default React.memo(StyleControls);
