import logoSrc from 'src/assets/Logo.svg';
import Modal from 'antd/lib/modal/Modal';
import { Alert, Button, Col, Row, Typography, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { SetPointMap } from './FlowTemperatureView';
import { UpdateTemperatureDTO } from 'src/hooks/objects/useUpdateTemperature';

interface Props {
    visible: boolean;
    objectId: number;
    input: SetPointMap[];
    update: (input: UpdateTemperatureDTO, objectId: number) => Promise<any>;
    onCancel: () => void;
    onSuccess: () => void;
    onRefresh: () => void;
    onError: (error: string) => void;
    calibrated?: 'DELTA' | 'ABSOLUTE';
}

function CommitChangesPopup(props: Props) {
    const { t } = useTranslation();

    const commitChanges = () => {
        props
            .update({ update: props.input }, props.objectId)
            .then((res: any) => {
                if (res['isModbusType']) {
                    message.success('Modbus parameter update pending');
                    props.onRefresh();
                } else {
                    props.onSuccess();
                }
            })
            .catch((e) => {
                props.onError(e.message.toString());
            });
    };

    return (
        <Modal
            width="60vh"
            footer={null}
            closable={false}
            visible={props.visible}
            onCancel={props.onCancel}
        >
            <Row gutter={[20, 10]}>
                <Col span={12}>
                    <Typography.Title level={4}>
                        {props?.calibrated === 'ABSOLUTE'
                            ? t('Note, automatic update required')
                            : t('Note, manual update required')}
                    </Typography.Title>
                </Col>

                <Col span={12}>
                    <img
                        className="h-5 float-right"
                        src={logoSrc}
                        alt="Nrlyze logo"
                    />
                </Col>

                <Col span={24}>
                    <Alert
                        showIcon
                        message={
                            props?.calibrated === 'ABSOLUTE'
                                ? t('Commiting to automatic stage')
                                : t(
                                      'Commiting to a new stage will need a manual update of your system.' +
                                          ' If the system change is not made the underlying data which your' +
                                          ' Nrlyze system uses is wrong leading to bad predictions',
                                  )
                        }
                        type="error"
                    />
                </Col>

                <Col span={12}>
                    <Button onClick={() => props.onCancel()} block>
                        {t('Cancel')}
                    </Button>
                </Col>

                <Col span={12}>
                    <Button
                        type="link"
                        block
                        style={{
                            backgroundColor: 'rgb(220, 38, 38)',
                            color: 'white',
                        }}
                        onClick={() => commitChanges()}
                    >
                        {t('I understand')}
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
}

export default CommitChangesPopup;
