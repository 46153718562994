export class ColorSelector {
    private static readonly colors = [
        '#3626A7',
        '#657ED4',
        '#C33149',
        '#2292A4',
        '#FCAB64',
    ] as const;

    private static badHash(str: string) {
        let hash = 0,
            i,
            chr;

        if (str.length === 0) return hash;

        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0;
        }

        return hash;
    }

    static getColor(id: string | number) {
        const index =
            Math.abs(this.badHash(id.toString())) % this.colors.length;

        return this.colors[index];
    }
}
