import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface Props {
    path?: {
        name: string;
        href: string;
    }[];
    action?: ReactNode;
    action2?: ReactNode;
}

export default function Header(props: Props) {
    const { path = [] } = props;
    return (
        <>
            <div className="pt-4 pl-6 pb-3 h-12 flex ">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/home">
                            <HomeOutlined
                                style={{
                                    lineHeight: 1,
                                    verticalAlign: 'middle',
                                    fontSize: '18px',
                                }}
                            />
                        </Link>
                    </Breadcrumb.Item>
                    {path.map((v) => (
                        <Breadcrumb.Item key={v.name}>
                            <Link to={v.href}>
                                <span
                                    style={{
                                        lineHeight: 1,
                                        verticalAlign: 'middle',
                                        fontSize: '18px',
                                    }}
                                >
                                    {v.name}
                                </span>
                            </Link>
                        </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
                <div className="ml-auto mr-5">{props.action}</div>
            </div>
        </>
    );
}
