import use3DData from './hooks/use3DData';
import Chart from './3d/chart';
import { useEffect, useRef, useState } from 'react';
import { message, Spin } from 'antd';
import mockData from './mockData.json';

import { useTranslation } from 'react-i18next';
import { WarningOutlined } from '@ant-design/icons';
import { GradientKey } from 'src/models/GradientKey';

interface House3DProps {
    objectId: number;
    stateId: number;
    sensors: {
        sensorName?: string;
        id: number;
    }[];
    referenceTemperature: number;
    setReferenceTemperature: (temp: number) => void;
    onNavigateToTable: () => void;
    selectedSensors: number[];
    tolerance?: number;
    onSensorSelected?: (id: number) => void;
    onSensorDeselected?: (id: number) => void;
    gradientKey?: GradientKey;
}

function MockChart() {
    return (
        <div className="mock-chart">
            <Chart
                onPointerOver={(id, over) => {}}
                selectedSensors={[]}
                onSensorSelected={(id, selected) => {}}
                referenceTemperature={0}
                objects={[mockData]}
                tolerance={1.0}
                continuousSpin={true}
            />
        </div>
    );
}

function House3D(props: House3DProps) {
    const {
        objectId,
        stateId,
        onNavigateToTable,
        selectedSensors,
        onSensorSelected,
        onSensorDeselected,
        sensors,
        tolerance,
        referenceTemperature,
        setReferenceTemperature,
        gradientKey,
    } = props;
    const { data, error } = use3DData(objectId, stateId);

    const [selectedSensor, setSelectedSensor] = useState<string>();
    const { t } = useTranslation();

    const [isReferenceSet, setIsReferenceSet] = useState(false);

    useEffect(() => {
        if (data !== undefined && !isReferenceSet) {
            const min = Math.min(
                ...data.averageTemperatures.map((dp) => dp.fromTemperature),
            );
            const max = Math.max(
                ...data.averageTemperatures.map((dp) => dp.fromTemperature),
            );

            if (Number.isNaN(referenceTemperature)) {
                if (data.referenceTemperatures?.pseudoDOT) {
                    setReferenceTemperature(
                        Math.round(data.referenceTemperatures.pseudoDOT),
                    );
                    setIsReferenceSet(true);
                } else if (data.referenceTemperatures?.dot) {
                    setReferenceTemperature(
                        Math.round(data.referenceTemperatures.dot),
                    );
                    setIsReferenceSet(true);
                }
            } else if (!Number.isFinite(referenceTemperature)) {
                setReferenceTemperature(NaN);
                setIsReferenceSet(true);
            } else {
                if (min > referenceTemperature) {
                    setReferenceTemperature(min);
                    setIsReferenceSet(true);
                }
                if (max < referenceTemperature) {
                    setReferenceTemperature(max);
                    setIsReferenceSet(true);
                }
            }
        }
    }, [data, referenceTemperature, isReferenceSet, setReferenceTemperature]);
    const container = useRef(null);

    useEffect(() => {
        if (error)
            message.error(
                t(
                    `3D model is incomplete. Please contact us for more information.`,
                ),
                7,
            );
    }, [error, t]);

    return (
        <div className="relative w-full h-full" ref={container}>
            <b className="absolute right-0 top-0">{selectedSensor}</b>
            {data && data.blocks.length !== 0 && (
                <>
                    <Chart
                        onPointerOver={(id, over) => {
                            if (over) {
                                const sensor = sensors.find(
                                    (sensor) => sensor.id === id,
                                );

                                setSelectedSensor(sensor?.sensorName);
                            } else {
                                setSelectedSensor(undefined);
                            }
                        }}
                        selectedSensors={selectedSensors}
                        onSensorSelected={(id, selected) => {
                            if (selected) {
                                onSensorSelected?.(id);
                            } else {
                                onSensorDeselected?.(id);
                            }
                        }}
                        referenceTemperature={referenceTemperature!}
                        objects={[data]}
                        tolerance={tolerance}
                        continuousSpin={false}
                        gradientKey={gradientKey}
                    />
                </>
            )}

            {data && data.blocks.length === 0 && (
                <>
                    {/* Overlay */}
                    <div className="w-full h-full flex items-center justify-center top-0  absolute z-30">
                        <div className="bg-black opacity-50 absolute w-full h-full z-0" />
                        <b className="mock-text text-white z-10">
                            {t('3D model missing. Contact us to get one.')}
                            <br />
                            <b
                                onClick={onNavigateToTable}
                                className="underline cursor-pointer"
                            >
                                {t('Press here for table view')}
                            </b>
                        </b>
                    </div>

                    <MockChart />
                </>
            )}

            {!data && !error && (
                <div className="absolute text-center flex align-middle flex-col left-1/2 top-1/2 transform -translate-x-1/2">
                    <h2>{t('Loading 3D model')}</h2>
                    <Spin />
                </div>
            )}
            {error && (
                <div className="absolute text-center flex align-middle flex-col left-1/2 top-1/2 transform -translate-x-1/2">
                    <h2>
                        {<WarningOutlined />} {t('Could not load 3D model')}
                    </h2>
                </div>
            )}
        </div>
    );
}

export default House3D;
