import { Table, Typography, Spin } from 'antd';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useViewport from 'src/hooks/generic/useViewport';
import { useNavigate } from 'react-router-dom';
import { Site } from 'src/models/Site';
import { SiteObject } from 'src/models/SiteObject';
import Chart from './3d/chart';
import { V3D_Object } from './3d/chart/types';
import useSite3DData from './hooks/useSite3DData';
import Nav from 'src/utils/nav/Nav';
import { generateGradientKey, gradients } from 'src/utils/gradients';
import { GradientKey } from 'src/models/GradientKey';
import SkeletonTable from './SkeletonTable';

function filterSensors(
    visualization: V3D_Object,
    dot: number,
    predicate: (value: number) => boolean,
) {
    const result: any[] = [];
    let numberOfDatapoints = 0;

    visualization.blocks.forEach((block) => {
        for (const datapoint of block.datapoints) {
            const temperatures = datapoint.temperatures.find(
                (t) =>
                    t.fromTemperature === Math.round(dot) &&
                    predicate(t.toTemperature),
            );

            if (temperatures !== undefined) {
                result.push(datapoint);
            }

            ++numberOfDatapoints;
        }
    });

    return { result, numberOfDatapoints };
}

interface ObjectTableViewProps {
    visualization: V3D_Object;
    object: SiteObject;
}

function ObjectTableView(props: ObjectTableViewProps) {
    const { object, visualization } = props;

    const { t } = useTranslation();
    const history = useNavigate();

    const [sensors, setSensors] = useState<any>();

    useEffect(() => {
        if (visualization.referenceTemperatures) {
            const cold = filterSensors(
                visualization,
                visualization.referenceTemperatures?.dot ?? NaN,
                (value) => value < (visualization.thresholds?.low ?? 0),
            );

            const hot = filterSensors(
                visualization,
                visualization.referenceTemperatures?.dot ?? NaN,
                (value) => value > (visualization.thresholds?.high ?? 99),
            );

            setSensors({ cold, hot });
        }
    }, [visualization]);

    const [data, setData] = useState<any>([]);

    useEffect(() => {
        if (sensors) {
            const values = [
                {
                    key:
                        visualization.referenceTemperatures.pseudoDOT !== null
                            ? t('Average temperature at {{degrees}}°C', {
                                  degrees:
                                      visualization.referenceTemperatures
                                          .pseudoDOT,
                              })
                            : t('Average temperature at DOT'),
                    value: `${
                        visualization.referenceTemperatures?.average
                            ? visualization.referenceTemperatures.average.toFixed(
                                  1,
                              ) + '°C'
                            : t('Not measured')
                    }`,
                },
                {
                    key: t('Cold'),
                    value: `${sensors.cold.result.length} st, ${Math.round(
                        (sensors.cold.result.length * 100) /
                            sensors.cold.numberOfDatapoints,
                    )}%`,
                },
                {
                    key: t('Hot'),
                    value: `${sensors.hot.result.length} st, ${Math.round(
                        (sensors.hot.result.length * 100) /
                            sensors.hot.numberOfDatapoints,
                    )}%`,
                },
                {
                    key: t('Potential savings'),
                    value: `${(100 * visualization.potentialSavings).toFixed(
                        1,
                    )}%`,
                },
            ];

            setData(values);
        }
    }, [t, sensors, visualization]);

    const navigateToAnalyticsPage = () => {
        history(
            Nav.build({
                path: '/analytics/:objectId',
                queryParams: {},
                routeParams: {
                    objectId: object.id,
                },
            }),
        );
    };

    const columns = [
        {
            title: object.name,
            dataIndex: 'key',
        },
        {
            title: '',
            dataIndex: 'value',
        },
    ];

    return (
        <div>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={false}
                onHeaderRow={() => ({
                    onClick: navigateToAnalyticsPage,
                })}
                onRow={() => ({ onClick: navigateToAnalyticsPage })}
                className="cursor-pointer hover:border-gray-400 border"
                bordered
            />
        </div>
    );
}

interface SiteTableViewProps {
    site: Site;
    visualization: V3D_Object[];
}

function SiteTableView(props: SiteTableViewProps) {
    const { site, visualization } = props;

    return (
        <>
            {site.objects?.map((object) => (
                <Suspense key={object.id}>
                    <ObjectTableView
                        visualization={
                            visualization.find((v) => v.objectId === object.id)!
                        }
                        object={object}
                    />
                </Suspense>
            ))}
        </>
    );
}

function GradientKeyView(props: { gradientKey: GradientKey }) {
    const { gradientKey } = props;

    return (
        <div className="flex gap-2">
            <div className="flex flex-col justify-between rounded-full overflow-hidden">
                {gradientKey.gradient.map((color, i) => (
                    <div
                        className="w-4 flex-1"
                        key={i}
                        style={{
                            backgroundColor: color,
                        }}
                    />
                ))}
            </div>
            <div className="flex flex-col justify-between">
                {gradientKey.labels.map((label, i) => (
                    <Typography.Text
                        type={
                            i !== gradientKey.middle ? 'secondary' : undefined
                        }
                        style={{ width: 'max-content' }}
                        key={i}
                    >
                        {label}°C
                    </Typography.Text>
                ))}
            </div>
        </div>
    );
}

interface Site3DProps {
    site: Site;
}

function Site3D(props: Site3DProps) {
    const { site } = props;

    const { data, isLoading } = useSite3DData(site.id);
    const container = useRef(null);
    const { screen } = useViewport();
    const [hoveredObject, setHoveredObject] = useState<V3D_Object>();

    const gradientKey = generateGradientKey(
        data?.[0]?.thresholds ?? { high: 22, low: 20 },
        gradients.thermometer.mix,
    );

    return (
        <>
            <div
                className="grid w-full h-full gap-8"
                style={{
                    gridTemplateColumns: screen > 1600 ? '1fr 60ch' : '1fr',
                    gridTemplateRows: screen > 1600 ? '1fr' : '1fr 1fr',
                }}
            >
                <div className="relative w-full h-full flex" ref={container}>
                    <div
                        className="absolute top-0 left-0 flex flex-col gap-4 z-10"
                        style={{
                            filter: 'drop-shadow(0 0 0.25rem #ffffff)',
                        }}
                    >
                        <b className="block h-4">{hoveredObject?.objectName}</b>
                        {!isLoading && data && !!data.length && (
                            <GradientKeyView gradientKey={gradientKey} />
                        )}
                    </div>
                    {isLoading && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Spin size="large" />
                        </div>
                    )}
                    {!isLoading && data && !!data.length && (
                        <Chart
                            onObjectPointerOver={(object) => {
                                setHoveredObject(object);
                            }}
                            onPointerOver={(id, over) => {}}
                            selectedSensors={[]}
                            onSensorSelected={(id, selected) => {}}
                            objects={data}
                            continuousSpin={false}
                            gradientKey={gradientKey}
                        />
                    )}
                </div>
                <div className="flex flex-col gap-4 overflow-scroll">
                    {isLoading && (
                        <SkeletonTable site={site} loading={isLoading} />
                    )}
                    {!isLoading &&
                        data &&
                        site.objects &&
                        data.length !== 0 && (
                            <SiteTableView site={site} visualization={data} />
                        )}
                </div>
            </div>
        </>
    );
}

export default Site3D;
