import { API } from 'src/api/API';
import { SiteObject } from 'src/models/SiteObject';
export const useGetHolistic = () => {
    const get = (
        object: Omit<
            SiteObject,
            | 'co2EmissionFactorElectricity'
            | 'co2EmissionFactorHeating'
            | 'outsideWalls'
            | 'siteId'
            | 'siteName'
        >,
    ): Promise<any> => {
        return new API().getHolistic(object).then((v) => {
            return v;
        });
    };
    return [get] as const;
};
