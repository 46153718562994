// import { Select } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPrettySensorName, SensorType } from 'src/models/Sensor';
import { SensorTypeSelector } from './SensorTypeSelector';

// const removeDuplicatedKeys = (object: Record<string, string | number>) => {
//     const out: Record<string, number> = {};

//     for (const key in object) {
//         const value = object[key];
//         if (typeof value !== 'string') {
//             out[key] = value;
//         }
//     }

//     return out;
// };

export interface SensorTypeSelectorProps {
    onChange?: (key: SensorType) => void;
    defaultValue: SensorType;
    forbidenSensors: SensorType[];
}

export const SensorTypeSelectorHidden = (props: SensorTypeSelectorProps) => {
    const { onChange, defaultValue, forbidenSensors } = props;

    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const [value, setValue] = useState(defaultValue);

    return (
        <>
            {isVisible && (
                <SensorTypeSelector
                    defaultValue={value}
                    forbidenSensors={forbidenSensors}
                    open={isVisible}
                    onChange={(sensorType) => {
                        setValue(sensorType);
                        setIsVisible(!isVisible);

                        if (onChange) {
                            onChange(sensorType);
                        }
                    }}
                    onBlur={(event) => {
                        setIsVisible(false);
                    }}
                />
            )}
            {!isVisible && (
                <div
                    className="cursor-pointer border border-gray-300 rounded-sm py-1 px-2.5"
                    onClick={() => {
                        setIsVisible(!isVisible);
                    }}
                >
                    {getPrettySensorName(value, t)}
                </div>
            )}
        </>
    );
};
