import { useNavigate, useParams } from 'react-router-dom';
import Nav from 'src/utils/nav/Nav';
import UpdateSensorView from 'src/views/objects/UpdateSensorView';

function UpdateSensorPage() {
    const {
        objectId: objectIdString,
        sensorId,
        sensorName,
        isActive,
        sensorFunctionTypeId,
    } = useParams<{
        sensorFunctionTypeId: string;
        isActive: string;
        objectId: string;
        sensorId: string;
        sensorName: string;
    }>();
    const objectId = Number.parseInt(objectIdString!);
    const history = useNavigate();

    return (
        <>
            <UpdateSensorView
                onSuccess={() => {
                    history(
                        Nav.build({
                            path: '/manage-object',
                            queryParams: {
                                index: '1',
                                objectId: objectId.toString(),
                            },
                        }),
                    );
                }}
                goBack={() => {
                    history(
                        Nav.build({
                            path: '/manage-object',
                            queryParams: {
                                index: '1',
                                objectId: objectId.toString(),
                            },
                        }),
                    );
                }}
                sensorFunctionTypeId={Number.parseInt(sensorFunctionTypeId!)}
                isActive={isActive === '1' ? true : false}
                sensorName={sensorName!}
                objectId={objectIdString!}
                sensorId={sensorId!}
            />
        </>
    );
}

export default UpdateSensorPage;
