import Auth from '@aws-amplify/auth';

export const useVerifyEmail = () => {
    const verifyEmail = (email: string, code: string) => {
        return Auth.confirmSignUp(email, code)
            .then((res) => {
                return res;
            })
            .catch((e) => {
                throw e;
            });
    };

    return [verifyEmail] as const;
};
