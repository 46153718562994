import { Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPrettySensorName, SensorType } from 'src/models/Sensor';
import { useSelector } from 'react-redux';
import { selectSensors } from 'src/store/reducers/sensorsSlice';

const removeDuplicatedKeys = (object: Record<string, string | number>) => {
    const out: Record<string, number> = {};

    for (const key in object) {
        const value = object[key];
        if (typeof value !== 'string') {
            out[key] = value;
        }
    }

    return out;
};

export interface SensorTypeSelectorProps {
    onChange?: (key: SensorType) => void;
    onBlur?: (event: any) => void;
    defaultValue: SensorType;
    forbidenSensors: SensorType[];
    open?: boolean;
}

export const SensorTypeSelector = (props: SensorTypeSelectorProps) => {
    const { t } = useTranslation();
    const sensorTypes = useSelector(selectSensors) || [];

    return (
        <>
            <Select
                defaultValue={props.defaultValue}
                onChange={props.onChange}
                style={{ width: '100%' }}
                open={props.open}
                onBlur={props.onBlur}
            >
                {Object.entries(removeDuplicatedKeys(SensorType)).map(
                    ([key, value]) => {
                        if (
                            !props.forbidenSensors.includes(value) &&
                            !sensorTypes?.entities.includes(value)
                        )
                            return (
                                <Select.Option key={key} value={value}>
                                    {getPrettySensorName(value, t)}
                                </Select.Option>
                            );
                        else {
                            return null;
                        }
                    },
                )}
            </Select>
        </>
    );
};
