import React from 'react';

import { Space, Alert } from 'antd';
import { StatusCode } from 'src/models/ModbusStatusCode';

interface Props {
    asycnQeueuStatus: StatusCode;
}

const StateMessageBox: React.FC<Props> = ({ asycnQeueuStatus }) => {
    const { message } = asycnQeueuStatus;
    return (
        <div>
            <Space direction="vertical" style={{ width: '100%' }}>
                <Alert description={message} type="error" showIcon={false} />
            </Space>
        </div>
    );
};

export default StateMessageBox;
