import { GradientKey } from 'src/models/GradientKey';
import { Typography } from 'antd';

function GradientKeyViewThermometer(props: {
    gradientKey: GradientKey;
    unit?: string;
}) {
    const { gradientKey, unit } = props;
    const getLabelByIndex = (label: string, index: number) => {
        if (index === 0) {
            return `>${Number(label.split('>')[1]).toFixed(1)}`;
        } else if (index === gradientKey.labels.length - 1) {
            return `<${Number(label.split('<')[1]).toFixed(1)}`;
        } else {
            return Number(label.split('-')[0]).toFixed(1);
        }
    };

    return (
        <div
            className="flex gap-2"
            style={{ height: '200px', alignItems: 'stretch' }}
        >
            <div className="flex flex-col justify-between rounded-full overflow-hidden">
                {gradientKey.gradient.map((color, i) => (
                    <div
                        className="w-4 flex-1"
                        key={i}
                        style={{
                            backgroundColor: color,
                        }}
                    />
                ))}
            </div>
            <div className="flex flex-col justify-between" style={{ flex: 1 }}>
                {gradientKey.labels.map((label, i) => (
                    <Typography.Text
                        type={
                            i !== gradientKey.middle ? 'secondary' : undefined
                        }
                        style={{ width: 'max-content', fontSize: '11px' }}
                        key={i}
                    >
                        {getLabelByIndex(label, i)}{' '}
                        {unit ? (
                            unit
                        ) : (
                            <>
                                g/m<sup>3</sup>
                            </>
                        )}
                    </Typography.Text>
                ))}
            </div>
        </div>
    );
}

export { GradientKeyViewThermometer };
