import {
    AreaChartOutlined,
    BarChartOutlined,
    LineChartOutlined,
    PieChartOutlined,
    SettingFilled,
} from '@ant-design/icons';
import { Skeleton } from 'antd';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TabContainer from 'src/components/TabContainer';
import { TabTitle } from 'src/components/TabTitle';
import { useControlStates } from 'src/hooks/objects/useControllStates';
import { useObject } from 'src/hooks/objects/useObject';
import { useReference } from 'src/hooks/objects/useReference';
import { formatDate } from 'src/utils/formatDate';
import Nav from 'src/utils/nav/Nav';
import {
    BooleanParam,
    StringParam,
    NumberParam,
    useQueryParams,
    withDefault,
} from 'use-query-params';
import AnalyticsView from '../sites/AnalyticsView';
import BalancingView from './BalancingView';
import OptimizeView from './OptimizeView';
import RealTimeView from './RealTimeView';

type GuageValues = {
    ratio: number | undefined;
    savings: number | undefined;
};

interface AnalyticsWrapperProps {
    objectId: number;
}

function AnalyticsWrapper(props: AnalyticsWrapperProps) {
    const [params, setParams] = useQueryParams({
        start: withDefault(
            StringParam,
            formatDate(
                new Date(
                    new Date().setFullYear(new Date().getFullYear(), 0, 1),
                ),
            ),
        ),
        stop: withDefault(
            StringParam,
            formatDate(
                new Date(
                    new Date().setFullYear(new Date().getFullYear() + 1, 0, 1),
                ),
            ),
        ),
        index: withDefault(NumberParam, 0),
        table: withDefault(BooleanParam, false),
        tab: withDefault(StringParam, 'realtime'),
    });

    const { objectId } = props;
    const history = useNavigate();
    const { data: object } = useObject(objectId);
    const {
        data: controlStates,
        mutate: revalidateControlStates,
    } = useControlStates(objectId);
    const { t } = useTranslation();

    const { data: reference } = useReference(objectId);
    const [guageValues] = useState<GuageValues>();
    const [activeTabIndex, setActiveTabIndex] = useState(params.index);

    return (
        <>
            <TabContainer
                children={[
                    {
                        tab: (
                            <TabTitle
                                title={t('Real time')}
                                icon={<AreaChartOutlined />}
                            />
                        ),
                        body: (
                            <>
                                {controlStates && (
                                    <Suspense fallback={<Skeleton />}>
                                        <RealTimeView
                                            objectId={objectId}
                                            controlStates={controlStates}
                                            setActiveTabIndex={
                                                setActiveTabIndex
                                            }
                                        />
                                    </Suspense>
                                )}
                            </>
                        ),
                    },
                    {
                        tab: (
                            <TabTitle
                                title={t('Balancing')}
                                icon={<PieChartOutlined />}
                            />
                        ),
                        body: (
                            <>
                                {controlStates && (
                                    <Suspense fallback={<Skeleton />}>
                                        <BalancingView
                                            objectId={objectId}
                                            controlStates={controlStates}
                                        />
                                    </Suspense>
                                )}
                            </>
                        ),
                    },
                    {
                        tab: (
                            <TabTitle
                                title={t('Optimize')}
                                icon={<LineChartOutlined />}
                            />
                        ),
                        body: (
                            <>
                                {controlStates && (
                                    <Suspense fallback={<Skeleton />}>
                                        <OptimizeView
                                            objectId={objectId}
                                            controlStates={controlStates}
                                            revalidateControlStates={
                                                revalidateControlStates
                                            }
                                        />
                                    </Suspense>
                                )}
                            </>
                        ),
                    },
                    {
                        tab: (
                            <TabTitle
                                icon={
                                    <BarChartOutlined
                                        style={{
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                }
                                title={t('Performance')}
                            />
                        ),
                        body: (
                            <>
                                <Suspense fallback={<Skeleton />}>
                                    {reference && (
                                        <AnalyticsView
                                            objectId={objectId}
                                            savings={guageValues?.savings}
                                            ratio={guageValues?.ratio}
                                            showTable={(v) => {
                                                setParams({
                                                    ...params,
                                                    table: v,
                                                });
                                            }}
                                            table={params.table}
                                            period={{
                                                start: params.start,
                                                stop: params.stop,
                                            }}
                                            reference={reference[0]}
                                            onPeriodChange={(p) => {
                                                setParams({
                                                    ...params,
                                                    start: p.start,
                                                    stop: p.stop,
                                                });
                                            }}
                                        />
                                    )}
                                </Suspense>
                            </>
                        ),
                    },
                ]}
                extra={[
                    <div
                        onClick={() => {
                            history(
                                Nav.build({
                                    path: '/manage-object',
                                    queryParams: {
                                        objectId: objectId.toString(),
                                        siteName: object?.siteName,
                                        siteId: `${object?.siteId}`,
                                    },
                                }),
                            );
                        }}
                    >
                        <TabTitle
                            title={t('Settings')}
                            icon={
                                <SettingFilled
                                    style={{ verticalAlign: 'middle' }}
                                />
                            }
                        />
                    </div>,
                ]}
                setActiveTabIndex={setActiveTabIndex}
                activeTabIndex={activeTabIndex}
            />
        </>
    );
}

export default AnalyticsWrapper;
