import {
    ApiOutlined,
    SettingFilled,
    UnorderedListOutlined,
} from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Select } from 'antd';
import { useControlStates } from 'src/hooks/objects/useControllStates';
import { useTranslation } from 'react-i18next';
import { TabTitle } from 'src/components/TabTitle';
import { useObject } from 'src/hooks/objects/useObject';
import Nav from 'src/utils/nav/Nav';
import ManageObjectView from 'src/views/objects/MangeObjectView';
import ConfigurationView from 'src/views/objects/ConfigurationView';
import {
    useQueryParams,
    withDefault,
    NumberParam,
    StringParam,
} from 'use-query-params';
import ObjectIntegrators from 'src/views/objects/Integrators';
import TabContainer from 'src/components/TabContainer';
import { useNavigate } from 'react-router-dom';
import { API } from 'src/api/API';
import { useMe } from 'src/hooks/users/useMe';
import ReportList from 'src/components/ReportEmail/ReportList';

function ManageReportComponent() {
    const [refreshKey, setRefreshKey] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    const [params] = useQueryParams({
        objectId: withDefault(NumberParam, -1),
    });

    const { data: controlStates } = useControlStates(params.objectId);

    const [controlStateId, setControlStateId] = useState(-1);

    const handleDownloadClick = async () => {
        setIsLoading(true);
        const data = await new API().createReport(
            params.objectId,
            controlStateId,
        );
        if (data?.report_path) {
            window.location.href = data.report_path;
        }
        setIsLoading(false);
        setRefreshKey((prevKey) => prevKey + 1); // Increment the key to force re-render
    };

    useEffect(() => {
        if (controlStates && controlStates.length > 0) {
            setControlStateId(
                controlStates[controlStates.length - 1].state_order,
            );
        }
    }, [controlStates]);

    return (
        <>
            <div className="flex" style={{ margin: '15px' }}>
                <Select
                    key={controlStateId ?? -1}
                    value={controlStateId}
                    onChange={(v) => {
                        setControlStateId(v);
                    }}
                    placeholder={t('Analysis periods')}
                    className="mb-6 w-4/5 sm:w-1/4"
                >
                    {controlStates &&
                        controlStates.length > 0 &&
                        controlStates.map((v: any) => {
                            const startDate: any = v.time_period_start;
                            let stopDate: string | null = v.time_period_stop;
                            let period = '';
                            if (stopDate === null) {
                                period =
                                    startDate.substring(4, 17) +
                                    '-' +
                                    t('Today');
                            } else {
                                period =
                                    startDate.substring(4, 17) +
                                    '-' +
                                    stopDate.substring(4, 17);
                            }

                            return (
                                <Select.Option
                                    key={v.state_order}
                                    value={v.state_order}
                                >
                                    {period}
                                </Select.Option>
                            );
                        })}
                </Select>
                <Button
                    style={{
                        marginLeft: '10px',
                        cursor: 'pointer',
                    }}
                    type="primary"
                    onClick={handleDownloadClick}
                    loading={isLoading}
                >
                    {isLoading ? 'Downloading...' : 'Download'}
                </Button>
            </div>
            <ReportList key={refreshKey} />
        </>
    );
}

function ManageObjectPage() {
    const [params] = useQueryParams({
        objectId: withDefault(NumberParam, -1),
        siteId: withDefault(NumberParam, -1),
        siteName: withDefault(StringParam, ''),
        index: withDefault(StringParam, '0'),
    });
    const { t } = useTranslation();

    const history = useNavigate();
    const { objectId, siteId } = params;
    const { data: object } = useObject(objectId);
    const { data: me } = useMe();
    const isReady = object || objectId === -1;

    return (
        <>
            <TabContainer
                children={[
                    {
                        body: (
                            <>
                                <Row>
                                    <Col span={24}>
                                        {isReady && (
                                            <>
                                                <ManageObjectView
                                                    siteId={siteId}
                                                    object={object}
                                                    onAdd={(siteId) => {
                                                        history(
                                                            Nav.build({
                                                                path:
                                                                    '/sites/objects/:siteId',
                                                                routeParams: {
                                                                    siteId: siteId,
                                                                },
                                                                queryParams: {},
                                                            }),
                                                        );
                                                    }}
                                                />
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            </>
                        ),
                        tab: (
                            <TabTitle
                                title={t('General')}
                                icon={<UnorderedListOutlined />}
                            />
                        ),
                    },
                    {
                        body: (
                            <>
                                <ConfigurationView objectId={objectId} />
                            </>
                        ),
                        tab:
                            objectId !== -1 ? (
                                <TabTitle
                                    title={t('Configuration')}
                                    icon={
                                        <SettingFilled
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                        />
                                    }
                                />
                            ) : (
                                <></>
                            ),
                    },
                    {
                        body: (
                            <>
                                <ObjectIntegrators objectId={objectId} />
                            </>
                        ),
                        tab:
                            objectId !== -1 ? (
                                <TabTitle
                                    title={t('Integrators')}
                                    icon={
                                        <ApiOutlined
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                        />
                                    }
                                />
                            ) : (
                                <></>
                            ),
                    },
                    {
                        body: (
                            <>
                                <ManageReportComponent />
                            </>
                        ),
                        tab:
                            objectId !== -1 &&
                            me &&
                            me.user &&
                            me.user.role === 'ADMIN' ? (
                                <TabTitle
                                    title={'Create Report'}
                                    icon={
                                        <ApiOutlined
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                        />
                                    }
                                />
                            ) : (
                                <></>
                            ),
                    },
                ]}
            />
            {/* <Tabs
                activeKey={params.tab}
                onChange={(key) => setParams({ tab: key })}
                tabBarExtraContent={
                    params.tab === 'config' ? (
                        <>
                            {' '}
                            <Button
                                onClick={() =>
                                    history(
                                        Nav.build({
                                            path: '/create-device/:objectId',
                                            routeParams: {
                                                objectId: objectId,
                                            },
                                            queryParams: {},
                                        }),
                                    )
                                }
                                type="primary"
                                className="p-2"
                            >
                                {t('Add device')}
                            </Button>{' '}
                        </>
                    ) : (
                        <></>
                    )
                }
            >
                <TabPane
                    key="general"
                    tab={
                        <TabTitle
                            title={t('General')}
                            icon={<UnorderedListOutlined />}
                        />
                    }
                >
                    <Row>
                        <Col span={24}>
                            {isReady && (
                                <>
                                    <ManageObjectView
                                        siteId={siteId}
                                        object={object}
                                        onSuccess={() => {
                                            history(
                                                Nav.build({
                                                    path: '/sites/objects/:siteId',
                                                    queryParams: {},
                                                    routeParams: {
                                                        siteId: siteId,
                                                    },
                                                }),
                                            );
                                        }}
                                    />
                                </>
                            )}
                        </Col>
                    </Row>
                </TabPane>
                <Tabs.TabPane
                    disabled={objectId === -1}
                    key="config"
                    tab={
                        <span className="text-xl">
                            <SettingFilled
                                style={{ verticalAlign: 'middle' }}
                            />
                            {t('Configuration')}
                        </span>
                    }
                >
                    <ConfigurationView objectId={objectId} />
                </Tabs.TabPane>

                <Tabs.TabPane
                    disabled={objectId === -1}
                    key="integrators"
                    tab={
                        <span className="text-xl">
                            <ApiOutlined
                                style={{ verticalAlign: 'middle' }}
                            />
                            {t('Integrators')}
                        </span>
                    }
                >
                    <ObjectIntegrators objectId={objectId} />
                </Tabs.TabPane>
            </Tabs> */}
        </>
    );
}

export default ManageObjectPage;
