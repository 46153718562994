import { PlusOutlined } from '@ant-design/icons';
import { Typography, Affix, Avatar, Dropdown, Menu, Table } from 'antd';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Site3D from 'src/components/House3D/Site3D';
import SkeletonTable from 'src/components/House3D/SkeletonTable';
import useViewport from 'src/hooks/generic/useViewport';
import useSiteObjectsStats from 'src/hooks/sites/useSiteObjectsStats';
import { Site } from 'src/models/Site';
import { SiteObject } from 'src/models/SiteObject';
import Nav from 'src/utils/nav/Nav';

interface Props {
    site: Site;
    tabBarExtraContent?: React.ReactNode;
}

interface ObjectRowProps {
    name: string;
    id: number;
    long: number | null;
    lat: number | null;
    placeId: string | null;
}
interface ObjectListProps {
    objects: SiteObject[];
    siteId: string;
}

export const ObjectList = (props: ObjectListProps) => {
    const history = useNavigate();
    const ObjectRow = (props: ObjectRowProps) => {
        return (
            <>
                {true && (
                    <div className="pt-4 cursor-pointer w-full flex">
                        <div
                            onClick={() => {
                                history(
                                    Nav.build({
                                        path: '/analytics/:objectId',
                                        queryParams: {},
                                        routeParams: { objectId: props.id },
                                    }),
                                );
                            }}
                            className="flex flex-col w-full"
                        >
                            <Typography.Text
                                style={{ fontSize: '20px', fontWeight: 200 }}
                                className="float-left p-1"
                            >
                                <b>{props.name}</b>
                            </Typography.Text>
                            <Typography.Text
                                style={{ color: '#A1A5AA', fontWeight: 450 }}
                                className="text-l p-1"
                            />
                        </div>
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            {props.objects.map((object) => (
                <ObjectRow
                    id={object.id}
                    name={object.name}
                    long={object.longitude}
                    lat={object.latitude}
                    placeId={object.placeId ?? null}
                    key={object.id}
                />
            ))}
        </>
    );
};

interface SiteVisualizationProps {
    site: Site;
}

function SiteVisualization(props: SiteVisualizationProps) {
    const { site } = props;

    return (
        <>
            {site.hasVisualization && (
                <Suspense>
                    <Site3D site={site} />
                </Suspense>
            )}
        </>
    );
}

function ObjectTableView(props: { object: SiteObject; stats: any }) {
    const { object, stats } = props;

    const history = useNavigate();
    const { t } = useTranslation();

    const navigateToAnalyticsPage = () => {
        history(
            Nav.build({
                path: '/analytics/:objectId',
                queryParams: {},
                routeParams: {
                    objectId: object.id,
                },
            }),
        );
    };

    const data = [
        {
            key:
                stats?.referenceTemperatures.pseudoDOT !== null
                    ? t('Average temperature at {{degrees}}°C', {
                          degrees: stats?.referenceTemperatures.pseudoDOT,
                      })
                    : t('Average temperature at DOT'),
            value: stats?.referenceTemperatures.average
                ? `${stats?.referenceTemperatures.average.toFixed(1)} °C`
                : t('Not measured'),
        },
        {
            key: t('Cold'),
            value: `${stats?.cold ?? 0} st, ${Math.round(
                stats
                    ? (100 * stats?.cold) /
                          (stats?.numberOfSensors !== 0
                              ? stats?.numberOfSensors
                              : 1)
                    : 0,
            )}%`,
        },
        {
            key: t('Hot'),
            value: `${stats?.hot ?? 0} st, ${Math.round(
                stats
                    ? (100 * stats?.hot) /
                          (stats?.numberOfSensors !== 0
                              ? stats?.numberOfSensors
                              : 1)
                    : 0,
            )}%`,
        },
        {
            key: t('Potential savings'),
            value: `${(100 * (stats?.potentialSavings ?? 0)).toFixed(1)}%`,
        },
    ];

    const columns = [
        {
            title: object.name,
            dataIndex: 'key',
        },
        {
            title: '',
            dataIndex: 'value',
        },
    ];

    return (
        <Table
            size="middle"
            columns={columns}
            dataSource={data}
            pagination={false}
            onHeaderRow={() => ({
                onClick: navigateToAnalyticsPage,
            })}
            onRow={() => ({ onClick: navigateToAnalyticsPage })}
            className="cursor-pointer hover:border-gray-400 border"
            bordered
        />
    );
}

function SiteTableView(props: { site: Site }) {
    const { site } = props;

    const { data: stats, isLoading } = useSiteObjectsStats(site.id);
    const { mode } = useViewport();

    return (
        <div
            className="grid gap-4"
            style={{
                gridTemplateColumns: `repeat(auto-fit, ${
                    mode === 'mobile' ? '1fr' : 'minmax(50ch, 1fr)'
                })`,
            }}
        >
            {isLoading && <SkeletonTable site={site} loading={isLoading} />}
            {!isLoading &&
                stats &&
                !!stats.length &&
                site.objects?.map((object) => (
                    <ObjectTableView
                        object={object}
                        stats={stats.find(
                            (stat: any) => stat.objectId === object.id,
                        )}
                    />
                ))}
        </div>
    );
}

export default function ObjectsView(props: Props) {
    const { t } = useTranslation();
    const { site } = props;
    const { mode } = useViewport();

    const history = useNavigate();

    return (
        <>
            {site.objects?.length === 0 && (
                <Typography.Text type="secondary">
                    {t('No objects')}
                </Typography.Text>
            )}
            {site.hasVisualization && <SiteVisualization site={site} />}
            {!site.hasVisualization && (
                <div style={{ height: mode === 'mobile' ? '90vh' : 'auto' }}>
                    {site.objects && (
                        <Suspense>
                            <SiteTableView site={site} />
                        </Suspense>
                    )}
                </div>
            )}
            {mode === 'mobile' && (
                <Affix
                    style={{
                        position: 'absolute',
                        top: '80%',
                        left: '80%',
                    }}
                >
                    <div>
                        <Dropdown
                            placement="topLeft"
                            trigger={['click', 'hover']}
                            arrow
                            overlay={
                                <Menu className="-translate-x-5" mode="inline">
                                    <Menu.Item
                                        onClick={() =>
                                            history(
                                                Nav.build({
                                                    path: '/manage-object',
                                                    queryParams: {
                                                        siteId: props.site.id.toString(),
                                                    },
                                                }),
                                            )
                                        }
                                    >
                                        {t('Add object')}
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Avatar
                                src={
                                    <PlusOutlined
                                        className="text-2xl"
                                        style={{ verticalAlign: 'middle' }}
                                    />
                                }
                                style={{ backgroundColor: '#51baa2' }}
                                size={65}
                                className="circle-shadow"
                            />
                        </Dropdown>
                    </div>
                </Affix>
            )}
        </>
    );
}
