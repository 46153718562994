import { useState, useEffect } from 'react';

const useViewport = () => {
    const mobileBreakPoint = 650;
    const [mode, setMode] = useState<'desktop' | 'mobile'>(
        window.innerWidth > mobileBreakPoint ? 'desktop' : 'mobile',
    );
    const [screen, setScreen] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setMode(
                window.innerWidth > mobileBreakPoint ? 'desktop' : 'mobile',
            );
            setScreen(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return { mode, screen };
};

export default useViewport;
