import logoSrc from 'src/assets/LogoWithoutText.svg';
import textSrc from 'src/assets/TextLogo.svg';
import { Languages, useLanguage } from 'src/hooks/generic/useLanguage';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags';
import { useMe } from 'src/hooks/users/useMe';
import useViewport from 'src/hooks/generic/useViewport';
import { Col, Popover, Row, Menu, message } from 'antd';
import { useNavigate } from 'react-router';
import { ColorSelector } from 'src/utils/ColorSelector';
import {
    HomeOutlined,
    ControlOutlined,
    SettingOutlined,
    ControlFilled,
    SettingFilled,
    HomeFilled,
    LogoutOutlined,
    ArrowLeftOutlined,
    EnvironmentOutlined,
    EnvironmentFilled,
} from '@ant-design/icons';
import Nav from 'src/utils/nav/Nav';
import { useSignOut } from 'src/hooks/users/useSignOut';

const LanguageSelector = () => {
    const [setLanguage, { language, allLanguages }] = useLanguage();

    return (
        <div className="overflow-auto">
            {Object.entries(allLanguages).map((entry) => {
                const [key, value] = entry;
                return (
                    <div
                        onClick={() => setLanguage(key as Languages)}
                        className="m-2 float-left h-4 cursor-pointer "
                    >
                        {key === language && (
                            <div className="h-full">
                                <Flag
                                    code={value.flagCode}
                                    className="h-full"
                                />
                            </div>
                        )}
                        {key !== language && (
                            <Flag code={value.flagCode} className="h-full" />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

const DeskTopbar = () => {
    const [, { language, allLanguages }] = useLanguage();
    const IconsStyle: React.CSSProperties = {
        fontSize: '16px',
        marginRight: '10px',
    };

    const history = useNavigate();
    const [signOut] = useSignOut();
    const { t } = useTranslation();
    const { data: me } = useMe();

    const handleSettingsClick = () => {
        history(Nav.build({ path: '/settings', queryParams: {} }));
    };

    const handleSignOutClick = () => {
        signOut().then(() => {
            message.success(
                'Sign out successful. Please redirect or reload the page',
            );
            history(Nav.build({ path: '/login' }));
        });
    };

    return (
        <>
            <div className="p-3 shadow-md">
                <Row>
                    <Col span={8}>
                        <img className="h-5" src={logoSrc} alt="Nrlyze logo" />
                    </Col>
                    <Col span={8}>
                        <div className="flex">
                            <a href="https://www.nrlyze.se/" className="m-auto">
                                <img className="h-5" src={textSrc} alt="" />
                            </a>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="flex" style={{ float: 'right' }}>
                            <Popover
                                placement="bottomRight"
                                content={<LanguageSelector />}
                            >
                                <Flag
                                    code={allLanguages[language].flagCode}
                                    className="w-8 mr-4"
                                />
                            </Popover>
                            <div style={{ width: '3rem' }}>
                                <Popover
                                    placement="bottomLeft"
                                    content={
                                        <Menu style={{ border: 'none' }}>
                                            <Menu.Item
                                                key="settings"
                                                onClick={handleSettingsClick}
                                            >
                                                <SettingOutlined
                                                    style={IconsStyle}
                                                />
                                                {t('Settings')}
                                            </Menu.Item>
                                            <Menu.Item
                                                key="signOut"
                                                onClick={handleSignOutClick}
                                            >
                                                <LogoutOutlined
                                                    style={IconsStyle}
                                                />
                                                {t('Sign out')}
                                            </Menu.Item>
                                        </Menu>
                                    }
                                >
                                    {me &&
                                        me.corporations &&
                                        me.corporations[0].imgSrc && (
                                            <div className="w-8 mr-4 float-right">
                                                <div className="h-full w-full border-zinc-600 border rounded-full ">
                                                    <img
                                                        className="m-auto h-full object-scale-down w-full max-h-full max-w-full"
                                                        alt="Corp logo"
                                                        src={
                                                            me.corporations[0]
                                                                .imgSrc
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    {me &&
                                        me.corporations &&
                                        !me.corporations[0].imgSrc && (
                                            <div className="flex ">
                                                <div
                                                    className="w-12 h-12 flex-none m-auto "
                                                    style={{
                                                        width: '2rem',
                                                        height: '2rem',
                                                    }}
                                                >
                                                    <div
                                                        className="h-full w-full rounded-full text-white flex "
                                                        style={{
                                                            backgroundColor: ColorSelector.getColor(
                                                                me
                                                                    .corporations[0]
                                                                    .name,
                                                            ),
                                                        }}
                                                    >
                                                        <div className="m-auto select-none">
                                                            {me.corporations[0].name?.charAt(
                                                                0,
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </Popover>
                            </div>
                        </div>
                    </Col>
                    {/* <Col span={6}>
       
                    </Col> */}
                </Row>
            </div>
        </>
    );
};

const MobileTopBar = (props: Props) => {
    const history = useNavigate();

    return (
        <>
            <div className="flex w-full justify-center border-t bg-white ">
                <div className="h-14 w-4/5 flex bottom-0 bg-white justify-between text-xl items-center">
                    <ArrowLeftOutlined
                        onClick={() => history(-1)}
                        style={{
                            verticalAlign: 'middle',
                        }}
                    />

                    {props.active !== 'home' ? (
                        <HomeOutlined
                            style={{ verticalAlign: 'middle' }}
                            onClick={() =>
                                history(Nav.build({ path: '/home' }))
                            }
                        />
                    ) : (
                        <HomeFilled
                            style={{
                                color: '#51baa2',
                                verticalAlign: 'middle',
                            }}
                        />
                    )}
                    {props.active !== 'sites' ? (
                        <ControlOutlined
                            style={{ verticalAlign: 'middle' }}
                            onClick={() =>
                                history(Nav.build({ path: '/sites' }))
                            }
                        />
                    ) : (
                        <ControlFilled
                            style={{
                                color: '#51baa2',
                                verticalAlign: 'middle',
                            }}
                            onClick={() =>
                                history(Nav.build({ path: '/sites' }))
                            }
                        />
                    )}
                    {props.active !== 'tree' ? (
                        <EnvironmentOutlined
                            style={{ verticalAlign: 'middle' }}
                            onClick={() =>
                                history(
                                    Nav.build({
                                        path: '/tree',
                                        queryParams: {},
                                    }),
                                )
                            }
                        />
                    ) : (
                        <EnvironmentFilled
                            style={{
                                color: '#51baa2',
                                verticalAlign: 'middle',
                            }}
                            onClick={() =>
                                history(
                                    Nav.build({
                                        path: '/tree',
                                        queryParams: {},
                                    }),
                                )
                            }
                        />
                    )}
                    {props.active !== 'settings' ? (
                        <SettingOutlined
                            style={{ verticalAlign: 'middle' }}
                            onClick={() =>
                                history(
                                    Nav.build({
                                        path: '/settings',
                                        queryParams: {},
                                    }),
                                )
                            }
                        />
                    ) : (
                        <SettingFilled
                            style={{
                                color: '#51baa2',
                                verticalAlign: 'middle',
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

type Props = {
    active: string;
};

export default function Topbar(props: Props) {
    const { mode } = useViewport();

    return (
        <>
            {mode === 'desktop' && <DeskTopbar />}
            {mode === 'mobile' && <MobileTopBar active={props.active} />}
        </>
    );
}
