import { API } from 'src/api/API';

export const useCreateBlocks = () => {
    const createBlocks = async (blocks: any): Promise<any> => {
        try {
            const res = await new API().createBlocks(blocks);
            return res;
        } catch (error) {
            console.error('Error creating blocks:', error);
            throw error;
        }
    };

    return [createBlocks] as const;
};
