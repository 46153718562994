import { useHealthCheck } from 'src/hooks/generic/useHealthCheck';
import F00 from 'src/pages/500';

type Props = {
    children: JSX.Element;
};

function ErrorHandler(props: Props) {
    const healtCheck = useHealthCheck();
    return <>{healtCheck && healtCheck.error ? <F00 /> : props.children}</>;
}

export default ErrorHandler;
