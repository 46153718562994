import { API } from 'src/api/API';
import { Site } from 'src/models/Site';
import { mutate } from 'swr';

export const useRemoveSiteFromCorporation = () => {
    const remove = async (
        coprorationId: number,
        siteId: number,
    ): Promise<Site[]> => {
        return new API()
            .removeSiteFromCorporation(coprorationId, siteId)
            .then(async (res) => {
                await mutate(`/corporations/${coprorationId}/sites`, res);
                return res;
            });
    };

    return [remove] as const;
};
