import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store/rootReducer';

export type MasterState = {
    master: any;
    loading: 'idle' | 'pending';
    error: any;
    data: any[];
};

const initialState: MasterState = {
    master: null,
    loading: 'idle',
    error: null,
    data: [],
};

export const masterModbusSlice = createSlice({
    name: 'masterModbus',
    initialState,
    reducers: {
        setMasterModbusProperties: (state, action: PayloadAction<any>) => {
            state.master = { ...action.payload };
        },
        setMasterModbusData: (state, action: PayloadAction<any[]>) => {
            state.data = [...action.payload];
        },
    },
});

export const selectModbus = (state: RootState) => state.masterModbus;

// Slice action creators
export const {
    setMasterModbusProperties,
    setMasterModbusData,
} = masterModbusSlice.actions;

export default masterModbusSlice.reducer;
