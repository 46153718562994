import { Input, notification, Skeleton, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useIntegratedSensors } from 'src/hooks/integrations/useIntegratedSensors';
import { useIntegration } from 'src/hooks/integrations/useIntegration';
import {
    IntegrationTag,
    useListAllTagsOnIntegration,
} from 'src/hooks/integrations/useListAllTagsOnIntegration';
import { useOrganizeTagsOnIntegration } from 'src/hooks/integrations/useOrganizeTagsOnIntegration';
import { Integration } from 'src/models/Integration';
import { SensorType } from 'src/models/Sensor';
import Nav from 'src/utils/nav/Nav';
import IntegratorTagTreeView from 'src/views/objects/IntegrationTagTreeView';
import { useDebounce } from 'use-debounce';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import IntegratorModbusView from 'src/views/objects/IntegratorModbusView';
import IntegratorModbusTcpView from 'src/views/objects/IntegratorModbusTcpView';

export interface SensorTypeToTagMapping {
    tag: IntegrationTag;
    sensorType: SensorType;
}

interface Props {
    integration?: Integration;
}

function selectTag(
    tag: IntegrationTag,
    sensorType: SensorType,
    selectedTags: SensorTypeToTagMapping[],
) {
    if (sensorType === SensorType.NONE) {
        return selectedTags.filter(
            (selection) => selection.tag.key !== tag.key,
        );
    } else {
        const index = selectedTags.findIndex(
            (selection) => selection.tag.key === tag.key,
        );
        if (index === -1) {
            return [
                ...selectedTags,
                {
                    tag: tag,
                    sensorType: sensorType,
                },
            ];
        } else {
            if (selectedTags[index].sensorType !== sensorType) {
                selectedTags[index].sensorType = sensorType;
            }

            return selectedTags;
        }
    }
}

function IntegrationPageNonModbus(props: Props) {
    const { integration } = props;
    const [{ objectId, integrationId }] = useQueryParams({
        objectId: withDefault(NumberParam, -1),
        integrationId: withDefault(NumberParam, -1),
    });

    const { data: tagTree } = useListAllTagsOnIntegration(
        objectId,
        integrationId,
    );

    const [searchQuery, setSearchQuery] = useState<string | undefined>(
        undefined,
    );

    const [organize] = useOrganizeTagsOnIntegration();

    const { data: integratedSensors } = useIntegratedSensors(
        objectId,
        integrationId,
    );

    const [selectedTags, setSelectedTags] = useState<SensorTypeToTagMapping[]>(
        [],
    );
    const [debouncedSearchQuery] = useDebounce(searchQuery, 100);

    const { t } = useTranslation();
    const history = useNavigate();

    return (
        <div className="flex flex-col gap-y-4 mx-2 sm:mx-0">
            <Typography.Title level={5}>
                {t('Sensors from {{name}}', {
                    name: integration?.integratorName,
                })}
            </Typography.Title>
            <Input
                value={searchQuery}
                placeholder={t('Search (separate keywords with ",")')}
                onChange={(e) => {
                    setSearchQuery(e.target.value);
                }}
            />
            {tagTree && integration && integratedSensors && (
                <IntegratorTagTreeView
                    tagTree={tagTree}
                    searchQuery={debouncedSearchQuery?.split(',')}
                    integration={integration}
                    integratedSensors={integratedSensors}
                    selectedTags={selectedTags}
                    onSubmit={(result: any) => {
                        organize(objectId, integrationId, result)
                            .then(() =>
                                history(
                                    Nav.build({
                                        path: '/analytics/:objectId',
                                        queryParams: {},
                                        routeParams: { objectId: objectId },
                                    }),
                                ),
                            )
                            .catch((e) =>
                                notification.open({
                                    message: t('Unable to sign in'),
                                    type: 'error',
                                    description: t(e.message),
                                    placement: 'bottomRight',
                                }),
                            );
                    }}
                    onSelect={(sensorType, tag) => {
                        setSelectedTags(
                            selectTag(tag, sensorType, selectedTags),
                        );
                    }}
                    onSelectMany={(sensorType, tags) => {
                        const newTags: SensorTypeToTagMapping[] = [];
                        const newSelectedTags = [...selectedTags];

                        for (const tag of tags) {
                            const selection = selectedTags.find(
                                (s) => s.tag.name === tag.name,
                            );
                            const newSelection: SensorTypeToTagMapping = {
                                tag,
                                sensorType,
                            };

                            if (selection === undefined) {
                                newTags.push(newSelection);
                            } else {
                                for (const ns of newSelectedTags) {
                                    if (ns === selection) {
                                        ns.sensorType = sensorType;
                                        break;
                                    }
                                }
                            }
                        }

                        setSelectedTags([...newSelectedTags, ...newTags]);
                    }}
                />
            )}
            {!tagTree && <Skeleton />}
        </div>
    );
}

function SetupIntegrationPage() {
    const [{ objectId, integrationId }] = useQueryParams({
        objectId: withDefault(NumberParam, -1),
        integrationId: withDefault(NumberParam, -1),
    });

    const { data: integration } = useIntegration(objectId, integrationId);

    if (integration && integration.integratorName === 'Modbus master') {
        return (
            <IntegratorModbusView
                objectId={objectId}
                integratorId={integration?.integratorId}
                integrationId={integration.integrationId}
            />
        );
    } else if (integration && integration.integratorName === 'Modbus tcp') {
        return (
            <IntegratorModbusTcpView
                objectId={objectId}
                integratorId={integration?.integratorId}
                integrationId={integration.integrationId}
            />
        );
    } else {
        return <IntegrationPageNonModbus integration={integration} />;
    }
}

export default SetupIntegrationPage;
