import { API } from 'src/api/API';
import { CreateSensorDTO, Sensor } from 'src/models/Sensor';
import { mutate } from 'swr';
export const useAddSensor = () => {
    const add = (
        objectId: number,
        sensor: CreateSensorDTO,
    ): Promise<Sensor> => {
        return new API().addSensor(sensor, objectId).then((v) => {
            mutate(`/objects`);
            mutate(`/objects/${objectId}/sensor`);
            return v;
        });
    };
    return [add] as const;
};
