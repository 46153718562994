import React, { useState } from 'react';
import { Modal, Input, Button, Select } from 'antd';
import { Site } from 'src/models/Site';
import { SiteObject } from 'src/models/SiteObject';

interface Props {
    title: string;
    message: string;
    onSubmit: (value: number, blockNames: string[]) => void;
    onChangeObject: (value: number) => void;
    sites: Site[] | undefined;
    setPrompt: (value: boolean) => void;
}

const Prompt: React.FC<Props> = ({
    title,
    message,
    onSubmit,
    sites,
    onChangeObject,
    setPrompt,
}) => {
    const [inputValue, setInputValue] = useState<number>(1);
    const [blockNames, setBlockNames] = useState<string[]>(
        Array.from({ length: 1 }, () => ''),
    );
    const [siteObjects, setSiteObjects] = useState<SiteObject[]>([]);

    const handleInputChange = (index: number, value: string) => {
        const newBlockNames = [...blockNames];
        newBlockNames[index] = value;
        setBlockNames(newBlockNames);
    };

    const handleSubmit = () => {
        if (inputValue && inputValue >= 1) {
            onSubmit(inputValue, blockNames);
        }
    };

    return (
        <Modal
            title={title}
            open={true} // Set the visibility as needed
            onCancel={() => {
                setPrompt(false);
            }} // Define onCancel behavior as needed
            footer={[
                <Button
                    key="cancel"
                    onClick={() => {
                        setPrompt(false);
                    }}
                >
                    Cancel
                </Button>, // Define onCancel behavior as needed
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    OK
                </Button>,
            ]}
        >
            <div className="flex mb-2">
                <div>
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Select a site"
                        optionFilterProp="children"
                        onChange={(siteId: number) => {
                            const siteObjects =
                                sites?.find((site) => site.id === siteId)
                                    ?.objects || [];
                            setSiteObjects(siteObjects);
                        }}
                        filterOption={(input, option) =>
                            ((option?.children as unknown) as string)
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                    >
                        {sites?.map((site) => (
                            <Select.Option key={site.id} value={site.id}>
                                {site.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                {!!siteObjects.length && (
                    <div className="ml-2">
                        <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Select a site object"
                            optionFilterProp="children"
                            onChange={onChangeObject}
                            filterOption={(input, option) =>
                                ((option?.children as unknown) as string)
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                        >
                            {siteObjects?.map((siteObject) => (
                                <Select.Option
                                    key={siteObject.id}
                                    value={siteObject.id}
                                >
                                    {siteObject.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                )}
            </div>
            <p>{message}</p>
            <Input
                type="number"
                value={inputValue.toString()}
                onChange={(e) => {
                    const newValue = Number(e.target.value);
                    setInputValue(newValue);

                    // Preserve existing block names and only add new ones
                    if (newValue > blockNames.length) {
                        const newBlockNames = [...blockNames];
                        for (let i = blockNames.length; i < newValue; i++) {
                            newBlockNames.push(``);
                        }
                        setBlockNames(newBlockNames);
                    } else {
                        // If reducing the number, trim the block names array
                        setBlockNames(blockNames.slice(0, newValue));
                    }
                }}
            />
            {inputValue < 1 && (
                <p style={{ color: 'red' }}>
                    Value must be greater than or equal to 1.
                </p>
            )}
            <div style={{ marginTop: '8px' }}>
                {blockNames.map((name, index) => (
                    <Input
                        key={index}
                        type="text"
                        style={{ marginBottom: '8px' }}
                        placeholder={`Block ${index + 1} name`}
                        value={name}
                        onChange={(e) =>
                            handleInputChange(index, e.target.value)
                        }
                    />
                ))}
            </div>
        </Modal>
    );
};

export default Prompt;
