import { API } from 'src/api/API';
import { CreateDeviceDTO, Device } from 'src/models/Device';
import { mutate } from 'swr';

export const useChangeDevice = () => {
    const change = (
        objectId: number,
        prevDeviceId: number,
        newDevice: CreateDeviceDTO,
    ): Promise<Device> => {
        return new API()
            .changeDevice(objectId, prevDeviceId, newDevice)
            .then((v) => {
                mutate(`/objects/${objectId}/devices`);
                return v;
            });
    };
    return [change] as const;
};
