// datePickerSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DatePickerState {
    viewType: 'day' | 'week' | 'month' | 'year';
}

const initialState: DatePickerState = {
    viewType: 'week',
};

const datePickerSlice = createSlice({
    name: 'datePicker',
    initialState,
    reducers: {
        setViewType: (
            state,
            action: PayloadAction<'day' | 'week' | 'month' | 'year'>,
        ) => {
            state.viewType = action.payload;
        },
    },
});

export const { setViewType } = datePickerSlice.actions;
export default datePickerSlice.reducer;
