import {
    notification,
    Typography,
    Row,
    Col,
    Modal,
    Button,
    Form,
    Input,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddCorporation } from 'src/hooks/corporations/useAddCorporation';
import logoSrc from 'src/assets/Logo.svg';

interface Props {
    visible: boolean;
    onCancel: () => void;
    onFinish: () => void;
}

export default function CorpPopup(props: Props) {
    const { visible, onCancel, onFinish } = props;
    const { t } = useTranslation();
    const [addCorporation] = useAddCorporation();
    const [isLoading, setIsLoading] = useState(false);

    const errorMessage = (title: string, messsage: string) => {
        notification.open({
            message: title,
            type: 'error',
            description: messsage,
            placement: 'bottomRight',
        });
    };

    return (
        <>
            <Modal
                visible={visible}
                onCancel={onCancel}
                footer={null}
                closable={false}
            >
                <div className="">
                    <Row>
                        <Col span={12}>
                            <Typography.Title level={4}>
                                {t('Add new corporation')}
                            </Typography.Title>
                        </Col>
                        <Col span={12}>
                            <img
                                className="h-5 float-right"
                                src={logoSrc}
                                alt="Nrlyze logo"
                            />
                        </Col>
                    </Row>

                    <Row justify="center">
                        <Col span={24}>
                            <Form
                                layout="vertical"
                                onFinish={(form: any) => {
                                    setIsLoading(true);
                                    addCorporation(form).catch((e) => {
                                        errorMessage(
                                            t('Not able to add'),
                                            e.message,
                                        );
                                    });
                                    setIsLoading(false);
                                    onFinish();
                                }}
                            >
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: t('Name is required'),
                                        },
                                    ]}
                                    name="name"
                                >
                                    <Input
                                        placeholder={t('Corporation name')}
                                    />
                                </Form.Item>

                                <Row
                                    gutter={[20, 20]}
                                    className="mt-10"
                                    justify="center"
                                >
                                    <Col xs={24} md={12}>
                                        <Button
                                            onClick={() => onCancel()}
                                            className="w-full"
                                            size="large"
                                            loading={isLoading}
                                        >
                                            {t('Cancel')}
                                        </Button>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Button
                                            className="w-full"
                                            size="large"
                                            loading={isLoading}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {t('Add corporation')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    );
}
