import { Typography } from 'antd';
import { RealTimeResponse } from 'src/hooks/objects/useRealTime';
import { Sensor } from 'src/models/Sensor';
import {
    SensorWithTemperature,
    TemperatureMapping,
} from 'src/models/Temperature';
import {
    generateGradientKey,
    getGradientColorForTemperature,
    gradients,
} from 'src/utils/gradients';

import { RootState } from 'src/store/rootReducer';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './DataSelector.scss';

interface DataSelectorProps {
    data: RealTimeResponse[] | undefined;
    onClick: (v: any) => void;
    include: number[];
    hotNCold: boolean;
    sensorAverages?: SensorWithTemperature[];
    prevSensorAverages?: SensorWithTemperature[];
    averageTemperatures?: TemperatureMapping[];
    prevAverageTemperatures?: TemperatureMapping[];
    referenceTemperature?: number;
    isDashed?: boolean;
}
export function DataSelector(props: DataSelectorProps) {
    const {
        data,
        onClick,
        include,
        sensorAverages,
        prevSensorAverages,
        averageTemperatures,
        prevAverageTemperatures,
        referenceTemperature,
        isDashed,
    } = props;

    const sensorIdsToAverages: Record<number, TemperatureMapping[]> = {};

    const { t } = useTranslation();

    const averages =
        sensorAverages && sensorAverages.flatMap((sa) => sa.data).length > 0
            ? sensorAverages
            : prevSensorAverages ?? [];

    for (const avg of averages) {
        Object.assign(sensorIdsToAverages, { [avg.id]: avg.data });
    }

    const { selectedFloorSensors } = useSelector(
        (state: RootState) => state.selectedObject,
    );

    // let i = 0;
    // let h = 0;
    // let c = 0;
    function colorPicker(sensor: Sensor, i: number = 0) {
        const type = sensor?.sensorFunctionTypeId;

        const floorColors = [
            '#FF0000',
            '#00FF00',
            '#0000FF',
            '#FFFF00 ',
            '#FF00FF',
            '#00FFFF ',
            '#800080 ',
            '#808080',
        ];

        if (type === undefined) {
            // Handle the case when sensorFunctionTypeId is undefined
            const index = selectedFloorSensors.findIndex(
                (floorSensor) => floorSensor.id === sensor.id,
            );
            if (index === -1) return '#f5d742';
            return floorColors[index % floorColors.length];
        }

        if (type === 0) {
            return '#91d5ff';
        }
        if (type === 1) {
            return '#003a8c';
        }
        if (type === 21) {
            return '#faad14';
        }
        if (type === 11) {
            return '#fa541c';
        }
        if (type === 61) {
            return '#820014';
        }
        if (type === -1) {
            const index =
                selectedFloorSensors.findIndex(
                    (floorSensor) => floorSensor.id === sensor.id,
                ) || 0;

            if (index === -1) return '#f5d742';
            return floorColors[index % floorColors.length];
        } else {
            const avg =
                0.1 *
                Math.round(
                    10 *
                        (averageTemperatures?.find(
                            (e) => e.fromTemperature === referenceTemperature,
                        )?.toTemperature ?? 21),
                );

            const t = getGradientColorForTemperature(
                sensorIdsToAverages[sensor.id] ?? [],
                averageTemperatures && averageTemperatures.length > 0
                    ? averageTemperatures
                    : prevAverageTemperatures ?? [],
                referenceTemperature ?? NaN,
                generateGradientKey(
                    { high: avg + 1, low: avg - 1 },
                    gradients.thermometer.mix,
                ),
                1,
            );

            return t ?? '#646464';
        }
        // } else {
        //     if (i < len / 2) {
        //         i++;
        //         return hot[h++];
        //     } else {
        //         i++;
        //         return cold[c++];
        //     }
        // }
    }

    const getSensorName = (sensor: RealTimeResponse) => {
        if (sensor.name && sensor.name === 'Medeltemperatur')
            return t('Average Temperature Object');
        else if (sensor.name !== null) return sensor.name;
        else return sensor.uniqueId;
    };

    return (
        <>
            {data?.map((v, i) => {
                if (v.data.length !== 0) {
                    return (
                        <div className="p-2 cursor-pointer lg:flex lg:flex-row sm:flex-col">
                            <div
                                className="lg:w-full sm:w-full"
                                key={v.uniqueId}
                            >
                                <div
                                    onClick={() => onClick(v.id)}
                                    className="flex"
                                >
                                    {isDashed ? (
                                        <>
                                            <div
                                                className="rounded-dashed h-dashed w-dashed mr-dashed mt-auto mb-auto"
                                                style={{
                                                    backgroundColor: colorPicker(
                                                        v,
                                                        i,
                                                    ),
                                                }}
                                            />
                                            <div
                                                className="rounded-dashed h-dashed w-dashed mr-dashed mt-auto mb-auto"
                                                style={{
                                                    backgroundColor: colorPicker(
                                                        v,
                                                        i,
                                                    ),
                                                }}
                                            />
                                            <div
                                                className="rounded-dashed h-dashed w-dashed mr-2 mt-auto mb-auto"
                                                style={{
                                                    backgroundColor: colorPicker(
                                                        v,
                                                        i,
                                                    ),
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <div
                                            className="h-s-1 w-s-1 mr-2 mt-auto mb-auto"
                                            style={{
                                                backgroundColor: colorPicker(
                                                    v,
                                                    i,
                                                ),
                                            }}
                                        />
                                    )}
                                    <Typography
                                        style={{
                                            fontWeight: include.includes(v.id)
                                                ? 'bold'
                                                : 'normal',
                                        }}
                                    >
                                        {getSensorName(v)}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    );
                } else return <div className="hidden" key={i} />;
            })}
        </>
    );
}
