import { useState } from 'react';
import { LocalDB } from 'src/api/LocalDB';

export const useFavoritesSites = () => {
    const [favorites, setFavorites] = useState<number[]>(
        LocalDB.getValue('favoritesites') || [],
    );

    const updateFavoriteSites = (newFavorites: number[]) => {
        setFavorites(newFavorites);
        LocalDB.setValue('favoritesites', newFavorites);
    };

    return [updateFavoriteSites, favorites] as const;
};
