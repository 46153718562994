import { API } from 'src/api/API';
import { SensorType } from 'src/models/Sensor';
import { mutate } from 'swr';
import { useDevices } from '../objects/useDevice';

export interface ConfigSensorDTO {
    name: string;
    sensorFunctionTypeId: SensorType;
    isActive: boolean;
}

export const useConfigSensor = (objectId: number) => {
    const { mutate: m } = useDevices(objectId);
    const config = (
        objectId: string,
        sensorId: string,
        configSensor: ConfigSensorDTO,
    ): Promise<any> => {
        return new API()
            .configSensor(objectId, sensorId, configSensor)
            .then(async (v) => {
                await mutate(`/${objectId}/sensors/${sensorId}`);
                m();
                return v;
            });
    };
    return [config] as const;
};
