import { Report } from 'src/models/Report';
import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';

export const useReportsByObjectId = (objectId: number) => {
    return useSWR<Report[]>(
        `/objects/performance-reports/${objectId}`,
        swrFetch,
    );
};
