import { useState, useEffect } from 'react';

const useViewHeight = () => {
    const [screen, setScreen] = useState(window.innerHeight);

    useEffect(() => {
        const handleWindowResize = () => setScreen(window.innerHeight);
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return { screen };
};

export default useViewHeight;
