import { useNavigate, useParams } from 'react-router-dom';
import { useObject } from 'src/hooks/objects/useObject';
import Nav from 'src/utils/nav/Nav';
import ChangeDeviceView from 'src/views/sites/ChangeDeviceView';

function ChangeDevicePage() {
    const { objectId: objectIdString, prevDeviceId } = useParams<{
        objectId: string;
        prevDeviceId: string;
    }>();
    const objectId = Number.parseInt(objectIdString!);
    const { data: object } = useObject(objectId);
    const history = useNavigate();

    return (
        <>
            {object !== undefined && (
                <ChangeDeviceView
                    onSuccess={() => {
                        history(
                            Nav.build({
                                path: '/manage-object',
                                queryParams: {
                                    index: '1',
                                    objectId: objectId.toString(),
                                },
                            }),
                        );
                    }}
                    objectId={objectId}
                    siteId={object.siteId}
                    siteName={object.siteName}
                    prevSensorId={Number.parseInt(prevDeviceId!)}
                />
            )}
        </>
    );
}

export default ChangeDevicePage;
