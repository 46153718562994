import { API } from 'src/api/API';

export const useOrganizeModbusOnIntegration = () => {
    const organize = (objectId: number, integrationId: number, data: any) => {
        return new API().organizeIntegrationModbus(
            objectId,
            integrationId,
            data,
        );
    };

    return [organize] as const;
};
