import { Typography, Row, Col } from 'antd';
import style from './Sidebar.module.scss';
import { ReactNode } from 'react';

interface Tabprops {
    name: string;
    icon: ReactNode;
    active?: boolean;
    hover?: boolean;
    onClick: () => void;
    className?: string;
}

export default function SideTab(props: Tabprops) {
    return (
        <>
            <div
                id={props.hover === false ? '' : style.hover}
                className="cursor-pointer"
            >
                <Row
                    onClick={props.onClick}
                    align="middle"
                    className="rounded-md p-2 mb-2"
                    gutter={10}
                    style={{
                        backgroundColor: props.active
                            ? '#EBECF0'
                            : 'transparent',
                        color: props.active ? '#3973BE' : 'black',
                    }}
                >
                    <Col> {props.icon}</Col>
                    <Col>
                        <Typography.Text
                            style={{
                                fontSize: 18,
                                fontWeight: 300,
                                color: props.active ? '#3973BE' : 'black',
                            }}
                        >
                            {props.name}
                        </Typography.Text>
                    </Col>
                </Row>
            </div>
        </>
    );
}
