import { API, CreateSite } from 'src/api/API';
import { Corporation } from 'src/models/Corporation';
import { mutate } from 'swr';
export const useAddSite = () => {
    const add = async (
        corporationid: number,
        site: CreateSite,
    ): Promise<Corporation> => {
        return new API().addSite(corporationid, site).then(async (res) => {
            await mutate(`/corporations/${corporationid}/sites`);
            await mutate(`/sites`);
            return res;
        });
    };
    return [add] as const;
};
