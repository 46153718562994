import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import AnalyticsWrapper from 'src/views/objects/AnalyticsWrapper';

function AnalyticsPage() {
    const { objectId: objectIdString } = useParams<{ objectId: string }>();
    const objectId = Number.parseInt(objectIdString!);

    return (
        <Suspense fallback={<></>}>
            <AnalyticsWrapper objectId={objectId} />
        </Suspense>
    );
}

export default AnalyticsPage;
