import {
    ApartmentOutlined,
    PlusOutlined,
    SettingFilled,
    UserAddOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {
    Typography,
    Row,
    Col,
    Button,
    Skeleton,
    Avatar,
    Tag,
    Affix,
    Menu,
    Dropdown,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { CorporationLogo } from 'src/components/CorporationLogo';
import TabContainer from 'src/components/TabContainer';
import { TabTitle } from 'src/components/TabTitle';
import useViewport from 'src/hooks/generic/useViewport';
import { useMe } from 'src/hooks/users/useMe';
import { useSignOut } from 'src/hooks/users/useSignOut';
import { useUsers } from 'src/hooks/users/useUsers';
import { Corporation } from 'src/models/Corporation';
import { User, UserRoles } from 'src/models/User';
import { ColorSelector } from 'src/utils/ColorSelector';
import Nav from 'src/utils/nav/Nav';
import { BooleanParam, useQueryParam } from 'use-query-params';
import CorpPopup from '../corporation/CorpPopup';
import UserPopup from '../users/InvitePopup';

interface UsersListProps {
    users: User[];
    onClick?: (user: User) => void;
    action?: (user: User) => JSX.Element;
}

export const UsersList = (props: UsersListProps) => {
    const { users, action, onClick } = props;

    return (
        <>
            {users.map((user) => {
                const firstName: string = user.name.split(' ')[0];
                const lastName: string | undefined = user.name.split(' ')[1];
                return (
                    <Row
                        onClick={() => onClick?.(user)}
                        align="middle"
                        className="cursor-pointer"
                    >
                        <Col span={18}>
                            <div className="float-left mr-5 mb-4">
                                <Avatar
                                    size="large"
                                    style={{
                                        backgroundColor: ColorSelector.getColor(
                                            user.email,
                                        ),
                                    }}
                                >
                                    {firstName.charAt(0)}
                                    {lastName?.charAt(0)}
                                </Avatar>
                            </div>

                            <div className="float-left">
                                <Typography.Text
                                    style={{ width: '15ch' }}
                                    ellipsis={true}
                                >
                                    <b>{user.name}</b>
                                    {user.role === UserRoles.ADMIN && (
                                        <span className="ml-2">
                                            <Tag color="blue">ADMIN</Tag>
                                        </span>
                                    )}
                                    <br />
                                    <Typography.Text
                                        style={{ width: '30ch' }}
                                        ellipsis={true}
                                        type="secondary"
                                    >
                                        {user.email}
                                    </Typography.Text>
                                </Typography.Text>
                            </div>
                        </Col>
                        <Col span={6}>{action?.(user)}</Col>
                    </Row>
                );
            })}
        </>
    );
};

function SettingsListView() {
    const { t } = useTranslation();

    const { data: me, isValidating: meIsLoading } = useMe();
    const { data: users, isValidating: usersIsLoading } = useUsers();
    const [corppopup, setCorpPopup] = useQueryParam(
        'add-corporation',
        BooleanParam,
    );
    const [addUser, setAddUser] = useQueryParam('add-user', BooleanParam);
    const history = useNavigate();
    const { mode } = useViewport();
    const [signOut] = useSignOut();

    const Corporations = (corp: Corporation[]) => {
        return corp.map((e) => {
            return (
                <Link
                    to={Nav.build({
                        path: '/corporations/:corporationId',
                        routeParams: {
                            corporationId: e.id,
                        },
                        queryParams: {},
                    })}
                >
                    <Row align="middle" className="cursor-pointer">
                        <Col span={18}>
                            <div className="w-12 h-12 float-left mr-5 mb-4">
                                <CorporationLogo corporation={e} />
                            </div>

                            <div className="float-left">
                                <Typography.Text
                                    style={{ width: '15ch' }}
                                    ellipsis={true}
                                >
                                    <b>{e.name}</b>
                                    <br />
                                    <Typography.Text
                                        style={{ width: '15ch' }}
                                        ellipsis={true}
                                        type="secondary"
                                    >
                                        {'Corp'}
                                    </Typography.Text>
                                </Typography.Text>
                            </div>
                        </Col>
                        <Col span={6}>
                            <SettingFilled
                                style={{ color: 'black' }}
                                className="float-right text-xl"
                            />
                        </Col>
                    </Row>
                </Link>
            );
        });
    };

    const Operations = () => {
        return (
            <div>
                <Button
                    block={mode === 'desktop' ? false : true}
                    type="link"
                    onClick={() => setCorpPopup(true)}
                    className="mr-3"
                    size="middle"
                >
                    <UserAddOutlined style={{ verticalAlign: 'middle' }} />
                    {t('Create corporation')}
                </Button>
                <Button
                    block={mode === 'desktop' ? false : true}
                    type="primary"
                    onClick={() => setAddUser(true)}
                    size="middle"
                >
                    <UserAddOutlined style={{ verticalAlign: 'middle' }} />
                    {t('Invite new user')}
                </Button>
            </div>
        );
    };

    return (
        <>
            <CorpPopup
                onCancel={() => setCorpPopup(false)}
                onFinish={() => setCorpPopup(false)}
                visible={corppopup === true}
            />
            <UserPopup
                onCancel={() => setAddUser(false)}
                onFinish={() => setAddUser(false)}
                visible={addUser === true}
            />

            <div style={{ height: mode === 'mobile' ? '90vh' : 'auto' }}>
                <TabContainer
                    extra={[<Operations />]}
                    children={[
                        {
                            body: (
                                <>
                                    <Row>
                                        <Col xs={12} span={18}>
                                            <Typography.Title
                                                level={
                                                    mode === 'desktop' ? 4 : 5
                                                }
                                            >
                                                {t('Users')}
                                            </Typography.Title>
                                        </Col>
                                        <Col xs={12} span={6}>
                                            <Typography.Title
                                                level={
                                                    mode === 'desktop' ? 4 : 5
                                                }
                                                className="w-full text-right"
                                            >
                                                {t('Actions')}
                                            </Typography.Title>
                                        </Col>
                                    </Row>

                                    {!usersIsLoading && users && (
                                        <>
                                            <UsersList
                                                users={users}
                                                onClick={(user) => {
                                                    history(
                                                        Nav.build({
                                                            path:
                                                                '/users/:userId',
                                                            routeParams: {
                                                                userId: user.id,
                                                            },
                                                        }),
                                                    );
                                                }}
                                                action={() => {
                                                    return (
                                                        <SettingFilled className="float-right text-xl" />
                                                    );
                                                }}
                                            />
                                            {mode === 'mobile' && (
                                                <>
                                                    <div className="flex ">
                                                        <div className="flex m-auto p-2">
                                                            <div className="m-auto">
                                                                <Button
                                                                    onClick={() =>
                                                                        signOut()
                                                                    }
                                                                    block
                                                                >
                                                                    {t(
                                                                        'Sign out',
                                                                    )}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                    {meIsLoading && <Skeleton active />}
                                </>
                            ),

                            tab: (
                                <TabTitle
                                    title={t('Users')}
                                    icon={<UserOutlined />}
                                />
                            ),
                        },
                        {
                            body: (
                                <>
                                    <Row>
                                        <Col xs={12} span={18}>
                                            <Typography.Title
                                                level={
                                                    mode === 'desktop' ? 4 : 5
                                                }
                                            >
                                                {t('Corporations')}
                                            </Typography.Title>
                                        </Col>
                                        <Col xs={12} span={6}>
                                            <Typography.Title
                                                level={
                                                    mode === 'desktop' ? 4 : 5
                                                }
                                                className="w-full text-right"
                                            >
                                                {t('Actions')}
                                            </Typography.Title>
                                        </Col>
                                    </Row>

                                    {meIsLoading && <Skeleton active />}
                                    {!meIsLoading &&
                                        me?.corporations &&
                                        Corporations(me?.corporations)}
                                    {!meIsLoading && !me?.corporations && (
                                        <p>error</p>
                                    )}
                                </>
                            ),

                            tab: (
                                <TabTitle
                                    title={t('Corporations')}
                                    icon={<ApartmentOutlined />}
                                />
                            ),
                        },
                    ]}
                />
                {/* <Tabs
                    className="w-full"
                    tabBarExtraContent={
                        screen === 'desktop' &&
                        me?.user.role === UserRoles.ADMIN && <Operations />
                    }
                    onChange={(key) => {
                        setTab(key);
                    }}
                    activeKey={tab}
                >
                    <TabPane
                        tab={
                            <TabTitle
                                title={t('Users')}
                                icon={<UserOutlined />}
                            />
                        }
                        key="users"
                    >
                        <Row>
                            <Col xs={12} span={18}>
                                <Typography.Title
                                    level={screen === 'desktop' ? 4 : 5}
                                >
                                    {t('Users')}
                                </Typography.Title>
                            </Col>
                            <Col xs={12} span={6}>
                                <Typography.Title
                                    level={screen === 'desktop' ? 4 : 5}
                                    className="w-full text-right"
                                >
                                    {t('Actions')}
                                </Typography.Title>
                            </Col>
                        </Row>

                        {!usersIsLoading && users && (
                            <>
                                <UsersList
                                    users={users}
                                    onClick={(user) => {
                                        history(
                                            Nav.build({
                                                path: '/users/:userId',
                                                routeParams: {
                                                    userId: user.id,
                                                },
                                            }),
                                        );
                                    }}
                                    action={() => {
                                        return (
                                            <SettingFilled className="float-right text-xl" />
                                        );
                                    }}
                                />
                                {screen === 'mobile' && (
                                    <>
                                        <Divider />
                                        <Row gutter={20}>
                                            <Col span={12} md={4}>
                                                <Button
                                                    onClick={() => signOut()}
                                                    block
                                                >
                                                    {t('Sign out')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </>
                        )}
                        {meIsLoading && <Skeleton active />}
                    </TabPane>

                    <TabPane
                        tab={
                            <TabTitle
                                title={t('Corporations')}
                                icon={<ApartmentOutlined />}
                            />
                        }
                        key="corporations"
                    >
                        <Row>
                            <Col xs={12} span={18}>
                                <Typography.Title
                                    level={screen === 'desktop' ? 4 : 5}
                                >
                                    {t('Corporations')}
                                </Typography.Title>
                            </Col>
                            <Col xs={12} span={6}>
                                <Typography.Title
                                    level={screen === 'desktop' ? 4 : 5}
                                    className="w-full text-right"
                                >
                                    {t('Actions')}
                                </Typography.Title>
                            </Col>
                        </Row>

                        {meIsLoading && <Skeleton active />}
                        {!meIsLoading &&
                            me?.corporations &&
                            Corporations(me?.corporations)}
                        {!meIsLoading && !me?.corporations && <p>error</p>}
                    </TabPane>
                </Tabs> */}
                {mode === 'mobile' && (
                    <Affix
                        style={{
                            position: 'absolute',
                            top: '80%',
                            left: '80%',
                        }}
                    >
                        <div>
                            <Dropdown
                                placement="topLeft"
                                trigger={['click', 'hover']}
                                arrow
                                overlay={
                                    <Menu>
                                        <Menu.Item
                                            onClick={() => setCorpPopup(true)}
                                        >
                                            {t('Add corporations')}
                                        </Menu.Item>
                                        <Menu.Item
                                            onClick={() => setAddUser(true)}
                                        >
                                            {t('Add user')}
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Avatar
                                    src={
                                        <PlusOutlined
                                            className="text-2xl"
                                            style={{ verticalAlign: 'middle' }}
                                        />
                                    }
                                    style={{ backgroundColor: '#51baa2' }}
                                    size={65}
                                    className="circle-shadow"
                                />
                            </Dropdown>
                        </div>
                    </Affix>
                )}
            </div>
        </>
    );
}

export default SettingsListView;
