import { PlusCircleFilled } from '@ant-design/icons';
import { Button, Drawer, Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { BooleanParam, useQueryParam } from 'use-query-params';
import MetryConfig from './metry/MetryConfig';
import MetryReadMore from './metry/MetryReadMore';
import metryLogoSource from './metry/icon.png';
import { useHasMetryIntegration } from 'src/hooks/integrations/useHasMetryIntegration';
import React from 'react';
interface Props {
    corporationId: number;
}

interface IntegratorRowProps {
    data: {
        name: string;
        id: string;
        description: string;
        icon?: string;
    };
    readMore: React.ReactNode;
    configPage: JSX.Element;
    hasIntegration?: boolean;
    loading: boolean;
}

export function SingleIntegrator(props: IntegratorRowProps) {
    const { data, configPage, hasIntegration, loading, readMore } = props;

    const { t } = useTranslation();
    const [showReadMoreModal, setShowReadMoreModal] = useQueryParam(
        data.id + '-readmore',
        BooleanParam,
    );
    const [showConfigPage, setShowConfigPage] = useQueryParam(
        data.id + '-config',
        BooleanParam,
    );

    return (
        <>
            <Modal
                footer={[
                    <Button onClick={() => setShowReadMoreModal(false)}>
                        {t('Close')}
                    </Button>,
                ]}
                title={t('{{name}} integration', { name: data.name })}
                onCancel={() => setShowReadMoreModal(false)}
                visible={!!showReadMoreModal}
            >
                {readMore}
            </Modal>
            <Drawer
                width={'50vw'}
                closable
                visible={!!showConfigPage}
                onClose={() => {
                    setShowConfigPage(false);
                }}
                placement={'right'}
            >
                {React.cloneElement(configPage, {
                    onClose: () => {
                        setShowConfigPage(false);
                    },
                })}
            </Drawer>
            <div className="flex h-32 w-full border-t cursor-pointer">
                <div className="my-auto pr-3">
                    {data.icon && (
                        <img
                            className="float-right w-24"
                            alt="Metry logo"
                            src={data.icon}
                        />
                    )}
                </div>
                <div className="my-auto w-full md:w-2/3">
                    <Typography.Title level={3}>{data.name}</Typography.Title>
                    <Typography.Paragraph ellipsis={{ rows: 2 }}>
                        {data.description}
                    </Typography.Paragraph>
                </div>
                <div className=" ml-auto my-auto pl-3">
                    <Button
                        loading={loading}
                        onClick={() => {
                            if (hasIntegration) {
                                setShowConfigPage(true);
                            } else {
                                setShowReadMoreModal(true);
                            }
                        }}
                        icon={<PlusCircleFilled />}
                        type="primary"
                    >
                        {hasIntegration ? t('Update') : t('Read more')}
                    </Button>
                </div>
            </div>
        </>
    );
}

function IntegratorsView(props: Props) {
    const { corporationId } = props;
    const { t } = useTranslation();
    const { data, isValidating } = useHasMetryIntegration(props.corporationId);

    const integrators: IntegratorRowProps[] = [
        {
            data: {
                id: 'metry',
                name: t('Metry'),
                description: t(
                    'Automated collection and structuring of energy data ' +
                        'from your building stock let you focus on energy' +
                        ' and resource management using any system you want.' +
                        ' Connecting Metry to Nrlyze will automaticly fill the energy ' +
                        'consumtion associated with your building.',
                ),
                icon: metryLogoSource,
            },
            loading: isValidating,
            hasIntegration: data?.exists,
            readMore: <MetryReadMore corporationId={corporationId} />,
            configPage: <MetryConfig corporationId={corporationId} />,
        },
    ];

    return (
        <div className="w-full">
            {integrators.map((props, i) => {
                return <SingleIntegrator key={i} {...props} />;
            })}
        </div>
    );
}

export default IntegratorsView;
