import { API } from 'src/api/API';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setTemplateSpaces } from 'src/store/reducers/templateSpacesSlice';

const useFetchTemplateSpaces = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchTemplateSpaces = async () => {
            try {
                const spaces = await new API().fetchTemplateSpaces();
                dispatch(setTemplateSpaces(spaces));
            } catch (error) {
                console.error('Error fetching spaces:', error);
            }
        };

        fetchTemplateSpaces();
        //eslint-disable-next-line
    }, []);
};

export default useFetchTemplateSpaces;
