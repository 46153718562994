import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Space {
    SpaceNumber: number;
    TemplateId: number;
    PosX: number;
    PosY: number;
    SizeX: number;
    SizeY: number;
}

const initialState: Space[] = [];

const templateSpacesSlice = createSlice({
    name: 'templateSpaces',
    initialState,
    reducers: {
        setTemplateSpaces: (state, action: PayloadAction<Space[]>) => {
            return [...action.payload];
        },
    },
});

export const { setTemplateSpaces } = templateSpacesSlice.actions;
export default templateSpacesSlice.reducer;
