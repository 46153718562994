import { API } from 'src/api/API';

export const usePartitionRealTime = () => {
    const fetch = async (
        objectId: number,
        sensors: number[],
        start: string,
        stop: string,
    ) => {
        const api = new API();
        const promises = [
            api.fetchRealTimePartion(sensors, start, stop, objectId),
        ];

        //promises.push(api.fetchRealTime(objectId, start, stop, 0));

        const [data1, data2 = []] = await Promise.all(promises);

        let data = [],
            avgTemp: any = [];

        if (data1.data && data1.data.length) {
            data = data1.data;
        }
        return [data, data2, avgTemp];
    };

    return [fetch] as const;
};
