import { Col, Row, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserRoles } from 'src/models/User';

interface Props {
    currentSelected: UserRoles;
    onChange: (role: UserRoles) => void;
}

function RoleSelector(props: Props) {
    const { currentSelected } = props;
    const { t } = useTranslation();
    return (
        <Row gutter={[40, 0]}>
            <Col
                color={currentSelected === UserRoles.ADMIN ? 'blue' : undefined}
                span={12}
            >
                <div
                    className={`p-3 ${
                        currentSelected === UserRoles.DEFAULT
                            ? 'cursor-not-allowed'
                            : ''
                    }`}
                >
                    <Tag>
                        <Typography.Text
                            disabled={currentSelected !== UserRoles.DEFAULT}
                        >
                            {t('Default')}
                        </Typography.Text>
                    </Tag>
                    <br />
                    <div className="mt-1">
                        <Typography.Text
                            disabled={currentSelected !== UserRoles.DEFAULT}
                        >
                            {t(
                                'The user will have limited access to the system. They will not be able to add new users or update current ones. For more information about roles click here.',
                            )}
                        </Typography.Text>
                    </div>
                </div>
            </Col>
            <Col span={12}>
                <div
                    className={`p-3 ${
                        currentSelected === UserRoles.ADMIN
                            ? ''
                            : 'cursor-not-allowed'
                    }`}
                >
                    <Tag
                        color={
                            currentSelected === UserRoles.ADMIN
                                ? 'blue'
                                : undefined
                        }
                    >
                        <Typography.Text
                            disabled={currentSelected !== UserRoles.ADMIN}
                        >
                            {t('Admin')}
                        </Typography.Text>
                    </Tag>

                    <div className="mt-1">
                        <Typography.Text
                            disabled={currentSelected !== UserRoles.ADMIN}
                        >
                            {t(
                                'The user will have full access to the system. They will be able to invite new users and update existing users. For more information click here.',
                            )}
                        </Typography.Text>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default RoleSelector;
