import { GradientKey } from 'src/models/GradientKey';
import { HumidityData } from 'src/views/objects/BalancingView';

export const gradients = {
    thermometer: {
        mix: [
            '#f5222d', // 7
            '#ee3800', // 6
            '#e24e00', // 5
            '#d65d00', // 4
            '#c86a00', // 3
            '#bc7300', // 2
            '#af7c00', // 1
            '#99870a', // 0
            '#389e0d', // mid
            '#00a792', // 0
            '#01a4a4', // 1
            '#01a2b5', // 2
            '#00a0c0', // 3
            '#009dd0', // 4
            '#0099e0', // 5
            '#0096ee', // 6
            '#1890ff', // 7
        ],
        humdityGradient: [
            '#04b2ff',
            '#04baff',
            '#04beff',
            '#04d3ff',
            '#04edff',
            '#04f1ff',
            '#0498ff',
            '#424242',
            '#616161',
            '#757575',
            '#9E9E9E',
            '#919191',
            '#9B9B9B',
            '#A2A2A2',
            '#AAAAAA',
        ],
        fade: [
            '#f5222d',
            '#ee3537',
            '#e64340',
            '#de4f49',
            '#d35a53',
            '#c9635c',
            '#be7169',
            '#ae7e79',
            '#8f8f8f',
            '#7c92ab',
            '#7392b7',
            '#6793c5',
            '#5c93d1',
            '#5192dc',
            '#4392e8',
            '#3291f3',
            '#1890ff',
        ],
    },
};

export function generateGradientKey(
    thresholds: { high: number; low: number },
    gradient: string[],
): GradientKey {
    const range = 4;

    const labels: string[] = [];

    labels.push(`>${thresholds.high + range}`);

    for (let i = 1; thresholds.high + range - i >= thresholds.high; ++i) {
        labels.push(
            `${thresholds.high + range - i}-${thresholds.high + range - i + 1}`,
        );
    }

    const middle = labels.length;

    labels.push(`${thresholds.low}-${thresholds.high}`);

    for (let i = 1; thresholds.low - i >= thresholds.low - range; ++i) {
        labels.push(`${thresholds.low - i}-${thresholds.low - i + 1}`);
    }

    labels.push(`<${thresholds.low - range}`);

    return {
        labels,
        middle,
        gradient,
        thresholds,
        edges: {
            min: thresholds.low - range,
            max: thresholds.high + range,
        },
    };
}

export function getGradientColorForTemperature(
    temperatures: { fromTemperature: number; toTemperature: number }[],
    averageTemperatures: { fromTemperature: number; toTemperature: number }[],
    outdoorTemp: number,
    gradientKey: GradientKey,
    tolerance?: number,
) {
    const averageTemperature = averageTemperatures.find(
        (temp) => temp.fromTemperature === outdoorTemp,
    );
    const temperature = temperatures.find(
        (temperature) => temperature.fromTemperature === outdoorTemp,
    );

    if (!temperature || !averageTemperature) return undefined;

    const colors = gradientKey?.gradient ?? gradients.thermometer.mix;

    const colorsMid = Math.round(colors.length / 2) - 1;

    temperature.toTemperature = Number.parseFloat(
        temperature.toTemperature.toFixed(1),
    );

    const lowerThreshold =
        gradientKey?.thresholds.low ??
        Number.parseFloat(
            (averageTemperature.toTemperature - (tolerance ?? 1)).toFixed(1),
        );
    const upperThreshold =
        gradientKey?.thresholds.high ??
        Number.parseFloat(
            (averageTemperature.toTemperature + (tolerance ?? 1)).toFixed(1),
        );
    const lowerEdge = gradientKey?.edges.min;
    const upperEdge = gradientKey?.edges.max;

    if (temperature.toTemperature < lowerEdge) {
        return colors[colors.length - 1];
    }
    if (temperature.toTemperature > upperEdge) {
        return colors[0];
    }
    if (
        temperature.toTemperature >= lowerThreshold &&
        temperature.toTemperature <= upperThreshold
    ) {
        return colors[colorsMid];
    }
    if (temperature.toTemperature < lowerThreshold) {
        const slice = colors.slice(colorsMid + 1);

        return slice[
            Math.floor(
                (1 -
                    (temperature.toTemperature - lowerEdge) /
                        (lowerThreshold - lowerEdge)) *
                    slice.length,
            )
        ];
    }
    if (temperature.toTemperature > upperThreshold) {
        const slice = colors.slice(0, colorsMid);

        return slice[
            Math.floor(
                ((upperEdge - temperature.toTemperature) /
                    (upperEdge - upperThreshold)) *
                    slice.length,
            )
        ];
    }

    return undefined;
}

export function getGradientColorForHumidity(
    humidityData: HumidityData[],
    currentHumidity: number,
    gradientKey: GradientKey,
) {
    const totalAbsoluteHumidity = humidityData.reduce(
        (sum, data) => sum + data.absoluteHumidity,
        0,
    );
    const averageHumidity = totalAbsoluteHumidity / humidityData.length;

    if (!currentHumidity || !averageHumidity) return undefined;

    const colors =
        gradientKey?.gradient ?? gradients.thermometer.humdityGradient;

    const colorsMid = Math.round(colors.length / 2) - 1;

    const lowerThreshold = gradientKey?.thresholds.low;
    const upperThreshold = gradientKey?.thresholds.high;
    const lowerEdge = gradientKey?.edges.min;
    const upperEdge = gradientKey?.edges.max;

    if (currentHumidity < lowerEdge) {
        return colors[colors.length - 1];
    }
    if (currentHumidity > upperEdge) {
        return colors[0];
    }
    if (
        currentHumidity >= lowerThreshold &&
        currentHumidity <= upperThreshold
    ) {
        return colors[colorsMid];
    }
    if (currentHumidity < lowerThreshold) {
        const slice = colors.slice(colorsMid + 1);

        return slice[
            Math.floor(
                (1 -
                    (currentHumidity - lowerEdge) /
                        (lowerThreshold - lowerEdge)) *
                    slice.length,
            )
        ];
    }
    if (currentHumidity > upperThreshold) {
        const slice = colors.slice(0, colorsMid);

        return slice[
            Math.floor(
                ((upperEdge - currentHumidity) / (upperEdge - upperThreshold)) *
                    slice.length,
            )
        ];
    }

    return undefined;
}

enum ColorStatus {
    NoConclusion = '#389e0d',
    PoorVentilation = '#7E350E',
    HighVentilation = '#215C98',
    ProbablyHighFeedCapacity = '#FFC000',
    DefinitelyHighFeedCapacity = '#FF0000',
    ProbablyLowCapacity = '#94DCF8',
    WaterLeak = '#7030A0',
}

function getHumidityStatus(
    gradientKey: GradientKey,
    humidityData: HumidityData[],
    currentHumidity: number,
) {
    const totalAbsoluteHumidity = humidityData.reduce(
        (sum, data) => sum + data.absoluteHumidity,
        0,
    );
    const averageHumidity = totalAbsoluteHumidity / humidityData.length;

    if (!currentHumidity || !averageHumidity) return undefined;

    const lowerThreshold = gradientKey?.thresholds.low;
    const upperThreshold = gradientKey?.thresholds.high;

    if (
        currentHumidity >= lowerThreshold &&
        currentHumidity <= upperThreshold
    ) {
        return 0;
    }
    if (currentHumidity < lowerThreshold) {
        return -1;
    }
    if (currentHumidity > upperThreshold) {
        return 1;
    }
    return undefined;
}

function getTemperatureStatus(
    temperatures: { fromTemperature: number; toTemperature: number }[],
    averageTemperatures: { fromTemperature: number; toTemperature: number }[],
    outdoorTemp: number,
    gradientKey: GradientKey,
    tolerance?: number,
) {
    const averageTemperature = averageTemperatures.find(
        (temp) => temp.fromTemperature === outdoorTemp,
    );
    const temperature = temperatures.find(
        (temperature) => temperature.fromTemperature === outdoorTemp,
    );

    if (!temperature || !averageTemperature) return undefined;

    temperature.toTemperature = Number.parseFloat(
        temperature.toTemperature.toFixed(1),
    );

    const lowerThreshold =
        gradientKey?.thresholds.low ??
        Number.parseFloat(
            (averageTemperature.toTemperature - (tolerance ?? 1)).toFixed(1),
        );
    const upperThreshold =
        gradientKey?.thresholds.high ??
        Number.parseFloat(
            (averageTemperature.toTemperature + (tolerance ?? 1)).toFixed(1),
        );

    if (
        temperature.toTemperature >= lowerThreshold &&
        temperature.toTemperature <= upperThreshold
    ) {
        return 0;
    } else if (temperature.toTemperature < lowerThreshold) {
        return -1;
    } else if (temperature.toTemperature > upperThreshold) {
        return 1;
    }

    return undefined;
}

export function getGradientColorForConclusion(
    temperatures: { fromTemperature: number; toTemperature: number }[],
    averageTemperatures: { fromTemperature: number; toTemperature: number }[],
    outdoorTemp: number,
    gradientKey: GradientKey,
    gradientKeyForHumidity: GradientKey,
    humidityData: HumidityData[],
    currentHumidity: number,
    tolerance?: number,
) {
    const temperatureStatus = getTemperatureStatus(
        temperatures,
        averageTemperatures,
        outdoorTemp,
        gradientKey,
        tolerance,
    );

    const humidityStatus = getHumidityStatus(
        gradientKeyForHumidity,
        humidityData,
        currentHumidity,
    );

    if (
        typeof temperatureStatus === 'undefined' ||
        typeof humidityStatus === 'undefined'
    )
        return undefined;

    if (
        (temperatureStatus > 0 && humidityStatus > 0) ||
        (temperatureStatus === 0 && humidityStatus > 0)
    ) {
        return ColorStatus.PoorVentilation;
    } else if (temperatureStatus > 0 && humidityStatus === 0) {
        return ColorStatus.ProbablyHighFeedCapacity;
    } else if (temperatureStatus > 0 && humidityStatus < 0) {
        return ColorStatus.DefinitelyHighFeedCapacity;
    } else if (temperatureStatus === 0 && humidityStatus === 0) {
        return ColorStatus.NoConclusion;
    } else if (
        (temperatureStatus === 0 || temperatureStatus < 1) &&
        humidityStatus < 0
    ) {
        return ColorStatus.HighVentilation;
    } else if (temperatureStatus < 0 && humidityStatus === 0) {
        return ColorStatus.ProbablyLowCapacity;
    } else if (temperatureStatus < 0 && humidityStatus > 0) {
        return ColorStatus.ProbablyLowCapacity;
    }

    return undefined;
}
