import { DeleteFilled } from '@ant-design/icons';
import {
    Col,
    Input,
    message,
    Popconfirm,
    Popover,
    Row,
    Typography,
} from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCorporationSites } from 'src/hooks/corporations/useCorporationsSites';
import { useRemoveSiteFromCorporation } from 'src/hooks/corporations/useRemoveSiteFromCorporation';
import useViewport from 'src/hooks/generic/useViewport';
import { Site } from 'src/models/Site';
import { ColorSelector } from 'src/utils/ColorSelector';
import { showError } from 'src/utils/showError';

interface Props {
    corporationId: number;
}

interface SitesListsProps {
    sites: Site[];
    onClick?: (user: Site) => void;
    action?: (user: Site) => JSX.Element;
}

export const SitesList = (props: SitesListsProps) => {
    const { sites, action, onClick } = props;
    const { t } = useTranslation();
    const { mode } = useViewport();

    return (
        <>
            {sites.map((site) => {
                return (
                    <Row
                        onClick={() => onClick?.(site)}
                        align="middle"
                        className="cursor-pointer"
                    >
                        <Col span={18}>
                            <div className="float-left mr-5 mb-4">
                                <Avatar
                                    size="large"
                                    style={{
                                        backgroundColor: ColorSelector.getColor(
                                            site.id,
                                        ),
                                    }}
                                >
                                    {site.name.charAt(0)}
                                </Avatar>
                            </div>
                            {mode === 'desktop' ? (
                                <Popover
                                    placement="topRight"
                                    content={
                                        <>
                                            {site.objects?.map((object) => {
                                                return (
                                                    <>
                                                        <Typography>
                                                            {object.name}
                                                        </Typography>
                                                    </>
                                                );
                                            })}
                                        </>
                                    }
                                >
                                    <div className="float-left">
                                        <Typography.Text>
                                            <b>{site.name}</b>
                                            <br />
                                            <Typography.Link type="secondary">
                                                {t(
                                                    'Total objects in site: {{numberOfObjects}}',
                                                    {
                                                        numberOfObjects:
                                                            site.objects
                                                                ?.length,
                                                    },
                                                )}
                                            </Typography.Link>
                                        </Typography.Text>
                                    </div>
                                </Popover>
                            ) : (
                                <div className="float-left">
                                    <Typography.Text>
                                        <b>{site.name}</b>
                                        <br />
                                        <Typography.Text type="secondary">
                                            {t(
                                                'Total objects in site: {{numberOfObjects}}',
                                                {
                                                    numberOfObjects:
                                                        site.objects?.length,
                                                },
                                            )}
                                        </Typography.Text>
                                    </Typography.Text>
                                </div>
                            )}
                        </Col>
                        <Col span={6}>{action?.(site)}</Col>
                    </Row>
                );
            })}
        </>
    );
};

function EditCorporationSites(props: Props) {
    const { corporationId } = props;
    const { t } = useTranslation();
    const { data: allSites } = useCorporationSites(corporationId);
    const [sites, setSites] = useState<Site[]>();
    const [removeSite] = useRemoveSiteFromCorporation();
    const { mode: screen } = useViewport();

    useEffect(() => {
        if (allSites) {
            setSites(allSites);
        }
    }, [allSites]);

    return (
        <Row align="middle" gutter={[100, 30]}>
            <Col span={screen === 'desktop' ? 12 : 24}>
                <Input.Search
                    enterButton
                    placeholder={t('Name')}
                    onChange={(e) => {
                        const term = e.target.value;
                        if (term.length === 0) {
                            setSites(allSites);
                        } else {
                            setSites(
                                sites?.filter(
                                    (v) =>
                                        v.name
                                            .toLowerCase()
                                            .includes(term.toLowerCase()) ||
                                        v.objects
                                            ?.map((v) => v.name)
                                            .join('')
                                            .toLowerCase()
                                            .includes(term.toLowerCase()),
                                ),
                            );
                        }
                    }}
                />
            </Col>
            <Col span={24}>
                {sites && (
                    <SitesList
                        sites={sites}
                        action={(site) => {
                            return (
                                <Popconfirm
                                    title={t(
                                        'Are you sure you want to remove site?',
                                    )}
                                    onConfirm={() => {
                                        removeSite(corporationId, site.id)
                                            .then(() => {
                                                message.success(
                                                    t('Removed site'),
                                                );
                                            })
                                            .catch((error) => {
                                                showError(
                                                    t('Not able to remove'),
                                                    error.message,
                                                );
                                            });
                                    }}
                                >
                                    <DeleteFilled className="float-right hover:scale-150 transform text-xl" />
                                </Popconfirm>
                            );
                        }}
                    />
                )}
            </Col>
        </Row>
    );
}

export default EditCorporationSites;
