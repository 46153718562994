interface Props {
    title: string;
    icon?: JSX.Element;
}

export const TabTitle = (props: Props) => {
    return (
        <div className="text-lg sm:text-xl flex justify-center items-center gap-x-2 cursor-pointer">
            {props.icon}
            {props.title}
        </div>
    );
};
