import { useEffect, useState } from 'react';
import { API } from 'src/api/API';
import { TemperatureWithControlState } from 'src/models/Temperature';

export interface SensorWithAverage extends TemperatureWithControlState {
    sensorName: string;
    sensorId: number;
    underlyingSensorId: number;
}

export const useSensorAverage = (objectId: number, stateId: number) => {
    const api = new API();
    const [sensorIds, setSensorIds] = useState<number[]>([]);
    const [sensors, setSensorAverages] = useState<SensorWithAverage[]>([]);

    const sensorsWithAverage = sensorIds.map(async (id) => {
        return api.fetchSensorsWithAverage(objectId, stateId, id);
    });

    useEffect(() => {
        Promise.all(sensorsWithAverage).then((sensorsAverages) => {
            setSensorAverages(sensorsAverages);
        });
        // eslint-disable-next-line
    }, [sensorIds, stateId]);

    return { sensors, setSensorIds, sensorIds };
};
