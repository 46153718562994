import { API } from 'src/api/API';
import { ChangeSensorDTO, Sensor } from 'src/models/Sensor';
import { mutate } from 'swr';
export const useChangeSensor = () => {
    const change = (
        objectId: number,
        prevSensorId: number,
        newSensor: ChangeSensorDTO,
    ): Promise<Sensor> => {
        return new API()
            .changeSensor(objectId, prevSensorId, newSensor)
            .then((v) => {
                mutate(`/objects/${objectId}/sensors/replace/${prevSensorId}`);
                return v;
            });
    };
    return [change] as const;
};
