import { Languages } from 'src/hooks/generic/useLanguage';
import { Warning } from 'src/models/Warning';

export class LocalDB {
    static getValue(key: 'choosenCameraId'): string;
    static getValue(key: 'favoritesites'): undefined | number[];
    static getValue(key: 'favoriteobjects'): undefined | number[];
    static getValue(key: 'closedWarnings'): undefined | Warning[];
    static getValue(key: 'choosenLanguage'): undefined;
    static getValue(key: string): unknown {
        const str = localStorage.getItem(key);
        if (str === null) return undefined;
        return JSON.parse(str);
    }

    static setValue(key: 'choosenCameraId', value: string): void;
    static setValue(key: 'favoritesites', value: number[]): void;
    static setValue(key: 'closedWarnings', value: Warning[]): void;
    static setValue(key: 'favoritesobjects', value: number[]): void;
    static setValue(key: 'choosenLanguage', value: Languages): void;
    static setValue(key: never, value: never): void {
        localStorage.setItem(key, JSON.stringify(value));
    }
}
