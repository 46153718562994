import { API } from 'src/api/API';
import { Corporation } from 'src/models/Corporation';
import { mutate } from 'swr';

export const useUpdateCorporation = () => {
    const update = async (user: Corporation): Promise<Corporation> => {
        return new API().updateCorporation(user).then((res) => {
            mutate(`/corporations/${res.id}`, res);
            return res;
        });
    };

    return [update] as const;
};
