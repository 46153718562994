/**
 *
 */

import { GenericLinkConfig, Links, RouteParams, QueryParams } from './routes';

class Nav {
    private static hasQueryParams(
        config: GenericLinkConfig<string, any, any>,
    ): config is GenericLinkConfig<string, QueryParams, RouteParams> {
        return 'queryParams' in config;
    }

    private static hasRouteParams(
        config: GenericLinkConfig<string, any, any>,
    ): config is GenericLinkConfig<string, any, RouteParams> {
        return 'routeParams' in config;
    }

    static build(config: Links) {
        const casted: GenericLinkConfig = config;

        let q = '';
        let path: string = config.path;

        if (this.hasRouteParams(casted)) {
            const { routeParams } = casted;

            for (const key in routeParams) {
                // to match react routes :key
                path = path.replace(':' + key, '' + routeParams[key]);
            }
        }

        if (this.hasQueryParams(casted)) {
            const { queryParams } = casted;
            const searchParams = new URLSearchParams();
            for (const key in queryParams) {
                const v = queryParams[key];
                if (v) searchParams.set(key, v);
            }
            q = '' + searchParams;
        }

        return `${path}?${q}`;
    }
}

export default Nav;
