import { API } from 'src/api/API';
import { Corporation } from 'src/models/Corporation';
import { mutate } from 'swr';

export const useAddMockTemperature = () => {
    const update = async (
        objectId: number,
        data: Record<number, number>,
    ): Promise<Corporation> => {
        return new API().addMockTemperature(objectId, data).then((res) => {
            mutate(`objects/${objectId}/temperatures`);
            mutate(`objects/${objectId}/average-temperatures`);
            mutate(`objects/${objectId}/flow-temperatures`);
            return res;
        });
    };

    return [update] as const;
};
