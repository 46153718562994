import { Button, Card, Form, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useForgotPasswordSubmit } from 'src/hooks/users/useForgotPasswordSubmit';
import { showError } from 'src/utils/showError';
import { useQueryParams, StringParam } from 'use-query-params';

function ForgotPasswordPage() {
    const history = useNavigate();
    const { t } = useTranslation();
    const [change] = useForgotPasswordSubmit();

    const [params] = useQueryParams({
        code: StringParam,
        email: StringParam,
    });

    type FormData = {
        password: string;
    };

    const onSubmit = (form: FormData) => {
        if (params.email && params.code)
            change(params.email, params.code, form.password)
                .then(() => {
                    history('/login');
                })
                .catch((err) => {
                    showError(t('Unable to change password'), err.message);
                });
    };

    return (
        <div className="flex w-screen h-screen">
            <div className="w-full md:w-96 m-auto">
                <Card>
                    <Typography.Title>{t('Change password')}</Typography.Title>
                    <Form layout="vertical" onFinish={onSubmit}>
                        <Form.Item
                            name="password"
                            label={t('Enter new password')}
                        >
                            <Input type="password" />
                        </Form.Item>
                        <Button htmlType="submit" className="float-right">
                            {t('Change password')}
                        </Button>
                    </Form>
                </Card>
            </div>
        </div>
    );
}

export default ForgotPasswordPage;
