import { useState } from 'react';
import { LocalDB } from 'src/api/LocalDB';

export const useFavoriteObjects = () => {
    const [favorites, setFavorites] = useState<number[]>(
        LocalDB.getValue('favoriteobjects') || [],
    );

    const updateFavoriteObjects = (newFavorites: number[]) => {
        setFavorites(newFavorites);
        LocalDB.setValue('favoritesobjects', newFavorites);
    };

    return [updateFavoriteObjects, favorites] as const;
};
