import React, { useState, useMemo, useCallback } from 'react';
import ReactMapGL, {
    Marker,
    GeolocateControl,
    NavigationControl,
} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { mapStyle } from './map-style-basic-v8';
import ControlPanel from './ControlPanel';
import mapboxgl from 'mapbox-gl';
import AutoSuggest from './AutoSuggest';

declare module 'mapbox-gl' {
    interface MapboxStatic {
        workerClass?: any; // Adjust the type as needed
    }
}

// eslint-disable-next-line import/no-webpack-loader-syntax
(mapboxgl as any).workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

interface MapProps {
    setPrompt: React.Dispatch<React.SetStateAction<boolean>>;
    corners: [number, number][];
    numBlocks: number;
    setCorners: React.Dispatch<React.SetStateAction<[number, number][]>>;
}

const Map: React.FC<MapProps> = ({
    setPrompt,
    corners,
    numBlocks,
    setCorners,
}) => {
    const [interactiveLayerIds, setInteractiveLayerIds] = useState<string[]>([
        'nonexist',
    ]);
    const [cursor, setCursor] = useState<string>('auto');

    const [viewport, setViewport] = useState({
        latitude: 59.31598569919558,
        longitude: 18.07043975084899,
        zoom: 11,
    });

    const onInteractiveLayersChange = useCallback(
        (layerFilter: (id: string) => boolean) => {
            setInteractiveLayerIds(
                mapStyle?.layers.map((layer) => layer.id).filter(layerFilter),
            );
        },
        [],
    );

    const numCorners = useMemo(() => {
        let num: number = Math.max(numBlocks, 1) + 2;
        return num;
    }, [numBlocks]);

    const handleClick = (event: any) => {
        //const feature = event.features && event.features[0];

        // if (!feature || (feature && feature.layer.id !== 'building')) {
        //     window.alert(`Please select building`);
        //     return;
        // }
        if (!corners.length) {
            setPrompt(true);
        }

        if (corners.length >= numCorners) {
            return;
        }
        const { lng, lat } = event.lngLat;
        setCorners((prevCorners) => [...prevCorners, [lng, lat]]);
    };

    const onMouseEnter = useCallback(() => setCursor('pointer'), []);
    const onMouseLeave = useCallback(() => setCursor('auto'), []);

    const handleSearchSelect = (suggestion: any) => {
        setViewport({
            ...viewport,
            latitude: suggestion.latitude,
            longitude: suggestion.longitude,
            zoom: 18, // Adjust zoom level as needed
        });
    };

    return (
        <ReactMapGL
            {...viewport}
            style={{ width: '100vw', height: '100vh' }}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            mapStyle={'mapbox://styles/mapbox/light-v11'}
            onClick={handleClick}
            cursor={cursor}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            interactiveLayerIds={interactiveLayerIds}
            onMove={(evt) => setViewport(evt.viewState)}
        >
            <GeolocateControl
                positionOptions={{ enableHighAccuracy: true }}
                trackUserLocation={true}
            />
            <NavigationControl showCompass={false} />
            {corners &&
                !!corners.length &&
                corners.map((corner, index) => {
                    return (
                        <Marker
                            key={index}
                            longitude={corner[0]}
                            latitude={corner[1]}
                        >
                            <div
                                className="bg-blue-500 rounded-full"
                                style={{ width: '15px', height: '15px' }}
                            />
                        </Marker>
                    );
                })}
            <ControlPanel onChange={onInteractiveLayersChange} />
            <div
                style={{
                    position: 'absolute',
                    top: 10,
                    right: 100,
                    zIndex: 100,
                }}
            >
                <AutoSuggest onSelect={handleSearchSelect} />
            </div>
        </ReactMapGL>
    );
};

export default Map;
