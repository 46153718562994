import { useRealTimeFetch } from 'src/hooks/objects/useRealTimeFetch';
import { Col, Row, Skeleton } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DataSelector } from 'src/components/DataSelector';
import { RootState } from 'src/store/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { usePartitionRealTime } from 'src/hooks/objects/usePartionRealTime2';
import Container from 'src/components/Container';
import { RealTimeGraph } from 'src/components/RealTimeGraph';
import { RealTimeGraphHeating } from 'src/components/RealTimeGraphHeating';
import RangePickerRealTime from 'src/components/RangePickerRealTime';
import { formatDate } from 'src/utils/formatDate';
import { useGetDOT } from 'src/hooks/objects/useGetDOT';
import { ControlState } from 'src/models/ControlState';
import { useAverageTemperaturesWithControlState } from 'src/hooks/objects/useAverageTemperaturesWithControllState';
import { useTemperatures } from 'src/hooks/objects/useTemperatures';
import { useRealTimeHeating } from 'src/hooks/objects/useRealTimeHeating';
import { clearSelectedKeys } from 'src/store/reducers/selectedKeysSlice';
import {
    setRefreshFlag,
    clearSelectedFloors,
    setAvgData,
    setPeriod,
} from 'src/store/reducers/selectedObjectSlice';
import { useAvgTempFloorRealTime } from 'src/hooks/objects/useAvgTempFloorRealTime';
import { useAvgTempObjRealTime } from 'src/hooks/objects/useAvgTempObjRealTime';

interface Props {
    objectId: number;
    controlStates: ControlState[];
    setActiveTabIndex?: (index: number) => void;
}

function RealTimeView(props: Props) {
    const { objectId, controlStates } = props;

    const period = {
        start: formatDate(
            new Date(
                new Date().setFullYear(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() - 7,
                ),
            ),
        ),
        stop: formatDate(
            new Date(
                new Date().setFullYear(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate(),
                ),
            ),
        ),
    };

    const [isHotColdActive, setHotColdActive] = useState(true);
    const [start, setStart] = useState(period.start);
    const [stop, setStop] = useState(period.stop);
    const { data: sensorAveragesList } = useTemperatures(
        objectId,
        controlStates[controlStates.length - 1].state_order,
    );
    const [sensorAverages, prevSensorAverages] = sensorAveragesList ?? [
        undefined,
        undefined,
    ];
    const {
        data: averageTemperatures,
        previous: previousAverageTemperatures,
    } = useAverageTemperaturesWithControlState(
        objectId,
        controlStates[controlStates.length - 1].state_order,
        true,
    );
    const { data: DOT } = useGetDOT(
        objectId,
        controlStates[controlStates.length - 1].state_order,
    );

    const selectedSensors = useSelector(
        (state: RootState) => state.selectedSensors,
    );

    const { t } = useTranslation();
    const [sensorType, setSensorType] = useState<{
        type1: number;
        type2: number;
        type3: number | undefined;
    }>({
        type1: 101,
        type2: 0,
        type3: undefined,
    });

    console.log(setSensorType);
    const {
        isLoading,
        data,
        fetchData,
        hotColdFlag,
        setHotColdFlag,
        setData,
        setSearchActive,
    } = useRealTimeFetch();
    const {
        isLoading: isLoadingHeating,
        data: dataHeating,
        hotColdFlag: hotColdFlagHeating,
    } = useRealTimeHeating(props.objectId, start, stop, 0, 21, 11, 1, 51, 61);

    const dispatch = useDispatch();

    const [refAreaLeft, setRefAreaLeft] = useState<number>(0);
    const [refAreaRight, setRefAreaRight] = useState<number>(0);
    const [left, setLeft] = useState<number | string>('dataMin');
    const [right, setRight] = useState<number | string>('dataMax');
    const [include, setInclude] = useState<number[]>([]);
    const [heatingInclude, setHeatingInclude] = useState<number[]>([]);
    const [timestamps, setTimeStamps] = useState<number[]>([]);
    const [partition] = usePartitionRealTime();
    const [index, setIndex] = useState(1); //eslint-disable-line
    const {
        refreshFlag,
        avgData,
        nodeType,
        selectedFloorSensors,
    } = useSelector((state: RootState) => state.selectedObject);

    const [fetchAvgTemp] = useAvgTempFloorRealTime();
    const [fetchAvgTempObj] = useAvgTempObjRealTime();
    const isMounted = useRef(false);

    const clearSensorsOnly = () => {
        setInclude([]);
        setHeatingInclude([]);
        setHotColdFlag(false);

        setData({
            ...data,
            data1: [],
            data2: [],
        });
    };

    const clearAllSensors = async () => {
        clearSensorsOnly();
        setHotColdActive(false);
        await dispatch(clearSelectedKeys());
        await dispatch(clearSelectedFloors());
    };

    useEffect(() => {
        fetchData(
            objectId,
            start,
            stop,
            sensorType.type1,
            sensorType.type2,
            sensorType.type3,
        );
        dispatch(setRefreshFlag(false));
        setHotColdActive(true);
        // eslint-disable-next-line
    }, [refreshFlag]);

    useEffect(() => {
        const selectedSensorsByObjectId = selectedSensors[objectId] || [];
        if (selectedSensorsByObjectId && selectedSensorsByObjectId.length) {
            //setPartionLoading(true);

            const sensorIds = selectedSensorsByObjectId.map(
                (sensorId) => sensorId,
            );

            // Perform the partition operation when selectedSensors change
            // eslint-disable-next-line
            partition(objectId, sensorIds, start, stop).then((v: any) => {
                //setPartionLoading(false);
                setInclude([]);
                setHeatingInclude([]);
                setHotColdFlag(false);
                if (v && v.length) {
                    if (v[1].length) {
                        setData({
                            ...data,
                            data1: v[0],
                            data2: v[1],
                        });
                    } else {
                        setData({
                            ...data,
                            data1: v[0],
                        });
                    }
                    setSearchActive(true);
                }
            });
        } else if (isHotColdActive) {
            fetchData(
                objectId,
                start,
                stop,
                sensorType.type1,
                sensorType.type2,
                sensorType.type3,
            );
        } else {
            clearSensorsOnly();
        }

        if (isHotColdActive && nodeType?.type === 'sensor') {
            setHotColdActive(false);
        }
        // eslint-disable-next-line
    }, [objectId, selectedSensors, start, stop]);

    useEffect(() => {
        if (
            start &&
            stop &&
            selectedFloorSensors &&
            selectedFloorSensors.length
        ) {
            if (selectedFloorSensors && selectedFloorSensors.length) {
                fetchAvgTemp(objectId, start, stop, selectedFloorSensors).then(
                    (result) => {
                        dispatch(setAvgData(result[0]));
                    },
                );
            } else {
                dispatch(setAvgData([]));
            }
        }
    }, [objectId, start, stop, selectedFloorSensors]); //eslint-disable-line

    const generateTimestampsInterval = (
        start: string,
        stop: Date,
        totalIntervals: number,
    ): number[] => {
        const startTimestamp = new Date(start).getTime();
        const stopTimestamp = stop.getTime();
        const totalDuration = stopTimestamp - startTimestamp;
        const intervalLength = totalDuration / totalIntervals;
        const timestamps: number[] = [];

        for (let i = 0; i <= totalIntervals; i++) {
            const timestamp = startTimestamp + i * intervalLength;
            timestamps.push(timestamp / 1000);
        }

        return timestamps;
    };

    useEffect(() => {
        const totalIntervals = 8;
        const endDate = new Date(stop!);
        endDate.setDate(endDate.getDate() + 1);
        endDate.setHours(0, 0, 0, 0);
        const timestamps = generateTimestampsInterval(
            new Date(start!).toDateString()!,
            endDate,
            totalIntervals,
        );
        setTimeStamps(timestamps);

        isMounted.current
            ? fetchAvgTempObj(objectId, start, stop).then((result) => {
                  setData((prevData) => ({
                      ...prevData,
                      avgObjData: result[0],
                  }));
              })
            : (isMounted.current = true);
    }, [start, stop]); // eslint-disable-line

    return (
        <>
            <Row gutter={[20, 20]}>
                <Col xs={24} md={24}>
                    <RangePickerRealTime
                        period={{ start: start, stop: stop }}
                        isHotColdActive={isHotColdActive}
                        onChange={({ start, stop }) => {
                            setStart(start);
                            setStop(stop);
                            dispatch(setPeriod({ start, stop }));
                        }}
                        clearSensors={clearAllSensors}
                        setHotAndColdState={setHotColdActive}
                    />
                    {!isLoading && (
                        <RealTimeGraph
                            mirror={index === 4}
                            newTimestamps={timestamps}
                            hotNCold={hotColdFlag}
                            refLeft={refAreaLeft}
                            refRight={refAreaRight}
                            start={start}
                            stop={stop}
                            left={left}
                            right={right}
                            onMouseDown={(e: any) => {
                                setRefAreaLeft(e.activeLabel);
                            }}
                            onMouseMove={(e: any) => {
                                refAreaLeft && setRefAreaRight(e.activeLabel);
                            }}
                            onReset={() => {
                                setLeft('datsMin');
                                setRight('dataMax');
                            }}
                            zoom={() => {
                                if (
                                    refAreaLeft === refAreaRight ||
                                    refAreaRight === 0
                                ) {
                                    setRefAreaLeft(0);
                                    setRefAreaRight(0);
                                    return;
                                }
                                if (refAreaLeft > refAreaRight) {
                                    setRight(refAreaLeft);
                                    setLeft(refAreaRight);
                                } else {
                                    setLeft(refAreaLeft);
                                    setRight(refAreaRight);
                                }
                                setRefAreaLeft(0);
                                setRefAreaRight(0);
                            }}
                            title={t('Inomhus')}
                            include={include}
                            data={data.data1}
                            data2={data.data2}
                            data3={data.data3}
                            avgObjData={data.avgObjData}
                            avgData={avgData}
                            firstAxisColor={undefined}
                            secondAxisColor={undefined}
                            sensorAverages={sensorAverages ?? []}
                            prevSensorAverages={prevSensorAverages}
                            averageTemperatures={
                                averageTemperatures?.data ?? []
                            }
                            isHotColdActive={isHotColdActive}
                            prevAverageTemperatures={
                                previousAverageTemperatures?.data ?? []
                            }
                            referenceTemperature={
                                DOT ? DOT.pseudoDOT ?? Math.round(DOT.dot) : NaN
                            }
                        />
                    )}

                    {isLoading && (
                        <Container padding cardTitle={t('Inomhus')}>
                            <div>
                                <Skeleton />
                            </div>
                        </Container>
                    )}
                    <Row style={{ justifyContent: 'center' }}>
                        {data.data1 && !isLoading && (
                            <DataSelector
                                hotNCold={hotColdFlag}
                                data={data.data1}
                                onClick={(val) => {
                                    if (!include.includes(val)) {
                                        setInclude([...include, val]);
                                    }
                                    if (include.includes(val)) {
                                        const temp = include.filter(
                                            (v) => v !== val,
                                        );
                                        setInclude(temp);
                                    }
                                }}
                                include={include}
                                sensorAverages={sensorAverages ?? []}
                                prevSensorAverages={prevSensorAverages ?? []}
                                averageTemperatures={
                                    averageTemperatures?.data ?? []
                                }
                                prevAverageTemperatures={
                                    previousAverageTemperatures?.data ?? []
                                }
                                referenceTemperature={
                                    DOT
                                        ? DOT.pseudoDOT ?? Math.round(DOT.dot)
                                        : NaN
                                }
                            />
                        )}
                        {/* {data.data2 && !isLoading && (
                            <DataSelector
                                hotNCold={false}
                                data={data.data2}
                                onClick={(val) => {
                                    if (!include.includes(val)) {
                                        setInclude([...include, val]);
                                    }
                                    if (include.includes(val)) {
                                        const temp = include.filter(
                                            (v) => v !== val,
                                        );
                                        setInclude(temp);
                                    }
                                }}
                                include={include}
                            />
                        )} */}
                        {data.data3 && !isLoading && (
                            <DataSelector
                                hotNCold={false}
                                data={data.data3}
                                onClick={(val) => {
                                    if (!include.includes(val)) {
                                        setInclude([...include, val]);
                                    }
                                    if (include.includes(val)) {
                                        const temp = include.filter(
                                            (v) => v !== val,
                                        );
                                        setInclude(temp);
                                    }
                                }}
                                include={include}
                            />
                        )}

                        {data.avgObjData && !isLoading && (
                            <DataSelector
                                hotNCold={false}
                                data={data.avgObjData}
                                onClick={(val) => {
                                    if (!include.includes(val)) {
                                        setInclude([...include, val]);
                                    }
                                    if (include.includes(val)) {
                                        const temp = include.filter(
                                            (v) => v !== val,
                                        );
                                        setInclude(temp);
                                    }
                                }}
                                include={include}
                                isDashed={true}
                            />
                        )}

                        {selectedFloorSensors &&
                            !!selectedFloorSensors.length &&
                            avgData &&
                            !!avgData.length &&
                            !isLoading && (
                                <DataSelector
                                    hotNCold={false}
                                    key={index}
                                    data={avgData}
                                    onClick={(val) => {
                                        if (!include.includes(val)) {
                                            setInclude([...include, val]);
                                        }
                                        if (include.includes(val)) {
                                            const temp = include.filter(
                                                (v) => v !== val,
                                            );
                                            setInclude(temp);
                                        }
                                    }}
                                    include={include}
                                    isDashed={true}
                                />
                            )}
                    </Row>
                </Col>
            </Row>

            <Row gutter={[20, 20]}>
                <Col xs={24} md={24}>
                    {!isLoadingHeating && (
                        <RealTimeGraphHeating
                            newTimestamps={timestamps}
                            hotNCold={hotColdFlagHeating}
                            refLeft={refAreaLeft}
                            refRight={refAreaRight}
                            start={start}
                            stop={stop}
                            left={left}
                            right={right}
                            onMouseDown={(e: any) => {
                                setRefAreaLeft(e.activeLabel);
                            }}
                            onMouseMove={(e: any) => {
                                refAreaLeft && setRefAreaRight(e.activeLabel);
                            }}
                            onReset={() => {
                                setLeft('dataMin');
                                setRight('dataMax');
                            }}
                            zoom={() => {
                                if (
                                    refAreaLeft === refAreaRight ||
                                    refAreaRight === 0
                                ) {
                                    setRefAreaLeft(0);
                                    setRefAreaRight(0);
                                    return;
                                }
                                if (refAreaLeft > refAreaRight) {
                                    setRight(refAreaLeft);
                                    setLeft(refAreaRight);
                                } else {
                                    setLeft(refAreaLeft);
                                    setRight(refAreaRight);
                                }
                                setRefAreaLeft(0);
                                setRefAreaRight(0);
                            }}
                            include={heatingInclude}
                            data={dataHeating.data1}
                            data2={dataHeating.data2}
                            data3={dataHeating.data3}
                            data4={dataHeating.data4}
                            data5={dataHeating.data5}
                            data6={dataHeating.data6}
                            firstAxisColor={'#91d5ff'}
                            secondAxisColor={'#fa541c'}
                            sensorAverages={sensorAverages ?? []}
                            prevSensorAverages={prevSensorAverages}
                            averageTemperatures={
                                averageTemperatures?.data ?? []
                            }
                            prevAverageTemperatures={
                                previousAverageTemperatures?.data ?? []
                            }
                            referenceTemperature={
                                DOT ? DOT.pseudoDOT ?? Math.round(DOT.dot) : NaN
                            }
                        />
                    )}
                </Col>
            </Row>

            <Row style={{ justifyContent: 'center' }}>
                {!isLoadingHeating &&
                    [
                        dataHeating.data1,
                        dataHeating.data2,
                        dataHeating.data3,
                        dataHeating.data4,
                        dataHeating.data5,
                        dataHeating.data6,
                    ].map(
                        (data, index) =>
                            data && (
                                <DataSelector
                                    key={index}
                                    hotNCold={false}
                                    data={data}
                                    onClick={(val) => {
                                        if (!heatingInclude.includes(val)) {
                                            setHeatingInclude([
                                                ...include,
                                                val,
                                            ]);
                                        } else {
                                            const temp = include.filter(
                                                (v) => v !== val,
                                            );
                                            setHeatingInclude(temp);
                                        }
                                    }}
                                    include={heatingInclude}
                                />
                            ),
                    )}
            </Row>
        </>
    );
}

export default RealTimeView;
