import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';

export const useMeterMeterId = (objectId: number) => {
    return useSWR<
        {
            objectId: number;
            meterId: string;
            metryIntegratorId: number;
            type: 'ELECTRIC' | 'HEATING';
        }[]
    >(`external/metry/objects/${objectId}`, swrFetch);
};
