import { Typography, Row, Col, Modal, Button, Form, Input, Select } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import logoSrc from 'src/assets/Logo.svg';
import RoleSelector from 'src/components/RoleSelector';
import { UserRoles } from 'src/models/User';
import { useAddUser } from 'src/hooks/users/useAddUser';
import { showError } from 'src/utils/showError';
import { useCorporations } from 'src/hooks/corporations/useCorporations';

interface Props {
    visible: boolean;
    onCancel: () => void;
    onFinish: () => void;
    corporationId?: number;
}

export default function UserPopup(props: Props) {
    const { visible, onCancel, onFinish, corporationId } = props;
    const { data: corporations } = useCorporations();
    const { t } = useTranslation();
    const [addUser] = useAddUser();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <Modal
                className="round"
                visible={visible}
                onCancel={onCancel}
                footer={null}
                closable={false}
            >
                <Row>
                    <Col span={12}>
                        <Typography.Title level={3}>
                            {t('Invite user')}
                        </Typography.Title>
                    </Col>
                    <Col span={12}>
                        <img
                            className="h-5 float-right"
                            src={logoSrc}
                            alt="Nrlyze logo"
                        />
                    </Col>
                </Row>

                <Form
                    layout="vertical"
                    onFinish={(form: any) => {
                        setIsLoading(true);
                        addUser(form.corporationId, form)
                            .catch((e) => {
                                showError(t('Not able to add user'), e.message);
                            })
                            .then(() => {
                                setIsLoading(false);
                                onFinish();
                            })
                            .catch((e) => {
                                showError(
                                    t('Not able to add new user'),
                                    e.getMessage(),
                                );
                            });
                    }}
                >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: t('Please select an email'),
                            },
                        ]}
                        name="email"
                    >
                        <Input placeholder="company@email.com" name="email" />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: t('Please select a name'),
                            },
                        ]}
                        name="name"
                    >
                        <Input placeholder="Alice Bobsson" name="email" />
                    </Form.Item>
                    {!corporationId && corporations && (
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t('Please select a corporation'),
                                },
                            ]}
                            name="corporationId"
                        >
                            <Select placeholder={t('Enter corporation')}>
                                {corporations.map((v) => (
                                    <Select.Option value={v.id}>
                                        {v.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    <RoleSelector
                        currentSelected={UserRoles.DEFAULT}
                        onChange={() => {}}
                    />

                    <Row gutter={40}>
                        <Col span={12}>
                            <Form.Item>
                                <Button
                                    onClick={() => onCancel()}
                                    className="w-full"
                                    size="large"
                                    loading={isLoading}
                                >
                                    {t('Cancel')}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item>
                                <Button
                                    className="w-full"
                                    size="large"
                                    loading={isLoading}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {t('Invite user')}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}
