import { API } from 'src/api/API';
import { mutate } from 'swr';

const fetchPartialKeys = async (partialKey: string) => {
    mutate((key) => key?.toString().includes(partialKey));
};

export const useAverageTemperaturesForSensor = () => {
    const invalidate = async (
        objectId: number,
        controlStateId: number,
        isActive: boolean,
        sensorId: number,
    ) => {
        const api = new API();
        const newAverage = await api.invalidateSensor(
            objectId,
            controlStateId,
            sensorId,
            isActive,
        );
        await fetchPartialKeys('realtime');

        return newAverage;
    };

    return [invalidate];
};
