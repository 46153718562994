import { TemperatureWithControlState } from 'src/models/Temperature';
import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';

export const useFlowTemperatures = (objectId: number) => {
    return useSWR<{
        desired: TemperatureWithControlState;
        measured: TemperatureWithControlState;
    }>(`objects/${objectId}/flow-temperatures`, swrFetch);
};

export const useFlowTemperaturesWithState = (
    objectId: number,
    stateId?: number,
) => {
    return useSWR<{
        desired: TemperatureWithControlState;
        measured: TemperatureWithControlState;
    }>(
        `objects/${objectId}/control-states/${stateId}/flow-temperatures`,
        swrFetch,
        {
            isPaused: () => stateId === undefined,
            suspense: true,
        },
    );
};
