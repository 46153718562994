import React, { useState } from 'react';
import { Table, Select, Input, Button, Modal } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useDispatch, useSelector } from 'react-redux';
import { setTableRows } from 'src/store/reducers/tableRowsSlice';
import { RootState } from 'src/store/rootReducer';
import ViewTemplate from 'src/components/3DMapping/ViewTemplate';
import ViewAllTemplates from 'src/components/3DMapping/ViewAllTemplates';

export interface TableRow {
    id: number;
    key: string;
    floorNumber: number;
    templateId: number;
}

interface FloorTemplate {
    Id: number;
    Name: string;
}

interface TableProps {
    floorTemplates: FloorTemplate[];
    blockIndex: number;
}

const MappingTableComponent: React.FC<TableProps> = (props: TableProps) => {
    const dispatch = useDispatch();
    const { floorTemplates, blockIndex } = props;
    const tableRows = useSelector((state: RootState) => state.tableRows);

    const [newRow, setNewRow] = useState<TableRow>({
        id: 1,
        key: String(Date.now()),
        floorNumber: 10,
        templateId: floorTemplates[0].Id,
    });

    // Modal state and visibility handler
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isViewAllModalVisible, setIsViewAllModalVisible] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
        null,
    );

    const showModal = (templateId: number) => {
        setSelectedTemplateId(templateId);
        setIsModalVisible(true);
    };

    const handleModalOk = () => {
        setIsModalVisible(false);
    };
    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const showModalAllTempaltes = () => {
        setIsViewAllModalVisible(true);
    };

    const handleModalViewAllOk = () => {
        setIsViewAllModalVisible(false);
    };
    const handleModalCancelViewAll = () => {
        setIsViewAllModalVisible(false);
    };

    const generateKey = (): string => String(tableRows[blockIndex].length + 1);

    const columns: ColumnProps<TableRow>[] = [
        {
            title: 'Floor Number',
            dataIndex: 'floorNumber',
            key: 'floorNumber',
            render: (text, record) =>
                renderEditableCell(text, record.key, 'floorNumber'),
        },
        {
            title: 'Template Id',
            dataIndex: 'templateId',
            key: 'templateId',
            render: (text, record) => {
                return (
                    <Select
                        value={
                            tableRows[blockIndex].find(
                                (row) => row.key === record.key,
                            )?.templateId
                        }
                        style={{ width: 120 }}
                        onChange={(value) =>
                            handleChange('templateId', value, record.key)
                        }
                    >
                        {floorTemplates.map((template) => (
                            <Select.Option
                                key={template.Id}
                                value={template.Id}
                            >
                                {template.Name}
                            </Select.Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Button
                        onClick={() => showModal(record.templateId)}
                        style={{ marginRight: 8 }}
                    >
                        View Template
                    </Button>
                    <Button onClick={() => handleDeleteRow(record.key)}>
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    const handleChange = (
        key: keyof TableRow,
        value: string | number,
        recordKey: string,
    ) => {
        const newData = tableRows[blockIndex].map((row) =>
            row.key === recordKey ? { ...row, [key]: value } : row,
        );
        dispatch(setTableRows({ blockIndex, tableRows: newData }));
    };

    const handleAddRow = () => {
        const newRowWithKey: TableRow = {
            ...newRow,
            key: String(tableRows[blockIndex].length + 1),
        };
        const newData = [...tableRows[blockIndex], newRowWithKey];
        dispatch(setTableRows({ blockIndex, tableRows: newData }));
        setNewRow({
            id: 0,
            key: generateKey(),
            floorNumber: 10 + newData?.length,
            templateId: floorTemplates[0].Id,
        });
    };

    const handleDeleteRow = (recordKey: string) => {
        const newData = tableRows[blockIndex].filter(
            (row) => row.key !== recordKey,
        );
        dispatch(setTableRows({ blockIndex, tableRows: newData }));
    };

    const renderEditableCell = (
        text: string,
        recordKey: string,
        dataIndex: keyof TableRow,
    ) => {
        return (
            <Input
                value={
                    tableRows[blockIndex].find(
                        (row) => row.key === recordKey,
                    )?.[dataIndex]
                }
                onChange={(e) =>
                    handleChange(dataIndex, e.target.value, recordKey)
                }
            />
        );
    };

    return (
        <div className="mb-2">
            <div className="flex justify-between mb-2">
                <div className="flex items-end flex-grow justify-end">
                    <Select
                        style={{ width: 300 }}
                        onChange={(value) => {
                            const updatedTableRows = tableRows[blockIndex].map(
                                (tableRow) => {
                                    const newTableRow = { ...tableRow };
                                    newTableRow.templateId = value;
                                    return newTableRow;
                                },
                            );

                            dispatch(
                                setTableRows({
                                    blockIndex,
                                    tableRows: updatedTableRows,
                                }),
                            );
                        }}
                    >
                        {floorTemplates.map((template) => (
                            <Select.Option
                                key={template.Id}
                                value={template.Id}
                            >
                                {template.Id} - {template.Name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
            <Button className="mb-2" onClick={handleAddRow}>
                Add Floor
            </Button>

            <Button
                onClick={() => showModalAllTempaltes()}
                style={{ marginLeft: 10 }}
            >
                View All Templates
            </Button>
            <Table dataSource={tableRows[blockIndex]} columns={columns} />
            <Modal
                title="View Template"
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                width={800}
            >
                <div>Template Id {selectedTemplateId}.</div>
                <ViewTemplate templateId={selectedTemplateId} />
            </Modal>
            <Modal
                title="View All Templates"
                open={isViewAllModalVisible}
                onOk={handleModalViewAllOk}
                onCancel={handleModalCancelViewAll}
                width={1000}
            >
                <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                    <ViewAllTemplates floorTemplates={floorTemplates} />
                </div>
            </Modal>
        </div>
    );
};

export default MappingTableComponent;
