import Auth from '@aws-amplify/auth';
import { mutate } from 'swr';

export const useSignOut = () => {
    const signIn = () => {
        return Auth.signOut().then((v) => {
            mutate('/users/me');
            return v;
        });
    };

    return [signIn] as const;
};
