import SitesView from 'src/views/sites/SitesView';
import { useSites } from 'src/hooks/users/useSites';

function SitePage() {
    const { data: sites } = useSites();
    sites?.sort((a, b) => a.name.localeCompare(b.name));

    return <>{sites ? <SitesView sites={sites} /> : null}</>;
}

export default SitePage;
