import MetryPanel from 'src/views/corporation/integrators/metry/MetryObjectPanel';

interface PanelConfig {
    component: JSX.Element;
}

interface IProps {
    objectId: number;
}

const ObjectIntegrators = (props: IProps) => {
    const { objectId } = props;

    const panels: PanelConfig[] = [
        {
            component: <MetryPanel objectId={objectId} />,
        },
    ];

    return <>{panels.map((panel) => panel.component)}</>;
};

export default ObjectIntegrators;
