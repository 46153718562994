import { Map, MapItem } from 'src/components/Map';
import { HomeTwoTone } from '@ant-design/icons';
import { useSites } from 'src/hooks/users/useSites';
import Nav from 'src/utils/nav/Nav';
import { useNavigate } from 'react-router';
import { Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

export default function MapView() {
    const { data: sites } = useSites();
    const history = useNavigate();
    const filterSites = sites?.map((v) =>
        v.objects?.filter((v) => v.latitude != null),
    );
    const { t } = useTranslation();
    function colorSelector(savings: number) {
        if (savings === -1) {
            return '#8c8c8c';
        }
        if (savings <= 0.02) {
            return '#51baa2';
        }
        if (savings >= 0.03) {
            return '#ffec3d';
        }

        if (savings <= 0.06) {
            return '#fa8c16';
        }
        if (savings >= 0.09) {
            return '#cf1322';
        } else {
            return '#8c8c8c';
        }
    }

    return (
        <>
            <div style={{ height: 500 }}>
                <Map
                    zoom={5}
                    center={{
                        lat: 60,
                        lng: 16,
                    }}
                >
                    {filterSites?.map((v) =>
                        v?.map((e) => {
                            const siteName = () => {
                                let tempSite = '';
                                sites?.forEach((site) =>
                                    site.objects?.forEach((object) => {
                                        if (object.id === e.id) {
                                            tempSite = site.name;
                                        }
                                    }),
                                );
                                return tempSite;
                            };
                            return (
                                <MapItem
                                    key={e.id}
                                    lng={e.longitude}
                                    lat={e.latitude}
                                >
                                    <Tooltip
                                        title={
                                            <>
                                                <div className="text-white">
                                                    <Typography
                                                        style={{
                                                            color: 'whitesmoke',
                                                        }}
                                                    >
                                                        {t(
                                                            'Site name {{name}}',
                                                            {
                                                                name: siteName(),
                                                            },
                                                        )}
                                                    </Typography>
                                                    <br />
                                                    <Typography
                                                        style={{
                                                            color: 'whitesmoke',
                                                        }}
                                                    >
                                                        {t(
                                                            'Object name: {{name}}',
                                                            { name: e.name },
                                                        )}
                                                    </Typography>
                                                </div>
                                            </>
                                        }
                                    >
                                        <HomeTwoTone
                                            twoToneColor={colorSelector(
                                                e.potentialSavings || -1,
                                            )}
                                            onClick={() => {
                                                history(
                                                    Nav.build({
                                                        path:
                                                            '/analytics/:objectId',
                                                        routeParams: {
                                                            objectId: e.id,
                                                        },
                                                        queryParams: {},
                                                    }),
                                                );
                                            }}
                                            style={{
                                                fontSize: '20px',
                                                transform:
                                                    'translate(-50%, -100%)',
                                            }}
                                        />
                                    </Tooltip>
                                </MapItem>
                            );
                        }),
                    )}
                </Map>
            </div>
        </>
    );
}
