import { Col, Row } from 'antd';
// import { useNavigate } from 'react-router';
import ManageSiteView from 'src/views/sites/ManageSiteView';

function AddSitePage() {
    // const history = useNavigate();
    return (
        <>
            <Row>
                <Col span={24}>
                    <>
                        <ManageSiteView
                        // onSuccess={() => {
                        //     history(
                        //         Nav.build({
                        //             path: '/sites',
                        //         }),
                        //     );
                        // }}
                        />
                    </>
                </Col>
            </Row>
        </>
    );
}

export default AddSitePage;
