import { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { env } from 'src/utils/env';
import { useLanguage } from '../generic/useLanguage';

export type LocationEntry = {
    formatted_address: string;
    place_id: string;
    geometry: {
        location: {
            lat: number;
            lng: number;
        };
    };
};

export const useLocationSearch = (address?: string) => {
    const [, { language }] = useLanguage();
    const [isInitialized, setIsInitialized] = useState(false);
    const [data, setData] = useState<LocationEntry[]>();
    const [loading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>();
    const [currentQuery, setCurrentQuery] = useState<string>();

    useEffect(() => {
        if (!address || address.length === 0) setData(undefined);
    }, [address]);

    useEffect(() => {
        if (address && currentQuery !== address && isInitialized) {
            setIsLoading(true);
            setCurrentQuery(address);
            Geocode.fromAddress(address)
                .then((response) => {
                    const validResponses = ['OK', 'ZERO_RESULTS'];
                    if (!validResponses.includes(response.status)) {
                        throw new Error(response.error_message);
                    } else {
                        setData(response.results);
                    }
                })
                .catch((error) => {
                    setError(error.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [data, address, isInitialized, currentQuery]);

    useEffect(() => {
        Geocode.setLanguage(language);
        if (!isInitialized) {
            Geocode.setApiKey(env.REACT_APP_GOOGLE_API_KEY);
            setIsInitialized(true);
        }
    }, [isInitialized, language]);

    return { error, data, loading };
};
