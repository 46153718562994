import { API } from 'src/api/API';

interface Space {
    MaxSpaceNumber: number;
    TemplateId: number;
}

export const useFetchSpaces = () => {
    const fetchSpaces = async (): Promise<Space[]> => {
        try {
            const spaces = await new API().fetchSpaces();
            return spaces;
        } catch (error) {
            console.error('Error fetching spaces:', error);
            throw error;
        }
    };

    return [fetchSpaces] as const;
};
