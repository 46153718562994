import React from 'react';
import { List, Button } from 'antd';
import { useReports } from 'src/hooks/reports/useReports';

const PerformanceReportPage: React.FC = () => {
    const { data: allReports } = useReports();
    const mappedReports = (allReports || []).map((report) => ({
        reportName: report.reportUrl.split('/').pop(),
        reportUrl: report.reportUrl,
        id: report.id,
    }));

    return (
        <div style={{ margin: '15px', height: '100vh', overflowY: 'auto' }}>
            {mappedReports && !!mappedReports.length && (
                <List
                    dataSource={mappedReports}
                    renderItem={(report) => (
                        <List.Item>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div style={{ flex: 1 }}>
                                    {report.reportName}
                                </div>
                                <a
                                    href={report.reportUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Button
                                        style={{ marginLeft: '10px' }}
                                        type="primary"
                                    >
                                        Download
                                    </Button>
                                </a>
                            </div>
                        </List.Item>
                    )}
                />
            )}
        </div>
    );
};

export default PerformanceReportPage;
