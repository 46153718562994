import { Steps, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

interface IProps {
    corporationId: number;
}

function MetryReadMore(props: IProps) {
    const { corporationId } = props;
    const { t } = useTranslation();
    const redirect = encodeURIComponent(
        `${window.location.protocol}//${window.location.host}/oauth/code`,
    );
    const state = encodeURIComponent('corporation_id=' + corporationId);
    const baseUrl = 'https://app.metry.io/id/oauth/authorize';
    const clientId =
        '8f8a9d0de00d757bfa20e65c30104be243a33f78d340bab35d51d8435147';
    const metryUrl = `${baseUrl}?client_id=${clientId}&redirect_uri=${redirect}&grant_type=authorization_code&response_type=code&state=${state}&scope=basic`;

    return (
        <div className="w-full">
            <Steps direction="vertical">
                <Steps.Step
                    status="process"
                    title={t('Grant access')}
                    description={
                        <>
                            <Typography.Paragraph>
                                {t(
                                    'Sign in to the account to grant Nrlyze access to Metry.',
                                )}
                                <a href={metryUrl}> {t('Sign in')}</a>
                            </Typography.Paragraph>
                        </>
                    }
                />
                <Steps.Step
                    status="wait"
                    title={t('Configure objects')}
                    description={
                        <>
                            <Typography.Paragraph>
                                {t(
                                    'Configure which objects are associated with your metry',
                                )}
                            </Typography.Paragraph>
                        </>
                    }
                />
            </Steps>
        </div>
    );
}

export default MetryReadMore;
