import {
    Col,
    Row,
    Form,
    Input,
    Card,
    Typography,
    Button,
    message,
    Modal,
    Alert,
    Empty,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import logoSrc from 'src/assets/Logo.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RoleSelector from 'src/components/RoleSelector';
import { useUpdateUser } from 'src/hooks/users/useUpdateUser';

import { User } from 'src/models/User';
import { useDeleteUser } from 'src/hooks/users/useDeleteUser';
import { showError } from 'src/utils/showError';
import useViewport from 'src/hooks/generic/useViewport';
import { useForgotPassword } from 'src/hooks/users/useForgotPassword';

interface Props {
    user?: User;
    isLoading: boolean;
    showDangerZone?: boolean;
    onDeleteUser: () => void;
}

function EditUserFeatures(props: Props) {
    const { user, showDangerZone = true, onDeleteUser } = props;
    const { t } = useTranslation();
    const [updateUser] = useUpdateUser();
    const [_deleteUser] = useDeleteUser();
    const [deletingUser, setDeletingUser] = useState(false);
    const { mode } = useViewport();
    const [forgotPassword] = useForgotPassword();

    const onSubmit = (form: Omit<User, 'id' | 'email'>) => {
        if (user) {
            updateUser({
                email: user.email,
                id: user.id,
                name: form.name,
                role: user.role,
            })
                .then(() => {
                    message.success(t('Successfully updated user!'));
                })
                .catch((e) => {
                    showError(t('Successfully updated user!'), e.getMessage());
                });
        } else {
            throw new Error(
                'You are trying to update a user which does not exists',
            );
        }
    };

    const [form] = useForm();

    useEffect(() => {
        if (user && form.getFieldValue('id') === undefined) {
            form.setFieldsValue(user);
        }
    }, [user, form]);

    const deleteUser = () => {
        if (user) {
            _deleteUser(user.id)
                .then(() => {
                    onDeleteUser();
                })
                .catch((e) => {
                    showError(t('Not able to delete user'), e.getMessage());
                });
        }
    };

    const DeletingUserModal = () => {
        return (
            <Modal
                closable={false}
                footer={null}
                visible={deletingUser}
                onOk={() => setDeletingUser(false)}
            >
                <Row>
                    <Col span={18}>
                        <Typography.Title level={4}>
                            {t('Deleting {{name}}', { name: user?.name })}
                        </Typography.Title>
                    </Col>
                    <Col span={6}>
                        <img
                            className="h-5 float-right"
                            src={logoSrc}
                            alt="Nrlyze logo"
                        />
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col xs={24} span={24}>
                        <Alert
                            message={t(
                                'Warning. Removing a user is a permanent action. The user will no longer have access to the system and their account will be removed.',
                            )}
                            type="error"
                        />
                    </Col>
                </Row>
                <br />
                <br />

                <Row gutter={20}>
                    <Col xs={24} span={12}>
                        <Button
                            className="mt-4"
                            onClick={() => setDeletingUser(false)}
                            block
                        >
                            {t('Cancel')}
                        </Button>
                    </Col>
                    <Col xs={24} span={12}>
                        <Button
                            className="mt-4"
                            type="link"
                            block
                            style={{
                                backgroundColor: 'rgb(220, 38, 38)',
                                color: 'white',
                            }}
                            onClick={() => deleteUser()}
                        >
                            {t('Remove')}
                        </Button>
                    </Col>
                </Row>
            </Modal>
        );
    };

    return (
        <div className="w-full">
            <DeletingUserModal />
            {user ? (
                <Row gutter={[20, 20]}>
                    <Col sm={24} md={12}>
                        <Card
                            title={
                                <Typography.Title level={4}>
                                    {t('Updating user {{name}}', {
                                        name: user.name,
                                    })}
                                </Typography.Title>
                            }
                            className="h-full"
                        >
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={onSubmit}
                            >
                                <Form.Item name="name" label={t('Name')}>
                                    <Input />
                                </Form.Item>
                                <RoleSelector
                                    onChange={() => {}}
                                    currentSelected={user.role}
                                />
                                <Button
                                    block={mode === 'desktop' ? false : true}
                                    htmlType="submit"
                                    className="float-right mt-4"
                                    type="primary"
                                >
                                    {t('Save changes')}
                                </Button>
                            </Form>
                        </Card>
                    </Col>

                    {showDangerZone && (
                        <Col sm={24} md={12}>
                            <Card
                                className="h-full"
                                title={
                                    <Typography.Title level={4} type="danger">
                                        {t('Danger zone')}
                                    </Typography.Title>
                                }
                            >
                                <div>
                                    <Row align="middle" gutter={[20, 20]}>
                                        <Col xs={24} md={18}>
                                            <Typography.Text>
                                                <Typography.Text strong>
                                                    {t('Delete user')}
                                                </Typography.Text>
                                                <br />
                                                {t(
                                                    'Removes {{name}} from your account. This action is irreversible and can not be undone',
                                                    { name: user.name },
                                                )}
                                            </Typography.Text>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Button
                                                block
                                                onClick={() =>
                                                    setDeletingUser(true)
                                                }
                                                type="text"
                                                style={{
                                                    backgroundColor:
                                                        'rgb(220, 38, 38)',
                                                    color: 'white',
                                                }}
                                            >
                                                {t('Delete')}
                                            </Button>
                                        </Col>

                                        <Col xs={24} md={18}>
                                            <Typography.Text>
                                                <Typography.Text strong>
                                                    {t('Reset password')}
                                                </Typography.Text>
                                                <br />
                                                {t(
                                                    'Resets the password for {{name}}. This action will send them an email where they can change password.',
                                                    { name: user.name },
                                                )}
                                            </Typography.Text>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Button
                                                block
                                                type="text"
                                                style={{
                                                    backgroundColor:
                                                        'rgb(220, 38, 38)',
                                                    color: 'white',
                                                }}
                                                onClick={() => {
                                                    forgotPassword(
                                                        user.email,
                                                    ).then(() => {
                                                        message.success(
                                                            t(
                                                                'Instructions sent to email',
                                                            ),
                                                        );
                                                    });
                                                }}
                                            >
                                                {t('Reset')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    )}
                </Row>
            ) : (
                <Empty description={t('No user found')} />
            )}
        </div>
    );
}

export default EditUserFeatures;
