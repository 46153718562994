import { API } from 'src/api/API';

export const useAvgTempObjRealTime = () => {
    const fetch = async (objectId: number, start: string, stop: string) => {
        const api = new API();
        const promises = [
            api.fetchRealTimeWithAvgObj(objectId, start, stop, 101),
        ];

        const [data] = await Promise.all(promises);

        const dataToPush = [];

        if (data.avgTemp && data.avgTemp.length) {
            dataToPush?.push({
                id: Math.floor(Math.random() * 9000000) + 1000000,
                name: 'Medeltemperatur',
                sensorFunctionTypeId: -1,
                data: data.avgTemp,
            });
        }

        return [dataToPush];
    };

    return [fetch] as const;
};
