import React, { useState } from 'react';
import Autosuggest, {
    SuggestionsFetchRequestedParams,
    SuggestionSelectedEventData,
} from 'react-autosuggest';
import './AutoSuggest.scss';

interface Suggestion {
    placeName: string;
    latitude: number;
    longitude: number;
}

interface AutoSuggestProps {
    onSelect: (suggestion: Suggestion) => void;
}

const AutoSuggest: React.FC<AutoSuggestProps> = ({ onSelect }) => {
    const [value, setValue] = useState<string>('');
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

    const getSuggestionValue = (suggestion: Suggestion) => suggestion.placeName;

    const renderSuggestion = (suggestion: Suggestion) => (
        <div>{suggestion.placeName}</div>
    );

    const onSuggestionsFetchRequested = async ({
        value,
    }: SuggestionsFetchRequestedParams) => {
        try {
            const response = await fetch(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&autocomplete=true`,
            );
            const data = await response.json();
            if (data.features && data.features.length > 0) {
                const newSuggestions = data.features.map((feature: any) => ({
                    placeName: feature.place_name,
                    latitude: feature.center[1],
                    longitude: feature.center[0],
                })) as Suggestion[];
                setSuggestions(newSuggestions);
            } else {
                setSuggestions([]);
            }
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        }
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const onSuggestionSelected = (
        event: React.FormEvent<any>,
        { suggestion }: SuggestionSelectedEventData<Suggestion>,
    ) => {
        onSelect(suggestion);
    };

    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={{
                className: 'autosuggest-input',
                placeholder: 'Search location...',
                value,
                onChange: (event, { newValue }) => setValue(newValue),
            }}
            theme={{
                container: 'autosuggest-container',
                suggestionsContainer: 'autosuggest-suggestions-container',
                suggestionsList: 'autosuggest-suggestions-list',
            }}
        />
    );
};

export default AutoSuggest;
