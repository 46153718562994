import { useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import {
    LocationEntry,
    useLocationSearch,
} from 'src/hooks/locations/useLocationSearch';
import { useDebouncedCallback } from 'use-debounce';
const { Option } = Select;

/**
 *
 *
 *
 *
 */

interface Props {
    onSelect: (location: LocationEntry) => void;
    value?: string;
    placeId?: string;
}

function LocationSearch(props: Props) {
    const { onSelect, value, placeId } = props;
    const [query, setQuery] = useState<string>();
    const { data, loading } = useLocationSearch(query);
    const { t } = useTranslation();

    const onSearch = useDebouncedCallback((q: string) => {
        if (q.length > 2) setQuery(q);
    }, 500);

    const onSubmit = (id: string) => {
        const place = data?.find((v) => v.place_id === id);

        if (!place) {
            throw new Error(`Not able to find place with id: ${id}`);
        } else {
            onSelect(place);
        }
    };

    return (
        <>
            <Select
                showSearch
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
                defaultValue={placeId}
                onSelect={onSubmit}
                onSearch={(v) => {
                    setQuery(undefined);
                    onSearch(v);
                }}
                loading={loading}
                placeholder={t('Enter address')}
                style={{ width: '100%' }}
            >
                {data?.map((v) => (
                    <Option value={v.place_id} key={v.place_id}>
                        {v.formatted_address}
                    </Option>
                ))}
                {!data && value && placeId && (
                    <Option value={placeId}>{value}</Option>
                )}
            </Select>
        </>
    );
}

export default LocationSearch;
