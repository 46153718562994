export const getPrettySensorName = (
    id: SensorType,
    t: (v: string) => string,
) => {
    const idToName: Record<SensorType, string> = {
        '-1': '---',
        0: t('SMHI observed outdoor temperature'), // keep
        1: t('Local north outdoor temperature'), // keep
        11: t('Heat feed'), // keep
        21: t('Heat return'), // keep
        51: t('Hot water feed'), // keep
        61: t('Hot water return'), // keep
        101: t('Response temperature'), // keep
        301: t('Heatcurve X1'),
        302: t('Heatcurve X2'),
        303: t('Heatcurve X3'),
        304: t('Heatcurve X4'),
        305: t('Heatcurve X5'),
        306: t('Heatcurve X6'),
        307: t('Heatcurve X7'),
        308: t('Heatcurve X8'),
        309: t('Heatcurve X9'),
        310: t('Heatcurve X10'),
        311: t('Heatcurve X11'),
        312: t('Heatcurve X12'),
        313: t('Heatcurve X13'),
        314: t('Heatcurve X14'),
        315: t('Heatcurve X15'),
        316: t('Heatcurve X16'),
        317: t('Heatcurve X17'),
        318: t('Heatcurve X18'),
        319: t('Heatcurve X19'),
        320: t('Heatcurve X20'),
        321: t('Heatcurve Y1'),
        322: t('Heatcurve Y2'),
        323: t('Heatcurve Y3'),
        324: t('Heatcurve Y4'),
        325: t('Heatcurve Y5'),
        326: t('Heatcurve Y6'),
        327: t('Heatcurve Y7'),
        328: t('Heatcurve Y8'),
        329: t('Heatcurve Y9'),
        330: t('Heatcurve Y10'),
        331: t('Heatcurve Y11'),
        332: t('Heatcurve Y12'),
        333: t('Heatcurve Y13'),
        334: t('Heatcurve Y14'),
        335: t('Heatcurve Y15'),
        336: t('Heatcurve Y16'),
        337: t('Heatcurve Y17'),
        338: t('Heatcurve Y18'),
        339: t('Heatcurve Y19'),
        340: t('Heatcurve Y20'),
    };

    return idToName[id];
};

export interface Sensor {
    id: number;
    isActive: boolean;
    isDeleted: boolean;
    name: string | null;
    objectId: number;
    sensorFunctionTypeId: SensorType;
    sensorFunctionTypeIndex: number;
    sensorSerialNumber: string;
    underlyingSensorId: number;
    integratorDatapointId: number;
    uniqueId: string;
    validTo: string;
    validFrom: string;
    latestMeasurementValue: number | null;
    latestMeasurementTimestamp: string | null;
}

export interface CreateSensorDTO {
    mac: string;
    isActive: boolean;
    name: string | null;
    sensorFunctionTypeId: number;
    objectId: number;
    onCollision?: 'REPLACE_OLD' | 'REUSE_OLD';
}

export interface ChangeSensorDTO {
    mac: string;
    onCollision?: 'REPLACE_OLD' | 'REUSE_OLD';
}

export enum SensorType {
    NONE = -1,
    SMHI_OBSERVED_OUTDOOR_TEMPERATURE = 0,
    LOCAL_SOUTH_OUTDOOR_TEMPERATURE = 1,
    HEAT_FEED = 11,
    HEAT_RETURN = 21,
    HOT_WATER_FEED = 51,
    HOT_WATER_RETURN = 61,
    RESPONSE_TEMPERATURE = 101,
    HEATCURVE_X_1 = 301,
    HEATCURVE_X_2 = 302,
    HEATCURVE_X_3 = 303,
    HEATCURVE_X_4 = 304,
    HEATCURVE_X_5 = 305,
    HEATCURVE_X_6 = 306,
    HEATCURVE_X_7 = 307,
    HEATCURVE_X_8 = 308,
    HEATCURVE_X_9 = 309,
    HEATCURVE_X_10 = 310,
    HEATCURVE_X_11 = 311,
    HEATCURVE_X_12 = 312,
    HEATCURVE_X_13 = 313,
    HEATCURVE_X_14 = 314,
    HEATCURVE_X_15 = 315,
    HEATCURVE_X_16 = 316,
    HEATCURVE_X_17 = 317,
    HEATCURVE_X_18 = 318,
    HEATCURVE_X_19 = 319,
    HEATCURVE_X_20 = 320,
    HEATCURVE_Y_1 = 321,
    HEATCURVE_Y_2 = 322,
    HEATCURVE_Y_3 = 323,
    HEATCURVE_Y_4 = 324,
    HEATCURVE_Y_5 = 325,
    HEATCURVE_Y_6 = 326,
    HEATCURVE_Y_7 = 327,
    HEATCURVE_Y_8 = 328,
    HEATCURVE_Y_9 = 329,
    HEATCURVE_Y_10 = 330,
    HEATCURVE_Y_11 = 331,
    HEATCURVE_Y_12 = 332,
    HEATCURVE_Y_13 = 333,
    HEATCURVE_Y_14 = 334,
    HEATCURVE_Y_15 = 335,
    HEATCURVE_Y_16 = 336,
    HEATCURVE_Y_17 = 337,
    HEATCURVE_Y_18 = 338,
    HEATCURVE_Y_19 = 339,
    HEATCURVE_Y_20 = 340,
}
