import {
    Col,
    Row,
    Form,
    Input,
    Card,
    Typography,
    Button,
    message,
    Modal,
    Alert,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import logoSrc from 'src/assets/Logo.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Corporation } from 'src/models/Corporation';
import { useUpdateCorporation } from 'src/hooks/corporations/useUpdateCorporation';
import { useDeleteCorporation } from 'src/hooks/corporations/useDeleteCorporation';
import { showError } from 'src/utils/showError';
import useViewport from 'src/hooks/generic/useViewport';

interface Props {
    corporation?: Corporation;
    isLoading: boolean;
    showDangerZone?: boolean;
    onDeleteCorporation: () => void;
}

function EditCorporationFeatures(props: Props) {
    const { corporation, showDangerZone = true, onDeleteCorporation } = props;
    const { t } = useTranslation();
    const [updateCorporation] = useUpdateCorporation();
    const [_deleteCorporation] = useDeleteCorporation();
    const [deletingCorporation, setDeletingCorporation] = useState(false);
    const { mode } = useViewport();

    const onSubmit = (form: Omit<Corporation, 'id'>) => {
        if (corporation) {
            updateCorporation({
                id: corporation.id,
                name: form.name,
                imgSrc: '',
            })
                .then(() => {
                    message.success(t('Successfully updated corporation!'));
                })
                .catch((err) => {
                    showError(t('Not able to update corporation'), err.message);
                });
        } else {
            alert('!');
        }
    };

    const [form] = useForm();

    useEffect(() => {
        if (corporation && form.getFieldValue('id') === undefined) {
            form.setFieldsValue(corporation);
        }
    }, [corporation, form]);

    const deleteCorporation = () => {
        if (corporation) {
            _deleteCorporation(corporation.id)
                .then(() => {
                    onDeleteCorporation();
                })
                .catch((e) => {
                    showError('Not able to delete user', e.getMessage());
                });
        }
    };

    const DeletingUserModal = () => {
        return (
            <Modal
                closable={false}
                footer={null}
                visible={deletingCorporation}
                onOk={() => setDeletingCorporation(false)}
            >
                <Row>
                    <Col span={18}>
                        <Typography.Title level={4}>
                            {t('Deleting {{name}}', {
                                name: corporation?.name,
                            })}
                        </Typography.Title>
                    </Col>
                    <Col span={6}>
                        <img
                            className="h-5 float-right"
                            src={logoSrc}
                            alt="Nrlyze logo"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Alert
                            message={t(
                                'Warning. Removing a user is a permanent action. The user will no longer have access to the system and their account will be removed.',
                            )}
                            type="error"
                        />
                    </Col>
                </Row>
                <br />
                <br />

                <Row gutter={[20, 10]}>
                    <Col xs={24} md={12}>
                        <Button
                            onClick={() => setDeletingCorporation(false)}
                            block
                        >
                            {t('Cancel')}
                        </Button>
                    </Col>
                    <Col xs={24} md={12}>
                        <Button
                            block
                            style={{
                                backgroundColor: 'rgb(220, 38, 38)',
                                color: 'white',
                                outline: '0 !important',
                            }}
                            onClick={() => deleteCorporation()}
                        >
                            {t('Remove')}
                        </Button>
                    </Col>
                </Row>
            </Modal>
        );
    };

    return (
        <div className="w-full">
            <DeletingUserModal />
            {corporation && (
                <Row gutter={[20, 20]}>
                    <Col xs={24} md={12}>
                        <Card
                            title={
                                <Typography.Title
                                    level={mode === 'desktop' ? 4 : 5}
                                >
                                    {t('Updating {{name}}', {
                                        name: corporation.name,
                                    })}
                                </Typography.Title>
                            }
                            className="h-full"
                        >
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={onSubmit}
                            >
                                <Form.Item name="name" label={t('Name')}>
                                    <Input />
                                </Form.Item>

                                <Button
                                    block={mode === 'desktop' ? false : true}
                                    htmlType="submit"
                                    className="float-right mt-4"
                                    type="primary"
                                >
                                    {t('Save changes')}
                                </Button>
                            </Form>
                        </Card>
                    </Col>
                    {showDangerZone && (
                        <Col xs={24} md={12}>
                            <Card
                                className="h-full"
                                title={
                                    <Typography.Title level={4} type="danger">
                                        {t('Danger zone')}
                                    </Typography.Title>
                                }
                            >
                                <div>
                                    <Row align="middle" gutter={[10, 20]}>
                                        <Col xs={24} span={20}>
                                            <Typography.Text>
                                                <Typography.Text strong>
                                                    {t('Delete corporation')}
                                                </Typography.Text>
                                                <br />
                                                {t(
                                                    'Removes {{name}} from your account. This action is irreversible and can not be undone',
                                                    { name: corporation.name },
                                                )}
                                            </Typography.Text>
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <Button
                                                block={
                                                    mode === 'desktop'
                                                        ? false
                                                        : true
                                                }
                                                onClick={() =>
                                                    setDeletingCorporation(true)
                                                }
                                                type="ghost"
                                                style={{
                                                    backgroundColor:
                                                        'rgb(220, 38, 38)',
                                                    color: 'white',
                                                }}
                                            >
                                                {t('Delete')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    )}
                </Row>
            )}
        </div>
    );
}

export default EditCorporationFeatures;
