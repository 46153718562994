// treeSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store/rootReducer';

export interface TreeDataNode {
    id: number;
    key: string;
    title: string;
    type: string;
    disabled: boolean;
    children?: TreeDataNode[];
}

interface TreeState {
    treeData: TreeDataNode[];
}

const initialState: TreeState = {
    treeData: [],
};

export const treeSlice = createSlice({
    name: 'tree',
    initialState,
    reducers: {
        setTreeData: (state, action: PayloadAction<TreeDataNode[]>) => {
            state.treeData = action.payload;
        },
    },
});

export const { setTreeData } = treeSlice.actions;

export const selectTreeData = (state: RootState) => state.tree.treeData;

export default treeSlice.reducer;
