import { Typography } from 'antd';
import React from 'react';
import './Legend.scss';

type DateEntry = {
    name: string;
    color: string;
    key: string;
    isDashed?: boolean;
};

interface LegendProps {
    data: DateEntry[];
    onClick: (name: string) => void;
    includes: string[];
}

function Legend(props: LegendProps) {
    const { data, onClick, includes } = props;

    return (
        <div className="flex justify-center gap-4   sm:flex-row flex-wrap">
            {data?.map((v) => {
                return (
                    <div
                        className={
                            'p-2' +
                            (includes.length === 0 ? '' : ' cursor-pointer')
                        }
                        key={v.key + v.name}
                    >
                        <div onClick={() => onClick(v.key)} className="flex ">
                            {v.isDashed ? (
                                <>
                                    <div
                                        className="rounded-dashed h-dashed w-dashed mr-dashed mt-auto mb-auto"
                                        style={{
                                            backgroundColor: v.color,
                                        }}
                                    />
                                    <div
                                        className="rounded-dashed h-dashed w-dashed mr-dashed mt-auto mb-auto"
                                        style={{
                                            backgroundColor: v.color,
                                        }}
                                    />
                                    <div
                                        className="rounded-dashed h-dashed w-dashed mr-2 mt-auto mb-auto"
                                        style={{
                                            backgroundColor: v.color,
                                        }}
                                    />
                                </>
                            ) : (
                                <div
                                    className="h-s-1 w-s-1 mr-2 mt-auto mb-auto"
                                    style={{
                                        backgroundColor: v.color,
                                    }}
                                />
                            )}
                            <Typography
                                style={{
                                    fontWeight: includes.includes(v.key)
                                        ? 'bold'
                                        : 'normal',
                                }}
                            >
                                {v.name}
                            </Typography>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default Legend;
