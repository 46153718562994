import { Typography, Table, Input, TableColumnType, Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDesiredTemperatures } from 'src/hooks/objects/useDesiredTemperatures';
import { useUpdateDesiredTemperature } from 'src/hooks/objects/useUpdateDesiredTemperatures';
import { useDebouncedCallback } from 'use-debounce';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    CartesianGrid,
} from 'recharts';
import useViewport from 'src/hooks/generic/useViewport';
import { useAverageTemperaturesWithControlState } from 'src/hooks/objects/useAverageTemperaturesWithControllState';
import Legend from '../objects/Legend';
import Container from 'src/components/Container';
import { ConditionalGraphTick, tickGenerator } from './FlowTemperatureView';
import validateFloatNumber from 'src/utils/validateFloatNumber';

interface Props {
    objectId: number;
    editable: boolean;
    stateId: number;
    revalidate: () => void;
}

export function EditDesiredTemperatures(props: Props) {
    const { objectId, stateId } = props;
    const { t } = useTranslation();
    const { data: desiredTemperatures } = useDesiredTemperatures(
        objectId,
        stateId,
    );
    const [updateDesiredTemperatures] = useUpdateDesiredTemperature();
    let { data: averageTemperatures } = useAverageTemperaturesWithControlState(
        objectId,
        stateId,
    );
    const { data: prevTemperatures } = useAverageTemperaturesWithControlState(
        objectId,
        stateId - 1,
    );
    const { mode } = useViewport();
    const currentDesired = desiredTemperatures?.data;
    const dataArr: any = currentDesired
        ?.map((v) => {
            return {
                fromTemperature: v.fromTemperature,
                currentDesired: v.toTemperature,
            };
        })
        ?.sort((a, b) => a.fromTemperature - b.fromTemperature);

    if (
        dataArr !== undefined &&
        averageTemperatures !== undefined &&
        prevTemperatures !== undefined
    ) {
        averageTemperatures.data.forEach((avgtemp) => {
            const temp = dataArr.find(
                (e: any) => e.fromTemperature === avgtemp.fromTemperature,
            );
            if (temp === undefined) {
                dataArr.push({
                    fromTemperature: avgtemp.fromTemperature,
                    averageTemp: avgtemp.toTemperature,
                });
            } else {
                temp.averageTemp = avgtemp.toTemperature;
            }
        });

        prevTemperatures.data.forEach((prevtemp) => {
            const temp = dataArr.find(
                (e: any) => e.fromTemperature === prevtemp.fromTemperature,
            );
            if (temp === undefined) {
                dataArr.push({
                    fromTemperature: prevtemp.fromTemperature,
                    prevTemp: prevtemp.toTemperature,
                });
            } else {
                temp.prevTemp = prevtemp.toTemperature;
            }
        });
    }

    const [currentValues, setCurrentValues] = useState<
        Record<number, number>
    >();

    useEffect(() => {
        if (!currentValues && currentDesired) {
            const r: Record<number, number> = {};
            currentDesired.forEach((v) => {
                r[v.fromTemperature] = v.toTemperature;
            });

            setCurrentValues(r);
        }
    }, [currentValues, currentDesired, desiredTemperatures]);

    const updateInAPI = useDebouncedCallback(async () => {
        if (currentValues) {
            updateDesiredTemperatures(objectId, currentValues, stateId).then(
                () => {
                    props.revalidate();
                },
            );
        }
    }, 1000);

    const onChange = (fromTmp: number, toTmp: number) => {
        setCurrentValues({ ...currentValues, [fromTmp]: toTmp });
        updateInAPI();
    };

    const columns: TableColumnType<any>[] = [
        {
            title: t('Outdoor'),
            dataIndex: 'fromTemperature',
            width: '10ch',
            render: (_: any, record: any, i: any) => {
                return (
                    <div className="flex justify-between" key={stateId}>
                        <Typography.Text>
                            {record.fromTemperature.toString()}
                        </Typography.Text>
                        <Typography.Text>{'°C'}</Typography.Text>
                    </div>
                );
            },
        },
        {
            title: t('Indoor'),
            dataIndex: 'currentDesired',
            width: '10ch',
            render: (_: any, record: any, i: any) => {
                return (
                    <Form.Item
                        style={{ margin: '0em' }}
                        rules={[
                            {
                                validator(_, value: string) {
                                    return validateFloatNumber(value)
                                        .then((number) => {
                                            if (number === null) {
                                                return Promise.reject(
                                                    t(
                                                        'Please enter a valid number!',
                                                    ),
                                                );
                                            }
                                        })
                                        .catch(() => {
                                            return Promise.reject(
                                                t(
                                                    'Please enter a valid number!',
                                                ),
                                            );
                                        });
                                },
                            },
                        ]}
                    >
                        <Input
                            key={stateId!}
                            disabled={!props.editable}
                            suffix={'°C'}
                            bordered={false}
                            onBlur={(e) => {
                                const value = Number.parseFloat(
                                    e.target.value.replace(',', '.'),
                                );
                                record.currentDesired = value;
                                onChange(record.fromTemperature, value);
                            }}
                            defaultValue={
                                record?.currentDesired?.toFixed(1) ?? undefined
                            }
                        />
                    </Form.Item>
                );
            },
        },
    ];

    return (
        <div>
            <Container cardTitle={t('Desired temperature')}>
                <div
                    className="grid gap-x-8"
                    style={{
                        gridTemplateColumns:
                            mode === 'desktop' ? '1fr 52ch' : '1fr',
                    }}
                >
                    <div>
                        {dataArr !== undefined &&
                            averageTemperatures !== undefined &&
                            prevTemperatures !== undefined && (
                                <ResponsiveContainer width="100%" height={500}>
                                    <LineChart
                                        data={dataArr}
                                        margin={{
                                            top: mode === 'desktop' ? 10 : 0,
                                            right: mode === 'desktop' ? 20 : 20,
                                            left: mode === 'desktop' ? 20 : 0,
                                            bottom: mode === 'desktop' ? 5 : 10,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            name={t('Degrees')}
                                            dataKey="fromTemperature"
                                            domain={['dataMin', 'dataMax']}
                                            interval={0}
                                            type="number"
                                            tick={
                                                <ConditionalGraphTick
                                                    screen={mode}
                                                />
                                            }
                                            tickMargin={
                                                mode === 'desktop' ? 0 : 8
                                            }
                                            ticks={
                                                mode === 'desktop'
                                                    ? tickGenerator(dataArr, 1)
                                                    : tickGenerator(dataArr, 5)
                                            }
                                        />
                                        <YAxis
                                            allowDecimals={false}
                                            type="number"
                                            domain={['auto', 'auto']}
                                            unit="°C"
                                            tickCount={5}
                                        />

                                        <Tooltip
                                            formatter={(v: number) =>
                                                Math.round(v * 10) / 10
                                            }
                                            labelFormatter={(v) =>
                                                t('At outdoor temperature') +
                                                ' ' +
                                                v +
                                                '°C'
                                            }
                                        />
                                        <Line
                                            strokeWidth={
                                                mode === 'desktop' ? 5 : 1.5
                                            }
                                            name={t('Desired temperature')}
                                            unit="°C"
                                            type="linear"
                                            dataKey="currentDesired"
                                            stroke="#82ca9d"
                                            activeDot={{ r: 8 }}
                                            connectNulls={true}
                                        />
                                        {averageTemperatures?.data[0] && (
                                            <Line
                                                name={t('Average temperature')}
                                                unit="°C"
                                                type="linear"
                                                strokeWidth={
                                                    mode === 'desktop' ? 5 : 1.5
                                                }
                                                dataKey="averageTemp"
                                                stroke="#8884d8"
                                                activeDot={{ r: 8 }}
                                            />
                                        )}
                                        {prevTemperatures?.data[0] && (
                                            <Line
                                                name={t('Previous temperature')}
                                                unit="°C"
                                                strokeDasharray="5 5"
                                                type="linear"
                                                strokeWidth={
                                                    mode === 'desktop' ? 5 : 1.5
                                                }
                                                dataKey="prevTemp"
                                                stroke="#8884d8"
                                                activeDot={{ r: 8 }}
                                            />
                                        )}
                                    </LineChart>
                                </ResponsiveContainer>
                            )}
                        <Legend
                            includes={[]}
                            data={[
                                {
                                    name: t('Previous temperature'),
                                    color: '#8884d8',
                                    key: '',
                                },
                                {
                                    name: t('Average temperature'),
                                    color: '#8884d8',
                                    key: '',
                                },
                                {
                                    name: t('Desired temperature'),
                                    color: '#82ca9d',
                                    key: '',
                                },
                            ]}
                            onClick={() => {}}
                        />
                    </div>
                    <div
                        key={stateId}
                        className="mb-16"
                        style={{ flexBasis: '52ch', flexGrow: 1 }}
                    >
                        <Table
                            dataSource={dataArr?.filter(
                                (e: any) => e.currentDesired !== undefined,
                            )}
                            columns={columns}
                            pagination={false}
                            direction="ltr"
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
}
