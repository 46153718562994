import style from './Layout.module.scss';
import { ReactNode } from 'react';

interface IProps {
    sidebar: ReactNode;
    header: ReactNode;
    children: ReactNode;
    topbar: ReactNode;
}

export default function Layout(props: IProps) {
    const { topbar, sidebar, header, children } = props;

    return (
        <div className="overflow-hidden">
            <div className="shadow-sm" id={style.topbar}>
                {topbar}
            </div>
            <div id={style.container}>
                <div id={style['sidebar']}>{sidebar}</div>
                <div id={style['content-container']}>
                    <div id={style.header}>{header}</div>
                    <div id={style.content}>{children}</div>
                </div>
            </div>
        </div>
    );
}
