import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';

export interface IntegrationTag {
    name: string;
    extra: string;
    apiIdentifier?: string;
    kind?: string;
    id: number;
    key: string | number;
    children: IntegrationTag[];
}

export const useListAllTagsOnIntegration = (
    objectId: number,
    integrationId: number,
) => {
    return useSWR<IntegrationTag>(
        `/objects/${objectId}/integrations/${integrationId}/list`,
        swrFetch,
    );
};
