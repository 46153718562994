import { API } from 'src/api/API';
import { SiteObject } from 'src/models/SiteObject';
import { mutate } from 'swr';

export type CreateObjectDTO = {
    name: string;
    longitude?: number;
    latitude?: number;
    aTemp?: number;
    buildYear: number;
    floorsAboveGround?: number;
    floorsBelowGround?: number;
    roofHeight?: number;
    outsideWalls?: number;
    shapeOfHouse?: number;
    co2EmissionFactorHeating?: number;
    co2EmissionFactorElectricity?: number;
};

export const useAddObject = () => {
    const add = async (
        siteId: number,
        object: CreateObjectDTO,
    ): Promise<SiteObject> => {
        return new API().addObject(siteId, object).then(async (v) => {
            await mutate(`/sites`);
            await mutate(`/sites/${siteId}/objects`);
            return v;
        });
    };
    return [add] as const;
};
