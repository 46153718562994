import { API } from 'src/api/API';
import { mutate } from 'swr';

export const useDeleteSensor = () => {
    const _delete = async (objectId: number, sensorId: number) => {
        return new API().deleteSensor(objectId, sensorId).then(() => {
            mutate(`objects/${objectId}/devices`);
            mutate(`/objects/${objectId}/sensors`);
            mutate(`objects/${objectId}/average-temperatures`);
        });
    };

    return [_delete] as const;
};
