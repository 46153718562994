import { useNavigate, useParams } from 'react-router';
import EditUserFeatures from 'src/views/users/EditUserFeatures';
import { useUser } from 'src/hooks/users/useUser';
import Nav from 'src/utils/nav/Nav';
import { UserRoles } from 'src/models/User';

function EditUser() {
    const { userId } = useParams<{ userId: string }>();
    const { data: user, isValidating: loading } = useUser(
        Number.parseInt(userId!),
    );
    const history = useNavigate();

    return (
        <div className="block w-full">
            <EditUserFeatures
                onDeleteUser={() => {
                    history(
                        Nav.build({
                            path: '/settings',
                            queryParams: { tab: 'users' },
                        }),
                    );
                }}
                showDangerZone={user?.role !== UserRoles.ADMIN}
                isLoading={loading}
                user={user}
            />
        </div>
    );
}

export default EditUser;
