import { User, UserRoles } from 'src/models/User';
type MockCallback<T> = (input: RequestInfo, init?: RequestInit) => T;
class Mock {
    map: Record<string, MockCallback<any>> = {};
    delay = 1500;

    private simulateTime(
        cb: (...input: any[]) => any,
        ms: number,
    ): Promise<any> {
        return new Promise((res, rej) => {
            setTimeout(() => {
                try {
                    res(cb());
                } catch (err) {
                    rej(err);
                }
            }, ms);
        });
    }

    public registerMock<T>(path: string, cb: MockCallback<T>) {
        this.map[path] = cb;
    }

    public mock(input: RequestInfo, init?: RequestInit) {
        if (typeof input !== 'string')
            throw new Error('Something wrong with mock value');
        if (!this.map[input])
            throw new Error(
                'You are trying to use mock for path ${}. This has no callback',
            );

        return this.simulateTime(() => {
            return this.map[input](input, init);
        }, this.delay);
    }
}

const mock = new Mock();

mock.registerMock<User>('/user', () => {
    return {
        email: 'test@test.com',
        id: 1,
        name: 'Test McTest',
        role: UserRoles.ADMIN,
    };
});

mock.registerMock<User>('/get-me', () => {
    return {
        email: 'current@test.se',
        id: 0,
        name: 'Kurre',
        ts: Date.now(),
    } as any;
});

export default mock;
