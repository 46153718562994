import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useIsSignedIn } from 'src/hooks/users/useIsSignedIn';
import AcceptView from 'src/views/users/AcceptInviteView';
import { useQueryParams, StringParam } from 'use-query-params';
import { useEffect } from 'react';
import Nav from 'src/utils/nav/Nav';

function AcceptInvitation() {
    const { data, isValidating, mutate } = useIsSignedIn();
    const { t } = useTranslation();
    const history = useNavigate();

    const [params] = useQueryParams({
        code: StringParam,
        email: StringParam,
    });

    useEffect(() => {
        if (!isValidating && data) {
            history(Nav.build({ path: '/home' }));
        }
    }, [isValidating, data, history]);

    return (
        <div>
            <div>
                <div className="w-screen h-screen  md:h-auto sm:w-96 m-auto">
                    {params.code && params.email && (
                        <AcceptView
                            email={params.email}
                            code={params.code}
                            onFail={(msg) => {
                                notification.open({
                                    message: t('Unable to sign in'),
                                    type: 'error',
                                    description: t(msg),
                                    placement: 'bottomRight',
                                });
                            }}
                            onSuccess={() => mutate()}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default AcceptInvitation;
