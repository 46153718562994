import { Col, Divider, Row, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';
import { useHasMetryIntegration } from 'src/hooks/integrations/useHasMetryIntegration';
import { useMeterMeterId } from 'src/hooks/integrations/useMetryMeterId';
import { useMetryMeters } from 'src/hooks/integrations/useMetryMeters';
import { useSetMeterMeterId } from 'src/hooks/integrations/useSetMetryMeterId';
import { useMe } from 'src/hooks/users/useMe';
import Nav from 'src/utils/nav/Nav';
import { SingleIntegrator } from '../IntegratorsView';
import metryLogoSource from './icon.png';

interface IdSelectorProps {
    value?: string;
    options: { id: string; name: string }[];
    onChange: (value: string) => void;
    loading?: boolean;
}

function IdSelector(props: IdSelectorProps) {
    const { value, onChange, options, loading } = props;

    const { t } = useTranslation();

    return (
        <Select
            loading={loading}
            onChange={onChange}
            defaultValue={value}
            placeholder={t('No metry integration')}
            allowClear
            className="w-full"
        >
            {options?.map((opt) => (
                <Select.Option key={opt.id} value={opt.id}>
                    {opt.name}
                </Select.Option>
            ))}
        </Select>
    );
}

interface IProps {
    objectId: number;
}

function ConfigPage(props: IProps) {
    const { objectId } = props;
    const { data: options, isValidating: optionsLoading } = useMetryMeters();
    const { data: hasMetry } = useHasMetryIntegration();
    const { t } = useTranslation();
    const { data: meters, isValidating: metersLoading } = useMeterMeterId(
        objectId,
    );
    const [set] = useSetMeterMeterId();
    const isLoading = optionsLoading || metersLoading;

    return (
        <>
            <Row gutter={[0, 10]}>
                <Col span={24}>
                    <div className="flex">
                        <img
                            className="my-auto w-20 float-left"
                            src={metryLogoSource}
                            alt="Metry logo"
                        />
                        <Typography.Title className="my-auto">
                            {t('Metry integration')}
                        </Typography.Title>
                    </div>
                    <Divider />
                </Col>
                {hasMetry?.isHidden && (
                    <Col span={24}>
                        <Typography.Text>
                            {t(
                                'Metry integration is exists and is manged by Nrlyze. ' +
                                    'We will update and make sure that object is correctly ' +
                                    'configured. If you have any questions do not hesitate to contact ' +
                                    'us at info@nrlyze.se',
                            )}
                        </Typography.Text>
                    </Col>
                )}
                {!hasMetry?.isHidden && (
                    <>
                        <Col md={12} sm={24}>
                            <Typography>{t('Heating metry sensor')}</Typography>

                            <IdSelector
                                loading={isLoading}
                                options={options || []}
                                value={
                                    meters?.find((v) => v.type === 'HEATING')
                                        ?.meterId
                                }
                                onChange={(value) => {
                                    if (!value)
                                        return set(
                                            objectId,
                                            null,
                                            null,
                                            'ELECTRIC',
                                        );

                                    const option = options?.find(
                                        (i) => i.id === value,
                                    );

                                    if (option) {
                                        set(
                                            objectId,
                                            value,
                                            option.metryIntegratorId,
                                            'HEATING',
                                        );
                                    }
                                }}
                            />
                        </Col>
                        <Col span={24} />
                        <Col md={12} sm={24}>
                            <Typography>
                                {t('Electric metry sensor')}
                            </Typography>

                            <IdSelector
                                loading={isLoading}
                                options={options || []}
                                value={
                                    meters?.find((v) => v.type === 'ELECTRIC')
                                        ?.meterId
                                }
                                onChange={(value) => {
                                    if (!value)
                                        return set(
                                            objectId,
                                            null,
                                            null,
                                            'ELECTRIC',
                                        );
                                    const option = options?.find(
                                        (i) => i.id === value,
                                    );
                                    if (option) {
                                        set(
                                            objectId,
                                            value,
                                            option!.metryIntegratorId,
                                            'ELECTRIC',
                                        );
                                    }
                                }}
                            />
                        </Col>
                    </>
                )}
            </Row>
        </>
    );
}

function MetryPanel(props: IProps) {
    const { objectId } = props;
    const { data: hasMetry } = useHasMetryIntegration();

    const { data: me } = useMe();
    const { t } = useTranslation();

    const corporationId = me?.corporations[0].id;
    return (
        <>
            <SingleIntegrator
                loading={false}
                readMore={
                    <Navigate
                        to={Nav.build({
                            path: '/corporations/:corporationId',
                            routeParams: { corporationId: corporationId! },
                            queryParams: {
                                tab: 'integrators',
                                'metry-readmore': '1',
                            },
                        })}
                    />
                }
                hasIntegration={hasMetry?.exists}
                data={{
                    id: 'metry',
                    name: t('Metry'),
                    description: t(
                        'Add metry energy consumption on your object',
                    ),
                    icon: metryLogoSource,
                }}
                configPage={<ConfigPage objectId={objectId} />}
            />
        </>
    );
}

export default MetryPanel;
