import { useNavigate, useParams } from 'react-router-dom';
import { useObject } from 'src/hooks/objects/useObject';
import Nav from 'src/utils/nav/Nav';
import AddSensorView from '../views/sites/AddSensorView';

function AddSensorPage() {
    const { objectId: objectIdString } = useParams<{
        objectId: string;
    }>();
    const objectId = Number.parseInt(objectIdString!);
    const history = useNavigate();
    const { data: object } = useObject(objectId);

    return (
        <>
            {object !== undefined && (
                <AddSensorView
                    onSuccess={() => {
                        history(
                            Nav.build({
                                path: '/manage-object',
                                queryParams: {
                                    index: '1',
                                    objectId: objectId.toString(),
                                    siteId: object.siteId.toString(),
                                    siteName: object.siteName,
                                },
                            }),
                        );
                    }}
                    object={object}
                />
            )}
        </>
    );
}

export default AddSensorPage;
