import {
    Form,
    Input,
    Button,
    Row,
    Col,
    Divider,
    Typography,
    message,
    Card,
    DatePicker,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Map } from 'src/components/Map';
import { useEditObject } from 'src/hooks/objects/useEditObject';
import { useAddObject } from 'src/hooks/sites/useAddObject';
import { SiteObject } from 'src/models/SiteObject';
import { showError } from 'src/utils/showError';
import LocationSearch from './LocationSearch';
import { HouseSelector } from 'src/pages/IFramePage';
import { usePlaceId } from 'src/hooks/objects/usePlaceId';
import validateFloatNumber from 'src/utils/validateFloatNumber';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Nav from 'src/utils/nav/Nav';

interface IProps {
    onAdd: (object: number) => void;
    object: SiteObject | undefined;
    siteId: number;
}

function MangeObjectView(props: IProps) {
    const { onAdd, object, siteId } = props;
    const { t } = useTranslation();
    const [location, setLocation] = useState<{
        longitude: number | undefined | null;
        latitude: number | undefined | null;
        placeId: string | undefined | null;
    }>();
    const [add] = useAddObject();
    const [shapeOfHouse, setShapeOfHouse] = useState(
        object?.shapeOfHouse || '',
    );
    const [update] = useEditObject();
    const name = object?.name;
    const { data: adress, isDataLoaded } = usePlaceId(
        object?.longitude ?? null,
        object?.latitude ?? null,
        object?.placeId ?? null,
    );

    const history = useNavigate();

    const [placeId, setPlaceId] = useState('');

    const addObject = (form: any) => {
        const year = form.buildYear?.year() || null;
        form = { ...form, buildYear: year };
        const updatedForm = {
            ...form,
            ...location,
            aTemp: Number.parseInt(form.aTemp),
            roofHeight: Number.parseFloat(
                form?.roofHeight?.replace
                    ? form?.roofHeight?.replace(',', '.')
                    : form?.roofHeight,
            ),
            floorsAboveGround: Number.parseInt(form?.floorsAboveGround),
            floorsBelowGround: Number.parseInt(form?.floorsBelowGround),
            numberOfOutsideWalls: Number.parseInt(form?.numberOfOutsideWalls),
        };
        add(siteId, updatedForm)
            .then((object) => {
                message.success(t('Succesfully created new object!'));
                onAdd(siteId);
            })
            .catch((er) => {
                showError(t('Not able to add new object'), er.message);
            });
    };

    const updateObject = (objectId: number, form: any) => {
        const year = form?.buildYear?.year() || null;
        form = {
            ...form,
            ...location,
            buildYear: year,
            aTemp: Number.parseInt(form.aTemp),
            roofHeight: form.roofHeight
                ? Number.parseFloat(
                      form.roofHeight.replace
                          ? form.roofHeight.replace(',', '.')
                          : form.roofHeight,
                  )
                : null,
            floorsAboveGround: Number.parseInt(form.floorsAboveGround),
            floorsBelowGround: Number.parseInt(form.floorsBelowGround),
            numberOfOutsideWalls: Number.parseInt(form.numberOfOutsideWalls),
        };

        form.placeId = placeId || object?.placeId;

        update(objectId, {
            ...form,
            shapeOfHouse: shapeOfHouse,
        })
            .then((object) => {
                message.success(t('Succesfully edited object!'));
                history(
                    Nav.build({
                        path: '/analytics/:objectId',
                        queryParams: {},
                        routeParams: { objectId: object.id },
                    }),
                );
                // onSuccess(object);
            })
            .catch((er) => {
                showError(t('Not able to add new object'), er.message);
            });
    };
    return (
        <>
            <Row
                justify="center"
                gutter={[20, 20]}
                style={{ marginBottom: '2em' }}
            >
                <Col md={12} xs={24}>
                    <Card
                        className="h-full"
                        title={
                            <Typography.Title level={4}>
                                {object === undefined
                                    ? t('Create object')
                                    : t('Edit object {{name}}', {
                                          name: object.name,
                                      })}
                            </Typography.Title>
                        }
                    >
                        {isDataLoaded && (
                            <Form
                                initialValues={{
                                    name: object?.name || null,
                                    placeId: adress ?? '',
                                    aTemp: object?.aTemp || null,
                                    roofHeight:
                                        object?.roofHeight
                                            ?.toString()
                                            .replace('.', ',') || null,
                                    floorsAboveGround:
                                        object?.floorsAboveGround || null,
                                    floorsBelowGround:
                                        object?.floorsBelowGround || null,
                                    numberOfOutsideWalls:
                                        object?.numberOfOutsideWalls || null,
                                }}
                                layout="vertical"
                                onFinish={(form) => {
                                    object === undefined
                                        ? addObject(form)
                                        : updateObject(object!.id, form);
                                }}
                            >
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: t('Name is required'),
                                        },
                                    ]}
                                    label={t('Object name')}
                                    name="name"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    rules={[
                                        {
                                            validator() {
                                                if (
                                                    placeId ||
                                                    object?.placeId
                                                ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    t('Address is required'),
                                                );
                                            },
                                            required: true,
                                        },
                                    ]}
                                    hasFeedback
                                    label={t("Object's address")}
                                    name="placeId"
                                >
                                    <LocationSearch
                                        value={adress ?? ''}
                                        placeId={object?.placeId ?? ''}
                                        onSelect={(v) => {
                                            setPlaceId(v.place_id);
                                            setLocation({
                                                latitude:
                                                    v.geometry.location.lat,
                                                longitude:
                                                    v.geometry.location.lng,
                                                placeId: v.place_id,
                                            });
                                        }}
                                    />
                                </Form.Item>

                                <Divider>
                                    <Typography.Text>
                                        {t('Extra information')}
                                    </Typography.Text>
                                </Divider>
                                <Row gutter={40}>
                                    <Col md={12} xs={24}>
                                        <Form.Item
                                            rules={[
                                                {
                                                    validator(_, value) {
                                                        if (
                                                            value === null ||
                                                            value === '' ||
                                                            (!isNaN(value) &&
                                                                value < 100000)
                                                        ) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(
                                                            t(
                                                                'Please enter a number smaller than 100 000!',
                                                            ),
                                                        );
                                                    },
                                                    required: false,
                                                },
                                            ]}
                                            name="aTemp"
                                            label={t('Size of object (ATemp)')}
                                        >
                                            <Input
                                                style={{ width: '100%' }}
                                                className="w-full"
                                                suffix="m²"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="buildYear"
                                            label={t('Build year of object')}
                                            rules={[{ required: false }]}
                                        >
                                            <DatePicker
                                                placeholder={t('Select year')}
                                                onChange={(value) =>
                                                    value?.year
                                                }
                                                defaultValue={
                                                    object?.buildYear
                                                        ? moment(
                                                              object.buildYear,
                                                              'YYYY',
                                                          )
                                                        : undefined
                                                }
                                                style={{ width: '100%' }}
                                                picker="year"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            rules={[
                                                {
                                                    validator(
                                                        _,
                                                        value: string,
                                                    ) {
                                                        return validateFloatNumber(
                                                            value,
                                                        )
                                                            .then((number) => {
                                                                if (
                                                                    number ===
                                                                        null ||
                                                                    (number >=
                                                                        2 &&
                                                                        number <=
                                                                            5)
                                                                ) {
                                                                    return Promise.resolve(
                                                                        number,
                                                                    );
                                                                }
                                                            })
                                                            .catch(() => {
                                                                return Promise.reject(
                                                                    t(
                                                                        'Please enter a number between 2 and 5!',
                                                                    ),
                                                                );
                                                            });
                                                    },
                                                    required: false,
                                                },
                                            ]}
                                            name="roofHeight"
                                            label={t('Ceiling height')}
                                        >
                                            <Input
                                                style={{ width: '100%' }}
                                                className="w-full"
                                                suffix="m"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            rules={[
                                                {
                                                    validator(_, value) {
                                                        if (
                                                            value === null ||
                                                            value === '' ||
                                                            (!isNaN(value) &&
                                                                value <= 2 &&
                                                                value >= 0)
                                                        ) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(
                                                            t(
                                                                'Please enter a numbe between 0 and 2!',
                                                            ),
                                                        );
                                                    },
                                                    required: false,
                                                },
                                            ]}
                                            name="floorsBelowGround"
                                            label={t('Floors below ground')}
                                        >
                                            <Input
                                                defaultValue={
                                                    object?.floorsBelowGround
                                                }
                                                style={{ width: '100%' }}
                                                suffix="st"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            rules={[
                                                {
                                                    validator(_, value) {
                                                        if (
                                                            value === null ||
                                                            value === '' ||
                                                            (!isNaN(value) &&
                                                                value <= 15 &&
                                                                value >= 1)
                                                        ) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(
                                                            t(
                                                                'Please enter a number greater between 1 and 15!',
                                                            ),
                                                        );
                                                    },
                                                    required: false,
                                                },
                                            ]}
                                            name="floorsAboveGround"
                                            label={t('Floors above ground')}
                                        >
                                            <Input
                                                defaultValue={
                                                    object?.floorsBelowGround
                                                }
                                                style={{ width: '100%' }}
                                                suffix="st"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            rules={[
                                                {
                                                    validator(_, value) {
                                                        if (
                                                            value === null ||
                                                            value === '' ||
                                                            (!isNaN(value) &&
                                                                value <= 8 &&
                                                                value >= 2)
                                                        ) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(
                                                            t(
                                                                'Please enter a number greater between 2 and 8!',
                                                            ),
                                                        );
                                                    },
                                                    required: false,
                                                },
                                            ]}
                                            name="numberOfOutsideWalls"
                                            label={t('Number of outside walls')}
                                        >
                                            <Input
                                                style={{ width: '100%' }}
                                                suffix="st"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="shapeOfHouse"
                                            label={t('Shape of the house')}
                                            rules={[{ required: false }]}
                                        >
                                            <HouseSelector
                                                shapeOfHouse={shapeOfHouse}
                                                onChange={(shape: string) =>
                                                    setShapeOfHouse(shape)
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Button
                                    className="w-full"
                                    size="large"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {name === undefined
                                        ? t('Add object')
                                        : t('Update object')}
                                </Button>
                            </Form>
                        )}
                    </Card>
                </Col>

                <Col md={12} xs={24}>
                    <Card
                        cover={
                            <Map
                                markCenter={
                                    location?.latitude !== undefined ||
                                    object?.latitude !== undefined
                                }
                                zoom={
                                    object?.latitude !== undefined ||
                                    location?.latitude !== undefined
                                        ? undefined
                                        : 5.2
                                }
                                center={{
                                    lat:
                                        location?.latitude ||
                                        object?.latitude ||
                                        60,
                                    lng:
                                        location?.longitude ||
                                        object?.longitude ||
                                        18.1499994,
                                }}
                            />
                        }
                        className="h-full"
                    />
                </Col>
            </Row>
        </>
    );
}

export default MangeObjectView;
