import { SensorWithTemperature } from 'src/models/Temperature';
import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';

export const useTemperatures = (objectId: number, stateId?: number) => {
    const urls = [
        `objects/${objectId}/control-states/${stateId}/temperatures`,
        stateId
            ? `objects/${objectId}/control-states/${stateId - 1}/temperatures`
            : null,
    ];

    return useSWR<SensorWithTemperature[][]>(
        urls,
        (args: any[]) => {
            return Promise.all([
                args[0] ? swrFetch(args[0]) : undefined,
                args[1] ? swrFetch(args[1]) : undefined,
            ]);
        },
        { suspense: false },
    );
};
