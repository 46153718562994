import { Typography } from 'antd';
import Card from 'antd/lib/card';
import { ReactNode, CSSProperties } from 'react';
import useViewport from 'src/hooks/generic/useViewport';

interface ContainerProps {
    cardTitle?: string;
    cardExtra?: ReactNode;
    className?: string;
    mobileClassName?: string;
    children: ReactNode;
    padding?: boolean;
    cover?: ReactNode;
    style?: CSSProperties;
    cardBodyStyle?: CSSProperties;
}

function Container(props: ContainerProps) {
    const { mode } = useViewport();
    const {
        cover,
        mobileClassName,
        className,
        cardTitle,
        cardExtra,
        children,
        style,
        cardBodyStyle,
    } = props;

    if (mode === 'mobile') {
        return (
            <div className={`px-4 ${mobileClassName}`} style={style}>
                {
                    <div className="flex flex-wrap justify-between items-center">
                        <Typography.Title level={5}>
                            {cardTitle}
                        </Typography.Title>
                        {cardExtra}
                    </div>
                }
                {cover}
                {children}
            </div>
        );
    } else {
        return (
            <Card
                cover={cover}
                title={cardTitle}
                className={className}
                extra={cardExtra}
                style={style}
                bodyStyle={cardBodyStyle}
            >
                {children}
            </Card>
        );
    }
}

export default Container;
