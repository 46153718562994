import { useEffect, useState } from 'react';
import { API } from 'src/api/API';
import { TemperatureWithControlState } from 'src/models/Temperature';

export const useAverageTemperaturesWithControlState = (
    id: number,
    controlState?: number,
    fetchPrevious?: boolean,
) => {
    const [cache, setCache] = useState<
        Record<number, TemperatureWithControlState>
    >();
    const [data, setData] = useState<TemperatureWithControlState>();
    const [previous, setPrevious] = useState<TemperatureWithControlState>();

    const [controlStateId, setControlStateId] = useState(controlState);

    useEffect(() => {
        if (controlState === undefined) {
            return;
        }

        if (controlState !== undefined && cache && controlState in cache) {
            setData(cache[controlState]);
        } else {
            (async () => {
                if (id && controlState !== undefined) {
                    const [currentData, prevData] = await Promise.all([
                        new API().fetchAverageTempertureWithControlState(
                            id,
                            controlState,
                        ),
                        controlState > 0
                            ? new API().fetchAverageTempertureWithControlState(
                                  id,
                                  controlState - 1,
                              )
                            : Promise.resolve(undefined),
                    ]);

                    const cached = { ...cache, [controlState]: currentData };
                    if (prevData) {
                        cached[controlState - 1] = prevData;
                    }

                    setCache(cached);
                    setData(currentData);
                    setPrevious(prevData);
                }
            })();
        }
    }, [cache, controlState, controlStateId, id]);

    return { data, previous, setAvgControlState: setControlStateId };
};
