import { ZoomOutOutlined } from '@ant-design/icons';
import { Button, Empty } from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ResponsiveContainer,
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Line,
    Tooltip,
    ReferenceArea,
} from 'recharts';
import { useContainerDimensions } from 'src/hooks/generic/useContainerDimension';
import useViewHeight from 'src/hooks/generic/useViewHeight';
import { RealTimeResponse } from 'src/hooks/objects/useRealTime';
import { Sensor } from 'src/models/Sensor';
import useViewport from 'src/hooks/generic/useViewport';
import {
    SensorWithTemperature,
    TemperatureMapping,
} from 'src/models/Temperature';
import {
    generateGradientKey,
    getGradientColorForTemperature,
    gradients,
} from 'src/utils/gradients';
import { RootState } from 'src/store/rootReducer';
import { useSelector } from 'react-redux';
// import { SensorWithAverage } from 'src/hooks/objects/useSensorAvergage';
import { minMaxArrValue } from 'src/utils/MinMax';
import Container from './Container';
import './RealTimeGraph.scss';

interface GraphProps {
    hotNCold: boolean;
    start?: string;
    stop?: string;
    data?: RealTimeResponse[];
    data1?: RealTimeResponse[];
    data2?: RealTimeResponse[];
    data3?: RealTimeResponse[];
    avgData?: any;
    avgObjData?: any;
    mirror?: boolean;
    include?: number[];
    newTimestamps?: number[];
    secondAxisColor?: string;
    firstAxisColor?: string;
    title: string;
    left: number | string;
    right: number | string;
    refLeft: number;
    refRight: number;
    sensorAverages: SensorWithTemperature[];
    prevSensorAverages?: SensorWithTemperature[];
    averageTemperatures: TemperatureMapping[];
    prevAverageTemperatures: TemperatureMapping[];
    referenceTemperature: number;
    zoom: (graphIndex: number) => void;
    onMouseDown: (e: any) => void;
    onMouseMove: (e: any) => void;
    onReset: () => void;
    isHotColdActive: boolean;
}

interface SensorDataEntry {
    timestamp: number;
    [sensorId: number]: number;
}

interface GroupedData {
    [sensorId: number]: SensorDataEntry[];
}

interface ClosestTimestamps {
    [sensorId: number]: number; // Numeric closest timestamp
}

interface CustomizedDotProps {
    cx?: number;
    cy?: number;
    stroke?: string;
    payload?: any; // Replace 'any' with the appropriate type for the payload data
    value?: number;
    index?: number;
}

interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
    label?: number;
}

export function RealTimeGraph(props: GraphProps) {
    const myRef = useRef(null);
    const { screen } = useViewHeight();
    const { mode } = useViewport();

    const [hoverTimer, setHoverTimer] = useState<NodeJS.Timeout | null>(null);
    const [indexes, setIndexes] = useState<{ [x: string]: number } | null>(
        null,
    );

    const { width: elementWidth } = useContainerDimensions(myRef);
    const {
        left,
        right,
        start,
        stop,
        refRight,
        refLeft,
        data,
        data1,
        data3,
        secondAxisColor,
        firstAxisColor,
        title,
        mirror,
        zoom,
        onMouseMove,
        onMouseDown,
        onReset,
        include,
        sensorAverages,
        prevSensorAverages,
        averageTemperatures,
        prevAverageTemperatures,
        referenceTemperature,
        newTimestamps,
        avgData,
        avgObjData,
    } = props;

    const sensorIdsToAverages: Record<number, TemperatureMapping[]> = {};
    const averages =
        sensorAverages.flatMap((sa) => sa.data).length > 0
            ? sensorAverages
            : prevSensorAverages ?? [];

    for (const avg of averages) {
        Object.assign(sensorIdsToAverages, { [avg.id]: avg.data });
    }

    const { t } = useTranslation();

    const { selectedFloorSensors } = useSelector(
        (state: RootState) => state.selectedObject,
    );

    const groupDataBySensorId = (data: SensorDataEntry[]): GroupedData => {
        const groupedData: GroupedData = {};

        data.forEach((entry) => {
            const { timestamp, ...rest } = entry;
            Object.entries(rest).forEach(([sensorId, value]) => {
                const parsedSensorId = parseInt(sensorId, 10);
                if (!groupedData[parsedSensorId]) {
                    groupedData[parsedSensorId] = [
                        { timestamp, [parsedSensorId]: value },
                    ];
                } else {
                    groupedData[parsedSensorId].push({
                        timestamp,
                        [parsedSensorId]: value,
                    });
                }
            });
        });

        return groupedData;
    };
    const getData = () => {
        const arr: { [x: number]: number; timestamp: number }[] = [];

        const exists = (timestamp: number) => {
            return (
                arr.findIndex((v) => {
                    return v.timestamp === timestamp;
                }) !== -1
            );
        };

        const sensorMap: { [x: number]: Sensor } = {};

        data?.forEach((sensor) => {
            sensorMap[sensor.id] = sensor;
            sensor.data.forEach((v, i) => {
                const amountOfEntries = 100;
                const mod = Math.ceil(sensor.data.length / amountOfEntries);

                if (i % mod === 0)
                    if (exists(v.timestamp)) {
                        arr.find((q) => q.timestamp === v.timestamp)![
                            sensor.id
                        ] = v.value;
                    } else {
                        arr.push({
                            timestamp: v.timestamp,
                            [sensor.id]: Math.round(v.value * 10) / 10,
                        });
                    }
            });
        });

        data1?.forEach((sensor) => {
            sensorMap[sensor.id] = sensor;
            sensor.data.forEach((v, i) => {
                const amountOfEntries = 100;
                const mod = Math.ceil(sensor.data.length / amountOfEntries);

                if (i % mod === 0)
                    if (exists(v.timestamp)) {
                        arr.find((q) => q.timestamp === v.timestamp)![
                            sensor.id
                        ] = v.value;
                    } else {
                        arr.push({
                            timestamp: v.timestamp,
                            [sensor.id]: Math.round(v.value * 10) / 10,
                        });
                    }
            });
        });

        // data2?.forEach((sensor) => {
        //     sensorMap[sensor.id] = sensor;
        //     sensor.data.forEach((v, i) => {
        //         const amountOfEntries = 100;
        //         const mod = Math.ceil(sensor.data.length / amountOfEntries);

        //         if (i % mod === 0)
        //             if (exists(v.timestamp)) {
        //                 arr.find((q) => q.timestamp === v.timestamp)![
        //                     sensor.id
        //                 ] = v.value;
        //             } else {
        //                 arr.push({
        //                     timestamp: v.timestamp,
        //                     [sensor.id]: Math.round(v.value * 10) / 10,
        //                 });
        //             }
        //     });
        // });
        data3?.forEach((sensor) => {
            sensorMap[sensor.id] = sensor;
            sensor.data.forEach((v, i) => {
                const amountOfEntries = 100;
                const mod = Math.ceil(sensor.data.length / amountOfEntries);
                if (i % mod === 0)
                    if (exists(v.timestamp)) {
                        arr.find((q) => q.timestamp === v.timestamp)![
                            sensor.id
                        ] = v.value;
                    } else {
                        arr.push({
                            timestamp: v.timestamp,
                            [sensor.id]: Math.round(v.value * 10) / 10,
                        });
                    }
            });
        });

        if (selectedFloorSensors && selectedFloorSensors.length) {
            avgData?.forEach((sensor: any) => {
                sensorMap[sensor.id] = sensor;
                sensor?.data?.forEach((v: any, i: any) => {
                    const amountOfEntries = 100;
                    const mod = Math.ceil(sensor.data.length / amountOfEntries);
                    if (i % mod === 0)
                        if (exists(v.timestamp)) {
                            arr.find((q) => q.timestamp === v.timestamp)![
                                sensor.id
                            ] = v.value;
                        } else {
                            arr.push({
                                timestamp: v.timestamp,
                                [sensor.id]: Math.round(v.value * 10) / 10,
                            });
                        }
                });
            });
        }

        avgObjData?.forEach((sensor: any) => {
            sensorMap[sensor.id] = sensor;
            sensor?.data?.forEach((v: any, i: any) => {
                const amountOfEntries = 100;
                const mod = Math.ceil(sensor.data.length / amountOfEntries);
                if (i % mod === 0)
                    if (exists(v.timestamp)) {
                        arr.find((q) => q.timestamp === v.timestamp)![
                            sensor.id
                        ] = v.value;
                    } else {
                        arr.push({
                            timestamp: v.timestamp,
                            [sensor.id]: Math.round(v.value * 10) / 10,
                        });
                    }
            });
        });

        let parsedData = arr.sort((c, p) => c.timestamp - p.timestamp);
        const groupedData = groupDataBySensorId(parsedData);

        const startDate: Date = new Date(start!);
        const endDate: Date = new Date(stop!);
        endDate.setDate(endDate.getDate() + 1);
        endDate.setHours(0, 0, 0, 0);

        startDate.setHours(0, 0, 0, 0);
        const daysDifference = Math.round(
            (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
        );

        if (parsedData && parsedData.length && daysDifference >= 1) {
            parsedData = parsedData.filter(
                (item: any) =>
                    item.timestamp <= newTimestamps![newTimestamps!.length - 1],
            );

            // we dont need to add pseudo entry points
            // newTimestamps!.forEach((chosenTimestamp: any) => {
            //     Object.keys(groupedData).forEach((sensorId) => {
            //         const dataEntries = groupedData[parseInt(sensorId, 10)];
            //         let closestTimestamp = dataEntries[0].timestamp; // Initialize with the first timestamp
            //         let minTimeDiff = Math.abs(
            //             chosenTimestamp - closestTimestamp,
            //         );

            //         let lastentry: any = null;

            //         dataEntries.forEach((entry) => {
            //             const timeDiff = Math.abs(
            //                 chosenTimestamp - entry.timestamp,
            //             );
            //             if (timeDiff < minTimeDiff) {
            //                 minTimeDiff = timeDiff;
            //                 closestTimestamp = entry.timestamp;
            //                 lastentry = entry;
            //             }
            //         });

            //         const newEntry = {
            //             ...lastentry,
            //             timestamp: chosenTimestamp,
            //         };

            //         parsedData.push(newEntry);
            //     });
            // });
            parsedData = parsedData.sort((c, p) => c.timestamp - p.timestamp);
        }

        return {
            parsedData,
            timestamps:
                daysDifference === 1
                    ? newTimestamps?.map((timestamp: any) => {
                          return { timestamp };
                      })
                    : null,
            groupedData,
            sensorMap,
            daysDifference: daysDifference || 5,
        };
    };

    const countTicks = (daysDiff: number) => {
        if (daysDiff === undefined) {
            return 5;
        } else if (daysDiff === 1) {
            return 11;
        } else if (daysDiff <= 30) {
            return 8;
        } else if (daysDiff >= 30 && daysDiff < 365) {
            return 7;
        } else if (daysDiff >= 365) {
            return 13;
        } else {
            return 5;
        }
    };

    const findClosestTimestamps = (
        groupedData: GroupedData,
        chosenTimestamp: number,
        sensId: any,
    ): ClosestTimestamps => {
        const closestTimestamps: ClosestTimestamps = {};
        Object.keys(groupedData)
            .filter((key) => key !== sensId.toString())
            .forEach((sensorId) => {
                const dataEntries = groupedData[parseInt(sensorId, 10)];
                let closestTimestamp = dataEntries[0].timestamp; // Initialize with the first timestamp
                let minTimeDiff = Math.abs(
                    chosenTimestamp - dataEntries[0].timestamp,
                );

                dataEntries.forEach((entry) => {
                    const timeDiff = Math.abs(
                        chosenTimestamp - entry.timestamp,
                    );
                    if (timeDiff < minTimeDiff) {
                        minTimeDiff = timeDiff;
                        closestTimestamp = entry.timestamp;
                    }
                });

                closestTimestamps[parseInt(sensorId, 10)] = closestTimestamp;
            });

        const closestTimestampMap: { [x: string]: number } = {};

        Object.keys(closestTimestamps).forEach((timestampKey) => {
            const timestampValue = closestTimestamps[Number(timestampKey)];
            closestTimestampMap[timestampKey] = parsedData.findIndex(
                (entry: any) => entry.timestamp === timestampValue,
            );
        });

        setIndexes(closestTimestampMap);

        return closestTimestamps;
    };

    const handleMouseOver = (sensorId: any, event: any, data: any) => {
        // If a hover timer is already set, clear it to avoid multiple events being triggered
        if (hoverTimer) {
            clearTimeout(hoverTimer);
        }
        // Set a new hover timer to trigger the event after 100ms
        const newTimer = setTimeout(() => {
            if (data?.index && data?.payload) {
                findClosestTimestamps(
                    groupedData,
                    data?.payload?.timestamp,
                    sensorId,
                );
            }
        }, 500);

        // Store the timer reference in the state
        setHoverTimer(newTimer);
    };

    const handleMouseOut = () => {
        // If the mouse moves out before 100ms, clear the hover timer
        if (hoverTimer) {
            clearTimeout(hoverTimer);
        }

        // Reset the hover timer state variable to null
        setHoverTimer(null);

        // Set indexes to an empty array
        setIndexes(null);
    };

    function formatTimestamp(label: number): string {
        const date = new Date(label * 1000);
        const day = date.getDate();
        const month = monthNames[date.getMonth()];
        const hours = date.getHours();
        const minutes = '0' + date.getMinutes();
        const stringHours =
            hours > 9 ? hours.toString() : '0' + hours.toString();

        return `${day} ${month}, ${stringHours}:${minutes.slice(-2)}`;
    }

    const getSensorName = (sensorName?: string | null) => {
        if (sensorName && sensorName === 'Medeltemperatur') {
            return t('Average Temperature Object');
        }
        return sensorName;
    };

    const CustomTooltip: React.FC<CustomTooltipProps> = ({
        active,
        payload,
        label,
    }) => {
        if (active && payload && payload.length) {
            const { name, value, unit, color } = payload[0];

            return (
                <div
                    className="custom-tooltip"
                    style={{
                        border: '1px solid rgb(204, 204, 204)',
                        background: '#fff',
                        padding: '10px',
                    }}
                >
                    {label ? <div>{`${formatTimestamp(label)}`}</div> : null}
                    <div style={{ color: color }}>{`${name} : ${value?.toFixed(
                        1,
                    )}${unit}`}</div>
                    {indexes &&
                        Object.keys(indexes)?.map(
                            (sensorId: any, i: number) => {
                                const sensorData =
                                    parsedData[indexes[Number(sensorId)]];
                                const sensor = sensorMap[Number(sensorId)];
                                return (
                                    <>
                                        <div
                                            style={{
                                                color: colorPicker(sensor, i),
                                            }}
                                        >{`${getSensorName(
                                            sensor?.name,
                                        )} : ${sensorData[sensorId]?.toFixed(
                                            1,
                                        )}${unit}`}</div>
                                    </>
                                );
                            },
                        )}
                </div>
            );
        }

        return null;
    };

    const CustomizedDot: React.FC<CustomizedDotProps> = (props) => {
        const { cx, cy, stroke } = props;
        if (
            cx &&
            cy &&
            props.index &&
            indexes &&
            Object.values(indexes).includes(props?.index)
        ) {
            return (
                <circle cx={cx} cy={cy} r={6} stroke={stroke} fill={stroke} />
            );
        }
        return null;
    };

    const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    const { parsedData, groupedData, sensorMap, daysDifference } = getData();
    const hasData = parsedData.length > 0;

    function colorPicker(sensor: Sensor, i: number = 0) {
        const type = sensor?.sensorFunctionTypeId;

        const floorColors = [
            '#FF0000',
            '#00FF00',
            '#0000FF',
            '#FFFF00 ',
            '#FF00FF',
            '#00FFFF ',
            '#800080 ',
            '#808080',
        ];

        if (type === undefined) {
            // Handle the case when sensorFunctionTypeId is undefined
            const index = selectedFloorSensors.findIndex(
                (floorSensor) => floorSensor.id === sensor.id,
            );
            if (index === -1) return '#f5d742';
            return floorColors[index % floorColors.length];
        }

        if (type === 0) {
            return '#91d5ff';
        }
        if (type === 1) {
            return '#003a8c';
        }
        if (type === 21) {
            return '#faad14';
        }
        if (type === 11) {
            return '#fa541c';
        }
        if (type === 61) {
            return '#820014';
        }

        if (type === -1) {
            const index =
                selectedFloorSensors.findIndex(
                    (floorSensor) => floorSensor.id === sensor.id,
                ) || 0;

            if (index === -1) return '#f5d742';
            return floorColors[index % floorColors.length];
        }
        // if (props.hotNCold) {
        //     if (index < 5) {
        //         index = index + 1;
        //         return hot[h++];
        //     } else {
        //         index = index + 1;
        //         return cold[c++];
        //     }
        // } else {
        const avg =
            0.1 *
            Math.round(
                10 *
                    (averageTemperatures.find(
                        (e) => e.fromTemperature === referenceTemperature,
                    )?.toTemperature ?? 21),
            );

        const t = getGradientColorForTemperature(
            sensorIdsToAverages[sensor.id] ?? [],
            averageTemperatures.length > 0
                ? averageTemperatures
                : prevAverageTemperatures,
            referenceTemperature,
            generateGradientKey(
                { high: avg + 1, low: avg - 1 },
                gradients.thermometer.mix,
            ),
            1,
        );

        return t ?? '#646464';
        // }
    }

    return (
        <>
            <div ref={myRef}>
                <Container
                    cardTitle={title}
                    cardExtra={
                        <div className=" flex">
                            <Button
                                onClick={onReset}
                                type="link"
                                className="m-auto"
                            >
                                <div className="text-lg">
                                    {t('Reset zoom')} <ZoomOutOutlined />
                                </div>
                            </Button>
                        </div>
                    }
                    style={{ border: 'none' }}
                    cardBodyStyle={{ padding: 0 }}
                >
                    {!hasData && <Empty description={t('No data')} />}
                    {hasData && (
                        <ResponsiveContainer height={screen / 2} width="100%">
                            <LineChart
                                onMouseDown={onMouseDown}
                                onMouseMove={onMouseMove}
                                onMouseUp={() => zoom(0)}
                                onMouseLeave={handleMouseOut}
                                data={parsedData}
                                margin={{
                                    top: 0,
                                    right: mode === 'desktop' ? 35 : 0,
                                    left: mode === 'desktop' ? 20 : 0,
                                    bottom: 0,
                                }}
                            >
                                <defs>
                                    <linearGradient
                                        id="#faad14"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset="50%"
                                            stopColor="#faad14"
                                            stopOpacity={0.2}
                                        />
                                        <stop
                                            offset="90%"
                                            stopColor="#FFFFFF"
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id="#fa541c"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset="50%"
                                            stopColor="#fa541c"
                                            stopOpacity={0.2}
                                        />
                                        <stop
                                            offset="90%"
                                            stopColor="#FFFFFF"
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id="#91d5ff"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset="50%"
                                            stopColor="#91d5ff"
                                            stopOpacity={0.2}
                                        />
                                        <stop
                                            offset="90%"
                                            stopColor="#FFFFFF"
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id="#003a8c"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset="10%"
                                            stopColor="#003a8c"
                                            stopOpacity={0.2}
                                        />
                                        <stop
                                            offset="90%"
                                            stopColor="#FFFFFF"
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id="#820014"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset="50%"
                                            stopColor="#820014"
                                            stopOpacity={0.2}
                                        />
                                        <stop
                                            offset="90%"
                                            stopColor="#FFFFFF"
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id="#52c41a"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset="10%"
                                            stopColor="#52c41a"
                                            stopOpacity={0.2}
                                        />
                                        <stop
                                            offset="90%"
                                            stopColor="#FFFFFF"
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id="#eb2f96"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset="10%"
                                            stopColor="#eb2f96"
                                            stopOpacity={0.2}
                                        />
                                        <stop
                                            offset="90%"
                                            stopColor="#FFFFFF"
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id="#722ed1"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset="10%"
                                            stopColor="#722ed1"
                                            stopOpacity={0.2}
                                        />
                                        <stop
                                            offset="90%"
                                            stopColor="#FFFFFF"
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id="#389e0d"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset="10%"
                                            stopColor="#389e0d"
                                            stopOpacity={0.2}
                                        />
                                        <stop
                                            offset="90%"
                                            stopColor="#FFFFFF"
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id="#f5222d"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset="10%"
                                            stopColor="#f5222d"
                                            stopOpacity={0.2}
                                        />
                                        <stop
                                            offset="90%"
                                            stopColor="#FFFFFF"
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id="#faad14"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset="10%"
                                            stopColor="#faad14"
                                            stopOpacity={0.2}
                                        />
                                        <stop
                                            offset="90%"
                                            stopColor="#FFFFFF"
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id="grey"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset="10%"
                                            stopColor="#8c8c8c"
                                            stopOpacity={0.2}
                                        />
                                        <stop
                                            offset="90%"
                                            stopColor="#FFFFFF"
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id="#ad8b00"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset="10%"
                                            stopColor="#ad8b00"
                                            stopOpacity={0.2}
                                        />
                                        <stop
                                            offset="90%"
                                            stopColor="#FFFFFF"
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id="#873800"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset="10%"
                                            stopColor="#873800"
                                            stopOpacity={0.2}
                                        />
                                        <stop
                                            offset="90%"
                                            stopColor="#FFFFFF"
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id="#90a729"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset="10%"
                                            stopColor="#90a729"
                                            stopOpacity={0.2}
                                        />
                                        <stop
                                            offset="90%"
                                            stopColor="#FFFFFF"
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id="#135200"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset="10%"
                                            stopColor="#135200"
                                            stopOpacity={0.2}
                                        />
                                        <stop
                                            offset="90%"
                                            stopColor="#FFFFFF"
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                </defs>
                                <CartesianGrid
                                    style={{
                                        stroke: '#ccc',
                                        strokeDasharray: '5 5',
                                    }}
                                    strokeDasharray="5 5"
                                />
                                {daysDifference && (
                                    <XAxis
                                        type="number"
                                        domain={[left, right]}
                                        tickFormatter={() => ''}
                                        ticks={
                                            daysDifference >= 1
                                                ? newTimestamps
                                                : []
                                        }
                                        dataKey="timestamp"
                                        angle={-45}
                                        interval={0}
                                        tickCount={countTicks(daysDifference)}
                                        allowDataOverflow={false}
                                    />
                                )}
                                {!mirror && (
                                    <YAxis
                                        padding={{ bottom: 50 }}
                                        mirror={true}
                                        yAxisId="left"
                                        orientation="left"
                                        allowDataOverflow={true}
                                        unit="°C"
                                        stroke={
                                            firstAxisColor
                                                ? firstAxisColor
                                                : undefined
                                        }
                                        tickCount={10}
                                        domain={minMaxArrValue(1, [
                                            avgData || [],
                                            avgObjData || [],
                                            data || [],
                                            data1 || [],
                                        ])}
                                        tickFormatter={(v) => v}
                                        type="number"
                                        allowDecimals={false}
                                        allowDuplicatedCategory={false}
                                    />
                                )}
                                {mirror && (
                                    <YAxis
                                        padding={{ bottom: 50 }}
                                        mirror={true}
                                        yAxisId="left"
                                        orientation="left"
                                        allowDataOverflow={true}
                                        unit="°C"
                                        stroke={
                                            secondAxisColor
                                                ? secondAxisColor
                                                : undefined
                                        }
                                        tickCount={10}
                                        domain={minMaxArrValue(1, [
                                            avgData || [],
                                            avgObjData || [],
                                            data || [],
                                            data1 || [],
                                        ])}
                                        tickFormatter={(v) => v.toFixed(0)}
                                        type="number"
                                        allowDecimals={false}
                                        allowDuplicatedCategory={false}
                                    />
                                )}
                                {mirror && (
                                    <YAxis
                                        padding={{ bottom: 50 }}
                                        mirror={true}
                                        yAxisId="right"
                                        orientation="right"
                                        allowDataOverflow={true}
                                        unit="°C"
                                        tickCount={10}
                                        stroke={
                                            secondAxisColor
                                                ? secondAxisColor
                                                : undefined
                                        }
                                        domain={minMaxArrValue(1, [
                                            data || [],
                                            data1 || [],
                                            avgData || [],
                                            avgObjData || [],
                                        ])}
                                        tickFormatter={(v) => v.toFixed(0)}
                                        type="number"
                                        allowDecimals={false}
                                        allowDuplicatedCategory={false}
                                    />
                                )}
                                {!mirror && (
                                    <YAxis
                                        padding={{ bottom: 50 }}
                                        mirror={true}
                                        yAxisId="right"
                                        orientation="right"
                                        allowDataOverflow={true}
                                        unit="°C"
                                        tickCount={10}
                                        domain={minMaxArrValue(1, [
                                            data || [],
                                            data1 || [],
                                            avgData || [],
                                            avgObjData || [],
                                        ])}
                                        tickFormatter={(v) => v.toFixed(0)}
                                        type="number"
                                        stroke={
                                            secondAxisColor
                                                ? secondAxisColor
                                                : undefined
                                        }
                                        allowDecimals={false}
                                        allowDuplicatedCategory={false}
                                    />
                                )}
                                <Tooltip
                                    labelStyle={{ width: elementWidth / 5 }}
                                    content={<CustomTooltip />}
                                    position={{
                                        y: -100,
                                        x: elementWidth - elementWidth / 1.1,
                                    }}
                                />
                                {data?.map((v, i) => {
                                    if (include?.length === 0) {
                                        return (
                                            <Line
                                                isAnimationActive={false}
                                                yAxisId="left"
                                                key={v.id}
                                                connectNulls={true}
                                                unit="°C"
                                                name={v.name || v.uniqueId}
                                                type="linear"
                                                strokeWidth={2}
                                                dataKey={v.id}
                                                stroke={colorPicker(v)}
                                                dot={
                                                    <CustomizedDot
                                                        stroke={colorPicker(v)}
                                                    />
                                                }
                                                onMouseOut={handleMouseOut}
                                                activeDot={{
                                                    r: 6,
                                                    onMouseOver: (
                                                        event,
                                                        data,
                                                    ) =>
                                                        handleMouseOver(
                                                            v.id,
                                                            event,
                                                            data,
                                                        ),
                                                    onMouseLeave: handleMouseOut,
                                                }}
                                            />
                                        );
                                    } else
                                        return include?.includes(v.id) ===
                                            true ? (
                                            <Line
                                                isAnimationActive={false}
                                                strokeWidth={2}
                                                yAxisId="left"
                                                key={v.id}
                                                connectNulls={true}
                                                unit="°C"
                                                name={v.name || v.uniqueId}
                                                type="linear"
                                                dataKey={v.id}
                                                stroke={colorPicker(v)}
                                                dot={
                                                    <CustomizedDot
                                                        stroke={colorPicker(v)}
                                                    />
                                                }
                                                onMouseOut={handleMouseOut}
                                                activeDot={{
                                                    r: 6,
                                                    onMouseOver: (
                                                        event,
                                                        data,
                                                    ) =>
                                                        handleMouseOver(
                                                            v.id,
                                                            event,
                                                            data,
                                                        ),
                                                    onMouseLeave: handleMouseOut,
                                                }}
                                            />
                                        ) : (
                                            // This is here as a mechanism for keep the colors from the control in sync with the graph since the controller update the props of this but not its own internal state notice dataKey prop diffrence
                                            <Line
                                                isAnimationActive={false}
                                                strokeWidth={2}
                                                yAxisId="left"
                                                key={v.id}
                                                connectNulls={true}
                                                unit="°C"
                                                name={v.name || v.uniqueId}
                                                type="linear"
                                                dataKey={-1}
                                                stroke={colorPicker(v)}
                                                dot={
                                                    <CustomizedDot
                                                        stroke={colorPicker(v)}
                                                    />
                                                }
                                                onMouseOut={handleMouseOut}
                                                activeDot={{
                                                    r: 6,
                                                    onMouseOver: (
                                                        event,
                                                        data,
                                                    ) =>
                                                        handleMouseOver(
                                                            v.id,
                                                            event,
                                                            data,
                                                        ),
                                                    onMouseLeave: handleMouseOut,
                                                }}
                                            />
                                        );
                                })}
                                {data1?.map((v, i) => {
                                    if (include?.length === 0) {
                                        return (
                                            <Line
                                                isAnimationActive={false}
                                                strokeWidth={2}
                                                yAxisId="left"
                                                key={v.id}
                                                connectNulls={true}
                                                unit="°C"
                                                name={v.name || v.uniqueId}
                                                type="linear"
                                                dataKey={v.id}
                                                stroke={colorPicker(v)}
                                                dot={
                                                    <CustomizedDot
                                                        stroke={colorPicker(v)}
                                                    />
                                                }
                                                onMouseOut={handleMouseOut}
                                                activeDot={{
                                                    r: 6,
                                                    onMouseOver: (
                                                        event,
                                                        data,
                                                    ) =>
                                                        handleMouseOver(
                                                            v.id,
                                                            event,
                                                            data,
                                                        ),
                                                    onMouseLeave: handleMouseOut,
                                                }}
                                            />
                                        );
                                    } else
                                        return include?.includes(v.id) ===
                                            true ? (
                                            <Line
                                                isAnimationActive={false}
                                                strokeWidth={2}
                                                yAxisId="left"
                                                key={v.id}
                                                connectNulls={true}
                                                unit="°C"
                                                name={v.name || v.uniqueId}
                                                type="linear"
                                                dataKey={v.id}
                                                stroke={colorPicker(v)}
                                                dot={
                                                    <CustomizedDot
                                                        stroke={colorPicker(v)}
                                                    />
                                                }
                                                onMouseOut={handleMouseOut}
                                                activeDot={{
                                                    r: 6,
                                                    onMouseOver: (
                                                        event,
                                                        data,
                                                    ) =>
                                                        handleMouseOver(
                                                            v.id,
                                                            event,
                                                            data,
                                                        ),
                                                    onMouseLeave: handleMouseOut,
                                                }}
                                            />
                                        ) : null;
                                })}
                                {/* {data2?.map((v, i) => {
                                    if (include?.length === 0) {
                                        return (
                                            <Line
                                                isAnimationActive={false}
                                                strokeWidth={2}
                                                dot={
                                                    <CustomizedDot
                                                        stroke={colorPicker(v)}
                                                    />
                                                }
                                                color={colorPicker(v)}
                                                yAxisId="right"
                                                key={v.id}
                                                connectNulls={true}
                                                unit="°C"
                                                name={v.name || v.uniqueId}
                                                type="linear"
                                                dataKey={v.id}
                                                stroke={colorPicker(v)}
                                                onMouseOut={handleMouseOut}
                                                onMouseLeave={handleMouseOut}
                                                activeDot={{
                                                    r: 6,
                                                    onMouseOver: (
                                                        event,
                                                        data,
                                                    ) =>
                                                        handleMouseOver(
                                                            v.id,
                                                            event,
                                                            data,
                                                        ),
                                                    onMouseLeave: handleMouseOut,
                                                }}
                                            />
                                        );
                                    } else
                                        return include?.includes(v.id) ===
                                            true ? (
                                            <Line
                                                isAnimationActive={false}
                                                strokeWidth={2}
                                                yAxisId="right"
                                                key={v.id}
                                                connectNulls={true}
                                                unit="°C"
                                                name={v.name || v.uniqueId}
                                                type="linear"
                                                dataKey={v.id}
                                                stroke={colorPicker(v)}
                                                dot={
                                                    <CustomizedDot
                                                        stroke={colorPicker(v)}
                                                    />
                                                }
                                                onMouseOut={handleMouseOut}
                                                activeDot={{
                                                    r: 6,
                                                    onMouseOver: (
                                                        event,
                                                        data,
                                                    ) =>
                                                        handleMouseOver(
                                                            v.id,
                                                            event,
                                                            data,
                                                        ),
                                                    onMouseLeave: handleMouseOut,
                                                }}
                                            />
                                        ) : null;
                                })} */}
                                {data3?.map((v, i) => {
                                    if (include?.length === 0) {
                                        return (
                                            <Line
                                                isAnimationActive={false}
                                                strokeWidth={2}
                                                yAxisId="right"
                                                key={v.id}
                                                connectNulls={true}
                                                unit="°C"
                                                name={v.name || v.uniqueId}
                                                type="linear"
                                                dataKey={v.id}
                                                stroke={colorPicker(v)}
                                                dot={
                                                    <CustomizedDot
                                                        stroke={colorPicker(v)}
                                                    />
                                                }
                                                onMouseOut={handleMouseOut}
                                                activeDot={{
                                                    r: 6,
                                                    onMouseOver: (
                                                        event,
                                                        data,
                                                    ) =>
                                                        handleMouseOver(
                                                            v.id,
                                                            event,
                                                            data,
                                                        ),
                                                    onMouseLeave: handleMouseOut,
                                                }}
                                            />
                                        );
                                    } else
                                        return include?.includes(v.id) ===
                                            true ? (
                                            <Line
                                                isAnimationActive={false}
                                                strokeWidth={2}
                                                yAxisId="right"
                                                key={v.id}
                                                connectNulls={true}
                                                unit="°C"
                                                name={v.name || v.uniqueId}
                                                type="linear"
                                                dataKey={v.id}
                                                stroke={colorPicker(v)}
                                                dot={
                                                    <CustomizedDot
                                                        stroke={colorPicker(v)}
                                                    />
                                                }
                                                onMouseOut={handleMouseOut}
                                                activeDot={{
                                                    r: 6,
                                                    onMouseOver: (
                                                        event,
                                                        data,
                                                    ) =>
                                                        handleMouseOver(
                                                            v.id,
                                                            event,
                                                            data,
                                                        ),
                                                    onMouseLeave: handleMouseOut,
                                                }}
                                            />
                                        ) : null;
                                })}
                                {selectedFloorSensors &&
                                    selectedFloorSensors.length &&
                                    avgData?.map((v: any, i: number) => {
                                        if (include?.length === 0) {
                                            return (
                                                <Line
                                                    isAnimationActive={false}
                                                    strokeWidth={3}
                                                    yAxisId="right"
                                                    key={v.id}
                                                    connectNulls={true}
                                                    unit="°C"
                                                    name={v.name || v.uniqueId}
                                                    type="linear"
                                                    dataKey={v.id}
                                                    stroke={colorPicker(v, i)}
                                                    dot={
                                                        <CustomizedDot
                                                            stroke={colorPicker(
                                                                v,
                                                                i,
                                                            )}
                                                        />
                                                    }
                                                    strokeDasharray="5 5"
                                                />
                                            );
                                        } else
                                            return include?.includes(v.id) ===
                                                true ? (
                                                <Line
                                                    isAnimationActive={false}
                                                    strokeWidth={3}
                                                    yAxisId="right"
                                                    key={v.id}
                                                    connectNulls={true}
                                                    unit="°C"
                                                    name={v.name || v.uniqueId}
                                                    type="linear"
                                                    dataKey={v.id}
                                                    stroke={colorPicker(v, i)}
                                                    dot={
                                                        <CustomizedDot
                                                            stroke={colorPicker(
                                                                v,
                                                                i,
                                                            )}
                                                        />
                                                    }
                                                    strokeDasharray="5 5"
                                                />
                                            ) : null;
                                    })}
                                {avgObjData?.map((v: any) => {
                                    if (include?.length === 0) {
                                        return (
                                            <Line
                                                isAnimationActive={false}
                                                strokeWidth={3}
                                                yAxisId="right"
                                                key={v.id}
                                                connectNulls={true}
                                                unit="°C"
                                                name={v.name || v.uniqueId}
                                                type="linear"
                                                dataKey={v.id}
                                                stroke={'#f5d742'}
                                                dot={
                                                    <CustomizedDot
                                                        stroke={'#f5d742'}
                                                    />
                                                }
                                                strokeDasharray="5 5"
                                            />
                                        );
                                    } else
                                        return include?.includes(v.id) ===
                                            true ? (
                                            <Line
                                                isAnimationActive={false}
                                                strokeWidth={3}
                                                yAxisId="right"
                                                key={v.id}
                                                connectNulls={true}
                                                unit="°C"
                                                name={v.name || v.uniqueId}
                                                type="linear"
                                                dataKey={v.id}
                                                stroke={'#f5d742'}
                                                dot={
                                                    <CustomizedDot
                                                        stroke={'#f5d742'}
                                                    />
                                                }
                                                strokeDasharray="5 5"
                                            />
                                        ) : null;
                                })}

                                {refLeft && refRight ? (
                                    <ReferenceArea
                                        yAxisId="left"
                                        x1={refLeft}
                                        x2={refRight}
                                        strokeOpacity={0.3}
                                    />
                                ) : null}
                            </LineChart>
                        </ResponsiveContainer>
                    )}
                </Container>
            </div>
        </>
    );
}
