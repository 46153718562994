import { EditFilled } from '@ant-design/icons';
import { Col, Input, Row, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useCorporationSites } from 'src/hooks/corporations/useCorporationsSites';
import useViewport from 'src/hooks/generic/useViewport';
import { SiteObject } from 'src/models/SiteObject';
import { ColorSelector } from 'src/utils/ColorSelector';
import Nav from 'src/utils/nav/Nav';

interface Props {
    corporationId: number;
}

interface SitesListsProps {
    objects: SiteObject[];
    onClick?: (user: SiteObject) => void;
}

export const ObjectList = (props: SitesListsProps) => {
    const { objects, onClick } = props;
    const history = useNavigate();

    return (
        <>
            {objects.map((object) => {
                return (
                    <Row
                        onClick={() => onClick?.(object)}
                        align="middle"
                        className="cursor-pointer"
                    >
                        <Col span={18}>
                            <div className="float-left mr-5 mb-4">
                                <Avatar
                                    size="large"
                                    style={{
                                        backgroundColor: ColorSelector.getColor(
                                            object.id,
                                        ),
                                    }}
                                >
                                    {object.name.charAt(0)}
                                </Avatar>

                                <Typography.Text className="ml-4">
                                    {object.name}
                                </Typography.Text>
                            </div>
                        </Col>
                        <Col span={6}>
                            {
                                <EditFilled
                                    className="float-right hover:scale-150 transform text-xl"
                                    onClick={() => {
                                        history(
                                            Nav.build({
                                                path: '/manage-object',
                                                queryParams: {
                                                    objectId: object.id.toString(),
                                                },
                                            }),
                                        );
                                    }}
                                />
                            }
                        </Col>
                    </Row>
                );
            })}
        </>
    );
};

function EditCorporationObject(props: Props) {
    const { corporationId } = props;
    const { t } = useTranslation();
    const { data: allSites } = useCorporationSites(corporationId);
    const [objects, setObjects] = useState<SiteObject[]>();
    const { mode } = useViewport();
    let allObjects: SiteObject[] | any = [];
    useEffect(() => {
        if (allSites) {
            allSites.forEach((site) => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                allObjects = allObjects.concat(site.objects!);
            });
        }
        setObjects(allObjects);
    }, [allSites]);
    return (
        <Row align="middle" gutter={[100, 30]}>
            <Col span={mode === 'desktop' ? 12 : 24}>
                <Input.Search
                    enterButton
                    placeholder={t('Name')}
                    onChange={(e) => {
                        const term = e.target.value;
                        if (term.length === 0) {
                            setObjects(allObjects);
                        } else {
                            setObjects(
                                objects?.filter((v) =>
                                    v.name
                                        .toLowerCase()
                                        .includes(term.toLowerCase()),
                                ),
                            );
                        }
                    }}
                />
            </Col>
            <Col span={24}>{objects && <ObjectList objects={objects} />}</Col>
            {/* <EditObjectPopup
                visible={showPopup}
                onFinish={(name: string) => {
                    edit(selectedObject, { name: name });
                    setShowPopup(false);
                }}
                onCancel={() => setShowPopup(false)}
            /> */}
        </Row>
    );
}

export default EditCorporationObject;
