import { Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPrettySensorName, SensorType } from 'src/models/Sensor';

const removeDuplicatedKeys = (object: Record<string, string | number>) => {
    const out: Record<string, number> = {};

    for (const key in object) {
        const value = object[key];
        if (typeof value !== 'string') {
            out[key] = value;
        }
    }

    return out;
};

export interface SensorTypeSelectorProps {
    onChange?: (key: SensorType) => void;
    onBlur?: (event: any) => void;
    defaultValue: SensorType;
    open?: boolean;
}

export const SensorTypeSelector = (props: SensorTypeSelectorProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Select
                defaultValue={props.defaultValue}
                onChange={props.onChange}
                style={{ width: '100%' }}
                open={props.open}
                onBlur={props.onBlur}
            >
                {Object.entries(removeDuplicatedKeys(SensorType)).map(
                    ([key, value]) => {
                        return (
                            <Select.Option key={key} value={value}>
                                {getPrettySensorName(value, t)}
                            </Select.Option>
                        );
                    },
                )}
            </Select>
        </>
    );
};
