import { useEffect, useState } from 'react';
import { API } from 'src/api/API';
import { V3D_Object } from '../3d/chart/types';

const use3DData = (objectId: number, stateId: number) => {
    const [cache, setCache] = useState<Record<string, V3D_Object>>({});
    const [error, setError] = useState<any>();

    useEffect(() => {
        if (cache[`${objectId}:${stateId}`] !== undefined) {
            return;
        }

        (async () => {
            await new API()
                .fetchObjectVisualization(objectId, stateId)
                .then((data) =>
                    setCache({ ...cache, [`${objectId}:${stateId}`]: data }),
                )
                .catch((e) => {
                    setError(e.message);
                });
        })();
    }, [stateId, objectId, cache]);

    return { data: cache[`${objectId}:${stateId}`], error };
};

export default use3DData;
