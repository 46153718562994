import { combineReducers } from '@reduxjs/toolkit';

// Reducers
import sensorsReducer from 'src/store/reducers/sensorsSlice';
import sensorNamesReducer from 'src/store/reducers/sensorNamesSlice';
import selectedSensorsReducer from 'src/store/reducers/selectedSensorsSlice';
import masterModbusReducer from 'src/store/reducers/masterModbusSlice';
import datePickerReducer from 'src/store/reducers/datePickerSlice';
import selectedKeysReducer from 'src/store/reducers/selectedKeysSlice';
import selectedObjectReducer from 'src/store/reducers/selectedObjectSlice';
import treeReducer from 'src/store/reducers/treeSlice';
import masterModbusTcpReducer from 'src/store/reducers/masterModbusTcpSlice';
import tableRowsReducer from 'src/store/reducers/tableRowsSlice';
import templateSpacesReducer from 'src/store/reducers/templateSpacesSlice';

const rootReducer = combineReducers({
    sensors: sensorsReducer,
    sensorNames: sensorNamesReducer,
    masterModbus: masterModbusReducer,
    masterModbusTcp: masterModbusTcpReducer,
    datePicker: datePickerReducer,
    selectedSensors: selectedSensorsReducer,
    selectedKeys: selectedKeysReducer,
    selectedObject: selectedObjectReducer,
    tree: treeReducer,
    tableRows: tableRowsReducer,
    templateSpaces: templateSpacesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
