// selectedObjectSlice.js

import { createSlice } from '@reduxjs/toolkit';

const data: any[] = [];

const period: any = {};

interface FloorOrBlockSensor {
    identifier: string;
    id: number;
    selectedFloors: number[];
}

interface TreeDataNode {
    id: number;
    key: string;
    title: string;
    type: string;
    disabled: boolean;
    children?: TreeDataNode[];
}

interface SelectedObjectState {
    refreshFlag: boolean;
    nodeType: TreeDataNode;
    avgData: any[];
    period: any;
    selectedFloorSensors: FloorOrBlockSensor[] | [];
}

const initialState: SelectedObjectState = {
    refreshFlag: false,
    nodeType: {
        id: 0,
        key: 'none',
        title: 'none',
        type: 'none',
        disabled: false,
    },
    avgData: data,
    period: period,
    selectedFloorSensors: [],
};

const selectedObjectSlice = createSlice({
    name: 'selectedObject',
    initialState: initialState,
    reducers: {
        setRefreshFlag: (state, action) => {
            state.refreshFlag = action.payload;
        },
        setNodeType: (state, action) => {
            state.nodeType = action.payload;
        },

        setAvgData: (state, action) => {
            state.avgData = [...action.payload];
        },

        setSelectedFloorSensors: (state, action) => {
            state.selectedFloorSensors = action.payload;
        },

        addToExistingFloorSensors: (state, action) => {
            state.selectedFloorSensors = [
                ...state.selectedFloorSensors,
                ...action.payload,
            ];
        },

        addFloorOrBlockSensor: (state, action) => {
            state.selectedFloorSensors = [
                ...state.selectedFloorSensors,
                action.payload,
            ];
        },

        removeExistingFloorSensor: (state, action) => {
            const filteredFloorsOrBlocks = state.selectedFloorSensors.filter(
                (item) => item.id !== action.payload,
            );
            state.selectedFloorSensors = [...filteredFloorsOrBlocks];
        },

        clearSelectedFloors: (state) => {
            state.selectedFloorSensors = [];
        },

        setPeriod: (state, action) => {
            state.period = { ...action.payload };
        },
    },
});

export const {
    setRefreshFlag,
    setNodeType,
    setAvgData,
    setSelectedFloorSensors,
    addToExistingFloorSensors,
    addFloorOrBlockSensor,
    removeExistingFloorSensor,
    clearSelectedFloors,
    setPeriod,
} = selectedObjectSlice.actions;

export default selectedObjectSlice.reducer;
