import { Alert, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useClosedWarnings, useWarnings } from 'src/hooks/generic/useWarnings';
import { Warning } from 'src/models/Warning';

function hasWarnings(warnings: undefined | Warning[]): warnings is Warning[] {
    return warnings !== undefined && warnings.length > 0;
}

function WarningView() {
    const { t } = useTranslation();
    const { data } = useWarnings();
    const [setClosedWarnings, closedWarnings] = useClosedWarnings();

    const onClose = (warning: Warning) => {
        setClosedWarnings([...(closedWarnings || []), warning]);
    };

    const warnings = data;

    return (
        <>
            {hasWarnings(warnings) &&
                warnings.map((warning) => {
                    const typeMap = {
                        LOW: 'success',
                        MEDIUM: 'warning',
                        HIGH: 'error',
                    } as const;

                    return (
                        <div className="mb-4">
                            <Alert
                                key={warning.id}
                                message={warning.title}
                                description={warning.description}
                                type={typeMap[warning.priority]}
                                showIcon
                                closable
                                onClose={() => onClose(warning)}
                            />
                        </div>
                    );
                })}
            {!hasWarnings(warnings) && (
                <Empty description={t('No new messages!')} />
            )}
        </>
    );
}

export default WarningView;
