import ManageSiteView from 'src/views/sites/ManageSiteView';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

function ManageSitePage() {
    const [params] = useQueryParams({
        siteId: StringParam,
        siteName: StringParam,
        tab: withDefault(StringParam, ''),
    });

    const siteId = Number.parseInt(params.siteId ?? '');
    return (
        <div className="w-1/2">
            <ManageSiteView
                siteId={siteId}
                siteName={params.siteName || undefined}
            />
        </div>
    );
}

export default ManageSitePage;
