import { API } from 'src/api/API';

export const useOrganizeTagsOnIntegration = () => {
    // TODO type this
    const organize = (
        objectId: number,
        integrationId: number,
        tagTree: any,
    ) => {
        return new API().organizeIntegration(objectId, integrationId, tagTree);
    };

    return [organize] as const;
};
