import { Col, Row, Typography, Form, Input, Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useTranslation } from 'react-i18next';
import { useAddSite } from 'src/hooks/sites/useAddSite';
import logoSrc from 'src/assets/Logo.svg';

interface Props {
    onCancel: () => void;
    onSuccess: () => void;
    corporationId: number;
    visible: boolean;
}

function AddSiteDialog(props: Props) {
    const { onCancel, onSuccess, corporationId, visible } = props;
    const [addSite] = useAddSite();
    const { t } = useTranslation();

    const onSubmit = async (form: { name: string }) => {
        await addSite(corporationId, form);
        onSuccess();
    };

    return (
        <Modal
            className="round"
            visible={visible}
            onCancel={onCancel}
            footer={null}
            closable={false}
        >
            <Row>
                <Col span={12}>
                    <Typography.Title level={4}>
                        {t('Add new site')}
                    </Typography.Title>
                </Col>
                <Col span={12}>
                    <img
                        className="h-5 float-right"
                        src={logoSrc}
                        alt="Nrlyze logo"
                    />
                </Col>

                <Col span={24} className="mb-3">
                    <Typography.Text strong type="secondary">
                        {t(
                            'A site is a collection of objects that makes organisation and finding your organisations objects easier',
                        )}
                    </Typography.Text>
                </Col>

                <Col span={24}>
                    <Form onFinish={onSubmit}>
                        <Row gutter={[20, 0]}>
                            <Col span={24}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                'Please select an email',
                                            ),
                                        },
                                    ]}
                                    name="name"
                                >
                                    <Input
                                        placeholder="Anläggning AB"
                                        name="name"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Button
                                    onClick={() => {
                                        onCancel();
                                    }}
                                    block
                                    size="large"
                                >
                                    {t('Cancel')}
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    htmlType="submit"
                                    block
                                    size="large"
                                    type="primary"
                                >
                                    {t('Add site')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Modal>
    );
}

export default AddSiteDialog;
