import { WarningOutlined } from '@ant-design/icons';
import { Card, Checkbox, Select, Table, Tabs, Typography, Tooltip } from 'antd';
import { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Area,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip as GraphTooltip,
    XAxis,
    YAxis,
} from 'recharts';
import House3D from 'src/components/House3D/House3D';
import { GradientKeyViewThermometer } from 'src/views/objects/GradientKeyHumidity';
import use3DData from 'src/components/House3D/hooks/use3DData';
import useViewport from 'src/hooks/generic/useViewport';
import { useAverageTemperaturesWithControlState } from 'src/hooks/objects/useAverageTemperaturesWithControllState';
import { useBalance } from 'src/hooks/objects/useBalance';
import { useGetDOT } from 'src/hooks/objects/useGetDOT';
import { useObject } from 'src/hooks/objects/useObject';
import { useSensorAverage } from 'src/hooks/objects/useSensorAvergage';
import { useAverageTemperaturesForSensor } from 'src/hooks/sensors/useInvalidateSensor';
import { TemperatureWithControlState } from 'src/models/Temperature';
import { formatDate } from 'src/utils/formatDate';
import { tickGenerator } from '../sites/FlowTemperatureView';
import {
    generateGradientKey,
    getGradientColorForTemperature,
    gradients,
} from 'src/utils/gradients';
import { GradientKey } from 'src/models/GradientKey';
import { ControlState } from 'src/models/ControlState';
import { useHumidity } from 'src/hooks/objects/useHumidity';
import './BalancingView.scss';
import PreferencesMenu from './PreferencesMenu';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { V3D_Object } from 'src/components/House3D/3d/chart/types';
import { GradientConclusionView } from './GradientConclusion';

export interface BalanceData {
    object: string;
    sensorName: string;
    indoorTemp: number;
    indoorTempAverage: number;
    indoorTempMin: number;
    deltaAverageAbs: number;
    deltaAverage: number;
    indoorTempMax: number;
    noisePos: number;
    noiseNeg: number;
    rc: number;
    deltaMedian: number;
    sensorId: number;
    id: number;
    isActive: 'TRUE' | 'FALSE';
}

export interface HumidityData {
    sensorFunctionType: number;
    sensorFunctionTypeIndex: number;
    absoluteHumidity: number;
    controlState: number;
}

export interface HumidityAPIData {
    humidity: {
        control_state: number;
        data: HumidityData[];
    };
}

interface ConditionalGraphTickProps {
    x?: number;
    y?: number;
    payload?: { value: number };
}

function ConditionalGraphTick(props: ConditionalGraphTickProps) {
    const { x, y, payload } = props;

    const value = payload?.value;

    const renderTick = () => {
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                    {value} °C
                </text>
            </g>
        );
    };

    return <>{value !== undefined && value % 5 === 0 ? renderTick() : ''} </>;
}

interface DotViewProps {
    objectId: number;
    controlStateId: number;
}

function DotView(props: DotViewProps) {
    const { controlStateId, objectId } = props;
    const { t } = useTranslation();
    const { data: DOT } = useGetDOT(objectId, controlStateId);
    return (
        <div className="flex flex-col">
            {DOT !== undefined && (
                <>
                    <Typography.Text>
                        {t('Dominating outdoor temperature ') +
                            ': ' +
                            DOT.dot?.toFixed(0) +
                            '°C'}
                    </Typography.Text>
                    <Typography.Text>
                        {DOT.pseudoDOT === null
                            ? t('Average temperature at DOT')
                            : t('Average temperature close DOT')}
                        {': '}
                        {DOT.average == null
                            ? t('Not measured')
                            : `${DOT.average.toFixed(1)}°C`}
                        {DOT.pseudoDOT && (
                            <>
                                {' '}
                                <Tooltip
                                    title={t(
                                        'When the current measuring state does not have data for DOT, we show data at the closest outdoor temperature to DOT.',
                                    )}
                                >
                                    <WarningOutlined />
                                </Tooltip>
                            </>
                        )}
                    </Typography.Text>
                </>
            )}
        </div>
    );
}

interface BalancingTableProps {
    sensorIds: number[];
    setSensorIds: (ids: number[]) => void;
    balanceFilter: 'ALL' | 'HOT' | 'COLD';
    threshold: number;
    onChange: (id: number, checked: boolean) => void;
    transformedData: any[];
}

function BalancingTable(props: BalancingTableProps) {
    const {
        sensorIds,
        setSensorIds,
        balanceFilter,
        onChange,
        threshold,
        transformedData,
    } = props;

    const { t } = useTranslation();

    const columns = [
        {
            title: t('Is active'),
            dataIndex: 'isActive',
            width: '6ch',
            render: (text: any, record: any, index: any) => {
                return (
                    <Checkbox
                        key={record.id}
                        defaultChecked={text}
                        onChange={(event) => {
                            event.stopPropagation();
                            onChange(record.id, event.target.checked);
                        }}
                    />
                );
            },
        },
        {
            title: t('Name'),
            dataIndex: 'shortSensorName',
            width: '21ch',
            sorter: (a: any, b: any) =>
                a.sensorName.localeCompare(b.sensorName),
            render: (text: any, record: any, index: any) => {
                return (
                    <div>
                        <Tooltip title={record.fullSensorName}>
                            <Typography.Text
                                style={{
                                    width: 'max-content',
                                }}
                            >
                                {text}
                            </Typography.Text>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            title: t('Temp'),
            width: '5ch',
            dataIndex: 'indoorTemp',
            sorter: (a: any, b: any) => a.indoorTemp - b.indoorTemp,
        },
        {
            title: t('Δ'),
            width: '5ch',
            dataIndex: 'deltaAverage',
            sorter: (a: any, b: any) => a.deltaAverage - b.deltaAverage,
        },
        {
            title: t('Min'),
            width: '5ch',
            dataIndex: 'indoorTempMin',
            sorter: (a: any, b: any) => a.indoorTempMin - b.indoorTempMin,
        },
        {
            title: t('Max'),
            width: '5ch',
            dataIndex: 'indoorTempMax',
            sorter: (a: any, b: any) => a.indoorTempMax - b.indoorTempMax,
        },
        {
            title: t('Mean'),
            width: '5ch',
            dataIndex: 'indoorTempAverage',
            sorter: (a: any, b: any) =>
                a.indoorTempAverage - b.indoorTempAverage,
        },
        {
            width: '6ch',
            title: t('Noise+'),
            dataIndex: 'noisePos',
            sorter: (a: any, b: any) => a.noisePos - b.noisePos,
        },
        {
            width: '6ch',
            title: t('Noise-'),
            dataIndex: 'noiseNeg',
            sorter: (a: any, b: any) => a.noiseNeg - b.noiseNeg,
        },
        {
            width: '6ch',
            title: t('RC%'),
            dataIndex: 'rc',
            sorter: (a: any, b: any) => a.rc - b.rc,
        },
    ];

    return (
        <div className="h-full">
            <Table
                size="small"
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            if (
                                (event.target as HTMLElement).classList.contains(
                                    'ant-checkbox-input',
                                )
                            ) {
                                return;
                            }

                            if (sensorIds.includes(record.id)) {
                                const temp = sensorIds.filter(
                                    (id) => id !== record.id,
                                );

                                setSensorIds(temp);
                            } else {
                                const temp = [...sensorIds, record.id];

                                setSensorIds(temp);
                            }
                        },
                    };
                }}
                pagination={false}
                rowClassName={(record) => {
                    if (balanceFilter === 'ALL') {
                        if (
                            parseFloat(record.deltaAverage) < 0 &&
                            parseFloat(record.deltaAverageAbs) >= threshold
                        ) {
                            return sensorIds.includes(record.id)
                                ? 'bg-blue-200 cursor-pointer font-semibold'
                                : 'bg-blue-200 cursor-pointer';
                        }
                        if (
                            parseFloat(record.deltaAverage) > 0 &&
                            parseFloat(record.deltaAverageAbs) >= threshold
                        ) {
                            return sensorIds.includes(record.id)
                                ? 'bg-red-200 cursor-pointer font-semibold'
                                : 'bg-red-200 cursor-pointer';
                        } else {
                            return sensorIds.includes(record.id)
                                ? 'bg-white cursor-pointer font-semibold'
                                : 'bg-white cursor-pointer';
                        }
                    }
                    if (balanceFilter === 'HOT') {
                        if (
                            parseFloat(record.deltaAverage) > 0 &&
                            parseFloat(record.deltaAverageAbs) >= threshold
                        ) {
                            return sensorIds.includes(record.id)
                                ? 'bg-red-200 cursor-pointer font-semibold'
                                : 'bg-red-200 cursor-pointer';
                        } else {
                            return 'hidden';
                        }
                    }
                    if (balanceFilter === 'COLD') {
                        if (
                            parseFloat(record.deltaAverage) < 0 &&
                            parseFloat(record.deltaAverageAbs) >= threshold
                        ) {
                            return sensorIds.includes(record.id)
                                ? 'bg-blue-200 cursor-pointer font-semibold'
                                : 'bg-blue-200 cursor-pointer';
                        } else {
                            return 'hidden';
                        }
                    } else {
                        return sensorIds.includes(record.id)
                            ? 'cursor-pointer font-semibold'
                            : 'cursor-pointer';
                    }
                }}
                dataSource={transformedData}
                columns={columns}
                scroll={{ y: `calc(100vh - 250px)` }}
            />
        </div>
    );
}

interface BalancingVisualizationProps {
    setTableView: any;
    sensorIds: number[];
    objectId: number;
    setSensorIds: (ids: number[]) => void;
    referenceTemperature: number;
    setReferenceTemperature: (temp: number) => void;
    transformedData: any[];
    controlStateId: number;
    threshold: number;
    gradientKey: GradientKey;
    humidityData: HumidityData[];
    humidityLevel: number;
    viewMode: string[];
    data: V3D_Object;
    error: any;
}

function BalancingVisualization(props: BalancingVisualizationProps) {
    const {
        controlStateId,
        objectId,
        referenceTemperature,
        sensorIds,
        setSensorIds,
        setTableView,
        transformedData,
        threshold,
        gradientKey,
        humidityData,
        humidityLevel,
        viewMode,
        data,
        error,
    } = props;

    const isHumidity =
        !!viewMode &&
        viewMode.length === 1 &&
        viewMode.some((option) => option.toLowerCase() === 'humidity');

    return (
        <div className="h-full">
            <House3D
                onNavigateToTable={() => setTableView('table')}
                selectedSensors={sensorIds}
                objectId={objectId}
                onSensorSelected={(id) => {
                    setSensorIds([...sensorIds, id]);
                }}
                referenceTemperature={referenceTemperature}
                sensors={transformedData}
                onSensorDeselected={(id) => {
                    setSensorIds([
                        ...sensorIds.filter((sensorId) => sensorId !== id),
                    ]);
                }}
                stateId={controlStateId}
                tolerance={threshold}
                gradientKey={gradientKey}
                humidityData={humidityData}
                humidityLevel={humidityLevel}
                viewMode={viewMode}
                data={data}
                error={error}
            />
            {viewMode &&
            viewMode.some((option) => option.toLowerCase() === 'conclusion') ? (
                <div
                    className="absolute top-0 left-0 flex flex-col gap-4 z-10"
                    style={{
                        filter: 'drop-shadow(0 0 0.25rem #ffffff)',
                    }}
                >
                    <GradientConclusionView />
                </div>
            ) : (
                <div
                    className="absolute top-0 left-0 flex flex-col gap-4 z-10"
                    style={{
                        filter: 'drop-shadow(0 0 0.25rem #ffffff)',
                    }}
                >
                    <GradientKeyViewThermometer
                        unit={isHumidity ? undefined : '°C'}
                        gradientKey={gradientKey}
                    />
                </div>
            )}
        </div>
    );
}

interface BalancingViewProps {
    objectId: number;
    controlStates: ControlState[];
}

function BalancingView(props: BalancingViewProps) {
    const { objectId, controlStates } = props;
    const { t } = useTranslation();
    const { mode, screen } = useViewport();
    const [controlStateId, setControlStateId] = useState(
        controlStates[controlStates.length - 1].state_order,
    );
    const [tableView, setTableView] = useState<'graph' | 'table'>('graph');
    const [selectedOptions, setSelectedOptions] = useState(['TEMPERATURE']);

    const { data: object } = useObject(objectId);

    //eslint-disable-next-line
    const { data: houseData, error } = use3DData(objectId, controlStateId);

    const balanceDates = {
        start: formatDate(
            new Date(controlStates[controlStateId].time_period_start),
        ),
        stop:
            controlStates[controlStateId].time_period_stop !== null
                ? formatDate(
                      new Date(controlStates[controlStateId].time_period_stop),
                  )
                : formatDate(new Date()),
    };

    const { data: balancingData } = useBalance(
        objectId,
        balanceDates?.start,
        balanceDates?.stop,
        controlStateId,
    );

    const { data: humiditityData } = useHumidity(objectId, controlStateId);
    //eslint-disable-next-line
    const [balanceFilter, setBalanceFilter] = useState<'ALL' | 'HOT' | 'COLD'>(
        'ALL',
    );
    const [threshold, setTreshold] = useState(1);
    const [humidityLevel, setHumidityLevel] = useState(0.2);
    const {
        sensors: sensorAverages,
        setSensorIds,
        sensorIds,
    } = useSensorAverage(objectId, controlStateId);
    const getSensorShortName = (sensorName: string) => {
        if (sensorName.length > 20) {
            return `...${sensorName.slice(sensorName.length - 20)}`;
        }

        return sensorName;
    };
    let transformedData = balancingData?.map((v) => {
        return {
            ...v,
            name: object?.name,
            sensorName:
                v.sensorName !== null ? v.sensorName : v.sensorId.toString(),
            shortSensorName:
                v.sensorName !== null
                    ? getSensorShortName(v.sensorName)
                    : v.sensorId.toString(),
            indoorTemp: v.indoorTemp.toFixed(1),
            deltaAverage: v.deltaAverage.toFixed(1),
            deltaAverageAbs: v.deltaAverageAbs.toFixed(1),
            indoorTempAverage: v.indoorTempAverage.toFixed(1),
            indoorTempMax: v.indoorTempMax.toFixed(1),
            indoorTempMin: v.indoorTempMin.toFixed(1),
            deltaMedian: v.deltaMedian.toFixed(1),
            noisePos: v.noisePos?.toFixed(1),
            noiseNeg: v.noiseNeg?.toFixed(1),
            rc: v.rc?.toFixed(1),
            sensorId: v.sensorId,
            isActive: v.isActive === 'TRUE',
        };
    });
    const {
        data: averageTemperatures,
    } = useAverageTemperaturesWithControlState(objectId, controlStateId);
    const [invalidate] = useAverageTemperaturesForSensor();
    const [balanceTemp, setBalanceTemp] = useState<
        TemperatureWithControlState | undefined
    >();
    const sensorIdToName = (id: number) => {
        const sensor = sensorAverages.find((sensor) => sensor.sensorId === id);
        if (sensor === undefined) {
            return '';
        }

        return sensor.sensorName;
    };
    const averageRefrence = (balanceTemp ?? averageTemperatures)?.data
        .map((v) => {
            const obj1 = {
                fromTemperature: v.fromTemperature,
                average: v.toTemperature,
                Mean:
                    threshold !== 0
                        ? [
                              (v.toTemperature - threshold).toFixed(1),
                              (v.toTemperature + threshold).toFixed(1),
                          ]
                        : v.toTemperature.toFixed(1),
            };
            const obj2: Record<string, any> = {};
            sensorAverages.forEach((avg) => {
                obj2[avg.sensorId.toString()] = avg?.data
                    .find((a: any) => a?.fromTemperature === v.fromTemperature)
                    ?.toTemperature.toFixed(1);
            });

            return Object.assign(obj1, obj2);
        })
        ?.sort((a, b) => a.fromTemperature - b.fromTemperature);
    const [referenceTemperature, setReferenceTemperature] = useState(
        averageRefrence?.[0]?.fromTemperature ?? NaN,
    );
    const onChange = (sensorId: number, isActive: boolean) => {
        invalidate(
            objectId,
            controlStateId,
            isActive,
            sensorId,
        ).then((v: TemperatureWithControlState) => setBalanceTemp(v));
    };
    const allSensors = sensorAverages.flatMap((e) =>
        e.data.map((t) => t.toTemperature),
    );

    const calculateDomain = (array: number[]) => {
        if (array.length === 0) {
            return [15, 25];
        }

        const min = Math.floor(Math.min(...array)) - 1;
        const max = Math.ceil(Math.max(...array)) + 1;

        const domain = [min > 15 ? 15 : min, max < 25 ? 25 : max];

        return domain;
    };

    let xTicks = tickGenerator(averageRefrence ?? [], 1);
    if (xTicks.length > 1) {
        xTicks = xTicks.slice(1, xTicks.length - 1);
    }

    const yDomain = calculateDomain(allSensors);

    let yTicks = tickGenerator(
        yDomain.map((value) => ({ fromTemperature: value })),
        1,
    );
    if (yTicks.length > 1) {
        yTicks = yTicks.slice(1, yTicks.length);
    }

    const avg =
        0.1 *
        Math.round(
            10 *
                (averageTemperatures?.data.find(
                    (e) => e.fromTemperature === referenceTemperature,
                )?.toTemperature ?? 21),
        );

    const getGradientKey = () => {
        if (
            selectedOptions &&
            selectedOptions.length === 1 &&
            selectedOptions.some(
                (option) => option.toLowerCase() === 'humidity',
            )
        ) {
            // Calculate average absolute humidity if humidity data exists
            let averageAbsoluteHumidity: any = undefined;
            if (humiditityData && humiditityData.length > 0) {
                const totalAbsoluteHumidity = humiditityData.reduce(
                    (sum, data) => sum + data.absoluteHumidity,
                    0,
                );
                averageAbsoluteHumidity =
                    totalAbsoluteHumidity / humiditityData.length;
            }

            if (averageAbsoluteHumidity) {
                const high =
                    averageAbsoluteHumidity * (1 + (humidityLevel ?? 0));
                const low =
                    averageAbsoluteHumidity * (1 - (humidityLevel ?? 0));

                return generateGradientKey(
                    { high, low },
                    gradients.thermometer.mix,
                );
            }
        }
        return generateGradientKey(
            { high: avg + threshold, low: avg - threshold },
            gradients.thermometer.mix,
        );
    };

    const handleCheckboxChange = (checkedValues: CheckboxValueType[]) => {
        if (checkedValues.length === 0) {
            return;
        }

        const currentCheckedValue = checkedValues[checkedValues.length - 1];

        if (currentCheckedValue === 'CONCLUSION') {
            setSelectedOptions(['CONCLUSION']);
        } else if (
            checkedValues.includes('TEMPERATURE') ||
            checkedValues.includes('HUMIDITY')
        ) {
            const filteredOptions = checkedValues.filter(
                (option) => option !== 'CONCLUSION',
            );
            setSelectedOptions(filteredOptions.map(String));
        } else {
            // it may change in future upon adding more options
            setSelectedOptions(checkedValues.map(String));
        }
    };

    useEffect(() => {
        if (
            (!humiditityData.length &&
                selectedOptions.includes('CONCLUSION')) ||
            selectedOptions.includes('HUMIDITY')
        ) {
            setSelectedOptions(['TEMPERATURE']);
        }
        //eslint-disable-next-line
    }, [controlStateId, humiditityData])

    return (
        <>
            <div className="w-full h-full flex flex-col">
                <div className="w-full gap-3 mb-5 flex-wrap flex p-2">
                    <Select
                        key={controlStateId ?? -1}
                        value={controlStateId}
                        onChange={(v) => {
                            setControlStateId(v);
                        }}
                        placeholder={t('Analysis periods')}
                        className="mb-6 w-4/5 sm:w-1/4"
                    >
                        {controlStates &&
                            controlStates.length > 0 &&
                            controlStates.map((v: any) => {
                                const startDate: any = v.time_period_start;
                                let stopDate: string | null =
                                    v.time_period_stop;
                                let period = '';
                                if (stopDate === null) {
                                    period =
                                        startDate.substring(4, 17) +
                                        '-' +
                                        t('Today');
                                } else {
                                    period =
                                        startDate.substring(4, 17) +
                                        '-' +
                                        stopDate.substring(4, 17);
                                }

                                return (
                                    <Select.Option
                                        key={v.state_order}
                                        value={v.state_order}
                                    >
                                        {period}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                    {/* <Radio.Group
                        className="w-screen sm:w-auto"
                        onChange={(v) => {
                            setBalanceFilter(v.target.value);
                        }}
                        defaultValue="ALL"
                        size="middle"
                    >
                        <Radio.Button value="ALL">{t('All')}</Radio.Button>
                        <Radio.Button value="COLD">{t('Cold')}</Radio.Button>
                        <Radio.Button value="HOT">{t('Hot')}</Radio.Button>
                    </Radio.Group> */}
                    <Checkbox.Group
                        className="w-screen sm:w-auto"
                        onChange={handleCheckboxChange}
                        defaultValue={selectedOptions}
                        value={selectedOptions}
                    >
                        <Checkbox value="TEMPERATURE">
                            {t('Temperature')}
                        </Checkbox>
                        <Checkbox
                            value="HUMIDITY"
                            disabled={
                                !(humiditityData && !!humiditityData.length)
                            }
                        >
                            {t('Humidity')}
                        </Checkbox>
                        <Checkbox
                            value="CONCLUSION"
                            disabled={
                                !(humiditityData && !!humiditityData.length)
                            }
                            style={{ color: 'red' }}
                        >
                            {t('Conclusion')}
                        </Checkbox>
                    </Checkbox.Group>

                    <PreferencesMenu
                        threshold={threshold}
                        humidityLevel={humidityLevel}
                        setThreshold={setTreshold}
                        setHumidityLevel={setHumidityLevel}
                        referenceTemperature={referenceTemperature}
                        setReferenceTemperature={setReferenceTemperature}
                        data={houseData}
                        viewMode={selectedOptions}
                        isHumidityDataAvailable={
                            humiditityData && !!humiditityData.length
                        }
                    />
                    <Suspense fallback={<></>}>
                        <DotView
                            objectId={objectId}
                            controlStateId={controlStateId}
                        />
                    </Suspense>
                </div>

                <div className="flex w-full justify-between flex-wrap flex-col h-full">
                    <div
                        className="grid flex-1"
                        style={{
                            gridTemplateColumns:
                                screen > 1600
                                    ? 'minmax(110ch, 55vw) 1fr'
                                    : '1fr',
                        }}
                    >
                        <Card className="h-full">
                            <Tabs
                                onChange={(value) => {
                                    setTableView(value as 'table' | 'graph');
                                }}
                                activeKey={tableView}
                                type="card"
                                className=""
                            >
                                <Tabs.TabPane
                                    key="graph"
                                    className="h-full"
                                    tab={t('3D')}
                                >
                                    <BalancingVisualization
                                        controlStateId={controlStateId}
                                        objectId={objectId}
                                        referenceTemperature={
                                            referenceTemperature
                                        }
                                        sensorIds={sensorIds}
                                        setReferenceTemperature={
                                            setReferenceTemperature
                                        }
                                        setSensorIds={setSensorIds}
                                        setTableView={setTableView}
                                        transformedData={transformedData}
                                        threshold={threshold}
                                        gradientKey={getGradientKey()}
                                        humidityData={humiditityData}
                                        humidityLevel={humidityLevel}
                                        viewMode={selectedOptions}
                                        data={houseData}
                                        error={error}
                                    />
                                </Tabs.TabPane>
                                {selectedOptions &&
                                    selectedOptions.some(
                                        (option) =>
                                            option.toLowerCase() ===
                                            'temperature',
                                    ) && (
                                        <Tabs.TabPane
                                            className="h-full overflow-hidden"
                                            key="table"
                                            tab={t('Table')}
                                        >
                                            <BalancingTable
                                                balanceFilter={balanceFilter}
                                                onChange={onChange}
                                                sensorIds={sensorIds}
                                                setSensorIds={setSensorIds}
                                                transformedData={
                                                    transformedData
                                                }
                                                threshold={threshold}
                                            />
                                        </Tabs.TabPane>
                                    )}
                            </Tabs>
                        </Card>
                        {selectedOptions &&
                            selectedOptions.some(
                                (option) =>
                                    option.toLowerCase() === 'temperature',
                            ) && (
                                <Card className="flex-1">
                                    {averageRefrence &&
                                        averageRefrence.length > 0 && (
                                            <ResponsiveContainer
                                                width="100%"
                                                height={
                                                    mode === 'desktop'
                                                        ? 600
                                                        : 400
                                                }
                                            >
                                                <ComposedChart
                                                    data={averageRefrence}
                                                    className="m-auto"
                                                    margin={{
                                                        top: 20,
                                                        right: 0,
                                                        left: 0,
                                                    }}
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis
                                                        unit="°C"
                                                        name={t('Degrees')}
                                                        dataKey="fromTemperature"
                                                        type="number"
                                                        domain={[
                                                            (dataMin: number) =>
                                                                dataMin,
                                                            (dataMax: number) =>
                                                                dataMax,
                                                        ]}
                                                        interval={0}
                                                        tick={
                                                            <ConditionalGraphTick />
                                                        }
                                                        ticks={xTicks}
                                                    />
                                                    <YAxis
                                                        mirror
                                                        domain={yDomain}
                                                        allowDecimals={false}
                                                        dataKey="average"
                                                        type="number"
                                                        unit="°C"
                                                        tickFormatter={(v) => {
                                                            return Math.round(
                                                                v,
                                                            ).toString();
                                                        }}
                                                        ticks={yTicks}
                                                        strokeWidth={0}
                                                    />
                                                    <Legend
                                                        wrapperStyle={{
                                                            position:
                                                                'absolute',
                                                            height: '20em',
                                                            overflowY: 'auto',
                                                        }}
                                                        layout="horizontal"
                                                        verticalAlign="bottom"
                                                        align="left"
                                                        iconType="line"
                                                        content={(props) => {
                                                            const {
                                                                payload,
                                                            } = props;

                                                            const mean = payload?.find(
                                                                (sensor) =>
                                                                    threshold !==
                                                                    0
                                                                        ? sensor.value ===
                                                                          t(
                                                                              'Mean interval',
                                                                          )
                                                                        : t(
                                                                              'Mean temperature',
                                                                          ),
                                                            );

                                                            const sensorNamesToBalancingData: Record<
                                                                string,
                                                                BalanceData
                                                            > = {};

                                                            const sorted = payload
                                                                ?.filter(
                                                                    (
                                                                        sensor,
                                                                    ) => {
                                                                        const balance = balancingData.find(
                                                                            (
                                                                                data,
                                                                            ) =>
                                                                                data.sensorName ===
                                                                                sensor.value,
                                                                        );

                                                                        if (
                                                                            balance !==
                                                                            undefined
                                                                        ) {
                                                                            sensorNamesToBalancingData[
                                                                                sensor.value
                                                                            ] = balance;
                                                                        }

                                                                        return (
                                                                            balance !==
                                                                            undefined
                                                                        );
                                                                    },
                                                                )
                                                                ?.sort(
                                                                    (a, b) =>
                                                                        -(
                                                                            sensorNamesToBalancingData[
                                                                                a
                                                                                    .value
                                                                            ]
                                                                                .rc -
                                                                            sensorNamesToBalancingData[
                                                                                b
                                                                                    .value
                                                                            ].rc
                                                                        ),
                                                                );

                                                            const RenderListItem = (props: {
                                                                name: string;
                                                                color: string;
                                                            }) => {
                                                                const {
                                                                    color,
                                                                    name,
                                                                } = props;

                                                                const balance =
                                                                    sensorNamesToBalancingData[
                                                                        name
                                                                    ];
                                                                return (
                                                                    <li
                                                                        className="recharts-legend-item legend-item-0"
                                                                        style={{
                                                                            display:
                                                                                'inline-block',
                                                                            marginRight:
                                                                                '10px',
                                                                        }}
                                                                    >
                                                                        <svg
                                                                            className="recharts-surface"
                                                                            width="14"
                                                                            height="14"
                                                                            style={{
                                                                                display:
                                                                                    'inline-block',
                                                                                verticalAlign:
                                                                                    'middle',
                                                                                marginRight:
                                                                                    '4px',
                                                                            }}
                                                                            viewBox="0 0 32 32"
                                                                        >
                                                                            <title />
                                                                            <desc />
                                                                            <path
                                                                                strokeWidth="4"
                                                                                fill="none"
                                                                                stroke={
                                                                                    color
                                                                                }
                                                                                d="M0,16h10.666666666666666
                A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
                H32M21.333333333333332,16
                A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
                                                                                className="recharts-legend-icon"
                                                                            />
                                                                        </svg>
                                                                        <span
                                                                            className="recharts-legend-item-text"
                                                                            style={{
                                                                                color: color,
                                                                            }}
                                                                        >
                                                                            {
                                                                                name
                                                                            }
                                                                            {balance !==
                                                                                undefined && (
                                                                                <>
                                                                                    ,
                                                                                    Min:{' '}
                                                                                    {balance.indoorTempMin.toFixed(
                                                                                        1,
                                                                                    )}

                                                                                    ,
                                                                                    Max:{' '}
                                                                                    {balance.indoorTempMax.toFixed(
                                                                                        1,
                                                                                    )}

                                                                                    ,
                                                                                    RC:{' '}
                                                                                    {balance.rc !==
                                                                                    null
                                                                                        ? `${balance.rc.toFixed(
                                                                                              1,
                                                                                          )}%`
                                                                                        : t(
                                                                                              'Not measured',
                                                                                          )}
                                                                                </>
                                                                            )}
                                                                        </span>
                                                                    </li>
                                                                );
                                                            };

                                                            return (
                                                                <>
                                                                    <ul
                                                                        className="recharts-default-legend"
                                                                        style={{
                                                                            padding: 0,
                                                                            margin: 0,
                                                                            textAlign:
                                                                                'left',

                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'column',
                                                                        }}
                                                                    >
                                                                        {mean !==
                                                                            undefined && (
                                                                            <RenderListItem
                                                                                name={
                                                                                    mean.value
                                                                                }
                                                                                color={
                                                                                    mean.color ??
                                                                                    '#51baa2'
                                                                                }
                                                                            />
                                                                        )}
                                                                        {sorted?.map(
                                                                            (
                                                                                entry,
                                                                            ) => (
                                                                                <RenderListItem
                                                                                    key={
                                                                                        entry.value
                                                                                    }
                                                                                    name={
                                                                                        entry.value
                                                                                    }
                                                                                    color={
                                                                                        entry.color ??
                                                                                        '#51baa2'
                                                                                    }
                                                                                />
                                                                            ),
                                                                        )}
                                                                    </ul>
                                                                </>
                                                            );
                                                        }}
                                                    />
                                                    {!Number.isNaN(
                                                        referenceTemperature,
                                                    ) && (
                                                        <ReferenceLine
                                                            x={
                                                                referenceTemperature
                                                            }
                                                            stroke="#808080"
                                                        />
                                                    )}
                                                    <GraphTooltip
                                                        formatter={(v: any) => {
                                                            return v;
                                                        }}
                                                        labelFormatter={(v) => {
                                                            return (
                                                                t(
                                                                    'At outdoor temperature',
                                                                ) +
                                                                ' ' +
                                                                v +
                                                                '°C'
                                                            );
                                                        }}
                                                    />
                                                    {threshold !== 0 ? (
                                                        <Area
                                                            unit="°C"
                                                            stroke="#51baa2"
                                                            fill="#51baa2"
                                                            dataKey={'Mean'}
                                                            name={t(
                                                                'Mean interval',
                                                            )}
                                                            opacity={0.5}
                                                        />
                                                    ) : (
                                                        <Line
                                                            stroke="#51baa2"
                                                            strokeWidth={3}
                                                            name={t(
                                                                'Mean temperature',
                                                            )}
                                                            unit="°C"
                                                            dataKey={'Mean'}
                                                        />
                                                    )}
                                                    {sensorIds.map((id, i) => {
                                                        return (
                                                            <Line
                                                                stroke={getGradientColorForTemperature(
                                                                    sensorAverages.find(
                                                                        (e) =>
                                                                            e.sensorId ===
                                                                            id,
                                                                    )?.data ??
                                                                        [],
                                                                    averageTemperatures?.data ??
                                                                        [],
                                                                    referenceTemperature,
                                                                    getGradientKey(),
                                                                    threshold,
                                                                )}
                                                                strokeWidth={3}
                                                                name={sensorIdToName(
                                                                    id,
                                                                )}
                                                                unit="°C"
                                                                dataKey={id}
                                                                key={id}
                                                            />
                                                        );
                                                    })}
                                                </ComposedChart>
                                            </ResponsiveContainer>
                                        )}
                                </Card>
                            )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default BalancingView;
