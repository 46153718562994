import { Reducer, useEffect, useReducer } from 'react';
import { Button, Card, Col, Form, Input, message, Modal, Row } from 'antd';
import logoSrc from 'src/assets/Logo.svg';
import { useTranslation } from 'react-i18next';
import { useSignIn } from 'src/hooks/users/useSignIn';
import { useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useForgotPassword } from 'src/hooks/users/useForgotPassword';

type FormValues = {
    username?: string;
    password?: string;
};

type Action =
    | { readonly type?: 'request' }
    | { readonly type?: 'reset' }
    | { readonly type?: 'loading' }
    | { readonly type?: 'success'; readonly username?: any }
    | { readonly type?: 'failure'; readonly requestError?: string };

type State = {
    signedIn?: boolean;
    username?: string;
    loading?: boolean;
    requestError?: string;
};

const reducer: Reducer<State, Action> = (
    state: State,
    action: Action,
): State => {
    switch (action.type) {
        case 'reset':
            return {
                ...state,
                loading: false,
                signedIn: undefined,
                requestError: undefined,
            };

        case 'failure':
            return {
                ...state,
                loading: false,
                signedIn: undefined,
                requestError: action.requestError,
            };
        case 'loading':
            return {
                ...state,
                requestError: undefined,
                signedIn: undefined,
                loading: true,
            };
        case 'success':
            return {
                ...state,
                loading: false,
                signedIn: true,
                username: action.username,
                requestError: undefined,
            };
    }
    return state;
};

interface IProps {
    onFail: (message: string, type: 'REQUEST_ERROR') => void;
    onSuccess: (token: string) => void;
}

function SignInView(props: IProps) {
    const { t } = useTranslation();
    const { onFail, onSuccess } = props;
    const [signIn] = useSignIn();

    const [passwordForm] = useForm();
    const initialState: State = { loading: false, signedIn: false };
    const [state, dispatch] = useReducer(reducer, initialState);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [forgotPassword] = useForgotPassword();
    const { loading, requestError } = state;

    useEffect(() => {
        if (requestError) {
            onFail(requestError, 'REQUEST_ERROR');
            dispatch({ type: 'reset' });
        }
    }, [requestError, onFail]);

    const onSubmit = (form: FormValues) => {
        dispatch({ type: 'loading' });

        if (form.username && form.password) {
            signIn(form.username, form.password)
                .then((token) => {
                    onSuccess(token);
                    dispatch({ type: 'success', username: form.username });
                })
                .catch((e) => {
                    dispatch({ type: 'failure', requestError: e.message });
                });
        }
    };

    type PasswordChangeForm = {
        email: string;
    };

    const onPasswordChange = (form: PasswordChangeForm) => {
        forgotPassword(form.email)
            .then(() => {
                message.success(t('Instructions sent to email'));
            })
            .finally(() => {
                setShowForgotPassword(false);
            });
    };

    return (
        <>
            <Modal
                title={t('Contact customer support')}
                onCancel={() => setShowForgotPassword(false)}
                onOk={() => {
                    passwordForm.submit();
                }}
                visible={showForgotPassword}
            >
                <div className="w-full md:w-96 m-auto">
                    <Form
                        form={passwordForm}
                        layout="vertical"
                        onFinish={onPasswordChange}
                    >
                        <Form.Item
                            name="email"
                            required
                            label={t('Enter email')}
                        >
                            <Input type="email" />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <Card className="w-full h-full">
                <div className="p-2">
                    <div className="w-full p-10 flex h-full">
                        <img
                            className="w-full"
                            src={logoSrc}
                            alt="Nrlyze logo"
                        />
                    </div>

                    <Form layout="vertical" onFinish={onSubmit}>
                        <Form.Item
                            rules={[
                                {
                                    message: 'Email is required',
                                    required: true,
                                },
                            ]}
                            label={t('Email')}
                            name="username"
                        >
                            <Input
                                placeholder="example@gmail.com"
                                type="email"
                            />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    message: 'Password is required',
                                    required: true,
                                },
                            ]}
                            required
                            label={t('Password')}
                            name="password"
                        >
                            <Input type="password" placeholder="********" />
                        </Form.Item>

                        <Row className="mt-20 mb-10" gutter={[20, 0]}>
                            <Col span={12}>
                                <Button
                                    onClick={() => setShowForgotPassword(true)}
                                    size="large"
                                    block
                                    className="float-right"
                                    type="default"
                                >
                                    {t('Reset password')}
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    loading={loading}
                                    size="large"
                                    block
                                    htmlType="submit"
                                    className="float-right"
                                    type="primary"
                                >
                                    {t('Sign in')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Card>
        </>
    );
}

export default SignInView;
