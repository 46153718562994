import { useState } from 'react';
import { LocalDB } from 'src/api/LocalDB';
import { Warning } from 'src/models/Warning';
import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';

export const useWarnings = () => {
    return useSWR<Warning[]>(`/warnings`, swrFetch);
};

export const useClosedWarnings = () => {
    const [closedWarnings, setClosedWarnings] = useState(
        LocalDB.getValue('closedWarnings'),
    );

    const update = (warnings: Warning[]) => {
        LocalDB.setValue('closedWarnings', warnings);
        setClosedWarnings(warnings);
    };

    return [update, closedWarnings] as const;
};
