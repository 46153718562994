import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedKeysState {
    selectedKeys: string[];
}

const initialState: SelectedKeysState = {
    selectedKeys: [],
};

const selectedKeysSlice = createSlice({
    name: 'selectedKeys',
    initialState,
    reducers: {
        setSelectedKeys: (state, action: PayloadAction<string[]>) => {
            state.selectedKeys = action.payload;
        },
        clearSelectedKeys: (state) => {
            state.selectedKeys = [];
        },

        addToExistingKeys: (state, action: PayloadAction<string[]>) => {
            const selectedKeysSet = new Set(state.selectedKeys);
            action.payload.forEach((key) => selectedKeysSet.add(key));
            state.selectedKeys = Array.from(selectedKeysSet);
        },

        excludeExistingKeys: (state, action: PayloadAction<string[]>) => {
            const selectedKeysSet = new Set(state.selectedKeys);
            action.payload.forEach((key) => selectedKeysSet.delete(key)); // Use delete to exclude keys
            state.selectedKeys = Array.from(selectedKeysSet);
        },
    },
});

export const {
    setSelectedKeys,
    clearSelectedKeys,
    addToExistingKeys,
    excludeExistingKeys,
} = selectedKeysSlice.actions;
export const selectSelectedKeys = (state: {
    selectedKeys: SelectedKeysState;
}) => state.selectedKeys.selectedKeys;
export default selectedKeysSlice.reducer;
