import { API } from 'src/api/API';
import { User } from 'src/models/User';
import { useUsers } from './useUsers';

export const useAddUser = () => {
    const { data = [], mutate } = useUsers();
    const add = async (
        corporationId: number,
        user: Omit<User, 'id'>,
    ): Promise<User> => {
        return new API().addUser(corporationId, user).then((user) => {
            mutate([...data, user]);
            return user;
        });
    };

    return [add] as const;
};
