import { API } from 'src/api/API';
import { User } from 'src/models/User';

export const useSignUpUser = () => {
    const add = async (
        user: Omit<User, 'id'> & { password: string },
    ): Promise<User> => {
        return new API().signUpUser(user);
    };

    return [add] as const;
};
