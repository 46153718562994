import { API } from 'src/api/API';
import { CreateDeviceDTO, Device } from 'src/models/Device';
import { mutate } from 'swr';

export const useAddDevice = () => {
    const add = (
        objectId: number,
        device: CreateDeviceDTO,
    ): Promise<Device> => {
        return new API().addDevice(objectId, device).then((v) => {
            mutate(`/objects/${objectId}/devices`);
            return v;
        });
    };
    return [add] as const;
};
