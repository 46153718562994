import { useMe } from './useMe';

export const useIsSignedIn = () => {
    const { isValidating, mutate, error } = useMe();

    return {
        data: error === undefined,
        isValidating,
        mutate,
        error,
    };
};
