import { useNavigate, useParams } from 'react-router-dom';
import { useObject } from 'src/hooks/objects/useObject';
import { useSites } from 'src/hooks/users/useSites';
import Nav from 'src/utils/nav/Nav';
import ChangeSensorView from 'src/views/sites/ChangeSensorView';

function ChangeSensorPage() {
    const {
        objectId: objectIdString,
        prevSensorId: prevSensorIdString,
    } = useParams<{
        objectId: string;
        prevSensorId: string;
    }>();
    const objectId = Number.parseInt(objectIdString!);
    const prevSensorId = Number.parseInt(prevSensorIdString!);
    const history = useNavigate();
    const { data: object } = useObject(objectId);
    const { data: sites } = useSites();

    const site = sites?.filter((v) =>
        v.objects?.find((o) => o.id === object!.id),
    )[0];

    return (
        <>
            {object !== undefined && site !== undefined && (
                <ChangeSensorView
                    onSuccess={() => {
                        history(
                            Nav.build({
                                path: '/manage-object',
                                queryParams: {
                                    index: '1',
                                    objectId: objectId.toString(),
                                    siteId: object.siteId.toString(),
                                    siteName: object.siteName,
                                },
                            }),
                        );
                    }}
                    object={object}
                    site={site}
                    prevSensorId={prevSensorId}
                />
            )}
        </>
    );
}

export default ChangeSensorPage;
