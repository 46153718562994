import { SiteObject } from 'src/models/SiteObject';
import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';

export const useObject = (objectId: number | string) => {
    return useSWR<SiteObject>(
        objectId !== -1 ? `/objects/${objectId}` : null,
        swrFetch,
        {
            suspense: false,
        },
    );
};
