import { API } from 'src/api/API';
import { Integration } from 'src/models/Integration';
export const useUpdateIntegration = () => {
    const updateIntegration = (
        objectId: number,
        integratorId: number,
        credentials?: any,
    ): Promise<Integration> => {
        return new API().updateIntegration(objectId, integratorId, credentials);
    };
    return [updateIntegration] as const;
};
