import mock from 'src/api/Mock';
import { Auth } from 'aws-amplify';
import { API } from 'src/api/API';

const shouldMock = false;
const _fetch = async (
    input: string,
    init: RequestInit | undefined = { headers: {} },
) => {
    const cus = await Auth.currentSession().catch(() => null);

    let headers = {
        ...init.headers,
    };

    if (input.startsWith('http')) {
        return fetch(input).then(async (v) => {
            if (!v.ok) {
                throw new Error((await v.json()).message);
            }
            return v.json();
        });
    }

    if (cus && cus.isValid()) {
        const authorization = cus
            ? `Bearer ${cus.getAccessToken().getJwtToken()}`
            : '';

        headers = {
            ...headers,
            authorization,
        };
    }

    return fetch(API.getURL(input), {
        ...init,
        headers,
    }).then(async (v) => {
        if (!v.ok) {
            // Gets the message which all errors
            // should have!
            throw new Error((await v.json()).message);
        }

        return v.json();
    });
};

export const swrFetch = (input: string, init?: RequestInit | undefined) => {
    if (shouldMock) {
        return mock.mock(input, init);
    } else {
        return _fetch(input, init);
    }
};
