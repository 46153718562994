import {
    Form,
    Input,
    Button,
    Row,
    Col,
    Typography,
    message,
    Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { addSensorType } from 'src/store/reducers/sensorsSlice';
import { useDispatch } from 'react-redux';
import { CreateSensorDTO, SensorType } from 'src/models/Sensor';
import { useAddSensor } from 'src/hooks/objects/useAddSensor';
import { showError } from 'src/utils/showError';
import Topbar from '../generic/Topbar';
import Nav from 'src/utils/nav/Nav';
import { useNavigate } from 'react-router-dom';
import { SensorTypeSelector } from 'src/components/SensorTypeSelector';
import { useCheckSensorCollisions } from 'src/hooks/objects/useCheckSensorCollisions';
import { SiteObject } from 'src/models/SiteObject';

import { useSelector } from 'react-redux';
import { selectSensorNames } from 'src/store/reducers/sensorNamesSlice';

type Props = {
    object: SiteObject;
    onSuccess(): void;
};
function AddSensorView(props: Props) {
    const [form] = Form.useForm();
    const { object, onSuccess } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    // const [scanActive, setScanActive] = useState(false);
    const [add] = useAddSensor();
    const [checkCollisions] = useCheckSensorCollisions();
    const history = useNavigate();
    const [sensorType, setSensorType] = useState(
        SensorType.RESPONSE_TEMPERATURE,
    );
    const [showCollisionWarning, setShowCollisionWarning] = useState(false);
    const [sensorToBeCreated, setSensorToBeCreated] = useState<
        CreateSensorDTO | undefined
    >(undefined);

    let forbiddenSensors;
    forbiddenSensors = [
        SensorType.NONE,
        SensorType.SMHI_OBSERVED_OUTDOOR_TEMPERATURE,
    ];

    const sensorNames = useSelector(selectSensorNames) || [];

    const addSensor = (tempSensor: CreateSensorDTO) => {
        add(object.id, tempSensor)
            .then(() => {
                message.success(t('Succesfully created new sensor!'));
                if (
                    tempSensor.sensorFunctionTypeId !==
                    SensorType.RESPONSE_TEMPERATURE
                ) {
                    dispatch(addSensorType(tempSensor.sensorFunctionTypeId));
                }
                onSuccess();
            })
            .catch((er) => {
                showError(t('Not able to add new sensor'), er.message);
            });
    };

    const handleOnSubmit = async (v: any) => {
        const tempSensor: CreateSensorDTO = {
            mac: v.id,
            name: v.name,
            isActive: true,
            objectId: object.id,
            sensorFunctionTypeId: sensorType,
        };

        if (tempSensor.name) {
            if (sensorNames?.entities.includes(tempSensor.name.trim())) {
                return showError(
                    t(
                        `Sensor name already registered on object. Edit it to make changes. Registration cancelled.`,
                    ),
                    '',
                );
            }
        }

        const collisions = await checkCollisions(object.id, tempSensor.mac);

        if (collisions.collisionsOnObject.length > 0) {
            return showError(
                t(
                    `Sensor already registered on object as ${collisions.collisionsOnObject[0].name}. Edit it to make changes. Registration cancelled.`,
                ),
                '',
            );
        }

        if (collisions.hasCollisions) {
            setShowCollisionWarning(true);
            setSensorToBeCreated(tempSensor);
        } else {
            addSensor(tempSensor);
        }
    };

    return (
        <>
            <Modal
                closable={false}
                footer={null}
                visible={showCollisionWarning}
            >
                <Typography.Paragraph strong>Warning</Typography.Paragraph>
                <Typography.Paragraph>
                    {t('This sensor is already registered on another object.')}
                </Typography.Paragraph>
                <Typography.Paragraph>
                    {t(
                        'Select Reuse to use it both on this and previous object(s).',
                    )}
                </Typography.Paragraph>
                <Typography.Paragraph>
                    {t(
                        'Select Move to use it on current object only, removing it from previous object(s).',
                    )}
                </Typography.Paragraph>
                {/* <Typography.Paragraph italic type="secondary">
                    {t(
                        'If either method is not allowed it means this sensor is already registered on this object or an object that you do not have access to.',
                    )}
                </Typography.Paragraph> */}
                <div className="flex justify-between gap-4">
                    <Button
                        onClick={() => {
                            setShowCollisionWarning(false);
                        }}
                    >
                        {t('Cancel')}
                    </Button>
                    <div className="flex gap-4">
                        <Button
                            type="primary"
                            onClick={() => {
                                const sensor = sensorToBeCreated;
                                if (sensor !== undefined) {
                                    sensor.onCollision = 'REUSE_OLD';

                                    addSensor(sensor);
                                }
                            }}
                        >
                            {t('Reuse')}
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                const sensor = sensorToBeCreated;
                                if (sensor !== undefined) {
                                    sensor.onCollision = 'REPLACE_OLD';

                                    addSensor(sensor);
                                }
                            }}
                        >
                            {t('Move')}
                        </Button>
                    </div>
                </div>
            </Modal>

            <div className="relative">
                <div className="w-screen p-10 h-screen">
                    <Typography.Title className="text-center" level={4}>
                        {t('Create sensor')}
                    </Typography.Title>
                    <Row align="middle" justify="center" gutter={[20, 20]}>
                        <Col lg={8} md={12} xs={24}>
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={handleOnSubmit}
                            >
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: t('Sensor name required'),
                                        },
                                    ]}
                                    label={t('Sensor name')}
                                    name="name"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    initialValue={sensorType}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('Sensor type required'),
                                        },
                                    ]}
                                    label={t('Sensor type')}
                                    name="type"
                                >
                                    <SensorTypeSelector
                                        forbidenSensors={
                                            (forbiddenSensors as SensorType[]) ?? [
                                                0,
                                            ]
                                        }
                                        defaultValue={sensorType}
                                        onChange={setSensorType}
                                    />
                                </Form.Item>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: t('Sensor id is required'),
                                            validator(_, value: string) {
                                                if (
                                                    value.length === 16 &&
                                                    value.match(
                                                        /[0-9A-Fa-f]{16}/,
                                                    )
                                                ) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(
                                                    new Error(
                                                        'Something went wrong, try again!',
                                                    ),
                                                );
                                            },
                                        },
                                    ]}
                                    label={t('Sensor id')}
                                    name="id"
                                >
                                    <Input placeholder="216454363530584742" />
                                </Form.Item>
                                <Button
                                    className="mt-3"
                                    block
                                    size="large"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {t('Add sensor')}
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </div>
                <div className="fixed bottom-0 w-screen">
                    <div id="go-back-button" className="w-full mb-5 flex">
                        <Button
                            onClick={() => {
                                history(
                                    Nav.build({
                                        path: '/manage-object',
                                        queryParams: {
                                            index: '1',
                                            objectId: object.id.toString(),
                                            siteId: object.siteId.toString(),
                                            siteName: object.siteName,
                                        },
                                    }),
                                );
                            }}
                            type="primary"
                            className="m-auto"
                        >
                            {t('Go back')}
                        </Button>
                    </div>
                    <Topbar active="sites" />
                </div>
            </div>
        </>
    );
}

export default AddSensorView;
