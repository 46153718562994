import { Integration } from 'src/models/Integration';
import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';
export const useIntegration = (objectId: number, integrationId: number) => {
    return useSWR<Integration>(
        `/objects/${objectId}/integrations/${integrationId}`,
        swrFetch,
        {
            suspense: true,
        },
    );
};
