import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import Nav from 'src/utils/nav/Nav';
import ObjectsView from 'src/views/objects/ObjectsView';
import { SettingFilled } from '@ant-design/icons';
import { useSite } from 'src/hooks/sites/useSite';

function ObjectsPage() {
    const { siteId: siteIdString } = useParams<{ siteId: string }>();
    const siteId = Number.parseInt(siteIdString!);
    const { data: site } = useSite(siteId);

    const { t } = useTranslation();
    const history = useNavigate();

    return (
        <>
            {site ? (
                <ObjectsView
                    site={site}
                    tabBarExtraContent={
                        <>
                            <div className="flex items-center gap-x-2">
                                <Button
                                    type="primary"
                                    onClick={() =>
                                        history(
                                            Nav.build({
                                                path: '/manage-object',
                                                queryParams: {
                                                    siteId: siteIdString,
                                                },
                                            }),
                                        )
                                    }
                                    className="float-right"
                                >
                                    {t('Add object')}
                                </Button>
                                <SettingFilled
                                    onClick={() =>
                                        history(
                                            Nav.build({
                                                path: '/manage-site',
                                                queryParams: {
                                                    siteName: site.name,
                                                    siteId: siteIdString,
                                                },
                                            }),
                                        )
                                    }
                                    className="text-xl cursor-pointer"
                                />
                            </div>
                        </>
                    }
                />
            ) : null}
        </>
    );
}

export default ObjectsPage;
