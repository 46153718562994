import { useState } from 'react';
import { API } from 'src/api/API';
import { SensorType, Sensor } from 'src/models/Sensor';
import {
    setSelectedKeys,
    clearSelectedKeys,
} from 'src/store/reducers/selectedKeysSlice';
import { TreeDataNode, selectTreeData } from 'src/store/reducers/treeSlice';
import { clearSelectedFloors } from 'src/store/reducers/selectedObjectSlice';
import { useDispatch, useSelector } from 'react-redux';

const findNodeByLastSegment = (
    node: TreeDataNode,
    targetSuffix: string,
): TreeDataNode | null => {
    const nodeKeySegments = String(node.key).split('-');
    const nodeLastSegment = `${nodeKeySegments.slice(-2).join('-')}`;

    if (nodeLastSegment === targetSuffix) {
        return node;
    }

    if (node.children) {
        for (const child of node.children) {
            const foundNode = findNodeByLastSegment(child, targetSuffix);
            if (foundNode) {
                return foundNode;
            }
        }
    }

    return null;
};

const findNodeByLastSegmentInTree = (
    allTreeData: TreeDataNode[],
    targetSuffix: string,
): TreeDataNode | null => {
    for (const node of allTreeData) {
        const foundNode = findNodeByLastSegment(node, targetSuffix);
        if (foundNode) {
            return foundNode;
        }
    }

    return null;
};

export interface RealTimeDataPoint {
    date: string;
    timestamp: number;
    timeString: string;
    value: number;
}

export interface RealTimeResponse extends Sensor {
    data: RealTimeDataPoint[];
}

export const useRealTimeFetch = () => {
    const [data, setData] = useState<{
        data1: RealTimeResponse[];
        data2: RealTimeResponse[];
        data3: RealTimeResponse[] | undefined;
        avgObjData: any | undefined;
    }>({
        data1: [],
        data2: [],
        data3: undefined,
        avgObjData: undefined,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [hotColdFlag, setHotColdFlag] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const dispatch = useDispatch();
    const treeData = useSelector(selectTreeData);

    const fetchData = async (
        id: number,
        start: string,
        stop: string,
        type1: SensorType,
        type2: SensorType,
        type3: SensorType | undefined,
    ) => {
        const api = new API();
        setIsLoading(true);

        try {
            const promises = [
                api.fetchRealTimeWithAvgObj(id, start, stop, type1),
                api.fetchRealTime(id, start, stop, type2),
            ];

            if (type3 !== undefined) {
                promises.push(api.fetchRealTime(id, start, stop, type3));
            } else {
                promises.push(Promise.resolve([])); // Add an empty promise if type3 is undefined
            }

            promises.push(api.fetchHotNCold(id, start, stop, type1));

            const [data1, data2, data3 = [], hotNCold] = await Promise.all(
                promises,
            );

            if (data1.measured_data && data1.measured_data.length > 10) {
                const selectedKeys: string[] = [];

                if (hotNCold.hot && hotNCold.hot.length) {
                    selectedKeys.push(
                        ...(hotNCold.hot.map((it: any) => it.id + '-' + id) ||
                            []),
                    );
                }

                if (hotNCold.cold && hotNCold.cold.length) {
                    selectedKeys.push(
                        ...(hotNCold.cold.map((it: any) => it.id + '-' + id) ||
                            []),
                    );
                }

                const newSelectedKeys = [];

                for (let selectedKey of selectedKeys) {
                    const node = findNodeByLastSegmentInTree(
                        treeData,
                        selectedKey,
                    );
                    if (node && node.key) {
                        newSelectedKeys.push(node.key);
                    }
                }

                dispatch(setSelectedKeys(newSelectedKeys));

                setHotColdFlag(true);

                const dataToPush = [];

                if (data1.avgTemp && data1.avgTemp.length) {
                    dataToPush?.push({
                        id: Math.floor(Math.random() * 9000000) + 1000000,
                        name: 'Medeltemperatur',
                        sensorFunctionTypeId: -1,
                        data: data1.avgTemp,
                    });
                }

                setData({
                    data1: hotNCold.hot.concat(hotNCold.cold),
                    data2,
                    data3,
                    avgObjData: dataToPush,
                });
            } else {
                const dataToPush = [];

                if (data1.avgTemp && data1.avgTemp.length) {
                    dataToPush?.push({
                        id: Math.floor(Math.random() * 9000000) + 1000000,
                        name: 'Medeltemperatur',
                        sensorFunctionTypeId: -1,
                        data: data1.avgTemp,
                    });
                }
                dispatch(clearSelectedKeys());
                setData({
                    data1: data1.measured_data || [],
                    data2,
                    data3,
                    avgObjData: dataToPush,
                });
            }

            setIsLoading(false);
            dispatch(clearSelectedFloors());
        } catch (error) {
            // Handle error here
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        data,
        hotColdFlag,
        searchActive,
        setHotColdFlag,
        setData,
        setSearchActive,
        setIsLoading,
        fetchData,
    };
};
