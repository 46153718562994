import { API } from 'src/api/API';
import { useHasMetryIntegration } from './useHasMetryIntegration';

export const useRemoveMetryIntegration = () => {
    const { mutate } = useHasMetryIntegration();
    const remove = (corporationId: number): Promise<void> => {
        return new API().removeMetry(corporationId).then((v) => {
            mutate();
            return v;
        });
    };
    return [remove] as const;
};
