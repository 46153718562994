export default function validateFloatNumber(
    value: string,
): Promise<number | null> {
    return new Promise((resolve, reject) => {
        if (value === null || value === '') {
            resolve(null);
            return;
        }

        let i = 0;
        while (i < value.length) {
            if (value.charAt(i).match(/[0-9,.]/) === null) {
                reject();
                return;
            }
            ++i;
        }

        resolve(Number(value.replace ? value.replace(',', '.') : value));
    });
}
