import { message } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { useVerifyEmail } from 'src/hooks/users/useVerifyEmail';
import { useQueryParams, StringParam } from 'use-query-params';

function VerifyEmail() {
    const [params] = useQueryParams({
        code: StringParam,
        email: StringParam,
    });

    const { t } = useTranslation();
    const [verify] = useVerifyEmail();

    useEffect(() => {
        if (params.email && params.code)
            verify(params.email, params.code)
                .then(() => {
                    message.success(t('Verified email'));
                })
                .catch(() => {
                    message.error(t('Not able to verify email'));
                });
    }, [params, verify, t]);

    return <Navigate to="/home" />;
}

export default VerifyEmail;
