import { API } from 'src/api/API';
import { mutate } from 'swr';
export const useInitMetry = () => {
    const init = (
        corporationId: number,
        code: string,
        redirectUrl: string,
    ): Promise<void> => {
        return new API()
            .initMetry(corporationId, code, redirectUrl)
            .then((v) => {
                mutate(
                    `external/metry/corporations/${corporationId}/integration_exists`,
                );
                return v;
            });
    };
    return [init] as const;
};
