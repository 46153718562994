import { API } from 'src/api/API';

interface CollisionOnObject {
    id: number;
    name: string;
}

interface Collisions {
    hasCollisions: boolean;
    collisionsOnObject: CollisionOnObject[];
    collisionsOutsideUserAccess: boolean;
}

export const useCheckSensorCollisions = () => {
    const checkSensorCollisions = (
        objectId: number,
        mac: string,
    ): Promise<Collisions> => {
        return new API().checkSensorCollisions(mac, objectId);
    };
    return [checkSensorCollisions] as const;
};
