import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';
import { V3D_Object } from '../3d/chart/types';
import { useState } from 'react';

const useSite3DData = (siteId: number) => {
    const [isLoading, setIsLoading] = useState(true);
    const { data, error } = useSWR<V3D_Object[], any>(
        `sites/${siteId}/visualization`,
        async () => {
            try {
                const response = await swrFetch(
                    `sites/${siteId}/visualization`,
                );
                setIsLoading(false);
                return response;
            } catch (error) {
                setIsLoading(false);
                throw error;
            }
        },
        {
            suspense: false,
        },
    );

    return {
        data,
        isLoading,
        isError: error,
    };
};

export default useSite3DData;
