import App from './App';
import React, { Suspense } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.scss';
import 'antd/dist/antd.less';
import 'src/utils/i18n/index';
import { Amplify } from 'aws-amplify';
import { env } from './utils/env';
import { QueryParamProvider } from 'use-query-params';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { SWRConfig } from 'swr';

// Store
import store from 'src/store';

export const browserHistory = createBrowserHistory();

Amplify.configure({
    aws_user_pools_web_client_id: env.REACT_APP_COGNITO_CLIENT_ID,
    aws_user_pools_id: env.REACT_APP_COGNITO_USERPOOL_ID,
});

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <SWRConfig value={{ revalidateOnFocus: false }}>
                <Suspense fallback={null}>
                    <BrowserRouter>
                        <QueryParamProvider adapter={ReactRouter6Adapter}>
                            <App />
                        </QueryParamProvider>
                    </BrowserRouter>
                </Suspense>
            </SWRConfig>
        </Provider>
    </React.StrictMode>,
);
