import { useNavigate, useParams } from 'react-router-dom';
import { useObject } from 'src/hooks/objects/useObject';
import Nav from 'src/utils/nav/Nav';
import AddDeviceView from 'src/views/objects/AddDeviceView';

function AddDevicePage() {
    const { objectId: objectIdString } = useParams<{
        objectId: string;
    }>();
    const objectId = Number.parseInt(objectIdString!);
    const { data: object } = useObject(objectId);
    const history = useNavigate();
    return (
        <>
            {object !== undefined && (
                <AddDeviceView
                    onSuccess={() => {
                        history(
                            Nav.build({
                                path: '/manage-object',
                                queryParams: {
                                    index: '1',
                                    objectId: objectId.toString(),
                                    siteId: `${object.siteId}`,
                                    siteName: object.siteName,
                                },
                            }),
                        );
                    }}
                    objectId={objectId}
                    siteId={object.siteId}
                    siteName={object.siteName}
                />
            )}
        </>
    );
}

export default AddDevicePage;
