import React from 'react';
import { useLocation } from 'react-router-dom';
import Layout from 'src/components/Layout';
import Topbar from 'src/views/generic/Topbar';
import Sidebar from 'src/views/generic/Sidebar';
import { useIsSignedIn } from 'src/hooks/users/useIsSignedIn';
import HeaderWithProps from './HeaderWithProps';

interface LayoutWrapperProps {
    children: React.ReactNode;
}

function LayoutWrapper({ children }: LayoutWrapperProps) {
    const location = useLocation();

    const { data: isSignedIn } = useIsSignedIn();

    let active = undefined;
    if (location.pathname.includes('home')) {
        active = 'home';
    } else if (
        location.pathname.includes('settings') ||
        location.pathname.includes('corporations')
    ) {
        active = 'settings';
    } else if (
        location.pathname.includes('sites') ||
        location.pathname.includes('manage-object') ||
        location.pathname.includes('manage-site') ||
        location.pathname.includes('create-site') ||
        location.pathname.includes('integrations') ||
        location.pathname.includes('analytics')
    ) {
        active = 'sites';
    } else if (location.pathname.includes('users')) {
        active = 'users';
    } else if (location.pathname.includes('tree')) {
        active = 'tree';
    }

    return active && isSignedIn ? (
        <Layout
            topbar={<Topbar active={active} />}
            header={<HeaderWithProps active={active} />}
            sidebar={<Sidebar active={active} />}
        >
            {children}
        </Layout>
    ) : (
        <>{children}</>
    );
}

export default LayoutWrapper;
