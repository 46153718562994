import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

import SignInView from 'src/views/users/SignInView';
import background from 'src/assets/LoginBackground.svg';
import { useIsSignedIn } from 'src/hooks/users/useIsSignedIn';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Nav from 'src/utils/nav/Nav';

export default function SignIn() {
    const { t } = useTranslation();
    const { data, isValidating, mutate } = useIsSignedIn();
    const history = useNavigate();

    useEffect(() => {
        if (!isValidating && data) {
            history(Nav.build({ path: '/home' }));
        }
    }, [isValidating, data, history]);

    return (
        <div
            className="flex h-screen w-screen"
            style={{
                backgroundSize: 'cover',
                backgroundImage: `url(${background})`,
            }}
        >
            <div className="w-screen h-screen  md:h-auto sm:w-96 m-auto">
                <SignInView
                    onFail={(msg) => {
                        notification.open({
                            message: t('Unable to sign in'),
                            type: 'error',
                            description: t(msg),
                            placement: 'bottomRight',
                        });
                    }}
                    onSuccess={() => {
                        mutate();
                    }}
                />
            </div>
        </div>
    );
}
