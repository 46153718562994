import { EnergyResponse } from 'src/models/EnergyResponse';
import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';

export const useEnergyConsumption = (
    id: number,
    start: string | null,
    stop: string | null,
) => {
    return useSWR<EnergyResponse[]>(
        `/objects/${id}/energy?interval=MONTHLY&start=${start}&stop=${stop}`,
        swrFetch,
        { suspense: true },
    );
};
