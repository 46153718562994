export enum UserRoles {
    'ADMIN' = 'ADMIN',
    'DEFAULT' = 'DEFAULT',
}
export interface User {
    email: string;
    name: string;
    id: number;
    role: UserRoles;
}
