import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const colorLabels = [
    'Normal',
    'Poor Ventilation',
    'High Ventilation',
    'Probably High Feed Capacity',
    'Definitely High Feed Capacity',
    'Probably Low Capacity',
    'WaterLeak',
];

const colorStatus = [
    '#389e0d',
    '#7E350E',
    '#215C98',
    '#FFC000',
    '#FF0000',
    '#94DCF8',
    '#7030A0',
];

function GradientConclusionView() {
    const { t } = useTranslation();

    return (
        <div
            className="flex gap-2"
            style={{ height: '200px', alignItems: 'stretch' }}
        >
            <div className="flex flex-col justify-between rounded-full overflow-hidden">
                {colorStatus.map((color, i) => (
                    <div
                        className="w-4 flex-1"
                        key={i}
                        style={{
                            backgroundColor: color,
                        }}
                    />
                ))}
            </div>
            <div className="flex flex-col justify-between" style={{ flex: 1 }}>
                {colorLabels.map((label, i) => (
                    <Typography.Text
                        // type={
                        //     i !== gradientKey.middle ? 'secondary' : undefined
                        // }
                        style={{ width: 'max-content' }}
                        key={i}
                    >
                        {t(label)}
                    </Typography.Text>
                ))}
            </div>
        </div>
    );
}

export { GradientConclusionView };
