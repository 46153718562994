// selectedSensorsSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedSensorsState {
    [objectId: string]: number[];
}

const initialState: SelectedSensorsState = {};

const selectedSensorsSlice = createSlice({
    name: 'selectedSensors',
    initialState,
    reducers: {
        setSelectedSensors: (
            state,
            action: PayloadAction<{ objectId: string; sensorIds: number[] }>,
        ) => {
            const { objectId, sensorIds } = action.payload;
            state[objectId] = [...sensorIds];
        },

        addToExistingSensors: (
            state,
            action: PayloadAction<{ objectId: string; sensorIds: number[] }>,
        ) => {
            const { objectId, sensorIds } = action.payload;
            const selectedSensorsSet = new Set(state[objectId]);
            sensorIds.forEach((key) => selectedSensorsSet.add(key));
            state[objectId] = Array.from(selectedSensorsSet);
        },

        deleteExistingSensors: (
            state,
            action: PayloadAction<{ objectId: string; sensorIds: number[] }>,
        ) => {
            const { objectId, sensorIds } = action.payload;
            const selectedSensorsSet = new Set(state[objectId]);
            sensorIds.forEach((key) => selectedSensorsSet.delete(key));
            state[objectId] = Array.from(selectedSensorsSet);
        },
    },
});

export const {
    setSelectedSensors,
    addToExistingSensors,
    deleteExistingSensors,
} = selectedSensorsSlice.actions;
export default selectedSensorsSlice.reducer;
