import { useIsSignedIn } from 'src/hooks/users/useIsSignedIn';
import SignIn from 'src/pages/SignIn';

interface IProps {
    children: React.ReactNode;
}

const AuthenticatedRoute = (props: IProps) => {
    const { data: isSignedIn } = useIsSignedIn();

    return (
        <>
            {/* {isValidating && <LoadingPage />} */}
            {isSignedIn && props.children}
            {!isSignedIn && <SignIn />}
        </>
    );
};

export default AuthenticatedRoute;
