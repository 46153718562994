import { useState } from 'react';
import Auth from '@aws-amplify/auth';

type State = {
    isSignIn: boolean;
    loading: boolean;
};

export const useSignIn = () => {
    const [isSignIn, setIsSingedIn] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const signIn = (email: string, password: string) => {
        setLoading(true);

        return Auth.signIn(email, password)
            .then((res) => {
                setIsSingedIn(true);
                return res;
            })
            .catch((e) => {
                setIsSingedIn(false);
                throw e;
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return [signIn, { isSignIn, loading }] as [typeof signIn, State];
};
