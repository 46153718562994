import {
    Form,
    Input,
    Button,
    Row,
    Col,
    Typography,
    message,
    Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ChangeSensorDTO } from 'src/models/Sensor';
import { showError } from 'src/utils/showError';
import Topbar from '../generic/Topbar';
import Nav from 'src/utils/nav/Nav';
import { useNavigate } from 'react-router-dom';
import { useChangeSensor } from 'src/hooks/objects/useChangeSensor';
import { useCheckSensorCollisions } from 'src/hooks/objects/useCheckSensorCollisions';
import { SiteObject } from 'src/models/SiteObject';
import { Site } from 'src/models/Site';

type Props = {
    object: SiteObject;
    site: Site;
    prevSensorId: number;
    onSuccess(): void;
};
function ChangeSensorView(props: Props) {
    const [form] = Form.useForm();
    const { prevSensorId, object, site, onSuccess } = props;
    const { t } = useTranslation();
    // const [scanActive, setScanActive] = useState(false);
    const [change] = useChangeSensor();
    const history = useNavigate();
    const [checkCollisions] = useCheckSensorCollisions();
    const [showCollisionWarning, setShowCollisionWarning] = useState(false);
    const [sensorToBeCreated, setSensorToBeCreated] = useState<
        ChangeSensorDTO | undefined
    >(undefined);

    const changeSensor = (newSensor: ChangeSensorDTO) => {
        change(object.id, prevSensorId, newSensor)
            .then(() => {
                message.success(t('Succesfully changed  sensor!'));
                onSuccess();
            })
            .catch((er: any) => {
                showError(t('Not able to change new sensor'), er.message);
            });
    };

    const handleOnSubmit = async (v: any) => {
        const newSensor: ChangeSensorDTO = {
            mac: v.id,
        };

        const collisions = await checkCollisions(object.id, newSensor.mac);

        if (collisions.collisionsOnObject.length > 0) {
            return showError(
                t(
                    `Sensor already registered on object as ${collisions.collisionsOnObject[0].name}. Edit it to make changes. Registration canceled.`,
                ),
                '',
            );
        }

        if (collisions.hasCollisions) {
            setShowCollisionWarning(true);
            setSensorToBeCreated(newSensor);
        } else {
            changeSensor(newSensor);
        }
    };

    return (
        <>
            <Modal
                closable={false}
                footer={null}
                visible={showCollisionWarning}
            >
                <Typography.Paragraph strong>Warning</Typography.Paragraph>
                <Typography.Paragraph>
                    {t('This sensor is already registered on another object.')}
                </Typography.Paragraph>
                <Typography.Paragraph>
                    {t(
                        'Select Reuse to use it both on this and previous object(s).',
                    )}
                </Typography.Paragraph>
                <Typography.Paragraph>
                    {t(
                        'Select Move to use it on current object only, removing it from previous object(s).',
                    )}
                </Typography.Paragraph>
                {/* <Typography.Paragraph italic type="secondary">
                    {t(
                        'If either method is not allowed it means this sensor is already registered on this object or an object that you do not have access to.',
                    )}
                </Typography.Paragraph> */}
                <div className="flex justify-between gap-4">
                    <Button
                        onClick={() => {
                            setShowCollisionWarning(false);
                        }}
                    >
                        {t('Cancel')}
                    </Button>
                    <div className="flex gap-4">
                        <Button
                            type="primary"
                            onClick={() => {
                                const sensor = sensorToBeCreated;
                                if (sensor !== undefined) {
                                    sensor.onCollision = 'REUSE_OLD';

                                    changeSensor(sensor);
                                }
                            }}
                        >
                            {t('Reuse')}
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                const sensor = sensorToBeCreated;
                                if (sensor !== undefined) {
                                    sensor.onCollision = 'REPLACE_OLD';

                                    changeSensor(sensor);
                                }
                            }}
                        >
                            {t('Move')}
                        </Button>
                    </div>
                </div>
            </Modal>

            <div className="relative">
                <div className="w-screen p-10 h-screen">
                    <Typography.Title className="text-center" level={4}>
                        {t('Change sensor')}
                    </Typography.Title>
                    <Row justify="center" align="middle" gutter={[20, 20]}>
                        <Col lg={8} md={12} xs={24}>
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={handleOnSubmit}
                            >
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: t('Sensor id is required'),
                                            validator(_, value: string) {
                                                if (
                                                    value.length === 16 &&
                                                    value.match(
                                                        /[0-9A-Fa-f]{16}/,
                                                    )
                                                ) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(
                                                    new Error(
                                                        'Something went wrong, try again!',
                                                    ),
                                                );
                                            },
                                        },
                                    ]}
                                    label={t('New sensor id')}
                                    name="id"
                                >
                                    <Input />
                                </Form.Item>
                                <Button
                                    className="mt-3"
                                    block
                                    size="large"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {t('Change sensor')}
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </div>
                <div className="fixed bottom-0 w-screen">
                    <div className="w-full mb-5 flex" id="go-back-button">
                        <Button
                            onClick={() => {
                                history(
                                    Nav.build({
                                        path: '/manage-object',
                                        queryParams: {
                                            index: '1',
                                            objectId: `${object.id}`,
                                            siteId: `${object.siteId}`,
                                            siteName: `${site.name}`,
                                        },
                                    }),
                                );
                            }}
                            type="primary"
                            className="m-auto"
                        >
                            {t('Go back')}
                        </Button>
                    </div>
                    <Topbar active="sites" />
                </div>
            </div>
        </>
    );
}

export default ChangeSensorView;
