import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useInitMetry } from 'src/hooks/integrations/useInitMetry';
import Nav from 'src/utils/nav/Nav';
import { showError } from 'src/utils/showError';
import { useQueryParams, StringParam } from 'use-query-params';

function OAuthCode() {
    const [{ code, state: stateURI }] = useQueryParams({
        code: StringParam,
        state: StringParam,
    });
    const corporationId = Number.parseInt(stateURI?.split('=')[1] || '');
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const waiting = !(error || success);
    const [init] = useInitMetry();
    const { t } = useTranslation();
    const history = useNavigate();
    const url = window.location.href.split('?')[0];

    useEffect(() => {
        if (error || success) return;
        if (code && corporationId) {
            init(corporationId, code, url)
                .catch((error) => {
                    setError(error.message);
                    showError(t('Unable to connect to Metry'), error.message);
                })
                .then(() => {
                    setSuccess('Successfully connected with metry');
                });
        }
    }, [error, code, corporationId, success, init, url, t]);

    useEffect(() => {
        history(
            Nav.build({
                path: '/corporations/:corporationId',
                routeParams: { corporationId },
                queryParams: {
                    tab: 'integrators',
                },
            }),
        );
    }, [corporationId, history, success]);

    return (
        <p>
            {waiting && <Typography.Text>Waiting...</Typography.Text>}
            {error && <Typography.Text>{error}</Typography.Text>}
            {success && <Typography.Text>{success}</Typography.Text>}
        </p>
    );
}

export default OAuthCode;
