import useSWR from 'swr';
import { swrFetch } from 'src/utils/swrFetch';

export interface DOTdata {
    dot: number;
    average: number;
    pseudoDOT?: number;
}

export const useGetDOT = (objectId: number, controlStateId: number) => {
    return useSWR<DOTdata>(
        controlStateId === -1
            ? null
            : `/objects/${objectId}/control-states/${controlStateId}/dot`,
        swrFetch,
        // { suspense: true },
    );
};
