import { Sensor } from 'src/models/Sensor';
import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';

export const useIntegratedSensors = (
    objectId: number,
    integrationId: number,
) => {
    return useSWR<Sensor[]>(
        `/objects/${objectId}/integrations/${integrationId}/sensors`,
        swrFetch,
    );
};
