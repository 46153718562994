import { Button, Form, Input, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAddIntegration } from 'src/hooks/integrations/useAddIntegration';

interface ElvacoCredentials {
    server: string;
    username: string;
    password: string;
}

interface Props {
    objectId: number;
    integratorId: number;
    onFinish: (integrationId: number) => void;
}

function IntegratorElvacoView(props: Props) {
    const { objectId, integratorId } = props;

    const { t } = useTranslation();
    const [addIntegration] = useAddIntegration();

    return (
        <>
            <div className="flex flex-wrap lg:flex-nowrap items-start gap-4 p-8">
                <img
                    className="hidden xl:block max-h-32 max-w-xs"
                    src="/elvaco.png"
                    alt="Elvaco logo"
                />
                <Typography className="flex-grow">
                    <Typography.Paragraph strong type="secondary">
                        {t('Elvaco header')}
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        {t('Elvaco description')}
                    </Typography.Paragraph>
                </Typography>
                <div className="w-full lg:w-96 max-w-full flex-shrink-0">
                    <Form
                        onFinish={async (form: ElvacoCredentials) => {
                            const integration = await addIntegration(
                                objectId,
                                integratorId,
                                form,
                            );

                            props.onFinish(integration.integrationId);
                        }}
                    >
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'Please specify the Elvaco server to be used for fetching sensor data',
                                    ),
                                },
                            ]}
                            name="server"
                        >
                            <Input
                                placeholder="https://any-elvaco-domain.com:PORT/Elvaco-Rest/rest"
                                name="server"
                            />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'Please input your Evlaco Server username',
                                    ),
                                },
                            ]}
                            name="username"
                        >
                            <Input placeholder="Username" name="username" />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'Please input your Elvaco Server password',
                                    ),
                                },
                            ]}
                            name="password"
                        >
                            <Input
                                placeholder="********"
                                name="password"
                                type="password"
                            />
                        </Form.Item>
                        <Button
                            htmlType="submit"
                            block
                            size="large"
                            type="primary"
                        >
                            {t('Create integration')}
                        </Button>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default IntegratorElvacoView;
