import { Button, Form, Input, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAddIntegration } from 'src/hooks/integrations/useAddIntegration';

interface ProptechCredentials {
    server: string;
    authUrl: string;
    clientId: string;
    secret: string;
}

interface Props {
    objectId: number;
    integratorId: number;
    onFinish: (integrationId: number) => void;
}

function IntegratorPropTechView(props: Props) {
    const { objectId, integratorId } = props;

    const { t } = useTranslation();
    const [addIntegration] = useAddIntegration();

    return (
        <>
            <div className="flex flex-wrap lg:flex-nowrap items-start gap-4 p-8">
                <img
                    className="hidden xl:block max-h-32 max-w-xs"
                    src="/proptech.svg"
                    alt="Proptech logo"
                />
                <Typography className="flex-grow">
                    <Typography.Paragraph strong type="secondary">
                        {t('Proptech header')}
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        {t('Proptech description')}
                    </Typography.Paragraph>
                </Typography>
                <div className="w-full lg:w-96 max-w-full flex-shrink-0">
                    <Form
                        onFinish={async (form: ProptechCredentials) => {
                            const integration = await addIntegration(
                                objectId,
                                integratorId,
                                form,
                            );

                            props.onFinish(integration.integrationId);
                        }}
                    >
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'Please specify the Proptech auth url',
                                    ),
                                },
                            ]}
                            name="authUrl"
                        >
                            <Input placeholder="Auth URL" name="authUrl" />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'Please specify the Proptech server to be used for fetching sensor data',
                                    ),
                                },
                            ]}
                            name="server"
                        >
                            <Input placeholder="Server URL" name="server" />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'Please input your Proptech Server clientId',
                                    ),
                                },
                            ]}
                            name="clientId"
                        >
                            <Input placeholder="clientId" name="clientId" />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'Please input your Proptech Server secret',
                                    ),
                                },
                            ]}
                            name="secret"
                        >
                            <Input
                                placeholder="********"
                                name="secret"
                                type="password"
                            />
                        </Form.Item>
                        <Button
                            htmlType="submit"
                            block
                            size="large"
                            type="primary"
                        >
                            {t('Create integration')}
                        </Button>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default IntegratorPropTechView;
