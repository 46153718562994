import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import SignIn from './pages/SignIn';
import VerifyEmail from './pages/VerifyEmail';
import DevPage from './pages/Dev';
import AcceptInvitation from './pages/AcceptInvitation';
import ForgotPassword from './pages/ForgotPassword';
import Settings from './pages/Settings';
import EditUser from './pages/EditUser';
import EditCorporation from './pages/EditCorporation2';
import HomePage from './pages/Home';
import SitePage from './pages/SitePage';
import AnalyticsPage from './pages/AnalyticsPage';
import MockSidebar from './views/4dev/MockSidebar';
import Home from './views/3dMapping/Home';
import AddSensorPage from './pages/AddSensorPage';
import AddDevicePage from './pages/AddDevicePage';
import ChangeSensorPage from './pages/ChangeSensorPage';
import ChangeDevicePage from './pages/ChangeDevicePage';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import ErrorHandler from './components/ErrorHandler';
import OAuthCode from './pages/OAuth/Code';
import IFramePage from './pages/IFramePage';
import UpdateSensorPage from './pages/UpdateSensorPage';
import ObjectsPage from './pages/ObjectsPage';
import AddSitePage from './pages/AddSitePage';
import MangeObjectPage from './pages/ManageObjectPage';
import ManageSitePage from './pages/ManageSitePage';
import IntegrationPage from './pages/IntegrationPage';
import SetupIntegrationPage from './pages/SetupIntegrationPage';
import LayoutWrapper from 'src/components/LayoutWrapper';
import ThreeDModelPage from './pages/3dModelPage';
import PerformanceReportPage from './pages/PerformanceReportPage';
import NavigationTree from './views/generic/NavigationTree';

export default function App() {
    return (
        <>
            <ErrorHandler>
                <LayoutWrapper>
                    <Routes>
                        {/* <Route path="/dev">
                                <Layout
                                    topbar={<Topbar active="home"></Topbar>}
                                    sidebar={<Sidebar active="user"></Sidebar>}
                                    children={<TabContainer>{mock}</TabContainer>}
                                    header={<Header></Header>}
                                />
                            </Route> */}
                        <Route element={<SignIn />} path="/login" />
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <NavigationTree />
                                </AuthenticatedRoute>
                            }
                            path="/tree"
                        />
                        <Route element={<OAuthCode />} path="/oauth/code" />
                        <Route element={<SignIn />} path="/sign-up" />
                        <Route
                            element={<AcceptInvitation />}
                            path="/accept-invitation"
                        />
                        <Route element={<VerifyEmail />} path="/verify-email" />
                        <Route
                            element={<ForgotPassword />}
                            path="/forgot-password"
                        />
                        {/* Authenticated routes */}
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <DevPage />
                                </AuthenticatedRoute>
                            }
                            path="/.dev"
                        />
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <Settings />
                                </AuthenticatedRoute>
                            }
                            path="/settings"
                        />
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <SetupIntegrationPage />
                                </AuthenticatedRoute>
                            }
                            path="/manage-object/integrations"
                        />
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <MangeObjectPage />
                                </AuthenticatedRoute>
                            }
                            path="/manage-object"
                        />
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <ManageSitePage />
                                </AuthenticatedRoute>
                            }
                            path="/manage-site"
                        />
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <AddSitePage />
                                </AuthenticatedRoute>
                            }
                            path="/create-site"
                        />
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <AddSensorPage />
                                </AuthenticatedRoute>
                            }
                            path="/create-sensor/:objectId/"
                        />
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <EditUser />
                                </AuthenticatedRoute>
                            }
                            path="/users/:userId"
                        />
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <EditCorporation />
                                </AuthenticatedRoute>
                            }
                            path="/corporations/:corporationId"
                        />
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <AddDevicePage />
                                </AuthenticatedRoute>
                            }
                            path="/create-device/:objectId"
                        />
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <ChangeSensorPage />
                                </AuthenticatedRoute>
                            }
                            path="/change-sensor/:objectId/:prevSensorId"
                        />
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <UpdateSensorPage />
                                </AuthenticatedRoute>
                            }
                            path="/update-sensor/:objectId/:sensorId/:sensorName/:isActive/:sensorFunctionTypeId"
                        />
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <ChangeDevicePage />
                                </AuthenticatedRoute>
                            }
                            path="/change-device/:objectId/:prevDeviceId"
                        />
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <HomePage />
                                </AuthenticatedRoute>
                            }
                            path="/home"
                        />
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <IntegrationPage />
                                </AuthenticatedRoute>
                            }
                            path="/integrations"
                        />
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <ObjectsPage />
                                </AuthenticatedRoute>
                            }
                            path="/sites/objects/:siteId"
                        />
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <SitePage />
                                </AuthenticatedRoute>
                            }
                            path="/sites"
                        />
                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <AnalyticsPage />
                                </AuthenticatedRoute>
                            }
                            path="/analytics/:objectId"
                        />

                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <ThreeDModelPage />
                                </AuthenticatedRoute>
                            }
                            path="/3dmodel/:objectId"
                        />

                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <PerformanceReportPage />
                                </AuthenticatedRoute>
                            }
                            path="/performance-reports"
                        />

                        <Route element={<IFramePage />} path="/iframe" />

                        <Route
                            element={
                                <AuthenticatedRoute>
                                    <Home />
                                </AuthenticatedRoute>
                            }
                            path="/3dmap"
                        />

                        {/* </ErrorHandler> */}
                        <Route path="*" element={<Navigate to="/home" />} />
                    </Routes>
                </LayoutWrapper>
            </ErrorHandler>
            {/* Some tools to make it easier to develop, should be removed in the future */}
            <MockSidebar />
        </>
    );
}
