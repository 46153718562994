import { API } from 'src/api/API';
import { Corporation } from 'src/models/Corporation';
import { mutate } from 'swr';

export const useUpdateDesiredTemperature = () => {
    const update = async (
        objectId: number,
        data: Record<number, number>,
        stateId: number,
    ): Promise<Corporation> => {
        return new API()
            .updateDesiredTemperature(objectId, data, stateId)
            .then((res) => {
                mutate(`objects/${objectId}/flow-temperatures`);
                mutate(
                    `objects/${objectId}/control-states/${stateId}/flow-temperatures`,
                );
                mutate(
                    `objects/${objectId}/control-states/${stateId}/desired-temperatures`,
                );
                mutate(`/objects/${objectId}`);

                return res;
            });
    };

    return [update] as const;
};
