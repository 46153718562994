import { Corporation } from 'src/models/Corporation';
import { ColorSelector } from 'src/utils/ColorSelector';

interface IProps {
    corporation: Corporation;
}

export const CorporationLogo = (props: IProps) => {
    const { corporation } = props;

    return (
        <>
            {!corporation.imgSrc && (
                <div
                    className="h-full w-full rounded-full text-white flex "
                    style={{
                        backgroundColor: ColorSelector.getColor(
                            corporation.name,
                        ),
                    }}
                >
                    <div className="m-auto select-none">
                        {corporation.name?.charAt(0)}
                    </div>
                </div>
            )}
            {!!corporation.imgSrc && (
                <div className="h-full w-full border-zinc-600 border rounded-full ">
                    <img
                        className="m-auto h-full object-scale-down w-full max-h-full max-w-full"
                        alt="Corp logo"
                        src={corporation.imgSrc}
                    />
                </div>
            )}
        </>
    );
};
