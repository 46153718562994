import { useState } from 'react';
import Auth from '@aws-amplify/auth';

export const useChangePassword = () => {
    const [isSignIn, setIsSingedIn] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const changePassword = async (
        email: string,
        tempPassword: string,
        password: string,
    ) => {
        return Auth.signIn(email, tempPassword).then((user) => {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                return Auth.completeNewPassword(user, password)
                    .then((res) => {
                        setIsSingedIn(true);
                        return res;
                    })
                    .catch((e) => {
                        setIsSingedIn(false);
                        throw e;
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                return user;
            }
        });
    };

    return [changePassword, { isSignIn, loading }] as const;
};
