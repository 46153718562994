import React, { FC } from 'react';
import './ActionMenu.scss';

interface ActionMenuProps {
    onUndo: () => void;
    onReset: () => void;
    isCornersLength: boolean;
}

const ActionMenu: FC<ActionMenuProps> = ({
    onUndo,
    onReset,
    isCornersLength,
}) => {
    return (
        <div>
            <div className="dropdown inline-block relative">
                <button className="bg-white text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center">
                    <span className="mr-1">Actions</span>
                    <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                    >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{' '}
                    </svg>
                </button>
                <ul
                    className="dropdown-menu absolute hidden text-gray-700 pt-1"
                    style={{ width: '150px' }}
                >
                    <li>
                        <button
                            className="rounded-t bg-white hover:bg-gray-200 hover:cursor-pointer py-2 px-4 block"
                            onClick={onReset}
                        >
                            Reset Corners
                        </button>
                    </li>
                    {isCornersLength && (
                        <li>
                            <button
                                className="bg-white hover:bg-gray-200 py-2 px-4 block hover:cursor-pointer"
                                onClick={onUndo}
                            >
                                Undo
                            </button>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default ActionMenu;
