import { Device } from 'src/models/Device';
import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';

export const useDevicesSerialNumbers = (objectId: number) => {
    return useSWR<Device[]>(
        `objects/${objectId}/devices-serial-numbers`,
        swrFetch,
    );
};
