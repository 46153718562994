import { API } from 'src/api/API';
import { Context } from 'src/models/Context';
import { Corporation } from 'src/models/Corporation';
import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';
import produce from 'immer';

export const useAddCorporation = () => {
    const { isValidating, mutate, data, error } = useSWR<Context>(
        '/users/me',
        swrFetch,
    );

    const add = async (corporation: Omit<Corporation, 'id'>) => {
        return new API().addCorporation(corporation).then((corp) => {
            mutate(
                produce(data, (data) => {
                    data?.corporations.push(corp);
                    return data;
                }),
            );
        });
    };

    return [
        add,
        { isValidating, mutate, data: data?.corporations, error },
    ] as const;
};
