import { useUpdateSensor } from 'src/hooks/objects/useUpdateSensor';
import { useDispatch } from 'react-redux';
import { setSensors } from 'src/store/reducers/sensorsSlice';
import { setSensorNames } from 'src/store/reducers/sensorNamesSlice';
import {
    Row,
    Button,
    Modal,
    Typography,
    Divider,
    Tooltip,
    Collapse,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Nav from 'src/utils/nav/Nav';
import { useNavigate } from 'react-router';
import { getPrettySensorName, Sensor, SensorType } from 'src/models/Sensor';
import {
    CloseOutlined,
    DeleteOutlined,
    DeploymentUnitOutlined,
    EditOutlined,
    HeatMapOutlined,
    HomeOutlined,
    InfoCircleOutlined,
    PlusOutlined,
    SwapOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import { useDevices } from 'src/hooks/objects/useDevice';
import { useDeleteDevice } from 'src/hooks/objects/useDeleteDevice';
import { useDeleteSensor } from 'src/hooks/objects/useDeleteSensor';
import { ReactElement, useEffect, useState } from 'react';
import { showError } from 'src/utils/showError';
import { Device } from 'src/models/Device';
// import { useSensorsLastMeasuredTemperature } from 'src/hooks/sensors/useSensorsLastMeasuredTemperature';
import { useSensors } from 'src/hooks/objects/useSensors';
import Container from 'src/components/Container';

interface Props {
    objectId: number;
}

type CheckLegendProps = {
    sensor: Sensor;
    onChange: (v: CheckboxChangeEvent) => void;
    onDelete?: () => void;
    onSwap?: () => void;
    onUpdate?: () => void;
    objectId: number;
};

const TypeToIcon: Record<SensorType, ReactElement<any, any>> = {
    '-1': <CloseOutlined />,
    '0': <DeploymentUnitOutlined />,
    '1': <DeploymentUnitOutlined />,
    '11': <HeatMapOutlined />,
    '21': <DeploymentUnitOutlined />,
    '51': <DeploymentUnitOutlined />,
    '61': <DeploymentUnitOutlined />,
    '101': <HomeOutlined />,
    '301': <HeatMapOutlined />,
    '302': <HeatMapOutlined />,
    '303': <HeatMapOutlined />,
    '304': <HeatMapOutlined />,
    '305': <HeatMapOutlined />,
    '306': <HeatMapOutlined />,
    '307': <HeatMapOutlined />,
    '308': <HeatMapOutlined />,
    '309': <HeatMapOutlined />,
    '310': <HeatMapOutlined />,
    '311': <HeatMapOutlined />,
    '312': <HeatMapOutlined />,
    '313': <HeatMapOutlined />,
    '314': <HeatMapOutlined />,
    '315': <HeatMapOutlined />,
    '316': <HeatMapOutlined />,
    '317': <HeatMapOutlined />,
    '318': <HeatMapOutlined />,
    '319': <HeatMapOutlined />,
    '320': <HeatMapOutlined />,
    '321': <HeatMapOutlined />,
    '322': <HeatMapOutlined />,
    '323': <HeatMapOutlined />,
    '324': <HeatMapOutlined />,
    '325': <HeatMapOutlined />,
    '326': <HeatMapOutlined />,
    '327': <HeatMapOutlined />,
    '328': <HeatMapOutlined />,
    '329': <HeatMapOutlined />,
    '330': <HeatMapOutlined />,
    '331': <HeatMapOutlined />,
    '332': <HeatMapOutlined />,
    '333': <HeatMapOutlined />,
    '334': <HeatMapOutlined />,
    '335': <HeatMapOutlined />,
    '336': <HeatMapOutlined />,
    '337': <HeatMapOutlined />,
    '338': <HeatMapOutlined />,
    '339': <HeatMapOutlined />,
    '340': <HeatMapOutlined />,
};

const CheckLegend = (props: CheckLegendProps) => {
    const { t } = useTranslation();

    return (
        <div className="py-4">
            <div className="flex md:flex-nowrap md:gap-y-0 gap-y-4 flex-wrap justify-between">
                <div
                    className="flex gap-x-3"
                    style={{ justifyContent: 'space-between', width: '100%' }}
                >
                    <div style={{ marginLeft: '15px' }}>
                        {t('Registered')}:{' '}
                        <span className="text-gray-500">
                            {props.sensor.validFrom !==
                                '1970-01-01 00:00:00' && (
                                <>{props.sensor.validFrom}</>
                            )}
                        </span>
                    </div>
                    <div
                        className="flex gap-x-3 text-xl"
                        style={{
                            justifyContent: 'flex-end',
                            marginRight: '15px',
                        }}
                    >
                        <Tooltip title={<>{t('Delete sensor')}</>}>
                            <DeleteOutlined onClick={props.onDelete} />
                        </Tooltip>
                        <Tooltip title={<>{t('Replace sensor')}</>}>
                            <SwapOutlined onClick={props.onSwap} />
                        </Tooltip>
                        <Tooltip title={<>{t('Edit sensor')}</>}>
                            <EditOutlined onClick={props.onUpdate} />
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface SensorConfigRowProps {
    sensor: Sensor;
    objectId: number;
}

function SensorConfigRow(props: SensorConfigRowProps) {
    const { sensor, objectId } = props;
    const history = useNavigate();
    const [updateSensor] = useUpdateSensor();
    const [deleteSensor] = useDeleteSensor();
    const { t } = useTranslation();
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [upForDeleteId, setUpForDeleteId] = useState(-1);

    const DeleteSensorPopUp = () => {
        return (
            <Modal
                title={t('Are you sure?')}
                visible={deleteVisible}
                footer={null}
                onCancel={() => setDeleteVisible(false)}
            >
                <Typography.Text>
                    {t(
                        'This action is irreversible and can not be undone. Are you sure that you want to proceed?',
                    )}
                </Typography.Text>
                <Button
                    type="link"
                    onClick={() => {
                        deleteSensor(objectId, upForDeleteId)
                            .then((object) => {
                                setDeleteVisible(false);
                                setUpForDeleteId(-1);
                            })
                            .catch((er) => {
                                showError(
                                    'Not able to delete sensor',
                                    er.message,
                                );
                            });
                    }}
                    block
                >
                    {t('Delete sensor')}
                </Button>
            </Modal>
        );
    };
    return (
        <div>
            <DeleteSensorPopUp />
            {sensor && (
                <CheckLegend
                    objectId={objectId}
                    key={sensor.id}
                    onDelete={() => {
                        setDeleteVisible(true);
                        setUpForDeleteId(sensor.id);
                    }}
                    onSwap={() =>
                        history(
                            Nav.build({
                                path: '/change-sensor/:objectId/:prevSensorId',
                                routeParams: {
                                    objectId: objectId,
                                    prevSensorId: sensor.id,
                                },
                                queryParams: {},
                            }),
                        )
                    }
                    onUpdate={() =>
                        history(
                            Nav.build({
                                path:
                                    '/update-sensor/:objectId/:sensorId/:sensorName/:isActive/:sensorFunctionTypeId',
                                routeParams: {
                                    sensorFunctionTypeId:
                                        sensor.sensorFunctionTypeId,
                                    isActive: sensor.isActive === true ? 1 : 0,
                                    objectId: objectId,
                                    sensorId: sensor.id,
                                    sensorName:
                                        sensor.name !== null
                                            ? sensor.name
                                            : sensor.uniqueId,
                                },
                                queryParams: {},
                            }),
                        )
                    }
                    onChange={(e: any) => {
                        updateSensor(objectId, sensor.id, {
                            ...sensor!,
                            isActive: e.target.checked,
                        }).catch((error) => {
                            showError(
                                t('Not able to update sensor'),
                                error.message,
                            );
                        });
                    }}
                    sensor={sensor}
                />
            )}
        </div>
    );
}

interface SensorPanelHeaderProps {
    sensor: Sensor;
}

const SensorPanelHeader: React.FC<SensorPanelHeaderProps> = ({ sensor }) => {
    const warningDays = 3;

    const getMacAddress = (macAddress: string) => {
        if (macAddress) {
            const decimalValue = BigInt(macAddress);
            const hexadecimalString = decimalValue
                .toString(16)
                .padStart(16, '0')
                .toUpperCase();
            return hexadecimalString;
        }
        return macAddress;
    };

    const showDateWarning = () => {
        if (sensor.latestMeasurementValue === null) return undefined;
        const timedeltaMs =
            Date.now() -
            new Date(sensor.latestMeasurementTimestamp ?? 0).getTime();

        const timedeltaDays = timedeltaMs / 1000 / 3400 / 24;
        if (timedeltaDays < warningDays) return undefined;
        return Math.floor(timedeltaDays);
    };

    const { t } = useTranslation();

    return (
        <div className="flex md:flex-nowrap md:gap-y-0 gap-y-4 flex-wrap justify-between">
            <div className="flex md:flex-nowrap flex-col">
                <div
                    className="ant-typography text-xl"
                    style={{ marginBottom: '0px' }}
                >
                    {sensor.name}
                </div>
                {sensor && sensor.sensorSerialNumber && (
                    <div className="ant-typography">
                        <strong className="text-gray-500">
                            {getMacAddress(sensor.sensorSerialNumber)}
                        </strong>
                    </div>
                )}
            </div>
            <div
                className="flex gap-x-3 text-xl"
                style={{ lineHeight: '1.25rem' }}
            >
                <Tooltip
                    title={
                        <>
                            <InfoCircleOutlined />
                            <br />
                            {getPrettySensorName(
                                sensor.sensorFunctionTypeId,
                                t,
                            )}
                        </>
                    }
                >
                    {TypeToIcon[sensor.sensorFunctionTypeId]}
                </Tooltip>
                <span>
                    {sensor.latestMeasurementValue &&
                        `${sensor.latestMeasurementValue?.toFixed(1)}°`}
                </span>

                {showDateWarning() && (
                    <Tooltip
                        title={
                            <>
                                {t(
                                    'It has been more than {{warningDays}} days since last measurement',
                                    { warningDays: showDateWarning() },
                                )}
                            </>
                        }
                    >
                        {<WarningOutlined />}
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

function ConfigurationView(props: Props) {
    const { objectId } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useNavigate();
    const { data: devices } = useDevices(objectId);
    const { data: sensors } = useSensors(objectId);

    const { Panel } = Collapse;

    useEffect(() => {
        if (sensors && sensors.length) {
            const sensorTypes = Array.from(
                new Set(
                    sensors
                        .filter(
                            (sensor) =>
                                sensor.sensorFunctionTypeId !==
                                SensorType.RESPONSE_TEMPERATURE,
                        )
                        .map((sensor) => sensor.sensorFunctionTypeId),
                ),
            );
            dispatch(setSensors(sensorTypes));

            const sensorNames = Array.from(
                new Set(
                    sensors
                        .map((sensor) => sensor.name)
                        .filter((name) => name !== null),
                ),
            );
            dispatch(setSensorNames(sensorNames as string[]));
        }
    }, [sensors, dispatch]);

    const DevicecardExtra = (props: { device: Device }) => {
        const { device } = props;

        const [deleteDevice] = useDeleteDevice();

        const [upForDeleteId, setUpForDeleteId] = useState(-1);
        const [deleteVisible, setDeleteVisible] = useState(false);

        const DeleteDevicePopUp = () => {
            return (
                <Modal
                    title={t('Are you sure?')}
                    visible={deleteVisible}
                    footer={null}
                    onCancel={() => setDeleteVisible(false)}
                >
                    <Typography.Text>
                        {t(
                            'This action is irreversible and can not be undone. Are you sure that you want to proceed?',
                        )}
                    </Typography.Text>
                    <Button
                        type="link"
                        onClick={() => {
                            deleteDevice(objectId, upForDeleteId)
                                .then((object) => {
                                    setDeleteVisible(false);
                                    setUpForDeleteId(-1);
                                })
                                .catch((er) => {
                                    showError(
                                        'Not able to delete device',
                                        er.message,
                                    );
                                });
                            setDeleteVisible(false);
                            setUpForDeleteId(-1);
                        }}
                        block
                    >
                        {t('Delete device')}
                    </Button>
                </Modal>
            );
        };

        return (
            <>
                <DeleteDevicePopUp />
                <div className="sm:bloc flex justify-center text-xl">
                    <Tooltip title={<>{t('Delete device')}</>}>
                        <DeleteOutlined
                            onClick={() => {
                                setUpForDeleteId(device.id);
                                setDeleteVisible(true);
                            }}
                            className="mx-2"
                            style={{ verticalAlign: 'middle' }}
                        />
                    </Tooltip>
                    <Tooltip title={<>{t('Replace device')}</>}>
                        <SwapOutlined
                            className="mx-2"
                            onClick={() => {
                                history(
                                    Nav.build({
                                        path:
                                            '/change-device/:objectId/:prevDeviceId',
                                        routeParams: {
                                            objectId: objectId,
                                            prevDeviceId: device.id,
                                        },
                                        queryParams: {},
                                    }),
                                );
                            }}
                            style={{ verticalAlign: 'middle' }}
                        />
                    </Tooltip>
                </div>
            </>
        );
    };

    return (
        <>
            <Container
                cardTitle={t('Devices')}
                cardExtra={
                    <Button
                        // block={
                        //     screen === 'desktop' ? false : true
                        // }
                        onClick={() =>
                            history(
                                Nav.build({
                                    path: '/create-device/:objectId',
                                    routeParams: {
                                        objectId: objectId,
                                    },
                                    queryParams: {},
                                }),
                            )
                        }
                        type="primary"
                        className="my-4"
                        icon={<PlusOutlined />}
                    >
                        {t('Add device')}
                    </Button>
                }
            >
                <Row align="middle" justify="space-between">
                    {devices
                        ?.filter((device) => !device.pseudoDevice)
                        .map((device) => {
                            return (
                                <>
                                    <Typography.Text>
                                        {device.serialNumber}
                                    </Typography.Text>
                                    <DevicecardExtra device={device} />
                                    <Divider />
                                </>
                            );
                        })}
                </Row>
            </Container>
            <Divider />
            <Container
                cardTitle={t('Sensors')}
                cardExtra={
                    <Button
                        className="p-2"
                        onClick={() =>
                            history(
                                Nav.build({
                                    path: '/create-sensor/:objectId/',
                                    routeParams: {
                                        objectId: objectId,
                                    },
                                    queryParams: {},
                                }),
                            )
                        }
                        type="primary"
                        icon={<PlusOutlined />}
                    >
                        {t('Add sensor')}
                    </Button>
                }
            >
                <Collapse>
                    {sensors?.map((sensor, index) => {
                        return (
                            <Panel
                                header={<SensorPanelHeader sensor={sensor} />}
                                key={index}
                            >
                                <SensorConfigRow
                                    objectId={objectId}
                                    sensor={sensor}
                                />
                            </Panel>
                        );
                    })}
                </Collapse>
            </Container>
        </>
    );
}

export default ConfigurationView;
