import { API } from 'src/api/API';
import { Sensor } from 'src/models/Sensor';

export const useSensors = () => {
    const fetchSensors = async (objectId: number): Promise<Sensor[]> => {
        try {
            const sensors = await new API().fetchMeasuredSensors(objectId);
            return sensors;
        } catch (error) {
            console.error('Error fetching sensors:', error);
            throw error;
        }
    };

    return [fetchSensors] as const;
};
