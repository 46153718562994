import { useCallback, useEffect, useState } from 'react';
import { API } from 'src/api/API';

import { SetPointMap } from 'src/views/sites/FlowTemperatureView';

export interface RealTimeDataPoint {
    date: string;
    timestamp: number;
    timeString: string;
    value: number;
}

export interface RecommendationTable {
    table: SetPointMap[];
    type: 'DELTA' | 'ABSOLUTE';
    source: 'default' | 'log';
}

export const useRecommendationWithControlState = (
    objectId: number,
    controlStateId: number,
) => {
    const [data, setData] = useState<RecommendationTable>({
        table: [],
        type: 'DELTA',
        source: 'default',
    });
    const [data1, setData1] = useState<RecommendationTable>({
        table: [],
        type: 'DELTA',
        source: 'default',
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isLatestState, setIsLatestState] = useState(true);

    const refetch = useCallback(async () => {
        if (controlStateId === undefined) {
            return;
        }

        let temp1: RecommendationTable;

        const temp = await new API().getRecommendation(
            objectId,
            controlStateId,
        );
        if (controlStateId - 1 >= 0) {
            temp1 = await new API().getRecommendation(
                objectId,
                controlStateId - 1,
            );
        } else {
            temp1 = { table: [], type: 'DELTA', source: 'default' };
        }

        setData(temp);
        setData1(temp1);
        setIsLoading(false);
    }, [controlStateId, objectId]);
    useEffect(() => {
        refetch();
    }, [refetch, controlStateId, objectId, isLoading, isLatestState]);

    const revalidate = async () => {
        setIsLoading(true);
        new API().getRecommendation(objectId, controlStateId!).then((v) => {
            setData(v);
            setIsLoading(false);
        });
    };
    return {
        isLoading,
        data,
        data1,
        setData,
        revalidate,
        controlStateId,
        setIsLoading,
        setIsLatestState,
        refetch,
    };
};
