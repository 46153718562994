import React, { useState, useEffect } from 'react';
import { Table, Select, Input, Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { SensorTypeSelector } from 'src/components/SensorTypeSelector';
import { useSelector } from 'react-redux';
import { selectModbus } from 'src/store/reducers/masterModbusTcpSlice';

interface TableRow {
    id: number;
    key: string;
    registerType: string;
    registerNumber: number;
    scaling: number;
    name: string;
    sensorFunctionType: string;
}

interface TableProps {
    currentStep: number;
    integrationId?: number;
    onPrevious: () => void;
    onIntegration: (data: any) => void;
    onEditIntegration: (data: any) => void;
}

const ModbusTableComponent: React.FC<TableProps> = (props: TableProps) => {
    const modbus = useSelector(selectModbus) || null;

    const [data, setData] = useState<TableRow[]>([]);
    const {
        currentStep,
        onPrevious,
        onIntegration,
        onEditIntegration,
        integrationId,
    } = props;
    const [newRow, setNewRow] = useState<TableRow>({
        id: 0,
        key: String(Date.now()),
        registerType: '',
        registerNumber: 0,
        scaling: 1,
        name: '',
        sensorFunctionType: '',
    });
    const generateKey = (): string => String(data.length + 1);

    const columns: ColumnProps<TableRow>[] = [
        {
            title: 'Register Type',
            dataIndex: 'registerType',
            key: 'registerType',
            render: (text, record) => {
                return (
                    <Select
                        value={text}
                        style={{ width: 120 }}
                        onChange={(value) =>
                            handleChange('registerType', value, record.key)
                        }
                    >
                        <Select.Option value="INPUT">INPUT</Select.Option>
                        <Select.Option value="HOLDING">HOLDING</Select.Option>
                    </Select>
                );
            },
        },
        {
            title: 'Register Number',
            dataIndex: 'registerNumber',
            key: 'registerNumber',
            render: (text, record) =>
                renderEditableCell(text, record.key, 'registerNumber'),
        },
        {
            title: 'Scaling',
            dataIndex: 'scaling',
            key: 'scaling',
            render: (text, record) =>
                renderEditableCell(text, record.key, 'scaling'),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) =>
                renderEditableCell(text, record.key, 'name'),
        },
        {
            title: 'Sensor Function',
            dataIndex: 'sensorFunctionType',
            key: 'sensorFunctionType',
            render: (text, record) => (
                <SensorTypeSelector
                    forbidenSensors={[]}
                    defaultValue={text}
                    onChange={(value) =>
                        handleChange('sensorFunctionType', value, record.key)
                    }
                />
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Button onClick={() => handleDeleteRow(record.key)}>
                    Delete
                </Button>
            ),
        },
    ];

    const handleChange = (
        key: keyof TableRow,
        value: string | number,
        recordKey: string,
    ) => {
        setData((prevData) =>
            prevData.map((row) =>
                row.key === recordKey ? { ...row, [key]: value } : row,
            ),
        );
    };

    const handleAddRow = () => {
        const newRowWithKey: TableRow = {
            ...newRow,
            key: String(data.length + 1),
        };
        setData([...data, newRowWithKey]);
        setNewRow({
            id: 0,
            key: generateKey(),
            registerType: '',
            registerNumber: 0,
            scaling: 1,
            name: '',
            sensorFunctionType: '',
        });
    };

    const handleDeleteRow = (recordKey: string) => {
        setData((prevData) => prevData.filter((row) => row.key !== recordKey));
    };

    const renderEditableCell = (
        text: string,
        recordKey: string,
        dataIndex: keyof TableRow,
    ) => {
        return (
            <Input
                value={data.find((row) => row.key === recordKey)?.[dataIndex]}
                onChange={(e) =>
                    handleChange(dataIndex, e.target.value, recordKey)
                }
            />
        );
    };

    useEffect(() => {
        if (modbus && modbus.data) {
            setData(modbus.data);
        }
    }, [modbus]);

    return (
        <div>
            <Table dataSource={data} columns={columns} />
            <Button onClick={handleAddRow}>Add</Button>
            {currentStep > 0 && (
                <div className="flex flex-col items-center">
                    <div className="mt-4">
                        <Button onClick={onPrevious} className="mr-2">
                            Previous
                        </Button>
                        {integrationId ? (
                            <Button
                                type="primary"
                                onClick={() => onEditIntegration(data)}
                            >
                                Edit Integration
                            </Button>
                        ) : (
                            <Button
                                type="primary"
                                onClick={() => onIntegration(data)}
                            >
                                Create Integration
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ModbusTableComponent;
