import { Button, Col, InputNumber, Row, Tabs, Typography } from 'antd';
import { useState } from 'react';
import { useDocumentKeyboardEvent } from 'src/hooks/generic/useKeyboard';
import { useAddMockTemperature } from 'src/hooks/objects/useAddMockTemperature';
import { env } from 'src/utils/env';

function MockObjectTemperatures() {
    const [objectId, setObjectId] = useState<number>(0);
    const [fromTemperature, setFromTemperature] = useState<number>(-1);
    const [toTemperature, setToTemperature] = useState<number>(-1);
    // const { data: flowTemperatures } = useFlowTemperatures(objectId);
    const [update] = useAddMockTemperature();

    const addMock = () => {
        update(objectId, {
            [fromTemperature]: toTemperature,
        });
    };

    return (
        <>
            <Row>
                <Col span={24}>
                    <Typography.Text strong>
                        Mock measured temperature
                    </Typography.Text>
                </Col>
                <Col span={8}>
                    <Typography>Object ID</Typography>
                    <InputNumber onChange={(v) => setObjectId(v as number)} />
                </Col>
                <Col span={8}>
                    <Typography>From temperature</Typography>
                    <InputNumber
                        step={5}
                        min={-15}
                        max={20}
                        onChange={(v) => setFromTemperature(v as number)}
                    />
                </Col>
                <Col span={8}>
                    <Typography>To temperature</Typography>
                    <InputNumber
                        step={0.5}
                        min={17}
                        max={25}
                        onChange={(v) => setToTemperature(v as number)}
                    />
                </Col>
                <Col span={24}>
                    <Button onClick={addMock}>Submit</Button>
                </Col>
            </Row>
        </>
    );
}

function MockSidebar() {
    const [isVisible, setIsVisible] = useState(false);

    useDocumentKeyboardEvent((ev) => {
        // ctrl + d to active dev mode
        if (ev.ctrlKey && ev.key === 'd') {
            setIsVisible(!isVisible);
        }
    });

    return (
        <>
            {isVisible && (
                <div className="fixed w-96 border-dashed border p-2 bg-white h-screen top-0 right-0 z-20">
                    <Row>
                        <Col span={24}>
                            <Typography.Title level={4}>4devs</Typography.Title>
                        </Col>
                    </Row>
                    <Tabs>
                        <Tabs.TabPane
                            tab="Versions"
                            tabKey="Versions"
                            key="Versions"
                        >
                            <Typography.Paragraph>
                                STAGE : {env.REACT_APP_STAGE}
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                API : {env.REACT_APP_API}
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                USERPOOL : {env.REACT_APP_COGNITO_USERPOOL_ID}
                            </Typography.Paragraph>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab="Mock data"
                            tabKey="Mock data"
                            key="Mock data"
                        >
                            <MockObjectTemperatures />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            )}
        </>
    );
}

export default MockSidebar;
