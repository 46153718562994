import MapView from '../views/generic/MapView';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import WarningView from 'src/views/generic/WarningView';
import { env } from 'src/utils/env';
import { HomeTwoTone } from '@ant-design/icons';
import Container from 'src/components/Container';

function MapLegend() {
    const { t } = useTranslation();
    return (
        <>
            <div className="flex  sm:absolute bottom-16 left-0 right-0">
                <div className="gap-2 m-auto flex">
                    <div className="flex flex-col">
                        <HomeTwoTone
                            className="text-2xl "
                            twoToneColor={'#8c8c8c'}
                        />
                        <Typography.Text>
                            {t('Potential unkown')}
                        </Typography.Text>
                    </div>
                    <div className="flex flex-col">
                        <HomeTwoTone
                            className="text-2xl "
                            twoToneColor={'#51baa2'}
                        />{' '}
                        <Typography.Text>
                            {t('Potential') + ' < 3%'}
                        </Typography.Text>
                    </div>
                    <div className="flex flex-col">
                        <HomeTwoTone
                            className="text-2xl"
                            twoToneColor={'#ffec3d'}
                        />

                        <Typography.Text>
                            {t('Potential') + ' >= 3%'}
                        </Typography.Text>
                    </div>
                    <div className="flex flex-col">
                        <HomeTwoTone
                            className="text-2xl "
                            twoToneColor={'#fa8c16'}
                        />
                        <Typography.Text>
                            {t('Potential') + ' >= 6%'}
                        </Typography.Text>
                    </div>
                    <div className="flex flex-col">
                        <HomeTwoTone
                            className="text-2xl "
                            twoToneColor={'#cf1322'}
                        />
                        <Typography.Text>
                            {t('Potential') + ' >= 9%'}
                        </Typography.Text>
                    </div>
                </div>
            </div>
        </>
    );
}

function HomePage() {
    const { t } = useTranslation();

    return (
        <>
            <Row gutter={[20, 20]}>
                <Col xs={24} md={12}>
                    <Container
                        className="h-full"
                        cardTitle={t('Your sites')}
                        cover={<MapView />}
                    >
                        <MapLegend />
                    </Container>
                </Col>
                <Col xs={24} md={12}>
                    <Container className="h-full" cardTitle={t('Warnings')}>
                        <WarningView />
                    </Container>
                </Col>

                <Col xs={24} md={24}>
                    <Typography.Paragraph className="text-center">
                        STAGE: {env.REACT_APP_STAGE}
                        <br />
                        VERSION: {env.REACT_APP_VERSION}
                    </Typography.Paragraph>
                </Col>
            </Row>
        </>
    );
}

export default HomePage;
