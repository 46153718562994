import React, { useState, useMemo, useEffect } from 'react';
import Map from 'src/components/3DMapping/Map';
import 'mapbox-gl/dist/mapbox-gl.css';
import BlockModal from 'src/components/3DMapping/BlockModal';
import Prompt from 'src/components/3DMapping/Prompt';
import { useFetchTemplates } from 'src/hooks/3dmapping/useFetchTemplates';
import useFetchTemplateSpaces from 'src/hooks/3dmapping/useFetchTemplateSpaces';
import NotificationMessage from 'src/components/3DMapping/NotificationMessage';
import ActionMenu from 'src/components/3DMapping/ActionMenu';
import { useDispatch } from 'react-redux';
import {
    addToExistingBlockIndex,
    clearTableRows,
} from 'src/store/reducers/tableRowsSlice';
import { useSitesAsc } from 'src/hooks/users/useSitesAsc';

const Home: React.FC = () => {
    const [corners, setCorners] = useState<[number, number][]>([]);
    const [showBlockModal, setShowBlockModal] = useState<boolean>(false);
    const [buildingBlocks, setBuildingBlocks] = useState<any[]>([]);
    const [isOpenPrompt, setPrompt] = useState<boolean>(false);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [numBlocks, setNumBlocks] = useState<number>(1);
    const [objectId, setObjectId] = useState<number>();
    const [blockNames, setBlockNames] = useState<string[]>([]);
    const floorTemplates = useFetchTemplates();
    useFetchTemplateSpaces();

    const { data: sites } = useSitesAsc();

    const dispatch = useDispatch();

    const numCorners: number = useMemo(() => {
        let num: number = Math.max(numBlocks, 1) + 2;
        return num;
    }, [numBlocks]);

    const handleResetClick = (): void => {
        setCorners([]);
        setBuildingBlocks([]);
        setNumBlocks(1);
        setBlockNames([]);
        dispatch(clearTableRows());
    };

    const handleUndoClick = (): void => {
        const cornersToUpdate: [number, number][] = [...corners];
        cornersToUpdate.pop();
        setCorners([...cornersToUpdate]);
    };

    const getAdjacentCorner = (
        bottomLeft: [number, number],
        bottomRight: [number, number],
        topRight: [number, number],
    ): [number, number] => {
        const deltaX: number = topRight[0] - bottomRight[0];
        const deltaY: number = topRight[1] - bottomRight[1];
        const topLeft: [number, number] = [
            bottomLeft[0] + deltaX,
            bottomLeft[1] + deltaY,
        ];
        return topLeft;
    };

    const distance = (
        lat1: number,
        lon1: number,
        lat2: number,
        lon2: number,
    ): number => {
        const R: number = 6371000; // Radius of the earth in meters
        const dLat: number = toRad(lat2 - lat1);
        const dLon: number = toRad(lon2 - lon1);
        const a: number =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(lat1)) *
                Math.cos(toRad(lat2)) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
        const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d: number = R * c; // Distance in meters
        return d;
    };

    const toRad = (degrees: number): number => {
        return (degrees * Math.PI) / 180;
    };

    const handlePromptSubmit = (value: number, names: string[]): void => {
        setNumBlocks(value);
        setPrompt(false);
        setBlockNames(names);
    };

    const onChangeObject = (value: number): void => {
        setObjectId(value);
    };

    const calculation = async () => {
        const allCorners: [number, number][] = [...corners];
        //top right should be pop up in each iteration
        let topRight: [number, number] = allCorners.pop()!;
        let width: number = 0;

        const cornersToAdd: [number, number][] = [];
        const blocks: any[] = [];

        for (let i = 1; i <= numBlocks; i++) {
            const lastIndex: number = allCorners.length - i;
            const bottomRight: [number, number] = allCorners[lastIndex];
            const bottomLeft: [number, number] = allCorners[lastIndex - 1];
            if (i === 1) {
                //distance will never change for each iteration
                width = distance(
                    bottomRight[1],
                    bottomRight[0],
                    topRight[1],
                    topRight[0],
                );
            }
            const topLeft: [number, number] = getAdjacentCorner(
                bottomLeft,
                bottomRight,
                topRight,
            );

            const blockNameIndex = numBlocks - i;
            const blockName = blockNames[blockNameIndex];

            const block: any = {
                ObjectId: objectId,
                Name: blockName,
                LeftCornerLong: Number(bottomLeft[0]),
                LeftCornerLat: Number(bottomLeft[1]),
                RightCornerLong: Number(bottomRight[0]),
                RightCornerLat: Number(bottomRight[1]),
                Group: 1,
                Width: Number(width).toFixed(2),
                ZOffset: 0,
                floorTemplate: floorTemplates?.data?.[0]?.Id,
            };

            blocks.unshift(block);
            cornersToAdd.push(topLeft);
            topRight = topLeft;
        }
        //show all the corners
        setCorners((prevCorners) => [...prevCorners, ...cornersToAdd]);
        setBuildingBlocks([...blocks]);

        blocks.forEach(async (_, index) => {
            await dispatch(
                addToExistingBlockIndex({ blockIndex: index, tableRows: [] }),
            );
        });

        setShowBlockModal(true);
    };

    const calculateBlocks = async () => {
        const [startCorner, endCorner] = corners;
        const longDelta = (endCorner[0] - startCorner[0]) / numBlocks;
        const latDelta = (endCorner[1] - startCorner[1]) / numBlocks;
        const numberOfCornersRequired = numBlocks - 1;

        let [currentLong, currentLat] = startCorner;
        const immediateCorners: [number, number][] = [];

        for (let i = 1; i <= numberOfCornersRequired; i++) {
            currentLat += latDelta;
            currentLong += longDelta;
            immediateCorners.push([currentLong, currentLat]);
        }

        const allCorners: [number, number][] = [
            startCorner,
            ...immediateCorners,
            endCorner,
        ];

        setCorners([...allCorners]);
    };

    useEffect(() => {
        if (corners.length === numCorners) {
            // We have all four corners, so calculate the block(s) and store them in the database
            calculation();
        }

        if (corners.length === 2 && numBlocks > 1) {
            calculateBlocks();
        }
        //eslint-disable-next-line
    }, [corners, numCorners, numBlocks]);

    return (
        <div>
            <Map
                setPrompt={setPrompt}
                numBlocks={numBlocks}
                corners={corners}
                setCorners={setCorners}
            />
            <div
                className="fixed w-full z-10 flex justify-center"
                style={{ top: '10px' }}
            >
                <ActionMenu
                    onUndo={handleUndoClick}
                    isCornersLength={corners ? !!corners.length : false}
                    onReset={handleResetClick}
                />
            </div>

            {showBlockModal && floorTemplates?.data && (
                <BlockModal
                    blocks={buildingBlocks}
                    floorTemplates={floorTemplates.data}
                    showModal={showBlockModal}
                    setShowModal={setShowBlockModal}
                    setShowNotification={setShowNotification}
                    setCorners={setCorners}
                    objectId={objectId}
                />
            )}
            {isOpenPrompt && (
                <Prompt
                    title="Enter Number of Blocks"
                    message="How many blocks do you want to create?"
                    onSubmit={handlePromptSubmit}
                    sites={sites || []}
                    onChangeObject={onChangeObject}
                    setPrompt={setPrompt}
                />
            )}
            {showNotification && (
                <NotificationMessage
                    type={'success'}
                    message={'Blocks Created Successfully'}
                />
            )}
        </div>
    );
};

export default Home;
