import { Collapse, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIntegrators } from 'src/hooks/integrations/useIntegrators';
import { useObject } from 'src/hooks/objects/useObject';
import Nav from 'src/utils/nav/Nav';
import IntegratorWebPortView from 'src/views/objects/IntegratorWebPortView';
import IntegratorEcoGuardView from 'src/views/objects/IntegratorEcoGuardView';
import { useQueryParams, withDefault, NumberParam } from 'use-query-params';
import { useNavigate } from 'react-router-dom';
import IntegratonReginView from 'src/views/objects/IntegratorReginView';
import IntegratorElvacoView from 'src/views/objects/IntegratorElvacoView';
import IntegratorModbusView from 'src/views/objects/IntegratorModbusView';
import IntegratorModbusTcpView from 'src/views/objects/IntegratorModbusTcpView';
import IntegratorDesigoView from 'src/views/objects/IntegratorDesigoView';
import IntegratorProptechView from 'src/views/objects/IntegratorProptechView';

const { Panel } = Collapse;

function IntegrationPage() {
    const [params] = useQueryParams({
        objectId: withDefault(NumberParam, -1),
    });

    const { data: object } = useObject(params.objectId);
    const { t } = useTranslation();
    const { data } = useIntegrators();
    const history = useNavigate();

    const onFinish = (integrationId: number) => {
        if (object !== undefined) {
            history(
                Nav.build({
                    path: '/manage-object/integrations',
                    queryParams: {
                        objectId: `${object.id}`,
                        integrationId: `${integrationId}`,
                    },
                }),
            );
        }
    };

    return (
        <>
            <Typography.Title level={4} className="my-3">
                {t('Add Integration for')} {object?.name}
            </Typography.Title>
            <Collapse bordered={false} defaultActiveKey={data?.[0].id}>
                {data?.map((integrator) => {
                    return (
                        <Panel header={integrator.name} key={integrator.id}>
                            {integrator.name === 'Web Port' && (
                                <IntegratorWebPortView
                                    objectId={params.objectId}
                                    integratorId={integrator.id}
                                    onFinish={onFinish}
                                />
                            )}
                            {integrator.name === 'EcoGuard' && (
                                <IntegratorEcoGuardView
                                    objectId={params.objectId}
                                    integratorId={integrator.id}
                                    onFinish={onFinish}
                                />
                            )}
                            {integrator.name === 'Regin' && (
                                <IntegratonReginView
                                    objectId={params.objectId}
                                    integratorId={integrator.id}
                                    onFinish={onFinish}
                                />
                            )}
                            {integrator.name.toLowerCase() === 'elvaco' && (
                                <IntegratorElvacoView
                                    objectId={params.objectId}
                                    integratorId={integrator.id}
                                    onFinish={onFinish}
                                />
                            )}
                            {integrator.name.toLowerCase() ===
                                'modbus master' && (
                                <IntegratorModbusView
                                    objectId={params.objectId}
                                    integratorId={integrator.id}
                                />
                            )}
                            {integrator.name.toLowerCase() === 'modbus tcp' && (
                                <IntegratorModbusTcpView
                                    objectId={params.objectId}
                                    integratorId={integrator.id}
                                />
                            )}
                            {integrator.name.toLowerCase() === 'desigo' && (
                                <IntegratorDesigoView
                                    objectId={params.objectId}
                                    integratorId={integrator.id}
                                    onFinish={onFinish}
                                />
                            )}
                            {integrator.name.toLowerCase() === 'proptechos' && (
                                <IntegratorProptechView
                                    objectId={params.objectId}
                                    integratorId={integrator.id}
                                    onFinish={onFinish}
                                />
                            )}
                        </Panel>
                    );
                })}
            </Collapse>
        </>
    );
}

export default IntegrationPage;
