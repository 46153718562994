import { API } from 'src/api/API';
import { mutate } from 'swr';

export const useSetMeterMeterId = () => {
    const add = (
        objectId: number,
        meterId: string | null,
        metryIntegratorId: number | null,
        type: 'ELECTRIC' | 'HEATING',
    ): Promise<void> => {
        return new API()
            .setMetryMeter(objectId, meterId, metryIntegratorId, type)
            .then((v) => {
                mutate(`external/metry/objects/${objectId}`);
                return v;
            });
    };
    return [add] as const;
};
