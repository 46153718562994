import { Button, Form, Input, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAddIntegration } from 'src/hooks/integrations/useAddIntegration';

interface ReginCredentials {
    account: string;
    username: string;
    password: string;
    device: string;
}

interface Props {
    objectId: number;
    integratorId: number;
    onFinish: (integrationId: number) => void;
}

function IntegratonReginView(props: Props) {
    const { objectId, integratorId } = props;

    const { t } = useTranslation();
    const [addIntegration] = useAddIntegration();

    return (
        <>
            <div className="flex flex-wrap lg:flex-nowrap items-start gap-4 p-8">
                <img
                    className="hidden xl:block max-h-32 max-w-xs"
                    src="https://www.regincontrols.com/static/images/logo-regin.svg"
                    alt="Regin logo"
                />
                <Typography className="flex-grow">
                    <Typography.Paragraph strong type="secondary">
                        {t('Regin header')}
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        {t('Regin description')}
                    </Typography.Paragraph>
                </Typography>
                <div className="w-full lg:w-96 max-w-full flex-shrink-0">
                    <Form
                        onFinish={async (form: ReginCredentials) => {
                            const integration = await addIntegration(
                                objectId,
                                integratorId,
                                form,
                            );

                            props.onFinish(integration.integrationId);
                        }}
                    >
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'Please specify the Regin account to be used for fetching sensor data',
                                    ),
                                },
                            ]}
                            name="account"
                        >
                            <Input placeholder="Account name" name="account" />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'Please input your Regin username',
                                    ),
                                },
                            ]}
                            name="username"
                        >
                            <Input placeholder="Username" name="username" />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'Please input your Regin password',
                                    ),
                                },
                            ]}
                            name="password"
                        >
                            <Input
                                placeholder="********"
                                name="password"
                                type="password"
                            />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'Please input your Regin device',
                                    ),
                                },
                            ]}
                            name="device"
                        >
                            <Input
                                placeholder="Device serial number"
                                name="device"
                            />
                        </Form.Item>
                        <Button
                            htmlType="submit"
                            block
                            size="large"
                            type="primary"
                        >
                            {t('Create integration')}
                        </Button>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default IntegratonReginView;
