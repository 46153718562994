import { Button, Card, Divider, Form, Input, Row, Typography } from 'antd';
import logoSrc from 'src/assets/Logo.svg';
import { useTranslation } from 'react-i18next';
import { useChangePassword } from 'src/hooks/users/useChangePassword';

interface IProps {
    email: string;
    code: string;
    onFail: (message: string, type: 'REQUEST_ERROR') => void;
    onSuccess: () => void;
}

export default function AcceptInviteView(props: IProps) {
    const { t } = useTranslation();
    const [changePass, { loading }] = useChangePassword();

    const onSubmit = (v: { password: string }) => {
        return changePass(props.email, props.code, v.password)
            .then(() => props.onSuccess())
            .catch((error) => {
                props.onFail(error.message, 'REQUEST_ERROR');
            });
    };
    return (
        <>
            <Card className="w-full h-full">
                <div className="p-2">
                    <div className="w-full p-10 flex h-full">
                        <img
                            className="w-full"
                            src={logoSrc}
                            alt="Nrlyze logo"
                        />
                    </div>
                    <Form layout="vertical" onFinish={onSubmit}>
                        <div className="text-center">
                            <Typography.Text>
                                {t(
                                    'Please choose a strong password for your Nrlyze account. The username will be the email address where you recieved the link to this page',
                                )}
                            </Typography.Text>
                        </div>
                        <Divider />
                        <Form.Item
                            rules={[
                                {
                                    message: t('Password does not match'),
                                    required: true,
                                },
                            ]}
                            required
                            label={t('Password')}
                            name="password2"
                        >
                            <Input type="password" placeholder="********" />
                        </Form.Item>

                        <Form.Item
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            getFieldValue('password') ===
                                            getFieldValue('password2')
                                        ) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(
                                                'Password does not match',
                                            );
                                        }
                                    },
                                }),
                            ]}
                            required
                            label={t('Re-enter password')}
                            name="password"
                        >
                            <Input type="password" placeholder="********" />
                        </Form.Item>

                        <Row className="mt-20 mb-10" gutter={[20, 0]}>
                            <Button
                                loading={loading}
                                type="primary"
                                size="large"
                                htmlType="submit"
                                block
                            >
                                {t('Set password')}
                            </Button>
                        </Row>
                    </Form>
                </div>
            </Card>
        </>
    );
}
