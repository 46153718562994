import { Form, Input, Button, Row, Col, Typography, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { showError } from 'src/utils/showError';
import Topbar from '../generic/Topbar';
import Nav from 'src/utils/nav/Nav';
import { useNavigate } from 'react-router-dom';
import { useChangeDevice } from 'src/hooks/objects/useChangeDevice';
import { CreateDeviceDTO } from 'src/models/Device';
// import { useState } from 'react';

type Props = {
    siteId: number;
    siteName: string;
    objectId: number;
    prevSensorId: number;
    onSuccess(): void;
};

function ChangeDeviceView(props: Props) {
    const [form] = Form.useForm();
    const { prevSensorId, objectId, onSuccess } = props;
    const { t } = useTranslation();
    // const [scanActive, setScanActive] = useState(false);
    const [change] = useChangeDevice();
    const history = useNavigate();

    const handleOnSubmit = (v: any) => {
        const newDevice: CreateDeviceDTO = {
            serialNumber: v.id,
        };
        change(objectId, prevSensorId, newDevice)
            .then(() => {
                message.success(t('Succesfully changed device!'));
                onSuccess();
            })
            .catch((er: any) => {
                showError(t('Not able to change device'), er.message);
            });
    };

    return (
        <>
            <div className="relative">
                <div className="w-screen p-10 h-screen">
                    <Typography.Title className="text-center" level={4}>
                        {t('Change device')}
                    </Typography.Title>
                    <Row align="middle" justify="center" gutter={[20, 20]}>
                        <Col lg={8} md={12} xs={24}>
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={handleOnSubmit}
                            >
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: t('Sensor id required'),
                                            validator(_, value: string) {
                                                if (
                                                    value.length === 12 &&
                                                    Number.isInteger(
                                                        Number(value),
                                                    )
                                                ) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(
                                                    new Error(
                                                        'Something went wrong, try again!',
                                                    ),
                                                );
                                            },
                                        },
                                    ]}
                                    label={t('New device id')}
                                    name="id"
                                >
                                    <Input />
                                </Form.Item>
                                <Button
                                    className="mt-3"
                                    block
                                    size="large"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {t('Change device')}
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </div>
                <div className="fixed bottom-0 w-screen">
                    <div className="w-full mb-5 flex " id="go-back-button">
                        <Button
                            onClick={() => {
                                history(
                                    Nav.build({
                                        path: '/manage-object',
                                        queryParams: {
                                            index: '1',
                                            objectId: `${objectId}`,
                                            siteId: `${props.siteId}`,
                                            siteName: `${props.siteName}`,
                                        },
                                    }),
                                );
                            }}
                            type="primary"
                            className="m-auto"
                        >
                            {t('Go back')}
                        </Button>
                    </div>
                    <Topbar active="sites" />
                </div>
            </div>
        </>
    );
}

export default ChangeDeviceView;
