import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import ThreeDModelView from 'src/views/objects/ThreeDModelView';

function ThreeDModelPage() {
    const { objectId: objectIdString } = useParams<{ objectId: string }>();
    const objectId = Number.parseInt(objectIdString!);

    return (
        <Suspense fallback={<></>}>
            <ThreeDModelView objectId={objectId} />
        </Suspense>
    );
}

export default ThreeDModelPage;
