import { Divider, message, Select, Skeleton } from 'antd';
import React, { Suspense, useState, useEffect } from 'react';
import { useQueryParams, withDefault, NumberParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useReadSetpointsOnIntegration } from 'src/hooks/integrations/useReadSetpointsOnIntegration';
import { useControlStates } from 'src/hooks/objects/useControllStates';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/rootReducer';
import { useFlowTemperaturesWithState } from 'src/hooks/objects/useFlowTemperature';
import { useObjectEffect } from 'src/hooks/objects/useObjectEffect';
import { useRecommendationWithControlState } from 'src/hooks/objects/useRecommendationWithControlState';
import { useUpdateTemperature } from 'src/hooks/objects/useUpdateTemperature';
import { ControlState } from 'src/models/ControlState';
import { showError } from 'src/utils/showError';
import CommitChangesPopup from '../sites/CommitChangesPopup';
import { EditDesiredTemperatures } from '../sites/EditDesiredTemperature';
import FlowTemperatureView, { SetPointMap } from '../sites/FlowTemperatureView';
import TotalSaved from './TotalSaved';
import { setRefreshFlag } from 'src/store/reducers/selectedObjectSlice';

function OptimizeStateSelector(props: {
    objectId: number;
    controlStateId: number;
    setControlStateId: (id: number) => void;
    setIsLoading: (state: boolean) => void;
}) {
    const { objectId, controlStateId, setControlStateId, setIsLoading } = props;
    const { t } = useTranslation();
    const { Option } = Select;
    const { data: controlStateIds } = useControlStates(objectId);

    return (
        <div className="w-screen sm:w-1/2 float-none sm:float-right">
            {controlStateIds && controlStateIds.length > 0 && (
                <Select
                    defaultValue={controlStateId}
                    key={controlStateId}
                    onChange={(v) => {
                        setControlStateId(v);
                        setIsLoading(true);
                    }}
                    placeholder={t('Analysis periods')}
                    className="sm:float-right float-none"
                >
                    {controlStateIds.map((v: any) => {
                        const startDate: any = v.time_period_start;
                        let stopDate: string | null = v.time_period_stop;
                        let period = '';
                        if (stopDate === null) {
                            period =
                                startDate.substring(4, 17) + '-' + t('Today');
                        } else {
                            period =
                                startDate.substring(4, 17) +
                                '-' +
                                stopDate.substring(4, 17);
                        }

                        return (
                            <Option key={v.state_order} value={v.state_order}>
                                {t('Analysis period') +
                                    ' ' +
                                    v.state_order +
                                    period}
                            </Option>
                        );
                    })}
                </Select>
            )}
        </div>
    );
}

interface OptimizeViewProps {
    objectId: number;
    controlStates: ControlState[];
    revalidateControlStates: any;
}

function OptimizeView(props: OptimizeViewProps) {
    const { objectId, controlStates, revalidateControlStates } = props;
    const { t } = useTranslation();
    const [flowIncludes, setFlowIncludes] = useState<string[]>(['']);

    const defaultControlStateId =
        controlStates[controlStates.length - 1].state_order;

    const [params] = useQueryParams({
        controlId: withDefault(NumberParam, defaultControlStateId),
    });

    const [controlStateId, setControlStateId] = useState(params.controlId);
    const [
        refreshEditDesiredTemperatures,
        setRefreshEditDesiredTemperatures,
    ] = useState(() => Date.now().toString());

    const {
        data: recommendation,
        data1: prevRecommendation,
        revalidate: revalidateRecommendation,
        setData: setRecommendation,
        isLoading,
        setIsLoading,
        refetch,
    } = useRecommendationWithControlState(objectId, controlStateId);
    const { data: effectData } = useObjectEffect(objectId);
    const { data: flowTemperatures } = useFlowTemperaturesWithState(
        objectId,
        controlStateId,
    );

    const { data: prevFlowTemperatures } = useFlowTemperaturesWithState(
        objectId,
        controlStateId !== undefined ? controlStateId - 1 : undefined,
    );
    const { revalidate: readSetpoints } = useReadSetpointsOnIntegration(
        objectId,
    );
    const [update] = useUpdateTemperature();
    const [showCommit, setShowCommit] = useState(false);

    const changeFrom = (input: SetPointMap) => {
        let temp: SetPointMap[] = [];
        recommendation.table?.forEach((v: SetPointMap) => {
            if (v.toTemperature === input.toTemperature) {
                v = input;
            }
            temp.push(v);
            setRecommendation({ ...recommendation, table: temp });
        });
    };

    const changeUpdate = (input: SetPointMap) => {
        let temp: SetPointMap[] = [];
        recommendation.table?.forEach((v: SetPointMap) => {
            if (v.fromTemperature === input.fromTemperature) {
                v = input;
            }
            temp.push(v);
        });
        setRecommendation({ ...recommendation, table: temp });
    };

    let newPotential = false;

    for (const e of recommendation.table) {
        if (e.update !== null && e.update !== e.toTemperature) {
            newPotential = true;
            break;
        }
    }

    const dispatch = useDispatch();

    const { refreshFlag } = useSelector(
        (state: RootState) => state.selectedObject,
    );

    useEffect(() => {
        dispatch(setRefreshFlag(false));
        const uniqueKey = Date.now().toString(); // or use a random value generator
        setRefreshEditDesiredTemperatures(uniqueKey);
        // eslint-disable-next-line
    }, [refreshFlag]);

    return (
        <>
            <Suspense fallback={<></>}>
                <CommitChangesPopup
                    key={refreshEditDesiredTemperatures}
                    update={update}
                    input={recommendation.table}
                    onError={(e) => {
                        showError(t('Unable to change state'), e);
                        setShowCommit(false);
                    }}
                    objectId={objectId}
                    onCancel={() => {
                        setShowCommit(false);
                    }}
                    calibrated={recommendation.type}
                    onSuccess={async () => {
                        setShowCommit(false);
                        message.success(t('Successfully changed state'));
                        await revalidateControlStates();
                        setControlStateId(controlStateId + 1);
                    }}
                    onRefresh={() => {
                        setShowCommit(false);
                        setRefreshEditDesiredTemperatures((prev) =>
                            prev === 'initialKey' ? 'updatedKey' : 'initialKey',
                        );
                    }}
                    visible={showCommit}
                />
            </Suspense>
            <Suspense fallback={<>loading states</>}>
                <OptimizeStateSelector
                    key={refreshEditDesiredTemperatures}
                    controlStateId={controlStateId}
                    objectId={objectId}
                    setControlStateId={setControlStateId}
                    setIsLoading={setIsLoading}
                />
            </Suspense>
            <div className="float-left w-full mt-5">
                <Suspense fallback={<Skeleton />}>
                    <EditDesiredTemperatures
                        key={refreshEditDesiredTemperatures}
                        editable={
                            controlStateId ===
                            controlStates[controlStates.length - 1].state_order
                        }
                        stateId={controlStateId}
                        objectId={objectId}
                        revalidate={() => revalidateRecommendation()}
                    />
                </Suspense>
            </div>

            <div className="float-left w-full mt-5">
                <Suspense fallback={<Skeleton />}>
                    <FlowTemperatureView
                        key={refreshEditDesiredTemperatures}
                        onClick={(name: string) => {
                            if (name === 'desiredFlowTemperature') {
                                const names = [
                                    'desiredFlowTemperature',
                                    'lowDesiredFlowTemperature',
                                    'highDesiredFlowTemperature',
                                ];
                                if (!flowIncludes.includes(name)) {
                                    setFlowIncludes([
                                        ...flowIncludes,
                                        ...names,
                                    ]);
                                } else {
                                    setFlowIncludes(
                                        flowIncludes.filter((name) => {
                                            return !names.includes(name);
                                        }),
                                    );
                                }
                            } else if (!flowIncludes.includes(name)) {
                                setFlowIncludes([...flowIncludes, name]);
                            } else {
                                setFlowIncludes(
                                    flowIncludes.filter((oldName) => {
                                        return oldName !== name;
                                    }),
                                );
                            }
                        }}
                        includes={flowIncludes}
                        isLoading={isLoading}
                        prevHeatCurve={prevRecommendation.table}
                        tableSource={recommendation.source}
                        editable={
                            controlStateId ===
                            controlStates[controlStates.length - 1].state_order
                        }
                        setModal={() => setShowCommit(true)}
                        changeFrom={(input: SetPointMap) => changeFrom(input)}
                        changeUpdate={(input: SetPointMap) =>
                            changeUpdate(input)
                        }
                        refetchRecommendation={async () => {
                            const response = await readSetpoints();
                            if (response.error) {
                                message.error(response.error);
                                return;
                            }
                            refetch();
                            revalidateRecommendation();
                            setRefreshEditDesiredTemperatures((prev) =>
                                prev === 'initialKey'
                                    ? 'updatedKey'
                                    : 'initialKey',
                            );
                        }}
                        flowTemperatures={flowTemperatures}
                        effectData={effectData}
                        prevFlowTemperatures={prevFlowTemperatures}
                        tableData={recommendation.table}
                        calibrated={recommendation.type}
                        stateId={controlStateId}
                        objectId={objectId}
                    />
                </Suspense>
            </div>
            <Divider />
            <div className="float-left w-full flex">
                <div className="w-full md:w-1/2 m-auto">
                    <Suspense fallback={<></>}>
                        {newPotential && (
                            <TotalSaved
                                key={refreshEditDesiredTemperatures}
                                objectId={objectId}
                                stateId={controlStateId}
                            />
                        )}
                    </Suspense>
                </div>
            </div>
        </>
    );
}

export default OptimizeView;
