import { useTranslation } from 'react-i18next';
import { LocalDB } from 'src/api/LocalDB';

export type Languages = 'swe' | 'en';
export type LanguageMap = Record<Languages, { name: string; flagCode: string }>;
type State = { language: Languages; allLanguages: LanguageMap };
const allLanguages: LanguageMap = {
    en: {
        flagCode: 'gb',
        name: 'English',
    },
    swe: {
        flagCode: 'swe',
        name: 'Swedish',
    },
};

export const useLanguage = () => {
    const { i18n } = useTranslation();

    const updateLanguage = (language: Languages) => {
        LocalDB.setValue('choosenLanguage', language);
        if (i18n.language !== language) {
            i18n.changeLanguage(language);
        }
    };

    const state: State = {
        language: i18n.language as Languages,
        allLanguages,
    };

    return [updateLanguage, state] as const;
};
