import { API } from 'src/api/API';

interface FloorOrBlockSensor {
    identifier: string;
    selectedFloors: number[];
}

export const useAvgTempFloorRealTime = () => {
    const fetch = async (
        objectId: number,
        start: string,
        stop: string,
        sensors: FloorOrBlockSensor[],
    ) => {
        const api = new API();
        const promises = [api.fetchAvgFloor(objectId, start, stop, sensors)];

        const [data] = await Promise.all(promises);

        return [data];
    };

    return [fetch] as const;
};
