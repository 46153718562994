import { TemperatureWithControlState } from 'src/models/Temperature';
import { swrFetch } from 'src/utils/swrFetch';
import useSWR from 'swr';

export const useDesiredTemperatures = (
    objectId: number,
    controlStateId: number,
) => {
    return useSWR<TemperatureWithControlState>(
        `objects/${objectId}/control-states/${controlStateId}/desired-temperatures`,
        swrFetch,
        { suspense: true },
    );
};
