import { API } from 'src/api/API';
import { SetPointMap } from 'src/views/sites/FlowTemperatureView';
import { mutate } from 'swr';

export type UpdateTemperatureDTO = {
    update: SetPointMap[];
};

export const useUpdateTemperature = () => {
    const update = async (
        update: UpdateTemperatureDTO,
        objectId: number,
    ): Promise<any> => {
        return new API()
            .updateTemperature(update, objectId)
            .then(async (res) => {
                mutate(`objects/${objectId}/control-states`);
                return res;
            });
    };
    return [update] as const;
};
