import {
    Button,
    Input,
    Skeleton,
    Table,
    TableColumnType,
    Typography,
    Tooltip,
    Form,
} from 'antd';
import { useTranslation } from 'react-i18next';

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip as ChartTooltip,
    ResponsiveContainer,
    CartesianGrid,
} from 'recharts';
import { TemperatureWithControlState } from 'src/models/Temperature';
import useViewport from 'src/hooks/generic/useViewport';
import { useObjectEffect } from 'src/hooks/objects/useObjectEffect';
import Legend from '../objects/Legend';
import Container from 'src/components/Container';
import { ReloadOutlined } from '@ant-design/icons';
import validateFloatNumber from 'src/utils/validateFloatNumber';
import StateMessageBox from 'src/views/sites/StateMessageBox';
import { useAsyncQueueStatus } from 'src/hooks/objects/useAsyncQueueStatus';

import { useQueryParams, withDefault, BooleanParam } from 'use-query-params';

export interface DataEntry {
    measuredFlowTemperature?: number;
    prevOptimalFlowTemperature?: number;
    prevMeasuredFlowTemperature?: number;
    desiredFlowTemperature?: number;
    highDesiredFlowTemperature?: number;
    lowDesiredFlowTemperature?: number;
    desiredEffect?: number;
    measuredEffect?: number;
    fromTemperature: number;
    setPoints?: number;
    nature?: string | number;
    prevHeatCurve?: number;
}

export interface SetPointMap {
    toTemperature: number;
    fromTemperature: number;
    nature?: string | number;
    measured: number;
    update: number;
}
interface Props {
    editable: boolean;
    objectId: number;
    isLoading: boolean;
    stateId?: number;
    prevHeatCurve: SetPointMap[];
    effectData?: {
        desired: TemperatureWithControlState;
        measured: TemperatureWithControlState;
    };
    includes: string[];
    onClick: (name: string) => void;
    setModal: () => void;
    changeUpdate: (input: SetPointMap) => void;
    changeFrom: (input: SetPointMap) => void;
    refetchRecommendation: () => void;
    calibrated: 'DELTA' | 'ABSOLUTE';
    tableData: SetPointMap[];
    tableSource: 'default' | 'log';
    flowTemperatures?: {
        desired: TemperatureWithControlState;
        measured: TemperatureWithControlState;
    };
    prevFlowTemperatures?: {
        desired: TemperatureWithControlState;
        measured: TemperatureWithControlState;
    };
}

interface ConditionalGraphTickProps {
    x?: number;
    y?: number;
    screen?: 'desktop' | 'mobile';
    payload?: { value: number };
}

export function ConditionalGraphTick(props: ConditionalGraphTickProps) {
    const { x, y, screen, payload } = props;

    const value = payload?.value;

    return (
        <>
            {value !== undefined && value % 5 === 0 && (
                <g transform={`translate(${x},${y})`}>
                    <text
                        x={0}
                        y={0}
                        dy={16}
                        textAnchor="middle"
                        fill="#666"
                        transform={screen === 'mobile' ? 'rotate(-45)' : ''}
                    >
                        {value} °C
                    </text>
                </g>
            )}
        </>
    );
}

export const tickGenerator = (data: DataEntry[], step: number) => {
    const result = [];
    let start = -15;
    let stop = 15;

    if (data !== undefined) {
        if (data.length > 0) {
            start = data[0].fromTemperature;
            stop = data.sort((a, b) => a.fromTemperature - b.fromTemperature)[
                data.length - 1
            ].fromTemperature;
        }
    }

    for (let i = start; i <= stop; i += step) {
        result.push(i);
    }

    return result;
};

export default function FlowTemperatureView(props: Props) {
    const {
        objectId,
        stateId,
        tableData,
        calibrated,
        // changeFrom,
        changeUpdate,
        refetchRecommendation,
        prevFlowTemperatures,
        flowTemperatures,
        prevHeatCurve,
        isLoading,
        tableSource,
        editable,
    } = props;
    const { t } = useTranslation();
    const { data: effectData } = useObjectEffect(objectId);
    const hasOptimalEffect = (() => {
        if (!effectData) return false;
        return (
            effectData?.measured?.data?.length > 0 ||
            effectData?.desired?.data?.length > 0
        );
    })();

    const desiredEffect = effectData?.desired;
    const measuredEffect = effectData?.measured;

    const { mode } = useViewport();
    const optimalFlow = flowTemperatures?.desired;
    const measuredFlowTemperatures = flowTemperatures?.measured;
    const prevOptimalFlow = prevFlowTemperatures?.desired;
    const prevMeasuredFlow = prevFlowTemperatures?.measured;
    const hasOptimalFlow = optimalFlow && optimalFlow.data.length > 0;

    const hasMeasuredFlowTemperatures =
        measuredFlowTemperatures && measuredFlowTemperatures.data.length > 0;

    const hasPrevOptimalFlow =
        prevOptimalFlow && prevOptimalFlow.data.length > 0;

    const hasPrevMeasuredFlow =
        prevMeasuredFlow && prevMeasuredFlow.data.length > 0;

    const [params] = useQueryParams({
        isCamera: withDefault(BooleanParam, false),
    });

    const { data: asyncQueueStatus } = useAsyncQueueStatus(objectId);

    const columns: TableColumnType<any>[] = [
        {
            title: t('Outdoor'),
            dataIndex: 'fromTemperature',
            width: '11ch',
            render: (_, record: SetPointMap, i) => {
                return (
                    <div className="flex justify-between">
                        <Typography.Text>
                            {record.fromTemperature.toString()}
                        </Typography.Text>
                        <div>
                            <Typography.Text>{'°C'}</Typography.Text>
                        </div>
                    </div>
                );
            },
        },
    ];

    if (calibrated === 'ABSOLUTE') {
        columns.push({
            title: t('Current'),
            dataIndex: 'toTemperature',
            width: '12ch',
            render: (_, record: SetPointMap, i) => {
                if (record.toTemperature !== null) {
                    return (
                        <Typography.Text>
                            {record.toTemperature.toFixed(1)}
                        </Typography.Text>
                    );
                }
            },
        });

        columns.push({
            title: t('Measured'),
            dataIndex: 'measured',
            width: '12ch',
            render: (_, record: SetPointMap, i) => {
                if (record.measured !== null) {
                    return (
                        <Typography.Text>
                            {record.measured.toFixed(1)}
                        </Typography.Text>
                    );
                }
            },
        });

        columns.push({
            title: t('Recommended'),
            dataIndex: 'update',
            width: '17ch',
            render: (_, record: SetPointMap, i) => {
                if (record.toTemperature !== null)
                    return (
                        <Form.Item
                            style={{ margin: '0em' }}
                            rules={[
                                {
                                    validator(_, value: string) {
                                        return validateFloatNumber(value)
                                            .then((number) => {
                                                if (number === null) {
                                                    return Promise.reject(
                                                        t(
                                                            'Please enter a valid number!',
                                                        ),
                                                    );
                                                }
                                            })
                                            .catch(() => {
                                                return Promise.reject(
                                                    t(
                                                        'Please enter a valid number!',
                                                    ),
                                                );
                                            });
                                    },
                                },
                            ]}
                        >
                            <Input
                                key={JSON.stringify(tableData)}
                                disabled={!props.editable}
                                suffix={'°C'}
                                bordered={false}
                                onBlur={(e) => {
                                    const value = Number.parseFloat(
                                        e.target.value.replace(',', '.'),
                                    );
                                    record.update = value;
                                    changeUpdate(record);
                                }}
                                defaultValue={
                                    record?.update?.toFixed(1) ?? undefined
                                }
                            />
                        </Form.Item>
                    );
            },
        });
    } else {
        columns.push({
            title: t('Change'),
            dataIndex: 'update',
            render: (_, record: SetPointMap, i) => {
                if (record.update !== null)
                    return (
                        <Form.Item
                            style={{ margin: '0em' }}
                            rules={[
                                {
                                    validator(_, value: string) {
                                        return validateFloatNumber(value)
                                            .then((number) => {
                                                if (number === null) {
                                                    return Promise.reject(
                                                        t(
                                                            'Please enter a valid number!',
                                                        ),
                                                    );
                                                }
                                            })
                                            .catch(() => {
                                                return Promise.reject(
                                                    t(
                                                        'Please enter a valid number!',
                                                    ),
                                                );
                                            });
                                    },
                                },
                            ]}
                        >
                            <Input
                                key={stateId!}
                                disabled={!props.editable}
                                suffix={'°C'}
                                bordered={false}
                                onBlur={(e) => {
                                    const value = Number.parseFloat(
                                        e.target.value.replace(',', '.'),
                                    );
                                    record.update = value;
                                    changeUpdate(record);
                                }}
                                defaultValue={
                                    record?.update?.toFixed(1) ?? undefined
                                }
                            />
                        </Form.Item>
                    );
            },
        });
    }

    const getData = () => {
        const temperatures: DataEntry[] = [];

        const addIfNotExist = (tmp: SetPointMap, key: keyof DataEntry) => {
            if (tmp.fromTemperature > 20) return;

            const temperature = temperatures.find(
                (t) => t.fromTemperature === tmp.fromTemperature,
            );

            let value = NaN;

            if (tmp.toTemperature !== null) {
                if (tmp.update !== undefined && tmp.update !== null) {
                    value = tmp.update;
                }

                if (key === 'setPoints' && calibrated === 'DELTA') {
                    if (Number.isNaN(value)) {
                        value = tmp.measured;
                    } else {
                        value += tmp.measured;
                    }
                }

                if (key !== 'setPoints') {
                    if (Number.isNaN(value) || key === 'prevHeatCurve') {
                        value = tmp.toTemperature;
                    } else {
                        value += tmp.toTemperature;
                    }
                }
            }

            if (temperature !== undefined) {
                temperature[key] = value;
            } else {
                temperatures.push({
                    [key]: value,
                    fromTemperature: tmp.fromTemperature,
                    nature: tmp.nature,
                });
            }
        };
        const desiredFlowTemperature = optimalFlow?.data.filter(
            (temp) => temp.nature && temp.nature === 'DESIRED',
        );
        desiredFlowTemperature?.forEach((tmp) => {
            addIfNotExist(tmp as SetPointMap, 'desiredFlowTemperature');
        });

        const highDesiredFlowTemperature = optimalFlow?.data.filter(
            (temp) =>
                temp.nature && temp.nature === 'DESIRED_EXTRAPOLATED_HIGH',
        );
        if (
            highDesiredFlowTemperature &&
            highDesiredFlowTemperature.length &&
            desiredFlowTemperature &&
            desiredFlowTemperature.length
        ) {
            highDesiredFlowTemperature.unshift(
                desiredFlowTemperature[desiredFlowTemperature.length - 1],
            );
        }

        highDesiredFlowTemperature?.forEach((tmp) => {
            addIfNotExist(tmp as SetPointMap, 'highDesiredFlowTemperature');
        });

        const lowDesiredFlowTemperature = optimalFlow?.data.filter(
            (temp) => temp.nature && temp.nature === 'DESIRED_EXTRAPOLATED_LOW',
        );
        if (
            lowDesiredFlowTemperature &&
            lowDesiredFlowTemperature.length &&
            desiredFlowTemperature &&
            desiredFlowTemperature.length
        ) {
            lowDesiredFlowTemperature.push(desiredFlowTemperature[0]);
        }
        lowDesiredFlowTemperature?.forEach((tmp) => {
            addIfNotExist(tmp as SetPointMap, 'lowDesiredFlowTemperature');
        });

        desiredEffect?.data.forEach((tmp) => {
            addIfNotExist(tmp as SetPointMap, 'desiredEffect');
        });

        measuredEffect?.data.forEach((tmp) => {
            addIfNotExist(tmp as SetPointMap, 'measuredEffect');
        });

        measuredFlowTemperatures?.data.forEach((tmp) => {
            addIfNotExist(tmp as SetPointMap, 'measuredFlowTemperature');
        });

        prevMeasuredFlow?.data.forEach((tmp) => {
            addIfNotExist(tmp as SetPointMap, 'prevMeasuredFlowTemperature');
        });

        prevOptimalFlow?.data.forEach((tmp) => {
            addIfNotExist(tmp as SetPointMap, 'prevOptimalFlowTemperature');
        });

        tableData?.forEach((tmp) => {
            addIfNotExist(tmp, 'setPoints');
        });

        if (tableSource === 'log') {
            tableData?.forEach((tmp) => {
                addIfNotExist(tmp, 'prevHeatCurve');
            });
        } else {
            prevHeatCurve?.forEach((tmp) => {
                addIfNotExist(tmp, 'prevHeatCurve');
            });
        }

        const temps = temperatures.sort(
            (c, p) => c.fromTemperature - p.fromTemperature,
        );
        return temps;
    };

    const data = getData();

    return (
        <div>
            {flowTemperatures && (
                <div>
                    <Container cardTitle={t('Feed temperature')}>
                        <div
                            className="grid gap-x-8 feed"
                            style={{
                                gridTemplateColumns:
                                    mode === 'desktop' &&
                                    params.isCamera === false
                                        ? '1fr 52ch'
                                        : '1fr',
                            }}
                        >
                            <div>
                                <ResponsiveContainer width="100%" height={500}>
                                    <LineChart
                                        data={data}
                                        margin={{
                                            top: mode === 'desktop' ? 10 : 0,
                                            right: mode === 'desktop' ? 20 : 20,
                                            left: mode === 'desktop' ? 20 : 0,
                                            bottom:
                                                mode === 'desktop' ? 10 : 10,
                                        }}
                                    >
                                        <XAxis
                                            unit="°C"
                                            name="Degrees"
                                            dataKey="fromTemperature"
                                            type="number"
                                            domain={['dataMin', 'dataMax']}
                                            interval={0}
                                            tick={
                                                <ConditionalGraphTick
                                                    screen={mode}
                                                />
                                            }
                                            tickMargin={
                                                mode === 'desktop' ? 0 : 8
                                            }
                                            ticks={
                                                mode === 'desktop'
                                                    ? tickGenerator(data, 1)
                                                    : tickGenerator(data, 5)
                                            }
                                        />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        {props.calibrated !== 'DELTA' && (
                                            <YAxis
                                                type="number"
                                                tickCount={5}
                                                orientation="left"
                                                domain={['auto', 'auto']}
                                                allowDecimals={false}
                                                unit="°C"
                                            />
                                        )}
                                        {hasOptimalEffect && (
                                            <YAxis
                                                yAxisId="right"
                                                orientation="right"
                                                allowDataOverflow={true}
                                                unit="kW"
                                                tickCount={10}
                                                domain={['auto', 'auto']}
                                                tickFormatter={(v) =>
                                                    v.toFixed(0)
                                                }
                                                type="number"
                                            />
                                        )}
                                        {props.calibrated !== 'DELTA' && (
                                            <ChartTooltip
                                                labelFormatter={(v) =>
                                                    t(
                                                        'At outdoor temperature',
                                                    ) +
                                                    ' ' +
                                                    v +
                                                    '°C'
                                                }
                                                formatter={(v: number) =>
                                                    Math.round(v * 10) / 10
                                                }
                                            />
                                        )}
                                        {hasOptimalEffect &&
                                            (props.includes.includes(
                                                'measuredEffect',
                                            ) ||
                                                props.includes.length ===
                                                    1) && (
                                                <Line
                                                    connectNulls
                                                    unit="kW"
                                                    name={t('Measured effect')}
                                                    type="monotone"
                                                    yAxisId="right"
                                                    dataKey="measuredEffect"
                                                    strokeWidth={
                                                        mode === 'desktop'
                                                            ? 5
                                                            : 1.5
                                                    }
                                                    activeDot={{ r: 8 }}
                                                    stroke="#faad14"
                                                />
                                            )}
                                        {hasOptimalEffect &&
                                            (props.includes.includes(
                                                'desiredEffect',
                                            ) ||
                                                props.includes.length ===
                                                    1) && (
                                                <Line
                                                    connectNulls
                                                    unit="kW"
                                                    name={t('Optimal effect')}
                                                    type="monotone"
                                                    yAxisId="right"
                                                    dataKey="desiredEffect"
                                                    strokeWidth={
                                                        mode === 'desktop'
                                                            ? 5
                                                            : 1.5
                                                    }
                                                    activeDot={{ r: 8 }}
                                                    stroke="#389e0d"
                                                />
                                            )}
                                        {hasOptimalFlow &&
                                            (props.includes.includes(
                                                'desiredFlowTemperature',
                                            ) ||
                                                props.includes.length ===
                                                    1) && (
                                                <Line
                                                    connectNulls
                                                    unit="°C"
                                                    name={t('Optimal')}
                                                    type="monotone"
                                                    dataKey="desiredFlowTemperature"
                                                    strokeWidth={
                                                        mode === 'desktop'
                                                            ? 5
                                                            : 1.5
                                                    }
                                                    stroke="rgba(130, 202, 157, 1)"
                                                    dot={false}
                                                />
                                            )}
                                        {hasOptimalFlow &&
                                            (props.includes.includes(
                                                'highDesiredFlowTemperature',
                                            ) ||
                                                props.includes.length ===
                                                    1) && (
                                                <Line
                                                    connectNulls
                                                    unit="°C"
                                                    name={t(
                                                        'Extrapolated optimal',
                                                    )}
                                                    type="monotone"
                                                    dataKey="highDesiredFlowTemperature"
                                                    strokeWidth={
                                                        mode === 'desktop'
                                                            ? 5
                                                            : 1.5
                                                    }
                                                    stroke="rgba(130, 202, 157, 0.5)"
                                                    dot={false}
                                                />
                                            )}

                                        {hasOptimalFlow &&
                                            (props.includes.includes(
                                                'lowDesiredFlowTemperature',
                                            ) ||
                                                props.includes.length ===
                                                    1) && (
                                                <Line
                                                    connectNulls
                                                    unit="°C"
                                                    name={t(
                                                        'Extrapolated optimal',
                                                    )}
                                                    type="monotone"
                                                    dataKey="lowDesiredFlowTemperature"
                                                    strokeWidth={
                                                        mode === 'desktop'
                                                            ? 5
                                                            : 1.5
                                                    }
                                                    stroke="rgba(130, 202, 157, 0.5)"
                                                    dot={false}
                                                />
                                            )}
                                        {hasMeasuredFlowTemperatures &&
                                            (props.includes.includes(
                                                'measuredFlowTemperature',
                                            ) ||
                                                props.includes.length ===
                                                    1) && (
                                                <Line
                                                    connectNulls
                                                    strokeWidth={
                                                        mode === 'desktop'
                                                            ? 5
                                                            : 1.5
                                                    }
                                                    unit="°C"
                                                    name={t('Measured')}
                                                    type="monotone"
                                                    dataKey="measuredFlowTemperature"
                                                    stroke="#8884d8"
                                                />
                                            )}
                                        {hasPrevOptimalFlow &&
                                            (props.includes.includes(
                                                'prevOptimalFlowTemperature',
                                            ) ||
                                                props.includes.length ===
                                                    1) && (
                                                <Line
                                                    connectNulls
                                                    strokeWidth={
                                                        mode === 'desktop'
                                                            ? 5
                                                            : 1.5
                                                    }
                                                    unit="°C"
                                                    name={t('Previous optimal')}
                                                    type="monotone"
                                                    dataKey="prevOptimalFlowTemperature"
                                                    stroke="#82ca9d"
                                                    strokeDasharray="5 5"
                                                />
                                            )}
                                        {hasPrevMeasuredFlow &&
                                            (props.includes.includes(
                                                'prevMeasuredFlowTemperature',
                                            ) ||
                                                props.includes.length ===
                                                    1) && (
                                                <Line
                                                    connectNulls
                                                    strokeWidth={
                                                        mode === 'desktop'
                                                            ? 5
                                                            : 1.5
                                                    }
                                                    unit="°C"
                                                    name={t(
                                                        'Previous measured',
                                                    )}
                                                    type="monotone"
                                                    dataKey="prevMeasuredFlowTemperature"
                                                    stroke="#8884d8"
                                                    strokeDasharray="5 5"
                                                />
                                            )}
                                        {tableData &&
                                            (props.includes.includes(
                                                'setPoints',
                                            ) ||
                                                props.includes.length ===
                                                    1) && (
                                                <Line
                                                    connectNulls
                                                    strokeWidth={
                                                        mode === 'desktop'
                                                            ? 5
                                                            : 1.5
                                                    }
                                                    unit="°C"
                                                    name={t('Recommended HC')}
                                                    type="linear"
                                                    dataKey="setPoints"
                                                    stroke="black"
                                                />
                                            )}

                                        {prevHeatCurve &&
                                            (props.includes.includes(
                                                'prevHeatCurve',
                                            ) ||
                                                props.includes.length ===
                                                    1) && (
                                                <Line
                                                    connectNulls
                                                    strokeWidth={
                                                        mode === 'desktop'
                                                            ? 5
                                                            : 1.5
                                                    }
                                                    unit="°C"
                                                    name={t('Current HC')}
                                                    type="linear"
                                                    dataKey="prevHeatCurve"
                                                    stroke="black"
                                                    strokeDasharray="5 5"
                                                />
                                            )}
                                    </LineChart>
                                </ResponsiveContainer>
                                <div>
                                    <Legend
                                        onClick={(name: string) =>
                                            props.onClick(name)
                                        }
                                        includes={props.includes}
                                        data={[
                                            {
                                                isDashed: true,
                                                name: t('Current HC'),
                                                key: 'prevHeatCurve',
                                                color: 'black',
                                            },
                                            {
                                                name: t('Recommended HC'),
                                                key: 'setPoints',
                                                color: 'black',
                                            },
                                            {
                                                name: t('Previous measured'),
                                                key:
                                                    'prevMeasuredFlowTemperature',
                                                color: '#8884d8',
                                            },
                                            {
                                                name: t('Measured'),
                                                key: 'measuredFlowTemperature',
                                                color: '#8884d8',
                                            },
                                            {
                                                name: t('Previous optimal'),
                                                key:
                                                    'prevOptimalFlowTemperature',
                                                color: '#82ca9d',
                                            },
                                            {
                                                name: t('Optimal'),
                                                key: 'desiredFlowTemperature',
                                                color: '#82ca9d',
                                            },
                                        ]}
                                    />
                                </div>
                            </div>

                            {tableData &&
                                !isLoading &&
                                params.isCamera === false && (
                                    <div key={stateId} className="mb-16">
                                        <Table
                                            pagination={false}
                                            direction="ltr"
                                            dataSource={[
                                                ...tableData.sort(
                                                    (a, b) =>
                                                        a.fromTemperature -
                                                        b.fromTemperature,
                                                ),
                                            ]}
                                            columns={columns}
                                        />
                                        {editable && (
                                            <div className="flex items-center gap-4 mx-4 editable">
                                                {calibrated === 'ABSOLUTE' && (
                                                    <Tooltip
                                                        title={t(
                                                            'Reload setpoints',
                                                        )}
                                                    >
                                                        <Button
                                                            onClick={
                                                                refetchRecommendation
                                                            }
                                                            disabled={
                                                                !!(
                                                                    asyncQueueStatus &&
                                                                    asyncQueueStatus.message
                                                                )
                                                            }
                                                        >
                                                            <ReloadOutlined />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                                <Button
                                                    className="m-2 flex-grow"
                                                    onClick={props.setModal}
                                                    type="primary"
                                                    disabled={
                                                        !!(
                                                            asyncQueueStatus &&
                                                            asyncQueueStatus.message
                                                        )
                                                    }
                                                >
                                                    {t('Commmit changes')}
                                                </Button>
                                            </div>
                                        )}
                                        {asyncQueueStatus &&
                                            asyncQueueStatus.message && (
                                                <StateMessageBox
                                                    asycnQeueuStatus={
                                                        asyncQueueStatus
                                                    }
                                                />
                                            )}
                                    </div>
                                )}
                            {isLoading && (
                                <div key={stateId} className="w-full sm:w-1/3">
                                    <Skeleton />
                                </div>
                            )}
                        </div>
                    </Container>
                </div>
            )}
        </div>
    );
}
