import { env } from 'src/utils/env';

export const useForgotPassword = () => {
    const forgotPassword = async (email: string) => {
        try {
            const response = await fetch(env.REACT_APP_LAMBDA_FORGOT_PASSWORD, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ Username: email }),
            });

            if (!response.ok) {
                throw new Error('Failed to send forgot password request');
            }

            return await response.json();
        } catch (error) {
            throw error;
        }
    };

    return [forgotPassword] as const;
};
