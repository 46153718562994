import React from 'react';
import {
    Row,
    Col,
    Button,
    Table,
    Statistic,
    Select,
    Empty,
    Divider,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { EnergyResponse } from 'src/models/EnergyResponse';
import { useEnergyConsumption } from 'src/hooks/sites/useEnergyConsumption';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Period } from 'src/models/EnergyResponse';
import {
    Bar,
    BarChart,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import useViewport from 'src/hooks/generic/useViewport';
import { formatDate } from 'src/utils/formatDate';
import Container from 'src/components/Container';

const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

interface ConsumptionGraphProps {
    consumption: EnergyResponse[];
    reference: Period;
}
interface ConsumptionTableProps {
    consumption: EnergyResponse[];
}

const ConsumptionTable = (props: ConsumptionTableProps) => {
    const { t } = useTranslation();
    const columns = [
        {
            title: t('Period'),
            dataIndex: 'period',
            key: 'period',
        },
        {
            title: t('Consumption'),
            dataIndex: 'consumption',
            key: 'consumption',
        },
        {
            title: t('Reference consumption'),
            dataIndex: 'reference',
            key: 'reference',
        },
        {
            title: t('Saved'),
            dataIndex: 'saved',
            key: 'saved',
        },
    ];

    const data = props.consumption.map((v, i) => {
        const d = new Date(v.period.start);
        const year = d.getFullYear().toString();

        return {
            key: i,
            period: monthNames[d.getMonth()] + ' ' + year,
            consumption: Math.round(v.data.districtHeating) + ' ' + v.unit,
            reference: Math.round(v.reference.districtHeating) + ' ' + v.unit,
            saved:
                v.data.districtHeating !== 0
                    ? Math.round(v.reference.districtHeating) -
                      Math.round(v.data.districtHeating) +
                      ' ' +
                      v.unit
                    : 0 + ' ' + v.unit,
        };
    });

    return (
        <>
            <div className="pt-10">
                <Table
                    scroll={{ x: 400 }}
                    pagination={false}
                    bordered
                    dataSource={data}
                    columns={columns}
                />
            </div>
        </>
    );
};

interface ConsumptionStatsProps {
    consumption: EnergyResponse[];
    reference: Period;
}

const Stats = (props: ConsumptionStatsProps) => {
    const { mode } = useViewport();
    const { t } = useTranslation();

    let saved = 0;
    let consumed = 0;
    let reference = 0;

    props.consumption.forEach((v, i) => {
        if (v.data.total !== 0) {
            consumed += v.data.total;
            reference += v.reference.total;
            saved += v.reference.total - v.data.total;
        }
    });

    const unit = props.consumption[0].unit;
    const savedRounded = Math.round(saved);
    const quota = reference === 0 ? 0 : 1 - consumed / reference;

    return (
        <div>
            <Row justify="center" gutter={[20, 20]}>
                <Col md={24} xs={24}>
                    <Statistic
                        groupSeparator=" "
                        title={t('Total savings')}
                        value={savedRounded}
                        precision={0}
                        valueStyle={{
                            color: savedRounded > 0 ? '#51baa2' : '#889DA7',
                            fontSize: mode === 'desktop' ? '18px' : '18px',
                        }}
                        prefix={
                            savedRounded > 0 ? (
                                <ArrowDownOutlined
                                    style={{ verticalAlign: 'middle' }}
                                />
                            ) : (
                                <ArrowUpOutlined
                                    style={{ verticalAlign: 'middle' }}
                                />
                            )
                        }
                        suffix={unit}
                    />
                </Col>
                <Col md={24} xs={24}>
                    <Statistic
                        groupSeparator=" "
                        title={t('CO-2 savings')}
                        value={0.044 * savedRounded}
                        precision={0}
                        valueStyle={{
                            color: savedRounded > 0 ? '#51baa2' : '#889DA7',
                            fontSize: mode === 'desktop' ? '18px' : '18px',
                        }}
                        prefix={
                            savedRounded > 0 ? (
                                <ArrowDownOutlined
                                    style={{ verticalAlign: 'middle' }}
                                />
                            ) : (
                                <ArrowUpOutlined
                                    style={{ verticalAlign: 'middle' }}
                                />
                            )
                        }
                        suffix="kg"
                    />
                </Col>
                <Col md={24} xs={24}>
                    <Statistic
                        groupSeparator=" "
                        title={t('Percentage saving')}
                        value={quota * 100}
                        precision={2}
                        valueStyle={{
                            color: quota > 0 ? '#51baa2' : '#889DA7',
                            fontSize: mode === 'desktop' ? '18px' : '18px',
                        }}
                        prefix={
                            savedRounded > 0 ? (
                                <ArrowDownOutlined
                                    style={{ verticalAlign: 'middle' }}
                                />
                            ) : (
                                <ArrowUpOutlined
                                    style={{ verticalAlign: 'middle' }}
                                />
                            )
                        }
                        suffix="%"
                    />
                </Col>
            </Row>
        </div>
    );
};

const ConsumptionGraph = React.memo((props: ConsumptionGraphProps) => {
    const { t } = useTranslation();
    const data: any[] = [];
    const { mode: screen } = useViewport();

    props.consumption.forEach((v, i) => {
        const d = new Date(v.period.start);
        const year = d.getFullYear().toString();
        data.push({
            period: monthNames[d.getMonth()] + ' ' + year,
            districtHeating: v.data.districtHeating,
            referenceHeating: v.reference.districtHeating,
            electricData: v.data.electricity,
            electricRefData: v.reference.electricity,
        });
    });

    const unit = props.consumption[0].unit;

    const isElectricDataPresent = data.some((item) => !!item.electricData);
    const isDistrictHeatingData = data.some((item) => !!item.districtHeating);
    const isElectricRefData = data.some((item) => !!item.electricRefData);
    const isReferenceHeating = data.some((item) => !!item.referenceHeating);

    return (
        <>
            <div className="mt-3 ">
                <ResponsiveContainer
                    height={screen === 'desktop' ? 400 : 350}
                    width="100%"
                >
                    <BarChart
                        margin={{
                            left: screen === 'desktop' ? 45 : 0,
                            bottom: screen === 'desktop' ? 20 : 40,
                            top: 20,
                        }}
                        data={data}
                    >
                        <XAxis tickMargin={20} dataKey="period" angle={-45} />

                        <Legend
                            wrapperStyle={{
                                position: 'relative',
                                marginTop: '25px',
                            }}
                            layout="horizontal"
                            verticalAlign="bottom"
                            align="center"
                        />
                        <Tooltip />
                        {isReferenceHeating && (
                            <Bar
                                name={t('Reference heating')}
                                unit={unit}
                                animationBegin={500}
                                animationDuration={1000}
                                dataKey="referenceHeating"
                                fill="#889da7"
                                stackId={'stack2'}
                            />
                        )}
                        {isElectricRefData && (
                            <Bar
                                name={t('Reference electricity')}
                                unit={unit}
                                animationBegin={1500}
                                animationDuration={1000}
                                dataKey="electricRefData"
                                stackId="stack2"
                                fill="#375a6b"
                            />
                        )}
                        {isDistrictHeatingData && (
                            <Bar
                                name={t('District heating')}
                                unit={unit}
                                animationBegin={1500}
                                animationDuration={1000}
                                dataKey="districtHeating"
                                stackId="stack"
                                fill="#51baa2"
                            />
                        )}
                        {isElectricDataPresent && (
                            <Bar
                                name={t('Electricity')}
                                unit={unit}
                                animationBegin={500}
                                animationDuration={1000}
                                dataKey="electricData"
                                stackId="stack"
                                fill="#51bab3"
                            />
                        )}
                        <YAxis
                            style={{ margin: 100 }}
                            width={20}
                            orientation={
                                screen === 'desktop' ? 'left' : 'right'
                            }
                            mirror={screen === 'desktop' ? false : true}
                            tickFormatter={(v: number) => v + ' ' + unit}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </>
    );
});

interface Props {
    period: { start: string; stop: string };
    onPeriodChange: (period: { start: string; stop: string }) => void;
    table: boolean;
    reference: Period;
    showTable: (display: boolean) => void;
    ratio?: number;
    savings?: number;
    objectId?: number;
}

function AnalyticsView(props: Props) {
    const {
        period,
        onPeriodChange,
        table,
        reference,
        showTable,
        objectId,
    } = props;

    const { data } = useEnergyConsumption(objectId!, period.start, period.stop);

    const { t } = useTranslation();
    if (reference === undefined) {
        return <Empty description={t('No data')} />;
    }
    const startDate = new Date(period.start);
    const stopDate = new Date(period.stop);
    const referenceYear = new Date(props.reference.start);
    const { Option } = Select;
    const deltaMonths = Math.round(
        (stopDate.getTime() - startDate.getTime()) / (30 * 3600 * 1000 * 24),
    );

    const validYears = new Array(
        new Date().getFullYear() - referenceYear.getFullYear() + 1,
    )
        .fill(0)
        .map((_, i) => referenceYear.getFullYear() + i);

    return (
        <div>
            <Row gutter={[20, 20]}>
                <Col md={16}>
                    <Container
                        mobileClassName="w-screen h-full"
                        className="h-full"
                        cardTitle={t('Energy consumption')}
                    >
                        <Row gutter={[20, 10]}>
                            <Col xs={24} md={8}>
                                <Select
                                    value={deltaMonths}
                                    placeholder={t('Select months')}
                                    className="w-full"
                                    onChange={(monthSpan: number) => {
                                        const newStopDate = new Date(startDate);
                                        newStopDate.setMonth(
                                            newStopDate.getMonth() + monthSpan,
                                        );
                                        onPeriodChange({
                                            start: formatDate(startDate),
                                            stop: formatDate(newStopDate),
                                        });
                                    }}
                                >
                                    <Option key={'12 months'} value={12}>
                                        {t('12 months')}
                                    </Option>
                                    <Option key={'9 months'} value={9}>
                                        {t('9 months')}
                                    </Option>
                                    <Option key={'6 months'} value={6}>
                                        {t('6 months')}
                                    </Option>
                                    <Option key={'3 months'} value={3}>
                                        {t('3 months')}
                                    </Option>
                                </Select>
                            </Col>
                            <Col xs={24} md={8}>
                                <Select
                                    value={startDate.getFullYear()}
                                    placeholder={t('Select year')}
                                    className="w-full"
                                    onChange={(year: number) => {
                                        const newStart = new Date(0);
                                        const newStop = new Date(0);
                                        newStart.setFullYear(year);
                                        newStop.setFullYear(year + 1);
                                        onPeriodChange({
                                            start: formatDate(newStart),
                                            stop: formatDate(newStop),
                                        });
                                    }}
                                >
                                    {validYears.map((v) => {
                                        return (
                                            <Option key={v} value={v}>
                                                {v}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Col>
                            <Col md={8} xs={24}>
                                <Button
                                    block
                                    type="primary"
                                    className="float-right"
                                    onClick={() => showTable(!props.table)}
                                >
                                    {props.table
                                        ? t('Show graph')
                                        : t('Show table')}
                                </Button>
                            </Col>
                        </Row>
                        <Divider />

                        {!table && data && (
                            <ConsumptionGraph
                                reference={reference}
                                consumption={data}
                            />
                        )}

                        {table && data && (
                            <ConsumptionTable consumption={data} />
                        )}
                    </Container>
                </Col>
                <Col md={8}>
                    <Container padding cardTitle={t('Performance')}>
                        {data && (
                            <Stats
                                consumption={data}
                                reference={props.reference}
                            />
                        )}
                    </Container>
                </Col>
            </Row>
        </div>
    );
}

export default React.memo(AnalyticsView);
