import {
    CheckCircleFilled,
    CloseCircleFilled,
    SettingFilled,
} from '@ant-design/icons';
import { Col, Input, Row, Popover, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useViewport from 'src/hooks/generic/useViewport';
import { useMe } from 'src/hooks/users/useMe';
import {
    Corporation,
    CorporationRights,
    CorporationsUsers,
} from 'src/models/Corporation';
import { User } from 'src/models/User';
import { UsersList } from '../settings/SettingsListView2';
import AddAccessDialog from './AddAccessDialog';

interface Props {
    users: CorporationsUsers;
    allUsers: User[];
    currentUser: User;
    corporation: Corporation;
}

function EditCorporationAccess(props: Props) {
    const { users, allUsers, corporation } = props;
    const { t } = useTranslation();
    const [listUsers, setListUsers] = useState(allUsers);
    const [selectedUserId, setSelectedUserId] = useState<number>();
    const [addAccessDialog, setAddAccessDialog] = useState(false);
    const { mode } = useViewport();
    const { data: me } = useMe();

    const buildAccessText = (rights?: CorporationRights[]) => {
        if (!rights) {
            return (
                <Typography.Text>
                    {t('User has no access to corporation')}
                </Typography.Text>
            );
        }

        const getText = (right: CorporationRights) => {
            if (right === CorporationRights.ALLOW_CHANGE_SETTINGS)
                return t('Allowed to change setting');

            if (right === CorporationRights.DELETE_CORPORATION)
                return t('Allowed to delete corporation');

            if (right === CorporationRights.ALLOW_CREATE_SITE)
                return t('Allowed to create site');

            if (right === CorporationRights.DELETE_SITE)
                return t('Allowed to delete site');

            if (right === CorporationRights.GET_CORPORATION)
                return t('Allowed to get site');
        };

        return (
            <>
                <Typography.Title level={4} type="secondary">
                    {t('User is allowed to')}
                </Typography.Title>

                {rights.map((v) => (
                    <>
                        {(v as CorporationRights) !==
                            CorporationRights.GET_CORPORATION &&
                            (v as CorporationRights) !==
                                CorporationRights.DELETE_CORPORATION && (
                                <>
                                    <Typography.Text>
                                        <CheckCircleFilled
                                            style={{
                                                color: 'green',
                                                verticalAlign: 'middle',
                                            }}
                                            color="green"
                                        />

                                        {getText(v)}
                                    </Typography.Text>
                                    <br />
                                </>
                            )}
                    </>
                ))}

                {Object.values(CorporationRights)
                    .filter((right) => !rights.includes(right))
                    .map((v) => (
                        <>
                            {(v as CorporationRights) !==
                                CorporationRights.GET_CORPORATION &&
                                (v as CorporationRights) !==
                                    CorporationRights.DELETE_CORPORATION && (
                                    <>
                                        <Typography.Text>
                                            <CloseCircleFilled
                                                style={{
                                                    color: 'red',
                                                    verticalAlign: 'middle',
                                                }}
                                                color="red"
                                            />
                                            {'  '}
                                            {getText(v as CorporationRights)}
                                        </Typography.Text>
                                        <br />
                                    </>
                                )}
                        </>
                    ))}
            </>
        );
    };

    return (
        <>
            <AddAccessDialog
                corporationId={corporation.id}
                userId={selectedUserId!}
                onCancel={() => setAddAccessDialog(false)}
                onFinish={() => setAddAccessDialog(false)}
                visible={addAccessDialog}
            />
            <Row gutter={[100, 30]}>
                <Col xs={24} md={12}>
                    <Input.Search
                        enterButton
                        placeholder={t('Name or email')}
                        onChange={(e) => {
                            const term = e.target.value;
                            if (term.length === 0) {
                                setListUsers(allUsers);
                            } else {
                                setListUsers(
                                    allUsers.filter(
                                        (v) =>
                                            v.name
                                                .toLowerCase()
                                                .includes(term.toLowerCase()) ||
                                            v.email
                                                .toLowerCase()
                                                .includes(term.toLowerCase()),
                                    ),
                                );
                            }
                        }}
                    />
                </Col>
                <Col span={24}>
                    <UsersList
                        users={listUsers}
                        action={(user) => {
                            const access = users.access[user.id.toString()];
                            return mode === 'desktop' ? (
                                <Popover
                                    placement="left"
                                    content={buildAccessText(access)}
                                >
                                    {user.email !== me?.user.email && (
                                        <SettingFilled
                                            onClick={() => {
                                                setSelectedUserId(user.id);
                                                setAddAccessDialog(true);
                                            }}
                                            className="text-xl float-right"
                                        />
                                    )}
                                </Popover>
                            ) : (
                                <>
                                    {user.email !== me?.user.email && (
                                        <SettingFilled
                                            onClick={() => {
                                                setSelectedUserId(user.id);
                                                setAddAccessDialog(true);
                                            }}
                                            className="text-xl float-right"
                                        />
                                    )}
                                </>
                            );
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}

export default EditCorporationAccess;
